import { gql, useMutation } from "@apollo/client";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { IconPicker } from "../components/IconPicker";
import { TextRenderer, TripleElementRenderer } from "../components/renderers";
import {
  CardProps,
  GetOriginalValueProps,
  InclusionData,
  InclusionItemData,
} from "../../types";
import s from "../Card.module.css";
import { EditStatus, FieldName } from "../../../../../../types";

type InclusionCardProps = Partial<CardProps> & {
  planId: string;
  fieldId: string;
  fieldName: FieldName;
  title: string;
  description: string;
  iconName: string;
  editStatus: EditStatus;
  inclusions: InclusionData[];
  setInclusions: (inclusions: InclusionData[]) => void;
};

const InclusionCard = forwardRef((props: InclusionCardProps, ref) => {
  const [title, setTitle] = useState(props.title);
  const [description, setDescription] = useState(props.description);
  const [iconName, setIconName] = useState(props.iconName);

  const [mutation] = useMutation<EditInclusionResponse, EditInclusionInput>(
    EDIT_INCLUSIONS_MUTATION
  );

  const persistChanges = async () => {
    const inclusion = {
      id: props.fieldId,
      title,
      description,
      iconName,
    };

    const categoryIndex = props.inclusions.findIndex(
      (inclusion: InclusionData) =>
        inclusion.category === inclusionCategories[props.fieldName]
    );

    const itemIndex =
      categoryIndex === -1
        ? -1
        : props.inclusions[categoryIndex].items.findIndex(
            (item: InclusionItemData) => item.id === props.fieldId
          );

    const inclusions = JSON.parse(JSON.stringify(props.inclusions));

    switch (props.editStatus) {
      case EditStatus.ADDED:
        if (categoryIndex !== -1) {
          inclusions[categoryIndex].items.push(inclusion);
        } else
          inclusions.push({
            category: inclusionCategories[props.fieldName],
            items: [inclusion],
          });
        break;
      case EditStatus.TOUCHED:
        inclusions[categoryIndex].items[itemIndex] = inclusion;
        break;
      case EditStatus.REMOVED:
        inclusions[categoryIndex].items.splice(itemIndex, 1);
        if (inclusions[categoryIndex].items.length === 0)
          // the category is now empty, so remove it
          inclusions.splice(categoryIndex, 1);
        break;
    }

    props.setInclusions(inclusions);

    const input: EditInclusionInput["input"] = {
      id: props.planId,
      inclusions,
    };

    return await mutation({ variables: { input } });
  };

  const getNewValue = () => ({ title, description, iconName });

  useImperativeHandle(ref, () => ({
    persistChanges,
    getNewValue,
  }));

  const iconPicker = (
    <div className={s.IconPicker}>
      <IconPicker
        iconName={iconName}
        setIconName={setIconName}
        disabled={!props.mutable}
      />
    </div>
  );

  return (
    <TripleElementRenderer
      sideElement={iconPicker}
      topElement={
        <TextRenderer
          text={title}
          setText={setTitle}
          label="title"
          mutable={!!props.mutable}
        />
      }
      bottomElement={
        <TextRenderer
          text={description}
          setText={setDescription}
          label="description"
          mutable={!!props.mutable}
        />
      }
    />
  );
});

const getOriginalValue = ({
  data,
  fieldId,
  fieldName,
}: GetOriginalValueProps) => {
  return data
    .inclusions!.find(
      (inclusion: InclusionData) =>
        inclusion.category === inclusionCategories[fieldName]
    )
    ?.items.find((item: InclusionItemData) => item.id === fieldId);
};

const inclusionCard = {
  Content: InclusionCard,
  getOriginalValue,
};
export default inclusionCard;

const inclusionCategories = {
  [FieldName.EXPERIENCEINCLUSIONS]: "体験・移動",
  [FieldName.GIFTINCLUSIONS]: "特典ギフト",
  [FieldName.MEALINCLUSIONS]: "お食事",
  [FieldName.NOTINCLUDEDINCLUSIONS]: "含まれないもの",
  [FieldName.SEATINCLUSIONS]: "お席",
} as { [K in FieldName]?: string };

const EDIT_INCLUSIONS_MUTATION = gql`
  mutation EditInclusionsMutation($input: EditPlanBasicInfoInput!) {
    editPlanBasicInfo(input: $input) {
      plan {
        id
        inclusions {
          category
          items {
            id
            title
            description
            iconName
          }
        }
      }
    }
  }
`;

type EditInclusionResponse = {
  plan: {
    id: string;
  };
};

type EditInclusionInput = {
  input: {
    id: string;
    inclusions: {
      category: string;
      items: {
        title: string;
        description?: string;
        iconName: string;
      }[];
    }[];
  };
};
