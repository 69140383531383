import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNotifications } from "../../../components/Notification";

export default function DeleteCompanyDialog({
  companyId,
  handleClose,
  refetch,
}: DeleteCompanyDialogProps) {
  const [deleteCompanyMutation] = useMutation<
    DeleteCompanyMutationResponse,
    DeleteCompanyMutationInput
  >(DELETE_COMPANY_MUTATION);
  const { showNotification } = useNotifications();

  const deleteCompany = (shouldDeleteVenues?: boolean) =>
    deleteCompanyMutation({
      variables: {
        input: {
          companyId: companyId!,
          deleteVenues: shouldDeleteVenues,
        },
      },
    })
      .then((res) => {
        res.data?.deleteCompany.success
          ? showNotification({
              message: `company ${
                shouldDeleteVenues ? "and venues " : ""
              }deleted`,
              severity: "success",
            })
          : showNotification({
              message: res.data?.deleteCompany.error!,
              severity: "error",
            });
      })
      .then(() => refetch());

  return (
    <Dialog open={companyId !== null} onClose={handleClose}>
      <DialogTitle>{"Delete company?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you wish to delete this company? <br />
          Should the venues of the company be deleted as well?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            deleteCompany();
            handleClose();
          }}
        >
          Delete Company
        </Button>
        <Button
          onClick={() => {
            deleteCompany(true);
            handleClose();
          }}
        >
          Delete Company and Venues
        </Button>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DELETE_COMPANY_MUTATION = gql`
  mutation DeleteCompanyMutation($input: DeleteCompanyInput!) {
    deleteCompany(input: $input) {
      success
      error
    }
  }
`;

type DeleteCompanyMutationInput = {
  input: {
    companyId: string;
    deleteVenues?: boolean;
  };
};

type DeleteCompanyMutationResponse = {
  deleteCompany: {
    success: boolean;
    error?: string;
  };
};

interface DeleteCompanyDialogProps {
  handleClose: () => void;
  companyId: string | null;
  refetch: () => void;
}
