import { Card, Grid, IconButton } from "@mui/material";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { CloudinaryImage } from "../Cloudinary";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ImageGallery(props: {
  index: number;
  src: string;
  allowDnd?: boolean;
  removeFunction: (src: string) => void;
  moveUpFunction?: () => void;
  moveDownFunction?: () => void;
  moveFunction: (sourceImg: string, targetImg: string) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<ImageType>({
    accept: "Card",
    hover(item, _monitor) {
      if (item.src === props.src) return;
      props.moveFunction(item.src, props.src);
    },
  });

  const [, drag] = useDrag({
    type: "Card",
    item: props,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (props.allowDnd !== false) {
    drag(drop(ref));
  }

  return (
    <Card ref={ref}>
      <Grid style={{ padding: "10px" }} container>
        <Grid item xs={12}>
          <CloudinaryImage
            style={{ width: "100%" }}
            src={props.src}
            transformations={["w_400"]}
          />
        </Grid>
        <Grid item xs={6}>
          {props.moveUpFunction && (
            <IconButton size="small" onClick={props.moveUpFunction}>
              <ArrowLeftIcon />
            </IconButton>
          )}
          {props.moveDownFunction && (
            <IconButton size="small" onClick={props.moveDownFunction}>
              <ArrowRightIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <IconButton
            size="small"
            onClick={() => {
              props.removeFunction(props.src);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
}

interface ImageType {
  index: number;
  src: string;
}
