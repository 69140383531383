import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { IconButton } from "@mui/material";
import queryString from "query-string";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../components/Button2";
import DataTable from "../../../components/DataTable";
import { BackofficeUser } from "../../../types";
import useStyles from "./styles";
import DeleteUserDialog from "../DeleteUserDialog";

export default function UsersList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const classes = useStyles();
  const [willDeleteUser, setWillDeleteUser] = useState<
    BackofficeUser | undefined
  >(undefined);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );

  const updateUrl = useCallback(
    ({ page }: { page: number }) => {
      const urlParams = { page: page + 1 };
      navigate({
        search: queryString.stringify(urlParams, {
          skipNull: true,
          skipEmptyString: true,
          sort: false,
        }),
      });
    },
    [navigate]
  );

  useEffect(() => updateUrl({ page }), [page, navigate, updateUrl]);

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_USERS, {
    variables: { offset: 0, limit: pageSize },
    notifyOnNetworkStatusChange: true,
  });

  if (error) return <>Error! {error.message}</>;
  const rows: [BackofficeUser] | null = data ? data.users.records : null;

  const columns = [
    { name: "", render: renderEdit },
    { name: "Username", field: "username" },
    { name: "Last Logged In", field: "lastLoggedInAt" },
    { name: "Created At", field: "createdAt" },
    { name: "", render: renderDeleteButton },
  ];

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        topBarExtras={renderTopBarExtras()}
        pagination={{
          totalRows: data ? data.users.totalRecords : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
      {willDeleteUser && (
        <DeleteUserDialog
          user={willDeleteUser}
          open={!!willDeleteUser}
          onConfirm={() => {
            setWillDeleteUser(undefined);
            refetch();
          }}
          onClose={() => {
            setWillDeleteUser(undefined);
          }}
        />
      )}
    </>
  );

  function renderDeleteButton(row: BackofficeUser) {
    return (
      <IconButton
        className={classes.deleteButton}
        onClick={() => setWillDeleteUser(row)}
      >
        <DeleteIcon />
      </IconButton>
    );
  }
}

function renderTopBarExtras() {
  return (
    <Button
      style={{ backgroundColor: "white", color: "#c8a063" }}
      variant="contained"
      component={Link}
      to="/users/new"
      startIcon={<AddIcon />}
    >
      New
    </Button>
  );
}

function renderEdit(row: BackofficeUser) {
  return (
    <Button size="small" component={Link} to={`/users/${row.id}`}>
      <EditIcon />
    </Button>
  );
}

const GET_USERS = gql`
  query UsersListQuery($offset: Int!, $limit: Int!) {
    users(offset: $offset, limit: $limit) {
      records {
        id
        username
        lastLoggedInAt
        createdAt
        backofficeUserRoles {
          roleId
        }
      }
      totalRecords
    }
  }
`;
