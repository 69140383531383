import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import queryString from "query-string";

import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button2";
import { IconButton } from "@mui/material";
import DeleteVenueDialog from "./Dialogs/DeleteVenue";

export default function VenuesList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );
  const [venueToBeRemoved, setVenueToBeRemoved] = useState<Venue | null>(null);

  useEffect(updateUrl, [page, search, navigate]);

  const { data, loading, error, fetchMore } = useQuery(VENUES_QUERY, {
    variables: { offset: 0, limit: 10, search: search },
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEditVenue },
    { name: "ID", field: "id" },
    { name: "Name", field: "name" },
    { name: "Plans", render: renderPlans },
    { name: ".", render: renderDeleteVenue },
  ];
  const rows = data ? data.venues.records : undefined;

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.venues.total : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
        topBarExtras={renderTopBarExtras()}
      />
      <DeleteVenueDialog
        open={venueToBeRemoved !== null}
        onClose={() => setVenueToBeRemoved(null)}
        venue={venueToBeRemoved}
      />
    </>
  );

  function updateUrl() {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderTopBarExtras() {
    return (
      <Button
        style={{ backgroundColor: "white", color: "#c8a063" }}
        variant="contained"
        component={Link}
        to="/venues/new"
        startIcon={<AddIcon />}
      >
        New
      </Button>
    );
  }

  function renderEditVenue(venue: Venue) {
    return (
      <Button variant="contained" component={Link} to={`/venues/${venue.id}`}>
        <EditIcon />
      </Button>
    );
  }

  function renderPlans(venue: Venue) {
    return <>{venue.plans.length}</>;
  }

  function renderDeleteVenue(venue: Venue) {
    return (
      <IconButton onClick={() => setVenueToBeRemoved(venue)}>
        <DeleteIcon />
      </IconButton>
    );
  }
}

const VENUES_QUERY = gql`
  query VenuesQuery($offset: Int!, $limit: Int!, $search: String) {
    venues(offset: $offset, limit: $limit, search: $search) {
      total
      records {
        id
        name
        plans {
          id
          name
        }
        publishedPlans {
          id
          name
        }
      }
    }
  }
`;

export interface Venue {
  id: string;
  name: string;
  plans: Array<{
    id: string;
    name: string;
  }>;
  publishedPlans: Array<{
    id: string;
    name: string;
  }>;
}
