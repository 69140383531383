import { DocumentNode, OperationVariables } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { Button } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNotifications } from "../../../components/Notification";

export function DownloadPdf({
  venue,
  date,
  pdfType,
  query,
  variables,
  buttonProps,
  children,
}: React.PropsWithChildren<DownloadPdfProps>) {
  const { showNotification } = useNotifications();

  const fileName = useMemo(
    () =>
      `【Annyお祝い体験】${pdfType}-${venue.name}-${date.getFullYear()}年${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}月ご利用分`,
    [date, pdfType, venue.name]
  );

  const apolloClient = useApolloClient();

  const getPdfData = useCallback(async () => {
    const res = await apolloClient.query<GetPdfResponse>({
      query,
      variables,
      // always generate new pdf so operators can instantly confirm changes they made
      fetchPolicy: "no-cache",
    });

    const { pdfData, error } = res.data[Object.keys(res.data)[0]];

    if (error) {
      showNotification({
        message: error,
        severity: "error",
      });
    } else {
      return pdfData;
    }
  }, [apolloClient, showNotification, query, variables]);

  return (
    <Button
      variant="outlined"
      onClick={async () => {
        const invoiceData = await getPdfData();
        if (!invoiceData) return;
        const data = Uint8Array.from(atob(invoiceData), (c) => c.charCodeAt(0));
        const blob = new Blob([data], {
          type: "application/pdf",
        });
        exportBlob(blob, `${fileName}.pdf`);
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

type DownloadPdfProps = {
  venue: {
    id: string;
    name: string;
    company?: {
      id: string;
      bankAccount?: {
        id: string;
      };
    };
  };
  date: Date;
  pdfType: string;
  query: DocumentNode;
  variables: OperationVariables;
  buttonProps?: {
    [x: string]: any;
  };
};

type GetPdfResponse = {
  [key: string]: {
    pdfData?: string;
    error?: string;
  };
};

const exportBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};
