import { gql, useMutation } from "@apollo/client";
import {
  Autocomplete,
  Card,
  CardContent,
  TextField as MuiTextField,
  Grid,
  TextFieldProps,
} from "@mui/material";
import { useState, useEffect } from "react";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { BackofficeUser, Partner } from "../../../types";
import UserRoleCheckbox from "../components/UserRoleCheckbox";
import { compareArrays } from "../../../helpers/compare-arrays";

interface EditBackofficeUserFormProps {
  user: BackofficeUser;
  refetch: () => void;
  partnerOptions: { id: string; label: string }[];
}

export default function EditBackofficeUserForm({
  user,
  partnerOptions,
  refetch,
}: EditBackofficeUserFormProps) {
  const { showNotification } = useNotifications();

  const [username, setUsername] = useState(user.username);
  const [usersEditable, setUsersEditable] = useState(user.usersEditable);
  const [roleSelected, setRoleSelected] = useState<String[]>([]);
  const [partner, setPartner] = useState(
    user.partner ? { id: user.partner.id, label: user.partner.name } : null
  );
  const [editBackofficeUser] = useMutation<EditUserResponse, EditUserInput>(
    EDIT_USER
  );

  useEffect(() => {
    if (user && Array.isArray(user.backofficeUserRoles)) {
      const roles = user.backofficeUserRoles.map((role) => role.roleId);
      setRoleSelected(roles);
    }
  }, [user]);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Id" value={user.id} disabled={true} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Created At"
                value={user.createdAt}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last Updated At"
                value={user.lastUpdatedAt || ""}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Last Logged In At"
                value={user.lastLoggedInAt || ""}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Username"
                value={username}
                onChange={(e: any) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={partner}
                onChange={(_: unknown, newValue) => {
                  setPartner(newValue);
                }}
                options={partnerOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Partner profile" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <UserRoleCheckbox
                setRoleSelected={setRoleSelected}
                roleSelected={roleSelected}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleEditBackofficeUser}>Save</Button>
      </div>
    </>
  );

  function handleEditBackofficeUser() {
    const isChangeRole = !compareArrays(
      roleSelected,
      user.backofficeUserRoles.map((role) => role.roleId)
    );
    if (roleSelected.length === 0)
      return showNotification({
        message: "Please select a role",
        severity: "error",
      });
    editBackofficeUser({
      variables: {
        id: user.id,
        username,
        usersEditable,
        partnerId: partner?.id,
        ...(isChangeRole && { backofficeUserRoles: roleSelected }),
      },
    })
      .then(() => {
        refetch();
        showNotification({
          message: "BackofficeUser successfully edited",
          severity: "success",
        });
      })
      .catch((error) => {
        showNotification({
          message: `Failed to save user ${error}`,
          severity: "error",
        });
      });
  }
}

function TextField(props: TextFieldProps) {
  return <MuiTextField {...props} fullWidth style={{ marginTop: "0px" }} />;
}

type EditUserInput = Pick<
  BackofficeUser,
  "id" | "username" | "usersEditable"
> & { partnerId?: Partner["id"]; backofficeUserRoles?: String[] };
type EditUserResponse = { user: Pick<BackofficeUser, "id"> };
const EDIT_USER = gql`
  mutation EditBackofficeUserMutation(
    $id: ID!
    $username: String
    $usersEditable: Boolean
    $partnerId: ID
    $backofficeUserRoles: [String]
  ) {
    editUser(
      input: {
        id: $id
        username: $username
        usersEditable: $usersEditable
        partnerId: $partnerId
        backofficeUserRoles: $backofficeUserRoles
      }
    ) {
      user {
        id
      }
    }
  }
`;
