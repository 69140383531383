import { useState } from "react";
import Button from "@mui/material/Button";

import { ActivityReservationType, DialogName } from "./types";
import { PaymentStatusColor } from "../../helpers";
import RefundDialog from "./Dialogs/Refund";
import CancelWithRefundDialog from "./Dialogs/CancelWithRefund";
import RefundWithoutCancellation from "./Dialogs/RefundWithoutCancellation";
import { Booking } from "../../../../types";
import CancelReservationDialog from "./Dialogs/CancelReservation";
import CancelDialog from "./Dialogs/Cancel";

function RefundPaymentButton(props: {
  reservations: ActivityReservationType[];
  booking: Booking;
}) {
  const { id, status, paymentStatus, paymentIntent } = props.booking;
  const [openDialog, setOpenDialog] = useState<null | DialogName>(null);

  if (props.booking.status === "REQUESTED") return null;

  return (
    <>
      <Button
        style={{
          backgroundColor: "white",
          color: PaymentStatusColor["REFUNDED"],
        }}
        variant="contained"
        color="primary"
        onClick={() => {
          if (status === "CANCELLED") {
            setOpenDialog("REFUND_WITHOUT_CANCELLATION");
          } else {
            setOpenDialog("REFUND");
          }
        }}
      >
        Refund Payment
      </Button>
      <RefundDialog
        open={openDialog === "REFUND"}
        setOpenDialog={setOpenDialog}
        closeDialog={closeDialog}
        reservationStatus={props.reservations[0].status}
      />
      <CancelWithRefundDialog
        bookingId={id}
        open={openDialog === "CANCEL_WITH_REFUND"}
        closeDialog={closeDialog}
        setOpenDialog={setOpenDialog}
        reservation={props.reservations[0]}
      />
      <RefundWithoutCancellation
        bookingId={id}
        open={openDialog === "REFUND_WITHOUT_CANCELLATION"}
        closeDialog={closeDialog}
      />
      <CancelReservationDialog
        reservation={props.reservations[0]}
        open={openDialog === "CANCEL_RESERVATION"}
        closeDialog={closeDialog}
        paymentMethod={paymentIntent?.method}
        paymentStatus={paymentStatus}
      />
      <CancelDialog
        open={openDialog === "CANCEL"}
        setOpenDialog={setOpenDialog}
        closeDialog={closeDialog}
      />
    </>
  );

  function closeDialog() {
    setOpenDialog(null);
  }
}

export default RefundPaymentButton;
