import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { default as TF } from "../../../components/TextField";
import { useNavigate } from "react-router-dom";
import UserRoleCheckbox from "../components/UserRoleCheckbox";

export default function AddNewBackofficeUser() {
  const { showNotification } = useNotifications();
  const [username, setUsername] = useState("");
  const [usersEditable, setUsersEditable] = useState(false);
  const [roleSelected, setRoleSelected] = useState<String[]>([]);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [addBackofficeUser] = useMutation(ADD_USER);
  const navigate = useNavigate();

  return (
    <>
      <div>
        <Button style={{ marginBottom: "15px" }} component={Link} to={`/users`}>
          Back to list
        </Button>
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={username}
                onChange={(e: any) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="password"
                label="Password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="password"
                label="Repeat Password"
                value={repeatPassword}
                onChange={(e: any) => setRepeatPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <UserRoleCheckbox
                setRoleSelected={setRoleSelected}
                roleSelected={roleSelected}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleAddNewBackofficeUser}>
          Add New BackofficeUser
        </Button>
      </div>
    </>
  );

  function handleAddNewBackofficeUser() {
    if (!username)
      return showNotification({
        message: "Please input a name",
        severity: "error",
      });

    if (!password)
      return showNotification({
        message: "Please input an password",
        severity: "error",
      });

    if (password !== repeatPassword)
      return showNotification({
        message: "Passwords are not the same",
        severity: "error",
      });

    if (roleSelected.length === 0)
      return showNotification({
        message: "Please select a role",
        severity: "error",
      });

    addBackofficeUser({
      variables: { username, usersEditable, password, roleSelected },
    })
      .then((result) => {
        showNotification({ message: "New user added", severity: "success" });
        navigate("/users");
      })
      .catch((error) => {
        showNotification({
          message: `Failed to add new user! ${error.message}`,
          severity: "error",
        });
      });
  }
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

const ADD_USER = gql`
  mutation AddBackofficeUserMutation(
    $username: String!
    $usersEditable: Boolean!
    $password: String!
    $roleSelected: [String]!
  ) {
    createUser(
      input: {
        username: $username
        usersEditable: $usersEditable
        password: $password
        roleSelected: $roleSelected
      }
    ) {
      user {
        id
      }
    }
  }
`;
