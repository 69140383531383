import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import Button from "../../../../components/Button2";
import TextField from "../../../../components/TextField";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Collection, LocationCollection } from "../../../../types";
import { useNotifications } from "../../../../components/Notification";
import Select from "../../../../components/Select";

export default function EditLocationCollectionsForm(
  props: EditLocationCollectionsFormProps
) {
  const { showNotification } = useNotifications();
  const [saveCollections] = useMutation(SAVE_COLLECTIONS_MUTATION);
  const [locationCollections, setLocationCollections] = useState(
    props.locationCollections
  );

  return (
    <>
      <Grid
        style={{ marginBottom: "10px" }}
        container
        justifyContent="flex-end"
      >
        <Button startIcon={<AddIcon />} onClick={handleAdd}>
          Add
        </Button>
      </Grid>
      <Grid style={{ marginBottom: "80px" }} container spacing={2}>
        {locationCollections.map((pc) => (
          <Grid key={pc.index} item xs={12} md={6}>
            <Card>
              <CardContent>
                <IconButton
                  style={{ float: "right" }}
                  size="small"
                  onClick={() => handleRemove(pc.index)}
                >
                  <DeleteIcon />
                </IconButton>
                <TextField
                  style={{ width: "50%" }}
                  label="Index"
                  value={pc.index}
                  readOnly={true}
                />
                <Select
                  style={{ marginTop: "12px" }}
                  label="Collection ID"
                  value={pc.planCollectionId}
                  options={props.collections.map((c) => ({
                    id: c.id,
                    name: `${c.name} (${c.slug})`,
                  }))}
                  onChange={(v) =>
                    handleChange(pc.index, "planCollectionId", v)
                  }
                />
                <div
                  style={{
                    marginTop: "5px",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  You can only select collections that use this location
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div className={"save-button"}>
        <Button onClick={handleSave}>Save</Button>
      </div>
    </>
  );

  function handleAdd() {
    let index = 0;
    if (locationCollections.length > 0) {
      const highest = locationCollections.reduce((prev, cur) =>
        prev.index > cur.index ? prev : cur
      );
      index = highest.index + 1;
    }

    setLocationCollections([
      ...locationCollections,
      { index: index, planCollectionId: "" },
    ]);
  }

  function handleRemove(index: number) {
    setLocationCollections(
      locationCollections.filter((c) => c.index !== index)
    );
  }

  function handleChange(index: number, field: string, value: any) {
    setLocationCollections(
      locationCollections.map((c) =>
        c.index === index ? { ...c, [field]: value } : c
      )
    );
  }

  function handleSave() {
    saveCollections({
      variables: {
        locationId: props.locationId,
        collections: removeEmptyProperties(locationCollections),
      },
    })
      .then(() => {
        showNotification({
          message: `Collections were saved`,
          severity: "success",
        });
      })
      .catch((e) => {
        showNotification({
          message: `Saving collections failed!`,
          severity: "error",
        });
      });
  }

  function removeEmptyProperties(collections: LocationCollection[]) {
    return collections.map((c) =>
      Object.entries(c).reduce(
        (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
        {}
      )
    );
  }
}

interface EditLocationCollectionsFormProps {
  locationId: string;
  locationCollections: LocationCollection[];
  collections: Collection[];
}

const SAVE_COLLECTIONS_MUTATION = gql`
  mutation SaveCollectionsMutation(
    $locationId: ID!
    $collections: [LocationCollectionToSave!]!
  ) {
    saveLocationCollections(
      input: { locationId: $locationId, collections: $collections }
    )
  }
`;
