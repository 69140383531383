import { useNotifications } from "../../../../../components/Notification";
import { gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Plan } from "../../../../../types";
import { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

type CopyPlanDialogProps = {
  isOpen: boolean;
  closeDialog: () => void;
} & Pick<Plan, "id">;

export default function CopyPlanDialog({
  id,
  isOpen,
  closeDialog,
}: CopyPlanDialogProps) {
  const { showNotification } = useNotifications();
  const [copyPlan] = useMutation<
    CopyPlanMutationResponse,
    CopyPlanMutationInput
  >(COPY_PLAN_MUTATION);
  const [shouldCopyItems, setShouldCopyItems] = useState(false);

  const handleCopyPlan = async () => {
    await copyPlan({ variables: { input: { planId: id, shouldCopyItems } } })
      .then((response) => {
        window.open(`/plans/${response.data?.copyPlan.id}/edit`, "_blank");
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: err.message,
          severity: "error",
        });
      });
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{"Copy plan"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldCopyItems}
                onChange={({ target: { checked } }) =>
                  setShouldCopyItems(checked)
                }
              />
            }
            label="Also copy items and itemcard"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          onClick={() => {
            closeDialog();
            handleCopyPlan();
          }}
          autoFocus
        >
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type CopyPlanMutationInput = {
  input: { planId: string; shouldCopyItems?: boolean };
};
type CopyPlanMutationResponse = { copyPlan: Pick<Plan, "id"> };
const COPY_PLAN_MUTATION = gql`
  mutation CopyPlanMutation($input: CopyPlanInput!) {
    copyPlan(input: $input) {
      id
    }
  }
`;
