import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import EditLocationSpecialsForm from "./form";

export default function Specials() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery(GET_LOCATION_SPECIALS_QUERY, {
    variables: { locationId: id },
  });
  if (loading) return <CircularProgress />;
  if (error) return <>{error.message}</>;

  const locationSpecials = data ? data.locationSpecials.records : null;
  return (
    <EditLocationSpecialsForm
      locationId={id!}
      locationSpecials={locationSpecials}
    />
  );
}

const GET_LOCATION_SPECIALS_QUERY = gql`
  query GetLocationSpecialsQuery($locationId: ID!) {
    locationSpecials(locationId: $locationId) {
      totalRecords
      records {
        title
        linkText
        imageUrl
        url
        index
      }
    }
  }
`;
