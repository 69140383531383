import { gql, useMutation } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment-timezone";
import { useState } from "react";
import Button from "../../../../components/Button2";
import { useNotifications } from "../../../../components/Notification";
import { GET_BOOKING } from "../index";
import { GET_BOOKING_ACTIVITY_RESERVATIONS } from "../ActivityReservations";
import { DataEditItem, SAVE_BOOKING_EDIT_MUTATION } from "../Basic";
import { EditStatus } from "../../../../types";

export default function ChangeDateTimeButton(props: {
  bookingId: string;
  currentReservationDateTime: string;
}) {
  const { showNotification } = useNotifications();
  const [saveBookingEdit] = useMutation(SAVE_BOOKING_EDIT_MUTATION);
  const [changeReservationDateTime] = useMutation(CHANGE_RESERVATION_DATETIME, {
    refetchQueries: [
      { query: GET_BOOKING, variables: { id: props.bookingId } },
      {
        query: GET_BOOKING_ACTIVITY_RESERVATIONS,
        variables: { bookingId: props.bookingId },
      },
    ],
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reservationDateTime, setReservationDateTime] = useState(
    new Date(props.currentReservationDateTime)
  );

  function handleChangeReservationDateTime() {
    changeReservationDateTime({
      variables: {
        bookingId: props.bookingId,
        newReservationDateTime: moment(reservationDateTime).format(
          "YYYY-MM-DD HH:mm"
        ),
      },
    })
      .then(async () => {
        const newReservationDateTime = moment(reservationDateTime).format(
          "YYYY-MM-DD HH:mm"
        );
        if (newReservationDateTime !== props.currentReservationDateTime) {
          const dataEdits: DataEditItem[] = [
            {
              fieldName: "Reservation DateTime",
              newValue: JSON.stringify({
                text: newReservationDateTime,
              }),
              oldValue: JSON.stringify({
                text: props.currentReservationDateTime,
              }),
              editStatus: EditStatus.TOUCHED,
            },
          ];
          await saveBookingEdit({
            variables: {
              input: { editData: dataEdits, bookingId: props.bookingId },
            },
          });
        }
        showNotification({
          message: `Reservation datetime was successfully changed to ${reservationDateTime}. Reservation status has been changed and confirmation emails were sent.`,
          severity: "success",
        });
        setDialogOpen(false);
      })
      .catch(() => {
        showNotification({
          message: `Changing reservation datetime failed!`,
          severity: "error",
        });
      });
  }

  return (
    <>
      <Button color="secondary" onClick={() => setDialogOpen(true)}>
        Change DateTime
      </Button>
      <ChangeDateTimeDialog
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        value={reservationDateTime}
        setValue={setReservationDateTime}
        changeReservationDateTime={handleChangeReservationDateTime}
        disableButton={Boolean(
          new Date(props.currentReservationDateTime).getTime() ===
            reservationDateTime.getTime()
        )}
      />
    </>
  );
}

function ChangeDateTimeDialog(props: {
  open: boolean;
  closeDialog: () => void;
  value: Date;
  setValue: (newDateTime: Date) => void;
  changeReservationDateTime: () => void;
  disableButton: boolean;
}) {
  return (
    <Dialog open={props.open} onClose={props.closeDialog}>
      <DialogTitle>Change Reservation DateTime</DialogTitle>
      <DialogContent>
        Changing the reservation datetime will also confirm the reservation
        status, if it was not already confirmed.
        <br />
        <br />
        A confirmation email will be sent to the customer and venues.
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <DateTimePicker
            // style={{ width: "156px" }}
            // inputVariant="outlined"
            format="yyyy-MM-dd HH:mm"
            ampm={false}
            value={props.value}
            onChange={(newValue) => props.setValue(newValue ?? new Date())}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            props.closeDialog();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={props.disableButton}
          onClick={() => props.changeReservationDateTime()}
        >
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const CHANGE_RESERVATION_DATETIME = gql`
  mutation($bookingId: ID!, $newReservationDateTime: String!) {
    changeBookingReservationDateTime(
      input: {
        bookingId: $bookingId
        newReservationDateTime: $newReservationDateTime
      }
    ) {
      success
    }
  }
`;
