import { useMemo, useRef, useState } from "react";
import { Pagination, Plan } from "../../../../../types";
import PageNavigation from "./PageNavigation";
import { TripleState } from "../../../components/BasicInfoForm/MultiStateCheckbox/TripleStateCheckbox";
import HistoryCard from "../cards/HistoryCard";
import useGetPartnerEdits from "../useGetPartnerEdits";
import { Paper } from "@mui/material";

export default function HistoryEditList({ id: planId }: Pick<Plan, "id">) {
  const [pagination, setPagination] = useState<Pagination>({
    limit: 10,
    offset: 0,
  });

  const [isAccepted, setIsAccepted] = useState<TripleState>(undefined);

  const { data, loading } = useGetPartnerEdits({
    planId,
    pagination,
    isAccepted,
    isVerified: true,
  });

  const partnerEdits = useMemo(() => data?.partnerEdits.records ?? [], [data]);

  const previousTotalRecords = useRef<number | undefined>(undefined);

  const totalRecords: number = useMemo(() => {
    const result =
      data?.partnerEdits.totalRecords ?? previousTotalRecords.current ?? 0;
    previousTotalRecords.current = result;
    return result;
  }, [data]);

  return (
    <div>
      <PageNavigation
        pagination={pagination}
        setPagination={setPagination}
        isAccepted={isAccepted}
        setIsAccepted={setIsAccepted}
        totalRecords={totalRecords}
        loading={loading}
      />

      {partnerEdits.map((edit) => (
        <Paper sx={{ marginBottom: "1rem" }} key={edit.id}>
          <HistoryCard edit={edit} />
        </Paper>
      ))}
    </div>
  );
}
