import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Form from "../Form/BasicInfo";

export default function BasicInfo() {
  const navigate = useNavigate();

  const [addNewVenue] = useMutation(ADD_NEW_VENUE_MUTATION);

  return (
    <Form
      onSubmit={async (venue) => {
        await addNewVenue({ variables: { input: venue } }).then((response) => {
          navigate(`/venues/${response.data.addNewVenue.venue.id}/edit`);
        });
      }}
    />
  );
}

const ADD_NEW_VENUE_MUTATION = gql`
  mutation AddNewVenueMutation($input: AddNewVenueInput!) {
    addNewVenue(input: $input) {
      venue {
        id
      }
    }
  }
`;
