import { Dispatch, SetStateAction } from "react";
import { LockableMoment } from "../../types";
import { DateChip, LockableChip } from "./DateChip";

type ClosedDatesListProps = {
  closedHolidays: string[];
  setClosedHolidays: Dispatch<SetStateAction<string[]>>;
  closedDates: LockableMoment[];
  setClosedDates: Dispatch<SetStateAction<LockableMoment[]>>;
  closedTimeSlots: string[];
  setClosedTimeSlots: Dispatch<SetStateAction<string[]>>;
};
export function ClosedDatesList({
  closedHolidays,
  setClosedHolidays,
  closedDates,
  setClosedDates,
  closedTimeSlots,
  setClosedTimeSlots,
}: ClosedDatesListProps) {
  return (
    <ul style={{ listStyleType: "none" }}>
      {closedHolidays
        .map((holiday) => ({
          moment: holiday,
          chip: (
            <DateChip
              label={holiday}
              onDelete={() => {
                setClosedHolidays((closedHolidays) =>
                  closedHolidays.filter(
                    (closedHoliday) => holiday !== closedHoliday
                  )
                );
              }}
            />
          ),
        }))
        .concat(
          closedDates.map((date) => ({
            moment: date.moment,
            chip: (
              <LockableChip
                label={date.moment}
                locked={date.locked}
                onClickLock={() => {
                  setClosedDates((dates) =>
                    dates.map((otherDate) =>
                      otherDate.moment !== date.moment
                        ? otherDate
                        : {
                            ...date,
                            locked: !date.locked,
                          }
                    )
                  );
                }}
                onDelete={() =>
                  setClosedDates((dates) =>
                    dates.filter(
                      (otherDate) => otherDate.moment !== date.moment
                    )
                  )
                }
              />
            ),
          }))
        )
        .concat(
          closedTimeSlots.map((timeSlot) => ({
            moment: timeSlot,
            chip: (
              <DateChip
                label={timeSlot}
                onDelete={() =>
                  setClosedTimeSlots((timeSlots) =>
                    timeSlots.filter(
                      (otherTimeSlot) => otherTimeSlot !== timeSlot
                    )
                  )
                }
              />
            ),
          }))
        )
        .sort((a, b) => {
          // sort holidays before dates and timeSlots
          const aStartsWithDigit = !isNaN(Number(a.moment[0]));
          const bStartsWithDigit = !isNaN(Number(b.moment[0]));
          if (aStartsWithDigit && !bStartsWithDigit) return 1;
          if (bStartsWithDigit && !aStartsWithDigit) return -1;
          return a.moment.localeCompare(b.moment);
        })
        .map(({ chip, moment }) => (
          <li key={moment}>{chip}</li>
        ))}
    </ul>
  );
}

type OpenDatesListProps = {
  openDates: string[];
  setOpenDates: Dispatch<SetStateAction<string[]>>;
  openTimeSlots: string[];
  setOpenTimeSlots: Dispatch<SetStateAction<string[]>>;
};
export function OpenDatesList({
  openDates,
  setOpenDates,
  openTimeSlots,
  setOpenTimeSlots,
}: OpenDatesListProps) {
  return (
    <ul style={{ listStyleType: "none" }}>
      {openDates
        .map((date) => ({
          moment: date,
          chip: (
            <DateChip
              label={date}
              onDelete={() =>
                setOpenDates((dates) =>
                  dates.filter((otherDate) => otherDate !== date)
                )
              }
            />
          ),
        }))
        .concat(
          openTimeSlots.map((timeSlot) => ({
            moment: timeSlot,
            chip: (
              <DateChip
                label={timeSlot}
                onDelete={() =>
                  setOpenTimeSlots((timeSlots) =>
                    timeSlots.filter(
                      (otherTimeSlot) => otherTimeSlot !== timeSlot
                    )
                  )
                }
              />
            ),
          }))
        )
        .sort((a, b) => a.moment.localeCompare(b.moment))
        .map(({ moment, chip }) => (
          <li key={moment}>{chip}</li>
        ))}
    </ul>
  );
}
