import { Card, CardContent, CardHeader, IconButton } from "@mui/material";
import MonthYearRangePicker, {
  MonthYearRangePickerProps,
} from "./MonthYearRangePicker";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMemo, useState } from "react";
import { differenceInCalendarMonths } from "date-fns";

export default function FeeFreePeriodPicker({
  ...dateRangePickerProps
}: FeeFreePeriodPickerProps) {
  const {
    startDate: fromDate,
    setStartDate: setFromDate,
    endDate: untilDate,
    setEndDate: setUntilDate,
  } = dateRangePickerProps;
  const [open, setOpen] = useState(fromDate !== null && untilDate !== null);

  const numMonths = useMemo(
    () =>
      fromDate === null || untilDate == null
        ? undefined
        : differenceInCalendarMonths(untilDate, fromDate) + 1,
    [fromDate, untilDate]
  );

  const cardTitle =
    "Fee Free Period" +
    (numMonths !== undefined
      ? ` - ${numMonths} ${numMonths === 1 ? "month" : "months"}`
      : "");

  const action = useMemo(
    () =>
      open
        ? {
            icon: <DeleteIcon />,
            fn: () => {
              setFromDate(null);
              setUntilDate(null);
              setOpen(false);
            },
          }
        : {
            icon: <AddIcon />,
            fn: () => {
              setOpen(true);
            },
          },
    [open, setFromDate, setUntilDate, setOpen]
  );

  return (
    <Card>
      <CardHeader
        title={cardTitle}
        action={<IconButton onClick={action.fn}>{action.icon}</IconButton>}
      />
      {open && (
        <CardContent>
          <MonthYearRangePicker {...dateRangePickerProps} />
        </CardContent>
      )}
    </Card>
  );
}

type FeeFreePeriodPickerProps = MonthYearRangePickerProps;
