import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { default as TF } from "../../../components/TextField";
import { Card, CardHeader, CardContent, Divider, Grid } from "@mui/material";
import RoleAccessPermission from "../components/RoleAccessPermission";
import { AccessPermisstionType } from "../Edit/RoleEditForm";
import useStyles from "../styles";

export default function AddNewRole() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { showNotification } = useNotifications();
  const [name, setName] = useState<string>("");
  const [accessPermission, setAccessPermission] = useState<
    AccessPermisstionType[]
  >([]);
  const [createRole] = useMutation(CREATE_ROLE);

  const handleAddNewRole = () => {
    if (!name)
      return showNotification({
        message: "Please input a name",
        severity: "error",
      });

    if (accessPermission.length === 0)
      return showNotification({
        message: "Please select access permission.",
        severity: "error",
      });

    createRole({
      variables: {
        input: {
          name: name,
          accessPermissions: accessPermission,
        },
      },
    })
      .then(() => {
        showNotification({
          message: "New role created successfully.",
          severity: "success",
        });
        navigate("/roles");
      })
      .catch(() => {
        showNotification({
          message: "Failed to create new role!",
          severity: "error",
        });
      });
  };

  return (
    <div style={{ paddingBottom: "80px" }}>
      <div>
        <Button style={{ marginBottom: "15px" }} component={Link} to={`/roles`}>
          Back to list
        </Button>
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "24px" }}>
        <CardHeader title={"Access permissions"} />
        <Divider />
        <CardContent style={{ padding: "16px" }}>
          <Grid container spacing={1}>
            <RoleAccessPermission
              accessPermission={accessPermission}
              setAccessPermission={setAccessPermission}
            />
          </Grid>
        </CardContent>
      </Card>
      <div className={classes.role_detail_button}>
        <Button onClick={handleAddNewRole}>Add New Role</Button>
      </div>
    </div>
  );
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

const CREATE_ROLE = gql`
  mutation CreateRoleMutation($input: CreateRoleInput!) {
    createRole(input: $input) {
      role {
        id
      }
    }
  }
`;
