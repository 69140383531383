import { gql, useMutation } from "@apollo/client";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { EditStatus } from "../../../../../../types";
import { TripleElementWithMediaRenderer } from "../components/renderers";
import { CardProps, FaqData, GetOriginalValueProps } from "../../types";

type FaqCardProps = Partial<CardProps> & {
  planId: string;
  fieldId: string;
  question: string;
  answer: string;
  imageUrl: string;
  priority: number;
  faqs: FaqData[];
  setFaqs: (faqs: FaqData[]) => void;
  editStatus: EditStatus;
};

const FaqCard = forwardRef((props: FaqCardProps, ref) => {
  const [question, setQuestion] = useState(props.question);
  const [answer, setAnswer] = useState(props.answer);
  // TODO: add image uploader to component that uses setImageUrl
  // eslint-disable-next-line
  const [imageUrl, _setImageUrl] = useState(props.imageUrl);

  const [editMutation] = useMutation<EditFaqResponse, EditFaqInput>(
    EDIT_FAQ_MUTATION
  );

  const [deleteMutation] = useMutation<DeleteFaqResponse, DeleteFaqInput>(
    DELETE_FAQ_MUTATION
  );

  const persistChanges = async () => {
    if (props.editStatus === EditStatus.REMOVED) {
      const input: DeleteFaqInput["input"] = {
        id: props.fieldId,
      };

      return await deleteMutation({ variables: { input } });
    } else {
      const input: EditFaqInput["input"] = {
        planFAQ: {
          id: props.fieldId,
          planId: props.planId,
          question,
          answer,
          imageUrl,
        },
      };

      return await editMutation({ variables: { input } });
    }
  };

  const getNewValue = () => ({ question, answer, imageUrl });

  useImperativeHandle(ref, () => ({
    persistChanges,
    getNewValue,
  }));

  return (
    <TripleElementWithMediaRenderer
      mediaUrl={imageUrl}
      topText={question}
      setTopText={setQuestion}
      topLabel="question"
      bottomText={answer}
      setBottomText={setAnswer}
      bottomLabel="answer"
      mutable={!!props.mutable}
    />
  );
});

const getOriginalValue = ({ data, fieldId }: GetOriginalValueProps) =>
  data.faqs.find((faq: FaqData) => faq.id === fieldId);

const faqCard = {
  Content: FaqCard,
  getOriginalValue,
};
export default faqCard;

const EDIT_FAQ_MUTATION = gql`
  mutation EditFaqMutation($input: SavePlanFAQInput!) {
    savePlanFAQ(input: $input) {
      planFAQ {
        id
      }
    }
  }
`;

type EditFaqResponse = {
  plan: {
    id: string;
  };
};

type EditFaqInput = {
  input: {
    planFAQ: {
      id?: string;
      planId: string;
      question: string;
      answer: string;
      imageUrl: string;
    };
  };
};

const DELETE_FAQ_MUTATION = gql`
  mutation DeleteFaqMutation($input: DeletePlanFAQInput!) {
    deletePlanFAQ(input: $input) {
      result
    }
  }
`;

type DeleteFaqResponse = {
  result: boolean;
};

type DeleteFaqInput = {
  input: {
    id: string;
  };
};
