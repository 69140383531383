import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import Schedule from "../../../../components/Schedule";
import { useNotifications } from "../../../../components/Notification";
import Button from "../../../../components/Button2";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";

export default function PlanView() {
  const noteLength = 1000;
  const { id: planId } = useParams<{ id: string }>();
  const { showNotification } = useNotifications();
  const [isPublishing, setIsPublishing] = useState(false);
  const { loading, error, data } = useQuery(PLAN_SCHEDULE_QUERY, {
    variables: { planId },
    fetchPolicy: "network-only",
  });

  const { data: planData } = useQuery(PLAN_DATA_SCHEDULE_QUERY, {
    variables: { planId },
    fetchPolicy: "network-only",
  });

  const [publishPlanAvailability] = useMutation<
    PublishPlanAvailabilityResponse,
    PublishPlanAvailabilityInput
  >(PUBLISH_PLAN_AVAILABILITY);

  const [saveScheduleNotes] = useMutation<
    EditPlanScheduleNotesInputResponse,
    EditPlanScheduleNotesInput
  >(EDIT_PLAN_SCHEDULE_NOTES_MUTATION);

  const [notes, setNotes] = useState<string>("");
  const [charactersLeft, setCharactersLeft] = useState<number>(
    noteLength - notes?.length
  );

  useEffect(() => {
    if (planData) {
      setNotes(planData.plan.scheduleNotes);
    }
  }, [planData]);

  if (loading || error || !data || !data.planSchedule || !planData) {
    return null;
  }

  const { planSchedule } = data;

  const handleSavingNotes = () => {
    if (notes.length > noteLength) {
      showNotification({
        message: `You cannot enter notes longer than ${noteLength} characters.`,
        severity: "error",
      });
      return;
    }
    setIsPublishing(true);
    saveScheduleNotes({
      variables: {
        input: { planId: planId!, scheduleNotes: notes },
      },
    })
      .then(() => {
        setIsPublishing(false);
        showNotification({
          severity: "success",
          message: "Notes were saved",
        });
      })
      .catch((err) => {
        setIsPublishing(false);
        showNotification({
          severity: "error",
          message: err.message,
        });
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item sm={12} container justifyContent="flex-end">
        <Button
          disabled={
            isPublishing ||
            planData.plan.bookingType === "INSTANT" ||
            planData.plan.bookingType === "AVAILABILITIES"
          }
          onClick={() => {
            setIsPublishing(true);
            publishPlanAvailability({
              variables: {
                input: { planId: planSchedule.id },
              },
            })
              .then(() => {
                setIsPublishing(false);
                showNotification({
                  severity: "success",
                  message: "Plan availability was published",
                });
              })
              .catch((err) => {
                setIsPublishing(false);
                showNotification({
                  severity: "error",
                  message: err.message,
                });
              });
          }}
        >
          {isPublishing ? (
            <CircularProgress size={20} />
          ) : planData.plan.bookingType === "INSTANT" ||
            planData.plan.bookingType === "AVAILABILITIES" ? (
            "Can't publish availability for 'instant' and 'availabilities' booking type plans"
          ) : (
            "Publish Availability"
          )}
        </Button>
      </Grid>
      <Grid item sm={12}>
        <Schedule resource={planSchedule} />
      </Grid>
      <Grid item sm={6}>
        <Card style={{ marginTop: "24px" }}>
          <Grid container justifyContent="space-between">
            <CardHeader title="Internal Notes" />
            <Button
              disabled={isPublishing ? true : false}
              onClick={handleSavingNotes}
              style={{ margin: "1%" }}
            >
              {isPublishing ? <CircularProgress size={20} /> : "Save Notes"}
            </Button>
          </Grid>
          <Divider />
          <CardContent>
            <TextField
              label={"Note"}
              multiline
              fullWidth
              value={notes}
              onChange={(e: any) => {
                setCharactersLeft(noteLength - e.target.value.length);
                setNotes(e.target.value);
              }}
            />
            <small>{charactersLeft} characters left.</small>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const PLAN_SCHEDULE_QUERY = gql`
  query PlanScheduleQuery($planId: ID!) {
    planSchedule(planId: $planId) {
      id
      name
      schedule {
        recurrenceRules {
          weekdays
          holidays
          hours
          minutes
        }
        cutoffTime {
          hours
        }
        bookablePeriod {
          months
        }
        closedDates {
          moment
          locked
        }
        closedTimeSlots
        closedHolidays
        openDates
        openTimeSlots
      }
    }
  }
`;

const PLAN_DATA_SCHEDULE_QUERY = gql`
  query PlanScheduleNotesQuery($planId: ID!) {
    plan(id: $planId) {
      scheduleNotes
      bookingType
    }
  }
`;

type PublishPlanAvailabilityInput = {
  input: {
    planId: string;
  };
};

type PublishPlanAvailabilityResponse = {
  publishedAt: string;
};

type EditPlanScheduleNotesInput = {
  input: {
    planId: string;
    scheduleNotes: string;
  };
};

type EditPlanScheduleNotesInputResponse = {
  scheduleNotes: string;
};

const PUBLISH_PLAN_AVAILABILITY = gql`
  mutation PublishPlanAvailabilityMutation(
    $input: PublishPlanAvailabilityInput!
  ) {
    publishPlanAvailability(input: $input) {
      publishedAt
    }
  }
`;

const EDIT_PLAN_SCHEDULE_NOTES_MUTATION = gql`
  mutation EditPlanScheduleNotesMutation($input: EditPlanScheduleNotesInput!) {
    editPlanScheduleNotes(input: $input) {
      scheduleNotes
    }
  }
`;
