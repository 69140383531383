import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import EditActivityForm from "./ActivityForm/EditActivity";
import NewActivityForm from "./ActivityForm/NewActivity";
import DataTabs from "../../../../components/DataTabs";
import { Activity } from "../../../../types";

type PlanActivitiesRecord = {
  id: string;
  activities: Activity[];
};

const toActivityForm = (
  activity: Activity
): Omit<Activity, "venue"> & { venueId?: string } => {
  const { venue, ...data } = activity;
  const venueId = venue?.id;

  return {
    ...data,
    venueId,
  };
};

export default function ActivityList() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error, refetch } = useQuery<{
    plan: PlanActivitiesRecord;
  }>(PLAN_ACTIVITIES_QUERY, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (!data || !data.plan || loading || error) {
    return null;
  }
  const { plan } = data;
  const activityTabs =
    plan.activities.length > 0
      ? plan.activities.map((a) => ({
          title: a.name || "",
          content: (
            <EditActivityForm
              activity={toActivityForm(a)}
              planId={plan.id}
              refetch={refetch}
              activities={plan.activities}
            />
          ),
        }))
      : [
          {
            title: "New activity",
            content: <NewActivityForm planId={plan.id} refetch={refetch} />,
          },
        ];

  return <DataTabs tabs={activityTabs} selectedTab={0} />;
}

const PLAN_ACTIVITIES_QUERY = gql`
  query PlanActivityQuery($id: ID!) {
    plan(id: $id) {
      id
      activities {
        id
        name
        courseMenu
        coursePhotos {
          activityId
          id
          photoIndex
          src
        }
        detailsForVenue
        contract {
          commissionRate
          onSiteCommissionRate
        }
        priceTypes {
          id
          name
          unitType
          amount
          contractedPrice
          currencyCode
          minAttendees
          maxAttendees
          index
          active
        }
        venue {
          id
          name
        }
        offset {
          minutes
          hours
        }
        seatOptions {
          id
          title
          details
          minAttendees
          maxAttendees
          photos {
            src
          }
          active
          price
        }
        questionsByVenue
      }
    }
  }
`;
