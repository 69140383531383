import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material";
import { PropsWithChildren } from "react";

const theme = createTheme({
  components: {
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: "16px",
          fontWeight: 700,
        },
      },
    },
  },
});

export default function CustomTheme({ children }: PropsWithChildren<any>) {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </ThemeProvider>
  );
}
