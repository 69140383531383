import { Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "../../../../components/TextField";
import PageSection from "../../../../components/PageSection";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";

type TemplateCardProps = {
  card_id: string;
  index: number;
  setIndex: (newIndex: number) => void;
  title: string;
  setTitle: (newTitle: string) => void;
  description: string;
  setDescription: (newDescription: string) => void;
  imageUrl: string;
  setImageUrl: (newImageUrl: string) => void;
  handleDelete: () => void;
};

export default function TemplateCardForm({
  card_id,
  index,
  setIndex,
  title,
  setTitle,
  description,
  setDescription,
  imageUrl,
  setImageUrl,
  handleDelete,
}: TemplateCardProps) {
  return (
    <PageSection lg={12}>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item lg={8}>
          <TextField
            required
            size="small"
            label="Index"
            error={index < 1}
            helperText={"Unique number starting from 1"}
            value={index}
            onChange={(e: any) => {
              const num = Number(e.target.value);
              if (!Number.isNaN(num)) {
                setIndex(Math.max(num, 1));
              }
            }}
          />
          <TextField
            required
            multiline
            label="Title"
            value={title}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
          />
          <TextField
            required
            multiline
            label="Description"
            value={description}
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          lg={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
            height: "auto",
          }}
        >
          <MediaUploader
            media={imageUrl ? imageUrl : null}
            allowedTypes={[MediaType.Image]}
            width={"80%"}
            uploadPath={`plans/templatecard/${card_id}`}
            onChangeFunction={(url: string | null) => {
              setImageUrl(url ? url : "");
            }}
          />
        </Grid>
      </Grid>
    </PageSection>
  );
}
