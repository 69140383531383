import { Paper, IconButton, LinearProgress } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Pagination } from "../../../../../types";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import {
  TripleState,
  TripleStateCheckbox,
} from "../../../components/BasicInfoForm/MultiStateCheckbox/TripleStateCheckbox";

type PageNavigationProps = {
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  totalRecords: number;
  isAccepted: TripleState;
  setIsAccepted: Dispatch<SetStateAction<TripleState>>;
  loading: boolean;
};

export default function PageNavigation({
  pagination,
  setPagination,
  totalRecords,
  isAccepted,
  setIsAccepted,
  loading,
}: PageNavigationProps) {
  const currentPage = useMemo(
    () => Math.floor(pagination.offset / pagination.limit),
    [pagination]
  );
  const lastPage = useMemo(
    () => Math.max(0, Math.ceil(totalRecords / pagination.limit) - 1),
    [totalRecords, pagination.limit]
  );

  const goToPage = useCallback(
    (page: number) => {
      setPagination((pagination) => {
        return {
          ...pagination,
          offset: Math.min(lastPage, Math.max(0, page)) * pagination.limit,
        };
      });
    },
    [lastPage, setPagination]
  );

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 2,
      }}
    >
      <Paper
        style={{
          display: "flex",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          alignItems: "center",
        }}
      >
        <IconButton disabled={currentPage === 0} onClick={() => goToPage(0)}>
          <KeyboardDoubleArrowLeftIcon />
        </IconButton>
        <IconButton
          disabled={currentPage === 0}
          onClick={() => goToPage(currentPage - 1)}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        <span style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
          {Math.min(pagination.offset + 1, totalRecords)} -{" "}
          {Math.min(pagination.offset + pagination.limit, totalRecords)} /{" "}
          {totalRecords}
        </span>

        <IconButton
          disabled={currentPage >= lastPage}
          onClick={() => goToPage(currentPage + 1)}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
        <IconButton
          disabled={currentPage >= lastPage}
          onClick={() => goToPage(lastPage)}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
        <div style={{ marginLeft: "auto" }}>
          <TripleStateCheckbox
            state={isAccepted}
            setState={(state: TripleState) => {
              goToPage(0);
              setIsAccepted(state);
            }}
            label={"Accepted"}
          />
        </div>
      </Paper>
      {loading && (
        <LinearProgress style={{ width: "100%", position: "absolute" }} />
      )}
    </div>
  );
}
