import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import Button from "../../../components/Button2";
import { Concierge } from "../../../types";
import EditConciergeForm from "./form";

export default function EditConcierge() {
  const { id } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery(GET_CONCIERGE, {
    variables: { id },
  });
  if (error) return <>Error! {error.message}</>;
  const concierge: Concierge | null = data ? data.concierge : null;

  return (
    <>
      <div>
        <Button
          style={{ marginBottom: "15px" }}
          component={Link}
          to={`/concierges`}
        >
          Back to list
        </Button>
      </div>
      {loading || !concierge ? (
        <CircularProgress />
      ) : (
        <EditConciergeForm concierge={concierge} />
      )}
    </>
  );
}

const GET_CONCIERGE = gql`
  query ConciergeEditQuery($id: ID!) {
    concierge(id: $id) {
      id
      createdAt
      name
      imageUrl
    }
  }
`;
