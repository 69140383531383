import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import ConciergesSelection from "../../../../components/ConciergeSelection";
import TextField from "../../../../components/TextField";

export default function ConciergeComment(props: ConciergeCommentProps) {
  return (
    <Card>
      <CardHeader title={"Concierge Comment"} />
      <Divider />
      <CardContent>
        <ConciergesSelection
          required
          onConciergeSelected={(concierge) => {
            props.conciergeId.set(concierge?.id || "");
          }}
          conciergeId={props.conciergeId.value}
        />
        <TextField
          label="Concierge Comment"
          value={props.comment.value}
          onChange={(e: any) => props.comment.set(e.target.value)}
        />
      </CardContent>
    </Card>
  );
}

interface ConciergeCommentProps {
  planId?: string;
  conciergeId: { value?: string; set: (value: string) => void };
  comment: { value?: string; set: (value: string) => void };
}
