import { FaBabyCarriage, FaGlassCheers, FaHiking, FaCar } from "react-icons/fa";
import { GiChickenOven, GiPresent, GiBeerStein } from "react-icons/gi";
import { GoPrimitiveDot } from "react-icons/go";
import { MdLocalActivity } from "react-icons/md";
import { RiRestaurantLine, RiCakeLine } from "react-icons/ri";

export default function Icon({ name, size, color }: IconProps) {
  switch (name) {
    case "FaBabyCarriage":
      return <FaBabyCarriage size={size} color={color} />;
    case "FaGlassCheers":
      return <FaGlassCheers size={size} color={color} />;
    case "FaHiking":
      return <FaHiking size={size} color={color} />;
    case "FaCar":
      return <FaCar size={size} color={color} />;
    case "GiChickenOven":
      return <GiChickenOven size={size} color={color} />;
    case "GiPresent":
      return <GiPresent size={size} color={color} />;
    case "GiBeerStein":
      return <GiBeerStein size={size} color={color} />;
    case "GoPrimitiveDot":
      return <GoPrimitiveDot size={size} color={color} />;
    case "MdLocalActivity":
      return <MdLocalActivity size={size} color={color} />;
    case "RiRestaurantLine":
      return <RiRestaurantLine size={size} color={color} />;
    case "RiCakeLine":
      return <RiCakeLine size={size} color={color} />;
    case "Bar":
      return (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="rgba(0, 0, 0, 0.54)"
            stroke="none"
          >
            <path
              d="M66 5101 c-46 -31 -66 -69 -66 -131 0 -62 20 -100 66 -131 27 -17 51
       -19 287 -19 l258 0 -3 -155 -3 -156 -68 -24 c-195 -68 -360 -249 -411 -451
       -12 -45 -16 -111 -16 -240 0 -197 5 -214 66 -255 28 -18 57 -19 584 -19 527 0
       556 1 584 19 61 41 66 58 66 255 0 129 -4 195 -16 240 -51 202 -216 383 -411
       451 l-68 24 -3 156 -3 155 751 0 751 0 -3 -155 -3 -156 -68 -24 c-195 -68
       -360 -249 -411 -451 -12 -45 -16 -111 -16 -240 0 -197 5 -214 66 -255 28 -18
       57 -19 584 -19 527 0 556 1 584 19 61 41 66 58 66 255 0 129 -4 195 -16 240
       -51 202 -216 383 -411 451 l-68 24 -3 156 -3 155 751 0 751 0 -3 -155 -3 -156
       -68 -24 c-195 -68 -360 -249 -411 -451 -12 -45 -16 -111 -16 -240 0 -197 5
       -214 66 -255 28 -18 57 -19 584 -19 527 0 556 1 584 19 61 41 66 58 66 255 0
       129 -4 195 -16 240 -51 202 -216 383 -411 451 l-68 24 -3 156 -3 155 258 0
       c236 0 260 2 287 19 46 31 66 69 66 131 0 62 -20 100 -66 131 -28 18 -96 19
       -2494 19 -2398 0 -2466 -1 -2494 -19z m821 -913 c42 -15 74 -37 114 -79 64
       -65 92 -127 103 -226 l7 -63 -351 0 -351 0 7 63 c11 101 39 162 104 227 72 72
       135 99 237 99 51 1 90 -6 130 -21z m1800 0 c42 -15 74 -37 114 -79 64 -65 92
       -127 103 -226 l7 -63 -351 0 -351 0 7 63 c11 101 39 162 104 227 72 72 135 99
       237 99 51 1 90 -6 130 -21z m1800 0 c42 -15 74 -37 114 -79 64 -65 92 -127
       103 -226 l7 -63 -351 0 -351 0 7 63 c11 101 39 162 104 227 72 72 135 99 237
       99 51 1 90 -6 130 -21z"
            />
            <path
              d="M66 2881 c-15 -10 -37 -32 -47 -47 -18 -28 -19 -69 -19 -1184 0
       -1115 1 -1156 19 -1184 10 -15 32 -37 47 -47 27 -18 53 -19 357 -19 l327 0 0
       -152 c0 -133 3 -157 19 -182 31 -46 69 -66 131 -66 62 0 100 20 131 66 16 25
       19 49 19 182 l0 152 450 0 450 0 0 -152 c0 -168 6 -189 66 -229 39 -26 129
       -26 168 0 60 40 66 61 66 229 l0 152 310 0 310 0 0 -152 c0 -168 6 -189 66
       -229 39 -26 129 -26 168 0 60 40 66 61 66 229 l0 152 450 0 450 0 0 -152 c0
       -168 6 -189 66 -229 39 -26 129 -26 168 0 60 40 66 61 66 229 l0 152 328 0
       c303 0 329 1 356 19 15 10 37 32 47 47 18 28 19 69 19 1184 0 1115 -1 1156
       -19 1184 -10 15 -32 37 -47 47 -28 18 -96 19 -2494 19 -2398 0 -2466 -1 -2494
       -19z m4754 -1231 l0 -950 -225 0 -225 0 0 260 0 260 28 0 c34 0 78 29 103 66
       18 27 19 53 19 384 0 331 -1 357 -19 384 -10 15 -32 37 -47 47 -28 18 -62 19
       -834 19 -772 0 -806 -1 -834 -19 -15 -10 -37 -32 -47 -47 -18 -27 -19 -53 -19
       -384 0 -331 1 -357 19 -384 25 -37 69 -66 104 -66 l27 0 0 -260 0 -260 -310 0
       -310 0 0 260 0 260 28 0 c34 0 78 29 103 66 18 27 19 53 19 384 0 331 -1 357
       -19 384 -10 15 -32 37 -47 47 -28 18 -62 19 -834 19 -772 0 -806 -1 -834 -19
       -15 -10 -37 -32 -47 -47 -18 -27 -19 -53 -19 -384 0 -331 1 -357 19 -384 25
       -37 69 -66 104 -66 l27 0 0 -260 0 -260 -225 0 -225 0 0 950 0 950 2260 0
       2260 0 0 -950z m-2720 20 l0 -150 -600 0 -600 0 0 150 0 150 600 0 600 0 0
       -150z m2120 0 l0 -150 -600 0 -600 0 0 150 0 150 600 0 600 0 0 -150z m-2270
       -710 l0 -260 -450 0 -450 0 0 260 0 260 450 0 450 0 0 -260z m2120 0 l0 -260
       -450 0 -450 0 0 260 0 260 450 0 450 0 0 -260z"
            />
          </g>
        </svg>
      );
    case "Conference":
      return (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="rgba(0, 0, 0, 0.54)"
            stroke="none"
          >
            <path
              d="M2432 4939 c-71 -28 -144 -101 -171 -174 -37 -100 -25 -230 30 -306
       12 -17 20 -31 18 -33 -2 -1 -24 -14 -49 -28 -146 -85 -260 -281 -260 -450 0
       -117 -47 -108 560 -108 608 0 560 -10 560 111 0 168 -113 361 -260 447 -25 14
       -47 27 -49 28 -2 2 6 16 18 33 34 47 51 108 51 182 0 189 -133 320 -323 318
       -47 0 -90 -7 -125 -20z m206 -169 c130 -79 74 -280 -78 -280 -85 0 -150 65
       -150 150 0 54 27 102 72 130 20 12 51 20 78 20 27 0 58 -8 78 -20z m84 -492
       c77 -36 151 -107 188 -181 53 -106 85 -97 -350 -97 -431 0 -400 -8 -356 86 43
       93 129 170 229 206 78 28 213 21 289 -14z"
            />
            <path
              d="M1152 4379 c-71 -28 -144 -101 -171 -174 -37 -100 -25 -230 30 -306
       12 -17 20 -31 18 -33 -2 -1 -24 -14 -49 -28 -146 -85 -260 -281 -260 -450 0
       -117 -47 -108 560 -108 608 0 560 -10 560 111 0 168 -113 361 -260 447 -25 14
       -47 27 -49 28 -2 2 6 16 18 33 55 76 67 206 30 306 -28 74 -100 146 -174 174
       -74 28 -180 28 -253 0z m206 -169 c130 -79 74 -280 -78 -280 -85 0 -150 65
       -150 150 0 85 65 150 150 150 27 0 58 -8 78 -20z m84 -492 c77 -36 151 -107
       188 -181 53 -106 85 -97 -350 -97 -431 0 -400 -8 -356 86 43 93 129 170 229
       206 78 28 213 21 289 -14z"
            />
            <path
              d="M3712 4379 c-71 -28 -144 -101 -171 -174 -37 -100 -25 -230 30 -306
       12 -17 20 -31 18 -33 -2 -1 -24 -14 -49 -28 -146 -85 -260 -281 -260 -450 0
       -117 -47 -108 560 -108 608 0 560 -10 560 111 0 168 -113 361 -260 447 -25 14
       -47 27 -49 28 -2 2 6 16 18 33 34 47 51 108 51 182 0 189 -133 320 -323 318
       -47 0 -90 -7 -125 -20z m206 -169 c130 -79 74 -280 -78 -280 -85 0 -150 65
       -150 150 0 85 65 150 150 150 27 0 58 -8 78 -20z m84 -492 c77 -36 151 -107
       188 -181 53 -106 85 -97 -350 -97 -431 0 -400 -8 -356 86 43 93 129 170 229
       206 78 28 213 21 289 -14z"
            />
            <path
              d="M2345 3419 c-310 -76 -570 -338 -645 -649 -28 -117 -28 -303 0 -420
       79 -328 349 -589 682 -656 100 -20 321 -14 413 11 98 26 253 109 334 177 139
       117 249 295 291 468 28 117 28 303 0 420 -75 314 -336 575 -650 650 -115 27
       -311 27 -425 -1z m318 -153 c137 -16 303 -102 409 -214 80 -85 128 -163 165
       -267 26 -74 28 -91 28 -225 0 -137 -2 -150 -31 -231 -38 -108 -105 -209 -189
       -287 -381 -357 -986 -202 -1163 297 -25 69 -27 89 -27 221 0 133 2 151 28 225
       73 208 235 372 441 447 44 16 114 33 156 37 74 7 88 7 183 -3z"
            />
            <path
              d="M592 3099 c-71 -28 -144 -101 -171 -174 -37 -100 -25 -230 30 -306
       12 -17 20 -31 18 -33 -2 -1 -24 -14 -49 -28 -146 -85 -260 -281 -260 -450 0
       -117 -47 -108 560 -108 608 0 560 -10 560 111 0 168 -113 361 -260 447 -25 14
       -47 27 -49 28 -2 2 6 16 18 33 55 76 67 206 30 306 -28 74 -100 146 -174 174
       -74 28 -180 28 -253 0z m206 -169 c130 -79 74 -280 -78 -280 -27 0 -58 8 -78
       20 -130 79 -74 280 78 280 27 0 58 -8 78 -20z m84 -492 c77 -36 151 -107 188
       -181 53 -106 85 -97 -350 -97 -431 0 -400 -8 -356 86 43 93 129 170 229 206
       78 28 213 21 289 -14z"
            />
            <path
              d="M4272 3099 c-71 -28 -144 -101 -171 -174 -37 -100 -25 -230 30 -306
       12 -17 20 -31 18 -33 -2 -1 -24 -14 -49 -28 -146 -85 -260 -281 -260 -450 0
       -117 -47 -108 560 -108 608 0 560 -10 560 111 0 168 -113 361 -260 447 -25 14
       -47 27 -49 28 -2 2 6 16 18 33 34 47 51 108 51 182 0 189 -133 320 -323 318
       -47 0 -90 -7 -125 -20z m206 -169 c130 -79 74 -280 -78 -280 -85 0 -150 65
       -150 150 0 85 65 150 150 150 27 0 58 -8 78 -20z m84 -492 c77 -36 151 -107
       188 -181 53 -106 85 -97 -350 -97 -431 0 -400 -8 -356 86 43 93 129 170 229
       206 78 28 213 21 289 -14z"
            />
            <path
              d="M1152 1819 c-71 -28 -144 -101 -171 -174 -37 -100 -25 -230 30 -306
       12 -17 20 -31 18 -33 -2 -1 -24 -14 -49 -28 -146 -85 -260 -281 -260 -450 0
       -117 -47 -108 560 -108 608 0 560 -10 560 111 0 168 -113 361 -260 447 -25 14
       -47 27 -49 28 -2 2 6 16 18 33 55 76 67 206 30 306 -28 74 -100 146 -174 174
       -74 28 -180 28 -253 0z m206 -169 c130 -79 74 -280 -78 -280 -152 0 -208 201
       -78 280 43 26 113 26 156 0z m84 -492 c77 -36 151 -107 188 -181 53 -106 85
       -97 -350 -97 -431 0 -400 -8 -356 86 43 93 129 170 229 206 78 28 213 21 289
       -14z"
            />
            <path
              d="M3712 1819 c-71 -28 -144 -101 -171 -174 -37 -100 -25 -230 30 -306
       12 -17 20 -31 18 -33 -2 -1 -24 -14 -49 -28 -146 -85 -260 -281 -260 -450 0
       -117 -47 -108 560 -108 608 0 560 -10 560 111 0 168 -113 361 -260 447 -25 14
       -47 27 -49 28 -2 2 6 16 18 33 34 47 51 108 51 182 0 189 -133 320 -323 318
       -47 0 -90 -7 -125 -20z m206 -169 c130 -79 74 -280 -78 -280 -152 0 -208 201
       -78 280 43 26 113 26 156 0z m84 -492 c77 -36 151 -107 188 -181 53 -106 85
       -97 -350 -97 -431 0 -400 -8 -356 86 43 93 129 170 229 206 78 28 213 21 289
       -14z"
            />
            <path
              d="M2432 1259 c-71 -28 -144 -101 -171 -174 -37 -100 -25 -230 30 -306
       12 -17 20 -31 18 -33 -2 -1 -24 -14 -49 -28 -146 -85 -260 -281 -260 -450 0
       -117 -47 -108 560 -108 608 0 560 -10 560 111 0 168 -113 361 -260 447 -25 14
       -47 27 -49 28 -2 2 6 16 18 33 34 47 51 108 51 182 0 189 -133 320 -323 318
       -47 0 -90 -7 -125 -20z m206 -169 c130 -79 74 -280 -78 -280 -85 0 -150 65
       -150 150 0 85 65 150 150 150 27 0 58 -8 78 -20z m84 -492 c77 -36 151 -107
       188 -181 53 -106 85 -97 -350 -97 -431 0 -400 -8 -356 86 43 93 129 170 229
       206 78 28 213 21 289 -14z"
            />
          </g>
        </svg>
      );

    case "DiningTable":
      return (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="rgba(0, 0, 0, 0.54)"
            stroke="none"
          >
            <path
              d="M1280 5102 c-32 -14 -58 -38 -79 -72 -21 -35 -21 -37 -21 -788 0
       -846 -4 -799 73 -850 l41 -27 1265 0 1266 0 35 22 c20 12 46 40 58 61 l22 40
       0 754 0 755 -23 34 c-12 18 -38 44 -56 56 l-34 23 -1266 -1 c-696 0 -1273 -3
       -1281 -7z m2473 -150 l27 -2 0 -715 0 -715 -1217 2 -1218 3 -3 705 c-1 388 0
       710 2 715 2 7 408 10 1192 9 654 0 1201 -1 1217 -2z"
            />
            <path
              d="M1518 4814 c-48 -26 -58 -60 -58 -199 0 -112 2 -127 20 -145 26 -26
       80 -26 111 -1 22 17 24 29 27 115 l4 96 939 0 939 0 0 -440 0 -440 -65 0 -64
       0 -54 73 c-29 39 -108 153 -174 252 -143 214 -173 245 -242 245 -65 0 -105
       -28 -164 -118 -40 -61 -51 -71 -63 -61 -8 7 -63 84 -122 173 -59 88 -120 175
       -135 193 -37 42 -96 59 -148 44 -50 -15 -88 -60 -220 -258 -61 -92 -170 -252
       -242 -355 -116 -167 -134 -188 -158 -188 l-28 0 -3 229 c-3 209 -5 231 -22
       250 -14 15 -31 21 -61 21 -75 0 -75 0 -75 -302 0 -259 0 -266 23 -299 13 -19
       38 -39 57 -46 50 -17 1990 -17 2040 0 19 7 44 27 57 46 l23 34 0 504 c0 554 1
       540 -60 577 -26 15 -121 16 -1042 16 -876 -1 -1017 -3 -1040 -16z m1017 -709
       l206 -300 -427 -3 c-235 -1 -429 0 -431 2 -3 3 91 144 209 315 162 235 216
       307 224 298 7 -7 105 -147 219 -312z m495 -100 c68 -99 127 -186 133 -192 7
       -10 -15 -13 -105 -13 l-115 0 -81 116 -80 116 56 83 c31 46 60 80 63 76 3 -3
       61 -87 129 -186z"
            />
            <path
              d="M125 3639 c-40 -15 -94 -67 -111 -106 -12 -27 -14 -213 -14 -1095 0
       -1043 0 -1064 20 -1096 24 -39 75 -72 113 -72 27 0 28 -1 22 -47 -3 -27 -17
       -169 -31 -318 -14 -148 -36 -368 -49 -488 -30 -264 -31 -322 -7 -360 34 -51
       49 -56 163 -57 93 0 110 3 133 21 14 11 31 29 36 39 6 11 64 279 131 597 66
       318 122 586 125 596 5 15 18 17 98 17 l93 0 -4 -27 c-1 -16 -17 -154 -33 -308
       -17 -154 -44 -386 -60 -515 -17 -129 -30 -261 -30 -293 0 -54 3 -62 34 -93
       l34 -34 125 0 c137 0 162 9 186 67 6 15 63 282 126 593 63 311 117 575 120
       587 4 18 12 23 39 23 33 0 35 -2 46 -53 6 -28 60 -288 121 -577 60 -289 116
       -544 125 -568 23 -61 50 -72 177 -72 l105 0 31 35 c17 20 34 48 38 63 5 21
       -79 932 -104 1128 -5 41 -5 41 30 48 44 8 93 62 103 112 18 99 -25 224 -105
       304 -86 86 -168 110 -367 110 -68 0 -124 2 -124 5 0 2 5 27 11 55 l11 50 1083
       0 c1024 0 1084 -1 1088 -17 3 -10 8 -35 12 -55 l6 -38 -129 0 c-71 0 -152 -4
       -180 -10 -172 -32 -293 -170 -300 -340 -2 -62 1 -81 17 -108 23 -35 75 -72
       104 -72 15 0 17 -7 12 -47 -3 -27 -17 -169 -31 -318 -14 -148 -36 -368 -49
       -488 -30 -264 -31 -322 -7 -360 34 -51 49 -56 163 -57 93 0 110 3 133 21 14
       11 31 29 36 39 6 11 64 279 131 597 66 318 122 586 125 595 4 13 16 18 43 18
       l38 0 103 -522 c56 -288 110 -559 118 -603 13 -65 21 -86 48 -113 l32 -32 124
       0 c135 0 155 7 183 60 21 41 19 72 -20 386 -19 154 -46 387 -60 519 -14 132
       -28 255 -31 273 l-5 32 95 0 94 0 12 -52 c6 -29 60 -289 121 -578 60 -289 116
       -543 124 -565 23 -62 53 -75 178 -75 l105 0 31 35 c17 20 34 48 38 63 5 21
       -79 934 -104 1130 -6 41 -5 42 23 42 39 0 90 32 114 72 20 32 20 49 17 782
       l-2 749 -27 20 c-20 15 -35 18 -62 13 -67 -13 -66 1 -66 -616 0 -302 -2 -550
       -4 -550 -2 0 -28 11 -58 25 -29 14 -62 25 -73 25 -20 0 -22 24 -79 700 -35
       418 -56 717 -52 742 10 63 39 121 87 173 65 71 141 103 167 72 6 -7 12 -88 14
       -201 3 -184 4 -188 27 -207 31 -25 81 -24 109 1 21 20 22 28 22 226 0 149 -4
       213 -14 237 -17 41 -74 91 -122 107 -126 42 -319 -71 -400 -234 -65 -133 -65
       -121 -1 -899 32 -380 58 -695 57 -699 0 -5 -96 -8 -214 -8 l-213 0 -7 50 c-4
       28 -4 54 -2 59 3 5 23 11 44 14 42 7 94 54 111 100 6 16 11 95 11 176 0 135
       -2 152 -23 191 -16 30 -37 51 -67 67 -39 21 -56 23 -187 23 -78 0 -143 2 -143
       5 0 2 14 38 30 80 36 92 39 151 8 190 -41 53 -59 55 -418 55 -358 0 -382 -3
       -415 -54 -32 -48 -30 -94 8 -189 l35 -87 -619 0 -619 0 26 58 c51 112 56 158
       24 210 -36 60 -53 62 -420 62 -367 0 -384 -2 -420 -62 -28 -46 -25 -91 14
       -185 l34 -83 -167 0 c-153 0 -170 -2 -211 -22 -30 -15 -53 -37 -67 -63 -21
       -37 -23 -53 -23 -193 0 -167 8 -200 55 -245 16 -14 48 -30 73 -37 43 -10 44
       -12 38 -42 -3 -17 -6 -41 -6 -54 l0 -24 -215 0 -215 0 0 23 c0 12 25 326 56
       697 64 754 64 753 2 880 -40 82 -133 177 -210 214 -64 31 -155 42 -203 25z
       m146 -178 c80 -50 140 -141 154 -231 5 -39 -97 -1351 -110 -1416 -3 -14 -15
       -23 -35 -27 -16 -4 -49 -16 -72 -27 -23 -11 -43 -20 -45 -20 -6 0 -3 1737 4
       1743 15 15 62 5 104 -22z m1554 -892 c-19 -44 -35 -83 -35 -85 0 -2 -89 -4
       -199 -4 l-199 0 -31 78 c-17 42 -31 80 -31 85 0 4 119 7 265 7 l264 0 -34 -81z
       m2006 59 c-5 -13 -21 -50 -34 -83 l-25 -60 -199 -3 -200 -2 -26 67 c-14 38
       -29 76 -33 86 -6 16 11 17 260 17 l266 0 -9 -22z m153 -315 l227 -6 -3 -116
       -3 -116 -1645 0 -1645 0 -3 117 -3 117 153 4 c323 6 2706 7 2922 0z m-2674
       -418 c0 -8 -79 -400 -175 -871 -96 -471 -175 -861 -175 -865 0 -5 -18 -9 -41
       -9 l-40 0 6 43 c6 43 195 1702 195 1712 0 3 52 5 115 5 94 0 115 -3 115 -15z
       m2730 2 c0 -19 189 -1676 195 -1715 6 -32 6 -32 -35 -32 -22 0 -40 3 -40 6 0
       3 -76 393 -170 865 -93 473 -170 867 -170 875 0 11 21 14 110 14 85 0 110 -3
       110 -13z m-3153 -279 c-3 -13 -9 -59 -12 -104 l-7 -81 -97 -7 c-123 -8 -322
       -8 -483 1 -127 6 -128 6 -128 30 0 49 51 122 105 150 55 29 79 31 354 32 l273
       1 -5 -22z m906 -11 c50 -30 107 -111 107 -154 l0 -23 -258 0 -259 0 21 106 20
       106 161 -4 c154 -3 162 -4 208 -31z m1929 -57 c9 -47 17 -93 18 -102 0 -17
       -19 -18 -260 -18 l-260 0 0 22 c0 36 40 107 76 134 60 46 97 54 259 51 l149
       -2 18 -85z m1129 58 c51 -30 98 -93 106 -144 l6 -34 -355 0 -355 0 -7 68 c-4
       37 -10 84 -13 105 l-6 38 289 -3 289 -3 46 -27z m-4361 -348 c0 -9 -210 -1025
       -225 -1087 -4 -16 -13 -23 -30 -23 -14 0 -25 2 -25 4 0 12 101 1027 106 1069
       l6 47 84 0 c51 0 84 -4 84 -10z m1270 1 c0 -17 99 -1025 106 -1069 5 -43 5
       -43 -22 -40 l-27 3 -113 540 c-62 297 -113 548 -114 558 0 15 11 17 85 17 47
       0 85 -4 85 -9z m1770 -1 c0 -9 -210 -1025 -225 -1087 -4 -16 -13 -23 -30 -23
       -14 0 -25 2 -25 4 0 12 101 1027 106 1069 l6 47 84 0 c51 0 84 -4 84 -10z
       m1270 1 c0 -17 99 -1025 106 -1069 5 -43 5 -43 -22 -40 l-27 3 -113 540 c-62
       297 -113 548 -114 558 0 15 11 17 85 17 47 0 85 -4 85 -9z"
            />
          </g>
        </svg>
      );
    case "Dinner":
      return (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="rgba(0, 0, 0, 0.54)"
            stroke="none"
          >
            <path
              d="M2470 4031 c-176 -58 -271 -242 -213 -413 l20 -58 -34 0 c-41 0 -83
       -37 -83 -74 0 -14 18 -72 41 -128 23 -57 50 -124 60 -150 l19 -48 -209 0 -209
       0 29 113 c35 137 35 152 4 182 l-24 25 -314 0 -314 0 -21 -23 c-28 -30 -27
       -59 3 -177 14 -52 25 -101 25 -107 0 -10 -31 -13 -123 -13 -175 0 -160 26
       -197 -341 -17 -162 -30 -295 -30 -296 0 -2 -90 -3 -200 -3 -200 0 -201 0 -225
       -25 -33 -32 -33 -78 0 -110 24 -25 26 -25 215 -25 l190 0 0 -27 c0 -16 -3 -52
       -6 -80 l-7 -53 -248 0 c-243 0 -248 0 -273 23 -24 20 -31 53 -106 464 -44 243
       -80 458 -80 477 0 25 7 40 26 55 34 27 67 27 99 -1 23 -21 29 -45 69 -262 47
       -261 53 -276 118 -276 35 0 78 39 78 72 0 42 -79 462 -95 505 -20 52 -91 118
       -141 132 -89 25 -169 9 -234 -46 -55 -47 -79 -102 -80 -176 0 -56 149 -907
       171 -975 6 -18 22 -46 36 -63 l26 -31 -37 -446 c-43 -521 -43 -525 -16 -552
       26 -26 83 -27 109 -1 26 26 25 23 61 466 17 209 33 401 36 428 l6 47 229 0
       c177 0 229 -3 229 -12 0 -7 -11 -123 -25 -258 -31 -307 -31 -323 -2 -351 19
       -19 30 -20 92 -16 80 6 130 25 198 74 27 19 50 33 52 31 1 -2 10 -88 19 -192
       14 -168 19 -192 38 -213 36 -39 110 -27 128 22 5 14 2 100 -9 229 -9 113 -16
       206 -15 206 19 0 86 -35 147 -76 91 -63 155 -84 251 -84 92 1 152 22 253 92
       114 79 197 88 280 30 117 -80 140 -94 180 -105 l43 -11 0 -83 0 -82 -221 -3
       c-218 -3 -221 -3 -240 -27 -26 -32 -25 -82 4 -109 l23 -22 515 0 516 0 21 23
       c27 29 28 77 3 108 -19 24 -22 24 -240 27 l-221 3 0 83 0 84 35 6 c19 4 78 36
       131 71 141 94 207 98 317 19 104 -74 161 -97 253 -102 108 -6 170 13 281 87
       48 32 99 61 113 65 l26 5 -18 -205 c-10 -129 -13 -215 -8 -229 18 -49 92 -61
       128 -22 19 21 24 45 38 213 9 104 18 190 19 192 2 2 26 -13 54 -33 99 -71 242
       -98 289 -55 28 27 28 45 -3 350 -14 135 -25 251 -25 258 0 9 52 12 229 12
       l229 0 6 -47 c3 -27 19 -219 36 -428 36 -443 35 -440 61 -466 26 -26 83 -25
       109 1 27 27 27 31 -16 552 l-37 446 26 31 c14 17 30 45 36 63 22 68 171 919
       171 975 -1 162 -149 268 -314 222 -50 -14 -121 -80 -141 -132 -16 -43 -95
       -463 -95 -505 0 -33 43 -72 78 -72 65 0 71 15 118 276 40 217 46 241 69 262
       32 28 65 28 99 1 19 -15 26 -30 26 -55 0 -19 -36 -234 -80 -477 -75 -411 -82
       -444 -106 -464 -25 -23 -30 -23 -273 -23 l-248 0 -7 53 c-3 28 -6 64 -6 80 l0
       27 190 0 c189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25 -25
       25 -225 25 -110 0 -200 1 -200 3 0 1 -13 134 -30 296 -37 367 -22 341 -197
       341 -92 0 -123 3 -123 13 0 6 11 55 25 107 31 120 31 150 2 178 -23 22 -29 22
       -336 22 l-312 0 -24 -25 c-31 -30 -31 -45 4 -182 l29 -113 -209 0 -209 0 19
       48 c10 26 37 93 60 150 23 56 41 114 41 128 0 37 -42 74 -84 74 l-34 0 20 58
       c52 146 -17 318 -156 389 -40 21 -69 27 -131 29 -44 2 -91 0 -105 -5z m170
       -173 c75 -52 99 -131 61 -205 -58 -114 -214 -118 -279 -7 -59 102 19 234 138
       234 32 0 57 -7 80 -22z m99 -510 c-12 -29 -33 -83 -48 -120 l-26 -68 -105 0
       -105 0 -26 68 c-15 37 -36 91 -48 120 l-21 52 200 0 200 0 -21 -52z m-1009
       -35 c0 -5 -7 -41 -17 -80 l-17 -73 -137 0 c-123 0 -138 2 -143 18 -3 9 -10 37
       -16 62 -6 25 -13 53 -16 63 -5 16 8 17 170 17 97 0 176 -3 176 -7z m2006 -10
       c-3 -10 -10 -38 -16 -63 -6 -25 -13 -53 -16 -62 -5 -16 -20 -18 -143 -18
       l-137 0 -17 73 c-10 39 -17 75 -17 80 0 4 79 7 176 7 162 0 175 -1 170 -17z
       m278 -350 c15 -114 136 -1357 134 -1365 -2 -5 -29 9 -61 32 -101 73 -160 94
       -257 94 -112 0 -151 -13 -261 -87 l-92 -62 -78 0 c-76 0 -79 1 -136 43 -32 23
       -87 57 -123 75 -59 29 -73 32 -160 32 -87 0 -101 -3 -160 -32 -36 -18 -91 -52
       -123 -75 -57 -42 -60 -43 -136 -43 l-77 0 -92 62 c-110 74 -150 87 -262 87
       -99 0 -138 -14 -257 -94 -87 -58 -105 -64 -179 -58 -46 4 -67 13 -138 62 -95
       65 -143 85 -230 93 -92 8 -173 -16 -270 -82 -45 -30 -82 -51 -84 -46 -2 7 122
       1282 133 1369 l6 42 1449 0 1448 0 6 -47z"
            />
          </g>
        </svg>
      );

    case "Terrace":
      return (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="rgba(0, 0, 0, 0.54)"
            stroke="none"
          >
            <path
              d="M2499 5009 c-41 -6 -365 -146 -1253 -543 -659 -295 -1209 -542 -1222
       -551 l-24 -15 0 -269 c0 -329 -13 -304 161 -310 l119 -3 0 -1159 0 -1159 -118
       0 c-171 0 -162 11 -162 -209 0 -213 -1 -211 110 -219 l70 -5 0 -206 c1 -222 3
       -231 54 -251 38 -14 4614 -14 4652 0 51 20 53 29 54 251 l0 206 70 5 c111 8
       110 6 110 219 0 220 9 209 -162 209 l-118 0 0 1159 0 1159 119 3 c174 6 161
       -19 161 310 l0 269 -25 16 c-57 37 -2418 1084 -2458 1090 -23 3 -51 7 -62 8
       -11 2 -45 -1 -76 -5z m-326 -362 c-48 -52 -221 -234 -383 -405 l-295 -312
       -519 0 c-286 0 -517 2 -514 5 5 5 1789 804 1796 805 2 0 -36 -42 -85 -93z
       m1597 -312 c465 -208 859 -384 875 -391 25 -11 -50 -13 -495 -14 l-525 0 -295
       312 c-162 171 -335 354 -384 405 -78 83 -85 93 -55 80 19 -8 414 -184 879
       -392z m-1293 -402 c-2 -2 -173 -2 -379 -1 l-374 3 375 396 376 396 3 -395 c1
       -217 1 -397 -1 -399z m389 562 c104 -110 267 -282 363 -382 l173 -183 -381 0
       -381 0 0 402 c0 391 1 402 18 383 10 -11 104 -110 208 -220z m2104 -870 l0
       -145 -1484 0 -1485 0 -20 -26 c-37 -47 -22 -103 33 -124 16 -6 119 -10 247
       -10 l219 0 0 -49 c0 -47 -1 -49 -33 -55 -58 -11 -171 -72 -220 -120 -94 -91
       -147 -211 -147 -331 0 -57 3 -66 26 -84 19 -15 41 -21 78 -21 51 0 52 -1 65
       -37 48 -134 165 -215 311 -215 146 0 263 81 311 215 13 36 14 37 65 37 37 0
       59 6 78 21 23 18 26 27 26 84 0 120 -54 241 -147 330 -51 49 -162 110 -219
       121 -33 6 -34 7 -34 55 l0 49 880 0 880 0 0 -555 c0 -390 3 -561 11 -579 14
       -31 57 -49 93 -40 57 14 56 -2 56 611 l0 563 60 0 60 0 0 -1160 0 -1160 -60 0
       -60 0 0 452 c0 450 0 452 -22 475 -30 32 -81 31 -113 -2 l-25 -24 0 -451 0
       -450 -160 0 -160 0 0 69 0 68 42 7 c84 14 78 -38 78 647 l0 608 -26 20 c-22
       17 -41 21 -109 21 -99 0 -161 -19 -207 -64 -72 -70 -73 -73 -76 -443 l-3 -332
       -148 -3 c-143 -3 -149 -4 -188 -31 -71 -49 -83 -84 -83 -244 0 -159 3 -167 78
       -179 l42 -7 0 -68 0 -69 -120 0 -120 0 0 344 0 343 72 7 c88 8 141 36 183 94
       26 38 30 52 30 110 0 83 -25 135 -84 177 l-43 30 -838 0 -838 0 -43 -30 c-59
       -42 -84 -94 -84 -177 0 -58 4 -72 30 -110 42 -58 95 -86 183 -94 l72 -7 0
       -343 0 -344 -115 0 -115 0 0 69 0 69 38 7 c69 13 72 21 72 178 0 160 -12 195
       -83 244 -39 27 -45 28 -188 31 l-148 3 -3 332 c-4 370 -4 373 -73 440 -49 48
       -110 67 -210 67 -68 0 -87 -4 -109 -21 l-26 -20 0 -295 c0 -281 1 -295 20
       -314 21 -21 69 -26 100 -10 33 18 40 66 40 286 0 232 -1 228 54 203 14 -7 30
       -25 36 -42 6 -19 10 -158 10 -367 0 -327 1 -338 21 -364 39 -49 65 -56 227
       -56 102 0 152 -4 160 -12 7 -7 12 -38 12 -70 l0 -58 -259 0 -259 0 -4 116 c-3
       114 -4 116 -32 141 -23 19 -36 23 -59 19 -65 -13 -67 -21 -67 -216 0 -200 1
       -204 78 -216 l42 -7 0 -68 0 -69 -160 0 -160 0 0 1160 0 1160 484 0 c311 0
       494 4 512 10 56 22 72 85 32 127 l-21 23 -784 0 -783 0 0 145 0 145 2405 0
       2405 0 0 -145z m-4410 -1465 l0 -1160 -60 0 -60 0 0 1160 0 1160 60 0 60 0 0
       -1160z m2131 878 c30 -14 71 -43 92 -64 36 -36 87 -120 87 -144 0 -7 -103 -10
       -310 -10 -202 0 -310 4 -310 10 0 26 46 104 83 141 98 98 236 124 358 67z m9
       -398 c-21 -39 -85 -72 -140 -72 -55 0 -119 33 -140 72 -11 20 -8 20 140 20
       148 0 151 0 140 -20z m1340 -850 l0 -490 -260 0 -260 0 0 58 c0 32 5 63 12 70
       8 8 58 12 160 12 162 0 188 7 227 56 20 26 21 37 21 364 0 210 4 348 11 367
       10 30 39 51 72 52 16 1 17 -29 17 -489z m-680 141 c14 -28 13 -38 -10 -61 -20
       -20 -33 -20 -790 -20 -757 0 -770 0 -790 20 -23 23 -24 33 -10 61 10 19 30 19
       800 19 770 0 790 0 800 -19z m-280 -311 l0 -70 -520 0 -520 0 0 70 0 70 520 0
       520 0 0 -70z m0 -275 l0 -45 -520 0 -520 0 0 45 0 45 520 0 520 0 0 -45z
       m-1590 -275 l0 -70 -145 0 -145 0 0 70 0 70 145 0 145 0 0 -70z m1590 0 l0
       -70 -520 0 -520 0 0 70 0 70 520 0 520 0 0 -70z m850 0 l0 -70 -150 0 -150 0
       0 70 0 70 150 0 150 0 0 -70z m1040 -285 l0 -55 -708 0 c-799 0 -752 5 -752
       -74 0 -31 6 -48 21 -62 20 -18 47 -19 635 -22 l614 -3 0 -154 0 -155 -2220 0
       -2220 0 0 155 0 155 1464 2 c1424 3 1464 4 1485 22 15 14 21 31 21 62 0 81
       146 74 -1597 74 l-1553 0 0 55 0 55 2405 0 2405 0 0 -55z"
            />
          </g>
        </svg>
      );

    case "TerraceB":
      return (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="rgba(0, 0, 0, 0.54)"
            stroke="none"
          >
            <path
              d="M489 5097 c-28 -18 -76 -100 -256 -427 l-222 -405 1275 -3 c701 -1
       1848 -1 2548 0 l1275 3 -207 405 c-174 341 -212 408 -241 427 l-34 23 -2052 0
       -2052 0 -34 -23z"
            />
            <path
              d="M3 3883 c7 -111 35 -194 99 -289 79 -119 195 -206 333 -251 94 -30
       253 -37 345 -14 90 22 210 84 275 144 l60 54 58 -53 c233 -216 621 -212 858 8
       l47 43 57 -52 c63 -57 190 -124 275 -144 30 -8 98 -13 150 -13 52 0 120 5 150
       13 85 20 212 87 275 144 l57 52 47 -43 c236 -220 626 -223 858 -8 l58 53 58
       -52 c69 -63 167 -116 257 -141 98 -27 255 -25 350 5 254 78 433 296 448 544
       l5 87 -2563 0 -2563 0 6 -87z"
            />
            <path
              d="M83 2370 c-58 -26 -83 -69 -83 -145 0 -61 105 -1342 115 -1402 11
       -65 82 -123 151 -123 30 0 35 -3 32 -17 -3 -10 -46 -129 -97 -265 -50 -136
       -91 -257 -91 -270 1 -41 38 -107 72 -128 48 -30 146 -24 184 10 36 35 39 41
       159 373 l108 297 191 -2 190 -3 113 -314 c62 -173 121 -323 131 -334 47 -52
       144 -64 203 -24 41 27 69 79 69 127 0 19 -43 150 -94 290 l-93 255 106 5 c125
       6 160 22 192 88 38 79 2 175 -78 208 -26 11 -142 14 -593 14 -308 0 -560 1
       -560 3 0 1 -25 287 -54 635 -61 705 -56 679 -136 720 -54 27 -82 27 -137 2z"
            />
            <path
              d="M4900 2368 c-80 -41 -75 -15 -136 -720 -29 -348 -54 -634 -54 -635 0
       -2 -252 -3 -560 -3 -451 0 -567 -3 -593 -14 -80 -33 -116 -129 -78 -208 32
       -66 67 -82 192 -88 l106 -5 -93 -255 c-51 -140 -93 -271 -94 -290 0 -48 28
       -100 69 -127 65 -44 177 -23 213 39 7 13 63 160 125 328 l111 305 197 0 197 0
       94 -275 c122 -358 119 -352 154 -385 26 -24 40 -30 93 -33 50 -2 69 1 95 18
       44 27 76 99 68 149 -3 20 -46 146 -95 281 l-88 245 41 5 c23 3 51 11 62 18 35
       19 73 70 79 105 10 60 115 1341 115 1402 0 76 -25 119 -83 145 -55 25 -83 25
       -137 -2z"
            />
            <path
              d="M1229 2107 c-55 -37 -61 -69 -57 -337 3 -267 6 -276 81 -311 41 -18
       87 -19 1307 -19 l1266 0 44 23 c76 38 80 55 79 322 0 257 -6 287 -58 322 l-34
       23 -1297 0 -1297 0 -34 -23z"
            />
            <path
              d="M2210 630 c0 -565 0 -565 55 -608 27 -21 36 -22 295 -22 259 0 268 1
       295 22 55 43 55 43 55 608 l0 520 -350 0 -350 0 0 -520z"
            />
          </g>
        </svg>
      );

    case "VIP":
      return (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="rgba(0, 0, 0, 0.54)"
            stroke="none"
          >
            <path
              d="M1060 5020 c-19 -19 -20 -33 -20 -220 0 -239 0 -240 100 -240 l60 0
       0 -1257 0 -1258 -79 79 c-64 64 -81 87 -86 118 -4 22 -22 70 -41 108 -77 153
       -235 231 -419 208 -127 -17 -247 -106 -303 -226 -24 -51 -27 -68 -27 -172 0
       -107 2 -119 29 -175 16 -33 51 -81 78 -108 l48 -48 0 -474 0 -475 -60 0 c-95
       0 -100 -9 -100 -180 l0 -140 -60 0 c-100 0 -100 -1 -100 -240 0 -187 1 -201
       20 -220 20 -20 33 -20 2460 -20 2427 0 2440 0 2460 20 19 19 20 33 20 220 0
       239 0 240 -100 240 l-60 0 0 140 c0 171 -5 180 -100 180 l-60 0 0 474 0 475
       49 50 c27 28 62 77 78 108 26 53 28 67 28 173 0 104 -3 120 -27 173 -35 74
       -121 160 -195 195 -53 24 -69 27 -173 27 -107 0 -119 -2 -175 -29 -107 -53
       -201 -175 -220 -285 -5 -30 -23 -54 -86 -117 l-79 -79 0 1258 0 1257 60 0
       c100 0 100 1 100 240 0 187 -1 201 -20 220 -20 20 -33 20 -1500 20 -1467 0
       -1480 0 -1500 -20z m2860 -220 l0 -80 -1360 0 -1360 0 0 80 0 80 1360 0 1360
       0 0 -80z m-160 -1562 l0 -1323 -78 -51 -77 -51 -5 1279 c-5 1252 -5 1280 -24
       1294 -17 12 -172 14 -1018 14 -985 0 -998 0 -1018 -20 -20 -20 -20 -33 -20
       -720 l0 -700 -80 0 -80 0 0 800 0 800 1200 0 1200 0 0 -1322z m-320 -257 l0
       -1258 -52 -23 c-286 -120 -531 -171 -828 -171 -285 0 -526 47 -782 153 l-98
       40 0 1259 0 1259 880 0 880 0 0 -1259z m-1920 -341 l0 -160 -80 0 -80 0 0 160
       0 160 80 0 80 0 0 -160z m-792 -266 c153 -63 191 -267 71 -381 -147 -139 -389
       -36 -389 167 0 91 47 167 130 211 44 23 137 25 188 3z m3840 0 c153 -63 191
       -267 71 -381 -147 -139 -389 -36 -389 167 0 91 47 167 130 211 44 23 137 25
       188 3z m-3048 -309 l0 -255 -80 52 -80 53 0 203 0 202 80 0 80 0 0 -255z
       m-420 -160 c47 -41 88 -75 93 -75 4 0 7 -286 7 -635 l0 -635 -80 0 -80 0 0
       140 c0 171 -5 180 -100 180 l-60 0 0 478 0 477 34 27 c18 15 46 47 62 72 16
       25 31 46 34 46 3 0 44 -34 90 -75z m3044 29 c16 -25 44 -57 62 -72 l34 -27 0
       -477 0 -478 -60 0 c-95 0 -100 -9 -100 -180 l0 -140 -80 0 -80 0 0 635 c0 349
       3 635 8 635 4 0 45 34 92 75 46 41 87 75 90 75 3 0 18 -21 34 -46z m-3424
       -614 l0 -440 -80 0 -80 0 0 440 0 440 80 0 80 0 0 -440z m3840 0 l0 -440 -80
       0 -80 0 0 440 0 440 80 0 80 0 0 -440z m-3123 340 c37 -22 70 -40 75 -40 4 0
       8 -238 8 -530 l0 -530 -80 0 -80 0 0 570 c0 314 2 570 6 570 3 0 35 -18 71
       -40z m2323 -530 l0 -570 -80 0 -80 0 0 529 0 529 28 11 c15 6 48 25 72 41 25
       17 48 30 53 30 4 0 7 -256 7 -570z m-1903 343 l143 -48 0 -252 c0 -239 1 -254
       20 -273 19 -19 33 -20 540 -20 507 0 521 1 540 20 19 19 20 34 20 273 l0 252
       143 48 c78 26 150 50 160 53 16 6 17 -21 17 -480 l0 -486 -880 0 -880 0 0 486
       c0 459 1 486 18 480 9 -3 81 -27 159 -53z m418 -98 c165 -24 495 -18 653 11
       l32 6 0 -176 0 -176 -400 0 -400 0 0 175 c0 96 3 175 8 175 4 0 52 -7 107 -15z
       m-1395 -735 l0 -80 -240 0 -240 0 0 80 0 80 240 0 240 0 0 -80z m3840 0 l0
       -80 -240 0 -240 0 0 80 0 80 240 0 240 0 0 -80z m160 -320 l0 -80 -2320 0
       -2320 0 0 80 0 80 2320 0 2320 0 0 -80z"
            />
            <path
              d="M2503 3902 c-10 -9 -42 -69 -72 -133 l-53 -116 -125 -19 c-149 -21
       -173 -35 -173 -96 0 -37 7 -49 94 -142 l93 -101 -18 -110 c-25 -150 -24 -177
       4 -203 39 -37 82 -29 201 37 l106 60 106 -60 c121 -67 165 -75 202 -36 27 28
       27 55 3 202 l-18 110 93 101 c87 93 94 105 94 142 0 62 -25 75 -173 97 l-124
       18 -58 124 c-32 68 -66 128 -76 134 -29 15 -87 11 -106 -9z m86 -327 c27 -64
       50 -80 134 -90 l58 -7 -52 -55 -52 -55 6 -79 c4 -43 4 -79 1 -79 -2 0 -27 11
       -54 25 -27 14 -59 25 -70 25 -11 0 -43 -11 -70 -25 -27 -14 -52 -25 -54 -25
       -3 0 -3 36 1 79 l6 79 -52 55 -52 55 58 7 c84 10 107 26 134 90 12 30 26 55
       29 55 3 0 17 -25 29 -55z"
            />
            <path
              d="M1840 2582 l0 -217 86 -130 c47 -72 93 -136 102 -142 20 -17 84 -17
       104 0 9 6 55 70 102 142 l86 130 0 217 0 218 -80 0 -80 0 0 -182 0 -181 -37
       -59 c-21 -32 -40 -58 -43 -58 -3 0 -22 26 -42 58 l-38 59 0 181 0 182 -80 0
       -80 0 0 -218z"
            />
            <path d="M2480 2440 l0 -360 80 0 80 0 0 360 0 360 -80 0 -80 0 0 -360z" />
            <path
              d="M2820 2780 c-19 -19 -20 -33 -20 -360 l0 -340 80 0 80 0 0 120 0 120
       70 0 c82 0 130 17 179 63 48 46 71 101 71 175 0 73 -19 124 -63 171 -57 59
       -95 71 -244 71 -120 0 -135 -2 -153 -20z m245 -154 c37 -16 52 -48 41 -92 -8
       -30 -57 -54 -111 -54 l-35 0 0 80 0 80 35 0 c20 0 51 -6 70 -14z"
            />
          </g>
        </svg>
      );

    default:
      return <GoPrimitiveDot size={size} color={color} />;
  }
}

interface IconProps {
  name: string;
  size?: number;
  color?: string;
}
