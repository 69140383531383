import { Checkbox as MuiCheckbox, FormControlLabel } from "@mui/material";

export default function Checkbox(props: {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  style?: any;
}) {
  return (
    <FormControlLabel
      style={{ marginLeft: "0px", ...props.style }}
      control={
        <MuiCheckbox
          disabled={props.disabled}
          checked={props.checked}
          onChange={(e: any) => props.onChange(e.target.checked)}
        />
      }
      label={props.label}
      labelPlacement={props.labelPlacement}
    />
  );
}
