import {
  Alert as MuiAlert,
  AlertProps,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";
import React from "react";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type Severity = "error" | "warning" | "info" | "success";

export type NotificationData = {
  message: string;
  severity: Severity;
};

const DurationBySeverity: Record<Severity, number> = {
  success: 4000,
  info: 4000,
  warning: 8000,
  error: 8000,
};

type NotificationProps = {
  message: string;
  severity: Severity;
  onClose: () => void;
};

export default function Notification(props: NotificationProps) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (
    _event?: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    props.onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={DurationBySeverity[props.severity]}
      onClose={handleClose}
    >
      <div>
        <Alert onClose={handleClose} severity={props.severity}>
          {props.message}
        </Alert>
      </div>
    </Snackbar>
  );
}

export function NotificationBar() {
  const { notification, clearNotification } = useNotifications();

  const handleClose = (
    _event?: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    clearNotification();
  };

  return (
    notification && (
      <Snackbar
        open={true}
        autoHideDuration={DurationBySeverity[notification.severity]}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <div>
          <Alert onClose={handleClose} severity={notification.severity}>
            {notification.message}
          </Alert>
        </div>
      </Snackbar>
    )
  );
}

export type NotificationsContextValue = {
  notification: NotificationData | null;
  showNotification: (notification: NotificationData) => void;
  clearNotification: () => void;
};

export const NotificationsContext = React.createContext<NotificationsContextValue>(
  {
    notification: null,
    showNotification: () => {},
    clearNotification: () => {},
  }
);

export function NotificationsProvider({ children }: any) {
  const [
    notification,
    setNotification,
  ] = React.useState<NotificationData | null>(null);

  const showNotification = React.useCallback(
    (notification: NotificationData) => {
      setNotification(notification);
    },
    [setNotification]
  );

  const clearNotification = React.useCallback(() => {
    setNotification(null);
  }, [setNotification]);

  return (
    <NotificationsContext.Provider
      value={{
        notification,
        showNotification,
        clearNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export function useNotifications() {
  return React.useContext(NotificationsContext);
}
