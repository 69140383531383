import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "../../../components/Notification";
import { getPlatform } from "../../../helpers/get-user-platform";

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    backgroundColor: "#ffffff",
  },
  header: {
    backgroundColor: "#C8A063",
    color: "#ffffff",
  },
  button: {
    backgroundColor: "#C8A063",
    color: "#ffffff",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#C8A063",
    },
  },
  boxed: {
    marginLeft: "4rem",
    marginRight: "4rem",
    background: "#fff",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bold",
    padding: "25px",
    boxShadow: "inset #C8A063 0 0 0 5px",
  },
  list: {
    columns: "4 auto",
    listStyleType: "none",
    marginLeft: "3rem",
    marginRight: "3rem",
    maxWidth: "1200px",
    textAlign: "start",
    columnGap: "2%",
  },
});

const REQUIRED_KEYS = [
  "bannerText",
  "name",
  "slug",
  "title",
  "description",
  "metaTitle",
  "listTitle",
  "listTitleDescription",
  "tagNames",
  "locationNames",
];

const OPTIONAL_KEYS = [
  "score",
  "showInHome",
  "isHomeContent",
  "homePriority",
  "allTags",
  "allLocations",
  "imageName",
  "priceMinimum",
  "priceMaximum",
];

function getMissingKeys(
  csvDataArray: Record<string, any>[],
  requiredKeys: string[],
  optionalKeys: string[]
): { missingKeys: string[]; unexpectedKeys: string[] } {
  const missingKeys: string[] = [];
  const unexpectedKeys: string[] = [];

  // Iterate through each object of the csvDataArray
  for (const originalObject of csvDataArray) {
    // Filter out any keys that are not specified as required
    const filteredObj = Object.keys(originalObject)
      .filter((key) => {
        // Keep track of keys that are neither required nor optional (= should not be a column)
        if (![...requiredKeys, ...optionalKeys].includes(key))
          unexpectedKeys.push(key);
        return requiredKeys.includes(key);
      })
      .reduce((obj: any, key: string) => {
        obj[key] = originalObject[key];
        return obj;
      }, {});

    // Check if the filtered object contains all of the specified keys
    if (Object.keys(filteredObj).length !== requiredKeys.length) {
      const missing = requiredKeys.filter(
        (key) => !Object.keys(filteredObj).includes(key)
      );
      missingKeys.push(...missing);
    }
  }

  // Return the array of unique missing keys
  return {
    missingKeys: [...new Set(missingKeys)],
    unexpectedKeys: [...new Set(unexpectedKeys)],
  };
}

function checkBooleanFields(csvDataArray: Record<string, any>[]) {
  return csvDataArray.every(
    (collectionInput) =>
      (collectionInput.showInHome === "" ||
        collectionInput.showInHome === "1") &&
      (collectionInput.isHomeContent === "" ||
        collectionInput.isHomeContent === "1") &&
      (collectionInput.allTags === "" || collectionInput.allTags === "1") &&
      (collectionInput.allLocations === "" ||
        collectionInput.allLocations === "1")
  );
}

export default function ImportCollection() {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [array, setArray] = useState<Array<Record<string, string>>>([]);
  const [importCollections] = useMutation(IMPORT_COLLECTIONS_MUTATION);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotifications();

  const fileReader = new FileReader();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0]);
  };

  function resetImportProcess(e: React.FormEvent<HTMLFormElement>) {
    // reset input file, so user can attach new file
    setFile(undefined);
    // @ts-ignore
    e.target[0].value = null;

    setLoading(false);
  }

  function csvToArray(inputString: string) {
    const platform = getPlatform().toLowerCase();
    const isWindows = /win/.test(platform);

    // for unix users it needs to be index of \n, for windows \r\n because of different CSV formats on saving
    const csvHeader = isWindows
      ? inputString.slice(0, inputString.indexOf("\r\n")).split(",")
      : inputString.slice(0, inputString.indexOf("\n")).split(",");

    let previousCharacter = "",
      row = [""],
      resultingMultiDimensionalArray = [row],
      columnIndex = 0,
      rowIndex = 0,
      isQuotedString = !0,
      currentCharacter;

    for (currentCharacter of inputString) {
      if ('"' === currentCharacter) {
        if (isQuotedString && currentCharacter === previousCharacter)
          row[columnIndex] += currentCharacter;
        isQuotedString = !isQuotedString;
      } else if ("," === currentCharacter && isQuotedString)
        currentCharacter = row[++columnIndex] = "";
      else if ("\n" === currentCharacter && isQuotedString) {
        if ("\r" === previousCharacter)
          row[columnIndex] = row[columnIndex].slice(0, -1);
        row = resultingMultiDimensionalArray[++rowIndex] = [
          (currentCharacter = ""),
        ];
        columnIndex = 0;
      } else row[columnIndex] += currentCharacter;
      previousCharacter = currentCharacter;
    }

    const csvRows = resultingMultiDimensionalArray.map((rowContent) =>
      rowContent.join(",")
    );

    // filter out the rows with only location key
    const newArray = csvRows
      .map((rowContent, i) => {
        // don't return first row (headers) and empty rows
        if (!rowContent || rowContent === " " || i === 0) return [];

        const values = rowContent.split(",");

        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {} as Record<string, any>);

        return obj;
      })
      .filter((collectionObj) => Object.keys(collectionObj).length > 1);

    return newArray;
  }

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (file) {
      fileReader.onload = function (event) {
        if (event && event.target) {
          const text = event.target.result as string;
          const csvDataArray = csvToArray(text);

          // check if column names match the expected format
          const validatedKeys = getMissingKeys(
            csvDataArray,
            REQUIRED_KEYS,
            OPTIONAL_KEYS
          );

          const isBooleanCompatibleInput = checkBooleanFields(csvDataArray);

          if (validatedKeys.missingKeys.length > 0) {
            showNotification({
              message: `The uploaded CSV file does not contain all required columns: ${validatedKeys.missingKeys}`,
              severity: "error",
            });
            resetImportProcess(e);
            return;
          }

          if (validatedKeys.unexpectedKeys.length > 0) {
            showNotification({
              message: `The uploaded CSV contains unexpected column names: ${validatedKeys.unexpectedKeys}`,
              severity: "error",
            });
            resetImportProcess(e);
            return;
          }

          // change the format to collections
          // rewrite the collection rows to the format expected by the API
          const formattedCollectionInput = csvDataArray.map((collection) => {
            collection.score = Number(collection.score) || 0;

            collection.priceMinimum =
              collection.priceMinimum === ""
                ? null
                : Number(collection.priceMinimum);
            collection.priceMaximum =
              collection.priceMaximum === ""
                ? null
                : Number(collection.priceMaximum);

            if (collection.tagNames && collection.tagNames.length > 0) {
              collection.tagNames = collection.tagNames.split(";");
            } else {
              showNotification({
                message: `The uploaded CSV is missing tagNames input.`,
                severity: "error",
              });
              resetImportProcess(e);
              return undefined;
            }

            if (
              collection.locationNames &&
              collection.locationNames.length > 0
            ) {
              collection.locationNames = collection.locationNames.split(";");
            } else {
              showNotification({
                message: `The uploaded CSV is missing locationNames input.`,
                severity: "error",
              });
              resetImportProcess(e);
              return undefined;
            }

            if (isBooleanCompatibleInput) {
              collection.showInHome = Boolean(collection.showInHome) || false;
              collection.isHomeContent =
                Boolean(collection.isHomeContent) || false;
              collection.allTags = Boolean(collection.allTags) || false;
              collection.allLocations =
                Boolean(collection.allLocations) || false;
              collection.homePriority = Number(collection.homePriority) || null;
            } else {
              showNotification({
                message: `The uploaded CSV contains unexpected column values for 'showInHome', 'isHomeContent', 'allTags' or/and 'allLocations'. Please make sure to only set '1' or no value.`,
                severity: "error",
              });
              resetImportProcess(e);
              return undefined;
            }

            if (
              (collection.priceMaximum === null &&
                collection.priceMinimum !== null) ||
              (collection.priceMinimum === null &&
                collection.priceMaximum !== null)
            ) {
              showNotification({
                message: `If you set a price minimum or a price maximum, you must also set the other. `,
                severity: "error",
              });
              resetImportProcess(e);
              return undefined;
            }

            if (collection.priceMaximum > collection.priceMinimum) {
              showNotification({
                message: `PriceMaximum must be larger than priceMinimum`,
                severity: "error",
              });
              resetImportProcess(e);
              return undefined;
            }

            return collection;
          });

          if (
            !formattedCollectionInput.every(
              (collection) => collection === undefined
            )
          ) {
            importCollections({
              variables: { input: { collections: formattedCollectionInput } },
            })
              .then((res) => {
                showNotification({
                  message: `Collections were imported successfully`,
                  severity: "success",
                });
                setArray(res.data.importPlanCollections.collections);
              })
              .catch((err: any) => {
                showNotification({
                  message: `Importing collections failed! ${err}`,
                  severity: "error",
                });
              })
              .finally(() => {
                resetImportProcess(e);
              });
          }
        }
      };
      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h1>IMPORT COLLECTIONS </h1>
      <div className={styles.boxed}>
        <ul style={{ textAlign: "left" }}>
          <li>
            For importing collections through a CSV file, please make sure the
            following column names are provided and contain a valid value.
          </li>
          <li>
            The content mostly follows the same rules compared to manually
            saving a collection.
          </li>
          <li>
            You cannot use <em>","</em> comma character anywhere.
          </li>
          <li>Do not add new columns.</li>
          <li>
            imageName must match the name of a collection image or remain empty.
          </li>
          <li>
            The order of columns does not matter, but the names are case
            sensitive.
          </li>
          <li>
            <em>tagNames and locationNames</em> can contain multiple values.
            They should be separated by a <em>";"</em> character.
          </li>
          <li>
            <em>showInHome, isHomeContent, allTags and allLocations</em> accept
            a <em>"1"</em> character for setting the value or no input if it
            should not be set.
          </li>
          <li>
            Columns with optional input:{" "}
            <em>
              score, showInHome, isHomeContent, homePriority, allTags,
              allLocations, imageName, priceMinimum, priceMaximum
            </em>
            .
          </li>
        </ul>
      </div>
      <ul className={styles.list}>
        {[...REQUIRED_KEYS, ...OPTIONAL_KEYS]
          .sort((keyX, keyY) => keyX.length - keyY.length)
          .map((key) => (
            <li key={key}>{key}</li>
          ))}
      </ul>
      <form onSubmit={handleOnSubmit}>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <Button type="submit" className={styles.button} disabled={loading}>
          {loading ? "IMPORTING..." : "IMPORT CSV"}
        </Button>
      </form>

      <br />

      <Table>
        <TableHead>
          <TableRow className={styles.header}>
            {headerKeys.map((key) => (
              <TableCell key={key}>{key.toUpperCase()}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {array.map((item, index) => (
            <TableRow key={index}>
              {Object.values(item).map((val, index) => (
                <TableCell
                  key={index}
                  style={{ color: Number(val) === 0 ? "red" : "black" }}
                >
                  {val}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

const IMPORT_COLLECTIONS_MUTATION = gql`
  mutation ImportCollectionsMutation($input: ImportPlanCollectionsInput!) {
    importPlanCollections(input: $input) {
      collections {
        id
        name
        title
        slug
        numPlans
        numPublishedPlans
      }
    }
  }
`;
