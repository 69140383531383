import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import ProductsForm from "./ProductsForm";
import { Product } from "../../../types";

export default function Products() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<{ templateProducts: Product[] }>(
    TEMPLATE_PRODUCTS_QUERY,
    {
      variables: { id },
      fetchPolicy: "network-only",
    }
  );

  if (!data || !data.templateProducts || loading || error) {
    return null;
  }
  const { templateProducts } = data;
  // Sort products by created_at in descending order
  const sortedProducts = templateProducts.sort((a, b) => {
    const dateA = new Date(a.created_at ?? 0).getTime();
    const dateB = new Date(b.created_at ?? 0).getTime();
    return dateA - dateB;
  });

  return <ProductsForm products={sortedProducts} />;
}

const TEMPLATE_PRODUCTS_QUERY = gql`
  query TemplateProductsQuery($id: ID!) {
    templateProducts(templateId: $id) {
      id
      templateId
      stock
      minimumDeliveryDays
      name
      overrideName
      sellingPrice
      created_at
    }
  }
`;
