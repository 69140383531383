import {
  Grid,
  Card,
  CardHeader,
  Typography,
  Avatar,
  CardContent,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";

type InfoCardProps = {
  title: string;
  avatar: {
    icon: JSX.Element;
    color: string;
  };
  content: React.ReactNode;
  subContent?: React.ReactNode;
  link?: string;
};

type OptionalLinkProps = { link?: string };
const OptionalLink = ({
  link,
  children,
}: React.PropsWithChildren<OptionalLinkProps>) =>
  link ? (
    <Link to={link} style={{ textDecoration: "none" }}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

export const InfoCard = ({
  title,
  avatar,
  content,
  subContent,
  link,
}: InfoCardProps) => {
  return (
    <Grid item xs={6} md={4}>
      <OptionalLink link={link}>
        <Card elevation={1} style={{ height: "100%" }}>
          <CardHeader
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
            title={
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
            }
            avatar={
              <Avatar style={{ backgroundColor: avatar.color }}>
                {avatar.icon}
              </Avatar>
            }
          />
          <CardContent
            style={{
              textAlign: "center",
              marginTop: 0,
              paddingTop: 6,
              paddingBottom: 6,
            }}
          >
            <Typography variant="h2">{content}</Typography>
            {subContent && (
              <>
                <Divider />

                <Typography
                  variant="subtitle1"
                  component="h4"
                  style={{ color: "gray", marginTop: 12 }}
                >
                  {subContent}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </OptionalLink>
    </Grid>
  );
};
