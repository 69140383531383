import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function DeleteContractDialog({
  open,
  handleClose,
  remove,
}: DeleteContractDialogProps) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Remove contract?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this contract?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              remove();
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

type DeleteContractDialogProps = {
  open: boolean;
  handleClose: () => void;
  remove: () => void;
};
