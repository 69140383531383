import { useDrag, useDrop } from "react-dnd";
import React from "react";
import TextField from "../../../../../components/TextField";
import { ItemType as TextFieldType } from "../../ActivityReservations/Items/Item";
import { PriceType } from "../../../../Plans/Edit/Activities/ActivityForm/PriceTypesForm";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

const ItemType = "PRICE_TYPE";

interface PriceTypeItemProps {
  priceTypes: PriceType[];
  type: TextFieldType;
  index: number;
  movePriceType: (fromIndex: number, toIndex: number) => void;
}

const PriceTypeOldItem: React.FC<PriceTypeItemProps> = ({
  type,
  index,
  movePriceType,
  priceTypes,
}: PriceTypeItemProps) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        movePriceType(draggedItem.index, index);
        draggedItem.index = index; // Update the dragged item's index to avoid redundant hover calls.
      }
    },
  });

  return (
    <div
      ref={(node) => dragRef(dropRef(node))}
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "12px",
        cursor: "grab",
        opacity: isDragging ? 0.3 : 1,
      }}
    >
      {index <= priceTypes.length - 1 ? (
        <KeyboardBackspaceRoundedIcon color="error" />
      ) : (
        <div style={{ minWidth: "25px" }} />
      )}
      <TextField
        style={{
          marginTop: 0,
          marginRight: 8,
        }}
        readOnly={true}
        value={type?.priceType ?? ""}
        label={"Price type"}
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
      <TextField
        style={{
          marginTop: 0,
          marginRight: 8,
        }}
        readOnly={true}
        value={type?.unitType ?? ""}
        label={"Unit type"}
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
      <TextField
        style={{ marginTop: 0 }}
        readOnly={true}
        value={type?.quantity ?? ""}
        label={"Quantity"}
        sx={{
          "& .MuiInputBase-input": {
            cursor: "grab",
          },
        }}
      />
    </div>
  );
};

export default PriceTypeOldItem;
