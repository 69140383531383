import { gql } from "@apollo/client";
import { Tag } from "../../../types";

export type DeleteTagMutationInput = Pick<Tag, "id">;

export type DeleteTagMutationResponse = { deleteTag: Tag };

export const DELETE_TAG_MUTATION = gql`
  mutation DeleteTagMutation($id: Int!) {
    deleteTag(id: $id) {
      id
      name
      tagCategoryId
    }
  }
`;
