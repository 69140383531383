import { Link } from "react-router-dom";
import DataTabs from "../../../../components/DataTabs";
import Button from "../../../../components/Button2";
import ReviewTab from "./ReviewTab";
import ReviewImagesTab from "./ReviewImagesTab";

export default function ViewReview() {
  return (
    <>
      <Button
        style={{ marginBottom: "15px" }}
        component={Link}
        to={`/bookings/reviews`}
      >
        Back to list
      </Button>
      <DataTabs
        tabs={[
          { title: "Review", content: <ReviewTab /> },
          { title: "Images", content: <ReviewImagesTab /> },
        ]}
      />
    </>
  );
}
