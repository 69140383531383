import { Button, TableCell, TableRow } from "@mui/material";
import { useMemo } from "react";
import { RecurrenceRule } from "../../types";
import { AllHolidays } from "./NewRecurrenceRule";

type RecurrenceRuleRowProps = Pick<RecurrenceRule, "hours" | "minutes"> & {
  dates: string[];
  setRecurrenceRules: React.Dispatch<React.SetStateAction<RecurrenceRule[]>>;
};

const prependZero = (n: number) => `${n}`.padStart(2, "0");

export default function RecurrenceRuleRow({
  dates,
  hours,
  minutes,
  setRecurrenceRules,
}: RecurrenceRuleRowProps) {
  const isHolidayRule = useMemo(() => AllHolidays.includes(dates[0]), [dates]);

  return (
    <TableRow>
      <TableCell>
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          {dates.join(", ")}
        </div>
      </TableCell>
      <TableCell>{hours.map(prependZero).join(", ")}</TableCell>
      <TableCell>{minutes.map(prependZero).join(", ")}</TableCell>
      <TableCell>
        <Button
          onClick={() => {
            setRecurrenceRules((rules) =>
              rules
                .map((rule) =>
                  rule.hours !== hours && rule.minutes !== minutes
                    ? rule
                    : {
                        ...rule,
                        ...(isHolidayRule
                          ? { holidays: undefined }
                          : { weekdays: [] }),
                      }
                )
                .filter(
                  (rule) =>
                    rule.weekdays.length > 0 || (rule.holidays ?? []).length > 0
                )
            );
          }}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}
