import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { Venue } from "../index";
import Notification, {
  NotificationData,
} from "../../../../components/Notification";

export default function DeleteVenueDialog(props: DeleteVenueDialogProps) {
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );
  const [deleteVenue] = useMutation(DELETE_VENUE_MUTATION);
  const numPublishedPlans = props.venue ? props.venue.publishedPlans.length : 0;

  return (
    <>
      {props.venue && (
        <Dialog open={props.open} onClose={props.onClose}>
          <DialogTitle>Delete Venue</DialogTitle>

          <DialogContent>
            {numPublishedPlans > 0 ? (
              <>
                Sorry, you can't delete this venue. It is being used by these
                published plans:
                <br />
                <br />
                <ul>
                  {props.venue.publishedPlans.map((plan) => (
                    <li key={`publishedPlan_${plan.id}`}>
                      <a
                        style={{ color: "black" }}
                        href={`/plans/${plan.id}`}
                        key={`publishedPlanUrl_${plan.id}`}
                      >
                        {plan.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                Are you sure you want to delete venue <b>{props.venue.name}</b>?
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              disabled={numPublishedPlans > 0}
              onClick={deleteVenueHandler}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function deleteVenueHandler() {
    if (props.venue) {
      const venueName = props.venue.name;

      deleteVenue({
        variables: { venueId: props.venue.id },
        refetchQueries: ["VenuesQuery"],
      }).then(() => {
        setNotification({
          message: `Venue ${venueName} was deleted`,
          severity: "success",
        });
      });
    }
    props.onClose();
  }
}

const DELETE_VENUE_MUTATION = gql`
  mutation DeleteVenueMutation($venueId: String!) {
    deleteVenue(input: { venueId: $venueId }) {
      success
    }
  }
`;

interface DeleteVenueDialogProps {
  open: boolean;
  onClose: () => void;
  venue: Venue | null;
}
