import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { CloudinaryProvider } from "./components/Cloudinary";
import { NotificationsProvider } from "./components/Notification";
import { GlobalContextProvider } from "./GlobalContext";
import Menu from "./components/Menu";
import Content from "./components/Content";
import createApolloClient from "./helpers/create-apollo-client";
import CustomTheme from "./CustomTheme";
import TagsProvider from "./providers/TagProvider";

const client = createApolloClient();

const CloudinaryFolder =
  process.env.ENVIRONMENT === "production" ? "omedetaro" : "omedetaro-staging";

// TODO - Move clodName and autoUploadMappingFolder to ENV vars
function App() {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");

    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <CloudinaryProvider
      cloudName={"de9yygzcj"}
      autoUploadMappingFolder={CloudinaryFolder}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <NotificationsProvider>
              <GlobalContextProvider>
                <TagsProvider>
                  <CustomTheme>
                    <Menu />
                    <Content />
                  </CustomTheme>
                </TagsProvider>
              </GlobalContextProvider>
            </NotificationsProvider>
          </BrowserRouter>
        </ApolloProvider>
      </LocalizationProvider>
    </CloudinaryProvider>
  );
}

export default App;
