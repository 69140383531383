import { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import Checkbox from "../../../components/Checkbox";
import TextField from "../../../components/TextField";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import AutocompleteDialog from "../../../components/AutocompleteDialog";
import { Template } from "../../../types";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

export default function Basic(props: { templateId: string }) {
  const { showNotification } = useNotifications();
  const {
    loading: templateLoading,
    error: templateError,
    data: templateData,
  } = useQuery(GET_TEMPLATE, {
    variables: { id: props.templateId },
  });
  const { data: unavailableDatesData } = useQuery(GET_TEMPLATE_UNAVAILABLE, {
    variables: { templateId: props.templateId },
  });

  const [name, setName] = useState<string>("");
  const [giftmallUrl, setGiftmallUrl] = useState<string>("");
  const [overrideDisplayName, setOverrideDisplayName] = useState<string>("");
  const [description, setTemplateDescription] = useState<string>("");
  const [defaultHowToGive, setDefaultHowToGive] = useState<string>("");
  const [defaultWhenToGive, setDefaultWhenToGive] = useState<string>("");
  const [commissionRate, setTemplateCommissionRate] = useState<number>(0);
  const [withPaperbag, setTemplateWithPaperbag] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const template: Template = templateData?.template;
  const templateUnavailable: any = unavailableDatesData?.templateUnavailable;

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const targetDateString = date.toISOString().split("T")[0];

      if (
        selectedDates.some(
          (d) => d.toISOString().split("T")[0] === targetDateString
        )
      ) {
        setSelectedDates(
          selectedDates.filter(
            (d) => d.toISOString().split("T")[0] !== targetDateString
          )
        );
      } else {
        setSelectedDates([...selectedDates, date]);
      }
    }
  };
  useEffect(() => {
    setName(template?.name ?? template?.name ?? "");
    setGiftmallUrl(template?.giftmallUrl ?? template?.giftmallUrl ?? "");
    setOverrideDisplayName(
      template?.overrideDisplayName ?? template?.displayName ?? ""
    );
    setTemplateDescription(template?.description ?? "");
    setDefaultHowToGive(template?.defaultHowToGive ?? "");
    setDefaultWhenToGive(template?.defaultWhenToGive ?? "");
    setTemplateCommissionRate(template?.commissionRate ?? 0);
    setTemplateWithPaperbag(template?.withPaperbag ?? false);
    const unavailableDatesConvert = templateUnavailable?.unavailableDates?.map(
      (item: string) => new Date(item)
    );
    setSelectedDates(unavailableDatesConvert ?? []);
  }, [
    template?.commissionRate,
    template?.defaultHowToGive,
    template?.defaultWhenToGive,
    template?.description,
    template?.displayName,
    template?.overrideDisplayName,
    template?.withPaperbag,
    unavailableDatesData?.templateUnavailable,
  ]);

  const [saveTemplate] = useMutation(SAVE_TEMPLATE_MUTATION, {
    update(cache) {
      const { template }: any = cache.readQuery({
        query: GET_TEMPLATE,
        variables: { id: props.templateId },
      });
      cache.writeQuery({
        query: GET_TEMPLATE,
        variables: { id: props.templateId },
        data: {
          template: {
            ...template,
            name,
            giftmallUrl,
            overrideDisplayName,
            description,
            defaultHowToGive,
            defaultWhenToGive,
            commissionRate,
            withPaperbag,
          },
        },
      });
      cache.writeQuery({
        query: GET_TEMPLATE_UNAVAILABLE,
        variables: { templateId: props.templateId },
        data: {
          templateUnavailable: {
            unavailableDates: selectedDates,
          },
        },
      });
    },
  });

  const {
    loading: giftingOptionsLoading,
    error: giftingOptionsError,
    data: giftingOptionsData,
  } = useQuery(GET_GIFTING_DEFAULTS);

  const [howToOptions, setHowToOptions] = useState<string[]>([]);
  const [whenToOptions, setWhenToOptions] = useState<string[]>([]);

  useEffect(() => {
    setHowToOptions(giftingOptionsData?.giftingDefaults.howToGive ?? []);
    setWhenToOptions(giftingOptionsData?.giftingDefaults.whenToGive ?? []);
  }, [giftingOptionsData, giftingOptionsLoading, giftingOptionsError]);

  const [saveDefaults] = useMutation(SAVE_GIFTING_DEFAULTS_MUTATION, {
    update(cache) {
      const { giftingDefaults }: any = cache.readQuery({
        query: GET_GIFTING_DEFAULTS,
      });
      cache.writeQuery({
        query: GET_GIFTING_DEFAULTS,
        data: {
          giftingDefaults: {
            ...giftingDefaults,
            howToGive: howToOptions,
            whenToGive: whenToOptions,
          },
        },
      });
      cache.writeQuery({
        query: GET_TEMPLATE_UNAVAILABLE,
        variables: { templateId: props.templateId },
        data: {
          templateUnavailable: {
            unavailableDates: selectedDates,
          },
        },
      });
    },
  });

  if (giftingOptionsLoading || templateLoading) return <>Loading...</>;
  if (giftingOptionsError || templateError)
    return <>Error! {giftingOptionsError!.message}</>;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title="Template Info" />
          <Divider />
          <CardContent>
            <Checkbox
              label="Use Paperbag"
              checked={withPaperbag}
              onChange={() => setTemplateWithPaperbag(!withPaperbag)}
            />
            <TextField size="small" readOnly label={"ID"} value={template.id} />
            <TextField
              required
              size="small"
              label={"Name"}
              value={name}
              onChange={(e: any) => {
                setName(e.target.value);
              }}
            />
            <TextField
              required
              label="Shop URL"
              size="small"
              value={giftmallUrl}
              onChange={(e: any) => {
                setGiftmallUrl(e.target.value);
              }}
            />
            <TextField
              required
              label={"Display name"}
              value={overrideDisplayName}
              onChange={(e: any) => {
                setOverrideDisplayName(e.target.value);
              }}
            />
            <TextField
              multiline={true}
              label="Description"
              value={description}
              onChange={(e: any) => {
                setTemplateDescription(e.target.value);
              }}
            />
            <AutocompleteDialog
              value={defaultHowToGive}
              options={howToOptions}
              onAdd={(newOption: string) =>
                setWhenToOptions((howToOptions) => [...howToOptions, newOption])
              }
              setValue={setDefaultHowToGive}
              label="Default how to give"
            />
            <AutocompleteDialog
              value={defaultWhenToGive}
              options={whenToOptions}
              onAdd={(newOption: string) =>
                setWhenToOptions((whenToOptions) => [
                  ...whenToOptions,
                  newOption,
                ])
              }
              setValue={setDefaultWhenToGive}
              label="Default when to give"
            />
            <TextField
              required
              error={templateError}
              label="Commission rate"
              value={commissionRate}
              type="number"
              size="small"
              helperText={
                templateError ? "Negative values are not allowed" : ""
              }
              onChange={(e: any) => {
                if (e.target.value >= 0) {
                  setTemplateCommissionRate(
                    Number(parseFloat(e.target.value).toFixed(2))
                  );
                }
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title="Non-delivery Dates" />
          <Divider />
          <CardContent>
            <DatePicker
              inline
              dateFormat="yyyy/MM/dd"
              selected={null}
              onSelect={handleDateChange}
              onChange={() => {}}
              dayClassName={(date) =>
                selectedDates.some((d) => d.getTime() === date.getTime())
                  ? "selected-non-delevery-day"
                  : ""
              }
            />

            <div>
              <h3>Selected dates:</h3>
              <ul>
                {selectedDates.map((date, index) => (
                  <li key={index}>{format(date, "yyyy-MM-dd")}</li>
                ))}
              </ul>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent style={{ padding: "10px" }}>
            <Button onClick={handleSaveTemplate}> Save </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  async function handleSaveTemplate() {
    // saveDefaults must happen before saveTemplate
    // to ensure the tag exists in the db that template wants to reference
    saveDefaults({
      variables: {
        input: {
          howToGive: defaultHowToGive,
          whenToGive: defaultWhenToGive,
        },
      },
    })
      .then(() =>
        saveTemplate({
          variables: {
            input: {
              id: props.templateId,
              name,
              giftmallUrl,
              overrideDisplayName,
              description,
              withPaperbag,
              commissionRate,
              defaultHowToGive,
              defaultWhenToGive,
              unavailableDates: selectedDates,
            },
          },
        })
      )
      .then(() => {
        showNotification({
          message: `Template was edited`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `Editing template failed! ${err}`,
          severity: "error",
        });
      });
  }
}

const GET_TEMPLATE = gql`
  query TemplateQuery($id: ID!) {
    template(templateId: $id) {
      id
      name
      displayName
      overrideDisplayName
      defaultHowToGive
      defaultWhenToGive
      description
      giftmallUrl
      withPaperbag
      commissionRate
    }
  }
`;

const GET_TEMPLATE_UNAVAILABLE = gql`
  query GetTemplateUnavailable($templateId: ID!) {
    templateUnavailable(templateId: $templateId) {
      id
      templateId
      unavailableDates
    }
  }
`;

const SAVE_TEMPLATE_MUTATION = gql`
  mutation SaveTemplateMutation($input: SaveTemplateInput!) {
    saveTemplate(input: $input) {
      success
    }
  }
`;

const GET_GIFTING_DEFAULTS = gql`
  query GiftingDefaultsQuery {
    giftingDefaults {
      howToGive
      whenToGive
    }
  }
`;

const SAVE_GIFTING_DEFAULTS_MUTATION = gql`
  mutation AddGiftingDefaultsMutation($input: AddGiftingDefaultsInput) {
    addGiftingDefaults(input: $input) {
      success
      error
    }
  }
`;
