import { Typography, Divider, Button, ButtonGroup } from "@mui/material";
import { useEffect, useState, useCallback } from "react";

const AllHours: number[][] = [
  [0, 1, 2, 3, 4, 5, 6, 7],
  [8, 9, 10, 11, 12, 13, 14, 15],
  [16, 17, 18, 19, 20, 21, 22, 23],
];
const AllMinutes: number[] = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

const prependZero = (n: number) => `${n}`.padStart(2, "0");

type OpenTimesProps = {
  triggerReset: boolean;
  setTriggerReset: (value: boolean) => void;
  setTimesMissing: (value: boolean) => void;
  setOpenTimes: (values: string[]) => void;
};

function MultiTimeSelector(props: OpenTimesProps) {
  const [hours, setHours] = useState<number[]>([]);
  const [minutes, setMinutes] = useState<number[]>([]);
  const {
    setTimesMissing,
    setOpenTimes,
    triggerReset,
    setTriggerReset,
  } = props;

  const someFieldsAreEmpty = hours.length === 0 || minutes.length === 0;
  const allFieldsAreEmpty = hours.length === 0 && minutes.length === 0;

  const memoizedReset = useCallback(() => {
    setHours([]);
    setMinutes([]);
    setTimesMissing(true);
  }, [setTimesMissing]);

  useEffect(() => {
    if (!someFieldsAreEmpty) {
      let selectedTimes = hours.flatMap((hour) => {
        return minutes.map((minute) => {
          return `${prependZero(hour)}:${prependZero(minute)}`;
        });
      });
      setOpenTimes(selectedTimes);
      setTimesMissing(false);
    }
  }, [hours, minutes, setOpenTimes, setTimesMissing, someFieldsAreEmpty]);

  useEffect(() => {
    if (triggerReset) {
      memoizedReset();
      setTriggerReset(false);
    }
  }, [triggerReset, setTriggerReset, memoizedReset]);

  return (
    <>
      <Typography>Hours:</Typography>
      {AllHours.map((row, idx) => (
        <ButtonGroup size="small" color="primary" key={idx}>
          {row.map((h) => (
            <Button
              variant={hours.includes(h) ? "contained" : "outlined"}
              key={h}
              onClick={() => {
                setHours((hours) =>
                  hours.includes(h)
                    ? hours.filter((h2) => h2 !== h)
                    : hours.concat(h)
                );
              }}
            >
              {prependZero(h)}
            </Button>
          ))}
        </ButtonGroup>
      ))}
      <Typography>Minutes:</Typography>
      <ButtonGroup size="small" color="primary">
        {AllMinutes.map((m) => (
          <Button
            variant={minutes.includes(m) ? "contained" : "outlined"}
            key={m}
            onClick={() => {
              setMinutes((minutes) =>
                minutes.includes(m)
                  ? minutes.filter((m2) => m2 !== m)
                  : minutes.concat(m)
              );
            }}
          >
            {m}
          </Button>
        ))}
      </ButtonGroup>
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      <Button
        disabled={allFieldsAreEmpty}
        onClick={() => {
          memoizedReset();
        }}
      >
        Reset
      </Button>
    </>
  );
}
export default MultiTimeSelector;
