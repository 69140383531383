import { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { IconButton, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export function MultiStateCheckbox<StateType>({
  state,
  setState,
  statesAndIcons,
  label,
}: MultiStateCheckboxProps<StateType>) {
  const [index, setIndex] = useState(
    statesAndIcons.findIndex((x) => x.state === state)
  );
  const StateIcon = statesAndIcons[index].icon;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
      <div style={{ position: "relative" }}>
        {StateIcon && (
          <StateIcon style={{ position: "absolute", left: "-4px" }} />
        )}

        <IconButton
          onClick={() => {
            const nextIndex = (index + 1) % statesAndIcons.length;
            setState(statesAndIcons[nextIndex].state);
            setIndex(nextIndex);
          }}
        >
          <CheckBoxOutlineBlankIcon
            style={{
              position: "absolute",
            }}
          />
        </IconButton>
      </div>
      <span>{label}</span>
    </div>
  );
}

type MultiStateCheckboxProps<StateType> = {
  state: StateType;
  setState: (state: StateType) => void;
  statesAndIcons: {
    state: StateType;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | null;
  }[];
  label: string;
};
