import { Routes, Route, Navigate } from "react-router-dom";
import { useGlobalContext } from "./GlobalContext";
import * as pages from "./pages";
import React from "react";
import { AccessPermission } from "./types";
import { menuItems } from "./components/Menu";

export type ProtectedRoute = {
  name?: string; // name of the route
  path: string; // path of route
  component: React.ComponentType<any>;
  group?: string; // route group
  childrenPaths?: string[]; // Subpaths do not need to be named
};

export const protectedRoutes: ProtectedRoute[] = [
  { name: "Home Page", path: "/", component: pages.HomePage, group: "Home" },
  {
    name: "Bookings List",
    path: "/bookings",
    component: pages.BookingsList,
    group: "Booking",
  },
  {
    name: "Add Bookings",
    path: "/bookings/:id",
    component: pages.ViewBooking,
    group: "Booking",
  },
  {
    name: "Bookings Reviews",
    path: "/bookings/reviews",
    component: pages.ReviewsList,
    group: "Booking",
  },
  {
    name: "Bookings Reviews Detail",
    path: "/bookings/reviews/:id",
    component: pages.ViewReview,
    group: "Booking",
  },
  {
    name: "Announcements List",
    path: "/announcements",
    component: pages.AnnouncementList,
    group: "Announcement",
  },
  {
    name: "Edit Announcements",
    path: "/announcements/:id",
    component: pages.EditAnnouncement,
    group: "Announcement",
  },
  {
    name: "Add Announcements",
    path: "/announcements/new",
    component: pages.NewAnnouncement,
    group: "Announcement",
  },
  {
    name: "Companies List",
    path: "/companies",
    component: pages.CompanyList,
    group: "Companie",
  },
  {
    name: "Edit Companies",
    path: "/companies/:id",
    component: pages.EditCompany,
    group: "Companie",
  },
  {
    name: "Add Companies",
    path: "/companies/new",
    component: pages.NewCompany,
    group: "Companie",
  },
  {
    name: "Concierges List",
    path: "/concierges",
    component: pages.ConciergesList,
    group: "Concierge",
  },
  {
    name: "Edit Concierges",
    path: "/concierges/:id",
    component: pages.EditConcierge,
    group: "Concierge",
  },
  {
    name: "Add Concierges",
    path: "/concierges/new",
    component: pages.AddNewConcierge,
    group: "Concierge",
  },
  { name: "Items List", path: "/items", component: pages.Items, group: "Item" },
  {
    name: "Add Items",
    path: "/items/new",
    component: pages.AddItem,
    group: "Item",
  },
  {
    name: "Edit Items",
    path: "/items/edit/:id",
    component: pages.EditItem,
    group: "Item",
  },
  {
    name: "Items Fonts",
    path: "/items/fonts",
    component: pages.Fonts,
    group: "Item",
  },
  {
    name: "Edit Items Font",
    path: "/items/fonts/:id",
    component: pages.EditFont,
    group: "Item",
  },
  {
    name: "Items Sales",
    path: "/items/sales",
    component: pages.ItemSales,
    group: "Item",
  },
  {
    name: "Items Stamps",
    path: "/items/stamps",
    component: pages.Stamps,
    group: "Item",
  },
  {
    name: "Edit Items Stamp",
    path: "/items/stamps/:id",
    component: pages.EditStamp,
    group: "Item",
  },
  {
    name: "Locations List",
    path: "/locations",
    component: pages.LocationsList,
    group: "Location",
  },
  {
    name: "Edit Locations",
    path: "/locations/:id",
    component: pages.EditLocation,
    group: "Location",
  },
  {
    name: "Add Locations",
    path: "/locations/new",
    component: pages.NewLocation,
    group: "Location",
  },
  {
    name: "Partners List",
    path: "/partners",
    component: pages.PartnersList,
    group: "Partner",
  },
  {
    name: "Edit Partners",
    path: "/partners/:id",
    component: pages.EditPartner,
    group: "Partner",
  },
  {
    name: "Add Partners",
    path: "/partners/new",
    component: pages.AddNewPartner,
    group: "Partner",
  },
  {
    name: "Plans Draft Overview",
    path: "/plans-draft/:id/overview",
    component: pages.PlanDraftOverview,
    group: "Plan",
  },
  { name: "Plans", path: "/plans", component: pages.Plans, group: "Plan" },
  {
    name: "Edit Plans",
    path: "/plans/:id",
    component: pages.EditPlan,
    childrenPaths: [
      "/plans/:id/:tab",
      "/plans/:id/edit",
      "/plans/:id/edit/:tab",
    ],
    group: "Plan",
  },
  { path: "/plans/:id/:tab", component: pages.EditPlan },
  { path: "/plans/:id/edit", component: pages.EditPlan },
  { path: "/plans/:id/edit/:tab", component: pages.EditPlan },
  {
    name: "Edit Plans Collections",
    path: "/plans/collections/:id",
    component: pages.EditCollection,
    group: "Plan",
  },
  {
    name: "Import Plans Collections",
    path: "/plans/collections/import",
    component: pages.ImportCollection,
    group: "Plan",
  },
  {
    name: "Add Plans Collections",
    path: "/plans/collections/new",
    component: pages.NewCollection,
    group: "Plan",
  },
  {
    name: "Plans Collections",
    path: "/plans/collections",
    component: pages.Collections,
    group: "Plan",
  },
  {
    name: "Add Plans Collections Image",
    path: "/plans/collections/images/new",
    component: pages.AddNewCollectionImage,
    group: "Plan",
  },
  {
    name: "Edit Plans Collections Image",
    path: "/plans/collections/images/:id",
    component: pages.EditCollectionImage,
    group: "Plan",
  },
  {
    name: "Plans Collections Image List",
    path: "/plans/collections/images",
    component: pages.CollectionImagesList,
    group: "Plan",
  },
  {
    name: "Import Plans",
    path: "/plans/import",
    component: pages.ImportPlan,
    group: "Plan",
  },
  {
    name: "Add Plans",
    path: "/plans/new",
    component: pages.NewPlan,
    group: "Plan",
  },
  { path: "/sentry-error", component: pages.SentryError },
  { path: "/user/change-password", component: pages.ChangePassword },
  { name: "Venues", path: "/venues", component: pages.Venues, group: "Venue" },
  {
    name: "Edit Venues",
    path: "/venues/:id",
    component: pages.EditVenue,
    childrenPaths: ["/venues/:id/edit", "/venues/:id/edit/:tab"],
    group: "Venue",
  },
  { path: "/venues/:id/edit", component: pages.EditVenue },
  { path: "/venues/:id/edit/:tab", component: pages.EditVenue },
  {
    name: "Venues Costs Overview",
    path: "/venues/costs-overview",
    component: pages.VenuesCosts,
    group: "Venue",
  },
  {
    name: "Export Venues",
    path: "/venues/export",
    component: pages.ExportVenues,
    group: "Venue",
  },
  {
    name: "Import Venues",
    path: "/venues/import",
    component: pages.ImportVenue,
    group: "Venue",
  },
  {
    name: "Add Venues",
    path: "/venues/new",
    component: pages.NewVenue,
    group: "Venue",
  },
  {
    name: "Venues Reservations",
    path: "/venues/reservations",
    component: pages.VenueReservations,
    group: "Venue",
  },
  { name: "Tags", path: "/tags", component: pages.Tags, group: "Tag" },
  {
    name: "Roles List",
    path: "/roles",
    component: pages.RolesList,
    group: "Role",
  },
  {
    name: "Add Roles",
    path: "/roles/new",
    component: pages.AddRole,
    group: "Role",
  },
  {
    name: "Edit Roles",
    path: "/roles/:id",
    component: pages.EditRole,
    group: "Role",
  },
  {
    name: "Users List",
    path: "/users",
    component: pages.UsersList,
    group: "User",
  },
  {
    name: "Add Users",
    path: "/users/new",
    component: pages.NewUser,
    group: "User",
  },
  {
    name: "Edit Users",
    path: "/users/:id",
    component: pages.EditUser,
    group: "User",
  },
];

function GetRoutes() {
  const { accessPermissions, isLoggedIn } = useGlobalContext();
  const publicRoutes = [{ path: "/login", component: pages.Login }];
  const homePath = accessPermissions?.find((r) => r.path === "/");
  const matchRoutes = protectedRoutes.filter((item) =>
    !isLoggedIn
      ? true
      : accessPermissions?.find(
          (route: AccessPermission) => item.path === route.path
        )
  );

  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<route.component />}
        />
      ))}
      {!homePath && isLoggedIn ? (
        <Route path={"/"} element={<NavigateRouter />} />
      ) : null}
      <>
        {matchRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <RequireAuth
                redirectTo={
                  window.location.pathname === "/"
                    ? "/login"
                    : `/login?callbackUrl=${window.location.pathname}`
                }
              >
                <route.component />
              </RequireAuth>
            }
          />
        ))}
      </>
      <Route
        path="*"
        element={
          <>
            {checkRouterMatch()
              ? "You not have permisstion!"
              : "Page not found"}
          </>
        }
      />
    </Routes>
  );
}

function RequireAuth({ children, redirectTo }: any) {
  const { isLoggedIn, accessPermissions } = useGlobalContext();

  return isLoggedIn && accessPermissions && accessPermissions?.length > 0 ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
}

function checkRouterMatch(): boolean {
  const currentPath = window.location.pathname;
  const pathMatch = protectedRoutes.find((r) =>
    r.path.includes(currentPath.split("/")[1])
  );
  return Boolean(pathMatch) || currentPath.includes("login");
}

function NavigateRouter() {
  const { accessPermissions } = useGlobalContext();
  const firstMenuItem = menuItems({ accessPermissions })[0];

  return firstMenuItem?.url ? <Navigate to={firstMenuItem?.url} /> : null;
}

export default GetRoutes;
