import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { default as TF } from "../../../components/TextField";

export default function AddNewPartner() {
  const { showNotification } = useNotifications();
  const [partnerId, setPartnerId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [addPartner] = useMutation(ADD_PARTNER);

  if (partnerId) return <Navigate to={`/partners/${partnerId}`} />;

  return (
    <>
      <div>
        <Button
          style={{ marginBottom: "15px" }}
          component={Link}
          to={`/partners`}
        >
          Back to list
        </Button>
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="password"
                label="Password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="password"
                label="Repeat Password"
                value={repeatPassword}
                onChange={(e: any) => setRepeatPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleAddNewPartner}>Add New Partner</Button>
      </div>
    </>
  );

  function handleAddNewPartner() {
    if (!name)
      return showNotification({
        message: "Please input a name",
        severity: "error",
      });

    if (!email)
      return showNotification({
        message: "Please input an email",
        severity: "error",
      });

    if (!password)
      return showNotification({
        message: "Please input an password",
        severity: "error",
      });

    if (password !== repeatPassword)
      return showNotification({
        message: "Passwords are not the same",
        severity: "error",
      });

    addPartner({ variables: { name, email, password } })
      .then((result) => {
        showNotification({ message: "New partner added", severity: "success" });
        setPartnerId(result.data.addPartner.partnerId);
      })
      .catch((error) => {
        showNotification({
          message: `Failed to add new partner! ${error.message}`,
          severity: "error",
        });
      });
  }
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

const ADD_PARTNER = gql`
  mutation AddPartnerMutation(
    $name: String!
    $email: String!
    $password: String!
  ) {
    addPartner(input: { name: $name, email: $email, password: $password }) {
      success
      partnerId
    }
  }
`;
