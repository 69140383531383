import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import AddIcon from "@mui/icons-material/Add";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "../../../components/Button2";
import DataTable from "../../../components/DataTable";
import { Announcement } from "../../../types";
import { maxRows } from "../constants";
import { ButtonGroup } from "@mui/material";
import { useNotifications } from "../../../components/Notification";

export default function AnnouncementList() {
  const { showNotification } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const pageSize = 10;
  const [page, setPage] = useState(defaultPage);
  useEffect(updateUrl, [page, navigate]);
  let { loading, error, data, fetchMore, refetch } = useQuery(
    GET_ANNOUNCEMENTS,
    {
      variables: { offset: page * pageSize, limit: pageSize },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );
  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT);
  const handleDelete = useCallback(
    async (announcementId: string) => {
      const shouldDelete = window.confirm(
        `Are you sure you want to delete announcement ${announcementId}?`
      );

      if (shouldDelete) {
        try {
          await deleteAnnouncement({
            variables: {
              id: announcementId,
            },
          });
          refetch();
          showNotification({
            message: "Deleted announcement",
            severity: "success",
          });
        } catch (e: unknown) {
          showNotification({
            message:
              "Failed to delete announcement" +
              (error instanceof Error ? `: ${error.message}` : ""),
            severity: "error",
          });
        }
      }
    },
    [deleteAnnouncement, refetch, showNotification, error]
  );
  if (error) return <>Error! {error.message}</>;
  const rows = data ? data.announcements.records : [];
  const totalRows = rows.length;
  const columns = [
    {
      name: "",
      render: (row: Announcement) => renderEditAndDelete(row, handleDelete),
    },
    { name: "ID", field: "id" },
    { name: "Synopsis", field: "synopsis" },
    { name: "Last Update", field: "updatedAt" },
  ];
  return (
    <DataTable
      loading={loading}
      columns={columns}
      rows={rows}
      topBarExtras={renderTopBarExtras(totalRows, maxRows)}
      pagination={{
        totalRows: totalRows,
        pageSize: pageSize,
        currentPage: page,
        onChangePage: (_, newPage) => {
          setPage(newPage);
          fetchMore({
            variables: {
              offset: newPage * pageSize,
              limit: pageSize,
            },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        },
      }}
    />
  );

  function getParsedQueryString() {
    return queryString.parse(location.search);
  }

  function defaultPage() {
    const parsedQueryString = getParsedQueryString();
    return parsedQueryString.page
      ? parseInt(parsedQueryString.page.toString()) - 1
      : 0;
  }

  function updateUrl() {
    const urlParams = { page: page + 1 };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderTopBarExtras(totalRows: number, maxRows: number) {
    const canAddRow = totalRows < maxRows;
    return (
      <Button
        style={{ backgroundColor: "white", color: "#c8a063" }}
        variant="contained"
        component={Link}
        to="/announcements/new"
        startIcon={canAddRow ? <AddIcon /> : null}
        disabled={!canAddRow}
      >
        {`${canAddRow ? "New" : "Full"} ${totalRows}/${maxRows}`}
      </Button>
    );
  }
}

function renderEditAndDelete(row: Announcement, handleDelete: any) {
  return (
    <ButtonGroup size="small">
      <Button component={Link} to={`/announcements/${row.id}`}>
        <EditIcon />
      </Button>
      <Button onClick={() => handleDelete(row.id)}>
        <DeleteForeverIcon />
      </Button>
    </ButtonGroup>
  );
}

const GET_ANNOUNCEMENTS = gql`
  query GetAnnouncementsQuery($offset: Int!, $limit: Int!) {
    announcements(offset: $offset, limit: $limit) {
      totalRecords
      records {
        id
        synopsis
        content
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($id: ID!) {
    deleteAnnouncement(input: { id: $id }) {
      success
      error
    }
  }
`;
