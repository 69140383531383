import { gql, useApolloClient } from "@apollo/client";
import { useCallback } from "react";

export function useDownloadContract({
  fileName,
  filePath,
  bucketName,
}: DownloadContractProps) {
  const apolloClient = useApolloClient();

  const getContractData = useCallback(async () => {
    const res = await apolloClient.query<
      DownloadContractResponse,
      DownloadContractInput
    >({
      query: DOWNLOAD_CONTRACT_QUERY,
      variables: { input: { filePath, bucketName } },
      fetchPolicy: "no-cache",
    });

    if (res.data.downloadFile.error)
      throw new Error(res.data.downloadFile.error);
    return res.data.downloadFile.file as string;
  }, [filePath, bucketName, apolloClient]);

  return async () => {
    const data = Uint8Array.from(atob(await getContractData()), (c) =>
      c.charCodeAt(0)
    );
    const blob = new Blob([data], {
      type: "application/pdf",
    });
    exportBlob(blob, fileName);
  };
}

type DownloadContractProps = {
  fileName: string;
  filePath: string;
  bucketName: string;
};

const exportBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const DOWNLOAD_CONTRACT_QUERY = gql`
  query DownloadContractQuery($input: downloadFileInput!) {
    downloadFile(input: $input) {
      error
      file
    }
  }
`;

type DownloadContractResponse = {
  downloadFile: {
    error?: string;
    file?: string;
  };
};

type DownloadContractInput = {
  input: {
    filePath: string;
    bucketName: string;
  };
};
