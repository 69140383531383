import { makeStyles } from "@mui/styles";

export default makeStyles({
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "16px",
  },
  boxContent: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
  },
  titleName: {
    display: "inline-block",
    fontSize: "14px",
    padding: "6px 10px",
    backgroundColor: "#fff",
    border: "1px solid #C8A063",
    color: "#333",
  },
  buttonLink: {
    backgroundColor: "#C8A063",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#C8A063",
      color: "#ffffff",
      opacity: 0.8,
    },
  },
  button: {
    backgroundColor: "#767171",
    color: "#ffffff",
    border: "1px solid #7e6316",
    "&:hover": {
      backgroundColor: "#767171",
      color: "#ffffff",
      opacity: 0.8,
    },
  },
});
