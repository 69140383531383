import React from "react";
import { BookingEditHistoryType } from ".";
import { TextField } from "@mui/material";

type Props = {
  edit: BookingEditHistoryType;
};

const BasicAddRemoveCard = ({ edit }: Props) => {
  const oldValue = JSON.parse(edit.oldValue);

  return (
    <div
      style={{
        backgroundColor: "#e8e8e8",
        padding: 10,
        borderRadius: 8,
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      {Object.entries(oldValue).map((item) => {
        return (
          <TextField
            value={item[1]}
            label={item[0]}
            multiline
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        );
      })}
    </div>
  );
};

export default BasicAddRemoveCard;
