import {
  IconButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Theme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "../TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  cardContent: {
    marginTop: theme.spacing(0),
    paddingTop: theme.spacing(0),
  },
}));

type TextFieldListProps = {
  values?: string[];
  label: string;
  onChange: (values: string[]) => void;
};

export default function TextFieldList(props: TextFieldListProps) {
  const classes = useStyles();

  const { onChange, label } = props;
  const values = props.values || [];

  return (
    <Card>
      <CardHeader
        className={classes.cardTitle}
        title={label}
        action={
          <IconButton onClick={() => onChange(values.concat(""))}>
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent className={classes.cardContent}>
        <Grid container>
          {values.map((v, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "12px",
              }}
              key={index}
            >
              <Grid item lg={10}>
                <TextField
                  style={{ marginTop: 0 }}
                  label=""
                  value={v}
                  size="small"
                  onChange={(e: any) => {
                    const copy = [...values];
                    copy[index] = e.target.value;

                    onChange(copy);
                  }}
                />
              </Grid>
              <Grid item lg={2}>
                <IconButton
                  onClick={() => {
                    onChange(values.filter((_, idx) => idx !== index));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </div>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
