import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
} from "@mui/material";
import Button from "../../../../components/Button2";
import TextField from "../../../../components/TextField";
import { Review } from "../../../../types";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { useNotifications } from "../../../../components/Notification";
import { v4 as uuid } from "uuid";
import ConciergesSelection from "../../../../components/ConciergeSelection";

export default function ReviewTab() {
  const { id } = useParams<{ id: string }>();
  const [saveReviewConciergeComment] = useMutation(
    SAVE_REVIEW_CONCIERGE_COMMENT_MUTATION
  );
  const [deleteReviewConciergeComment] = useMutation(
    DELETE_REVIEW_CONCIERGE_COMMENT_MUTATION
  );
  const { showNotification } = useNotifications();

  const { loading, error, data } = useQuery(GET_REVIEW, {
    variables: { id },
  });

  const review: Review | null = data ? data.review : null;
  const [comment, setComment] = useState(review?.comment ?? null);
  useEffect(() => setComment(review?.comment ?? null), [
    review,
    review?.comment,
  ]);

  if (loading) return <LinearProgress />;
  if (error) return <>{error.message}</>;

  const onChangeConciergeId = (e: any) => {
    if (e) e.preventDefault();
    let newComment = comment ? { ...comment } : null;
    if (newComment && !!e) {
      setComment(comment ? { ...comment, conciergeId: e || "" } : null);
    }
  };
  const onChangeComment = (e: any) => {
    if (e) e.preventDefault();
    let newComment = comment ? { ...comment } : null;
    if (newComment && !!e) {
      newComment.comment = e.target.value;
    }
    setComment(newComment);
  };

  const onAddComment = () => {
    if (!!comment || !review?.id) return;
    setComment({
      id: "",
      conciergeId: "",
      comment: "",
      reviewId: review.id,
    });
  };

  const onSaveComment = () => {
    if (!comment) return;
    let id = comment.id;
    if (!id) {
      id = uuid();
      setComment({
        ...comment,
        id,
      });
    }
    saveReviewConciergeComment({
      variables: {
        input: {
          comment: {
            ...comment,
            id,
          },
        },
      },
    })
      .then(() => {
        showNotification({
          message: `Review's concierge comment has been saved!`,
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: `Review's concierge comment could not be saved!`,
          severity: "error",
        });
      });
  };

  const onDeleteComment = () => {
    if (!comment) return;
    if (!!comment.id) {
      deleteReviewConciergeComment({
        variables: {
          input: {
            id: comment.id,
          },
        },
      })
        .then(() => {
          showNotification({
            message: `Review's concierge comment has been removed!`,
            severity: "success",
          });
        })
        .catch(() => {
          showNotification({
            message: `Review's concierge comment could not be removed!`,
            severity: "error",
          });
        });
    }
    setComment(null);
  };

  return review ? (
    <Card>
      <CardContent>
        <TextField readOnly={true} label={"ID"} value={review.id} />
        <TextField
          readOnly={true}
          label={"Created At"}
          value={review.createdAt}
        />
        <TextField
          readOnly={true}
          label={"Booking ID"}
          value={review.bookingId}
        />
        <TextField readOnly={true} label={"Rating"} value={review.rating} />
        <TextField
          readOnly={true}
          multiline
          label={"Content"}
          value={review.content}
        />
        <TextField readOnly={true} label={"Show"} value={review.show} />
      </CardContent>
      {!comment ? (
        <CardContent>
          <Grid item xs={12}>
            <Button
              startIcon={<AddIcon />}
              style={{
                backgroundColor: "#c8a063",
                color: "white",
                display: "inline",
              }}
              onClick={onAddComment}
            >
              Add comment
            </Button>
          </Grid>
        </CardContent>
      ) : (
        <>
          <CardHeader title="Concierge Comment" />
          <CardContent>
            <ConciergesSelection
              required
              onConciergeSelected={(concierge) => {
                onChangeConciergeId(concierge?.id);
              }}
              conciergeId={comment.conciergeId}
            />
            <TextField
              required
              multiline={true}
              label="Comment"
              value={comment.comment}
              onChange={onChangeComment}
            />
            <Grid item xs={12} style={{ marginTop: "12px" }}>
              <Button
                startIcon={<SaveIcon />}
                style={{ backgroundColor: "#c8a063", color: "white" }}
                onClick={onSaveComment}
              >
                Save Comment
              </Button>
              <Button
                startIcon={<ClearIcon />}
                style={{
                  backgroundColor: "#c8a063",
                  color: "white",
                  marginLeft: "10px",
                }}
                onClick={onDeleteComment}
              >
                Delete Comment
              </Button>
            </Grid>
          </CardContent>
        </>
      )}
    </Card>
  ) : null;
}

const GET_REVIEW = gql`
  query ViewReviewQuery($id: String!) {
    review(id: $id) {
      id
      createdAt
      bookingId
      planId
      reviewAt
      content
      rating
      show
      comment {
        id
        reviewId
        conciergeId
        comment
      }
    }
  }
`;

const SAVE_REVIEW_CONCIERGE_COMMENT_MUTATION = gql`
  mutation SaveReviewConciergeCommentMutation(
    $input: SaveReviewConciergeCommentInput!
  ) {
    saveReviewConciergeComment(input: $input) {
      comment {
        id
      }
    }
  }
`;

const DELETE_REVIEW_CONCIERGE_COMMENT_MUTATION = gql`
  mutation DeleteReviewConciergeCommentMutation(
    $input: DeleteReviewConciergeCommentInput!
  ) {
    deleteReviewConciergeComment(input: $input) {
      result
    }
  }
`;
