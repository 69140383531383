import { gql, useQuery } from "@apollo/client";
import TagIcon from "@mui/icons-material/Tag";
import { Dispatch, SetStateAction, useState } from "react";
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Tag, TagCategory } from "../../types";
import TagsTable from "./tagsTable";
import Select from "../../components/Select";

export default function TagsSelection(props: {
  tags: Tag[];
  setTags: Dispatch<SetStateAction<Tag[]>>;
  allTagsFilter?: boolean;
  setAllTagsFilter?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [tagCategory, setTagCategory] = useState<TagCategory>();

  const { data, loading, error } = useQuery(TAG_CATEGORIES_QUERY);
  if (loading) return <>loading...</>;
  if (error) return <>ERROR: {error.message}</>;

  const tagCategories: TagCategory[] = data.tagCategories;

  function changeFilterType(event: any) {
    const value = event.target.value === "true";
    props.setAllTagsFilter!(value);
  }

  return (
    <>
      {props.allTagsFilter !== undefined &&
        props.setAllTagsFilter !== undefined && (
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Tag filter rules</FormLabel>
            <RadioGroup
              row
              aria-label="tag-filter"
              name="tag-filter"
              value={String(props.allTagsFilter)}
              onChange={changeFilterType}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="All tags must match"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Any matching tag"
              />
            </RadioGroup>
          </FormControl>
        )}

      {props.tags.map((tag: Tag) => (
        <Chip
          key={tag.id}
          style={{
            backgroundColor: "#c8a063",
            color: "#ffffff",
            fontSize: "18px",
            padding: "5px",
            margin: "2px",
          }}
          icon={<TagIcon style={{ color: "#fff" }} />}
          label={`${tag.tagCategory.name}: ${tag.name}`}
          onDelete={() =>
            props.setTags((tags) => tags.filter((t) => t.id !== tag.id))
          }
        />
      ))}
      {props.tags.length > 0 && <br />}
      <Select
        style={{ marginTop: "20px" }}
        label="Add new tag (select category)"
        value={tagCategory ? tagCategory.id.toString() : ""}
        options={[
          { id: "", name: "TAG CATEGORIES", disabled: true },
          ...tagCategories.map((tc: TagCategory) => ({
            id: tc.id.toString(),
            name: tc.name,
          })),
        ]}
        onChange={(value: any) =>
          setTagCategory(tagCategories.find((tc) => tc.id === Number(value)))
        }
      />
      {tagCategory && (
        <div style={{ marginTop: "15px", width: "100%", height: "360px" }}>
          <TagsTable
            tagCategory={tagCategory}
            tags={props.tags}
            onAdd={(tag: Tag) =>
              !props.tags.find((t) => t.id === tag.id) &&
              props.setTags([...props.tags, tag])
            }
          />
        </div>
      )}
    </>
  );
}

const TAG_CATEGORIES_QUERY = gql`
  query TagCategoriesQuery {
    tagCategories {
      id
      adminName
      name
      createdAt
    }
  }
`;
