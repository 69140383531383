import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { DialogName } from "../types";

export default function CancelDialog(props: {
  open: boolean;
  setOpenDialog: (name: DialogName) => void;
  closeDialog: () => void;
}) {
  return (
    <Dialog open={props.open} onClose={props.closeDialog}>
      <DialogTitle>Cancel Booking</DialogTitle>
      <DialogContent>Do you also want to refund the payment?</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setOpenDialog("CANCEL_WITHOUT_REFUND");
          }}
        >
          No
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.setOpenDialog("CANCEL_WITH_REFUND");
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
