import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import TextField from "../../../../components/TextField";
import Button from "../../../../components/Button2";
import { useNotifications } from "../../../../components/Notification";
import { Stamp } from "../../../../types";

export default function Basic(props: { stamp: Stamp }) {
  const stamp = props.stamp;

  const { showNotification } = useNotifications();
  const [overrideDisplayName, setOverrideDisplayName] = useState<string>(
    stamp.overrideDisplayName ?? (stamp?.displayName || "")
  );

  const [saveStamp] = useMutation(SAVE_STAMP_MUTATION);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title="Stamp Info"
            action={<Button onClick={handleSaveStamp}> Save </Button>}
          />
          <Divider />
          <CardContent>
            <TextField readOnly size="small" label={"ID"} value={stamp.id} />
            <TextField
              readOnly
              size="small"
              label={"Name"}
              value={stamp.name}
            />
            <TextField
              required
              label={"Display name"}
              value={overrideDisplayName}
              onChange={(e: any) => {
                setOverrideDisplayName(e.target.value);
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Preview" />
          <Divider />
          <CardContent>
            {stamp.svgUrl.length > 0 ? (
              <img
                src={stamp.svgUrl}
                style={{ backgroundColor: "lightgray" }}
                alt="preview of stamp"
              />
            ) : (
              <p>No image available</p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  function handleSaveStamp() {
    saveStamp({
      variables: { id: stamp.id, overrideDisplayName: overrideDisplayName },
    })
      .then(() => {
        showNotification({
          message: `Stamp was edited`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `Editing stamp failed! ${err}`,
          severity: "error",
        });
      });
  }
}

const SAVE_STAMP_MUTATION = gql`
  mutation SaveStampMutation($id: ID!, $overrideDisplayName: String!) {
    saveStamp(input: { id: $id, overrideDisplayName: $overrideDisplayName }) {
      success
    }
  }
`;
