import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import Button from "../../../components/Button2";
import MediaUploader, { MediaType } from "../../../components/MediaUploader";
import { useNotifications } from "../../../components/Notification";
import { default as TF } from "../../../components/TextField";
import { Concierge } from "../../../types";

interface EditConciergeFormProps {
  concierge: Concierge;
}

export default function EditConciergeForm(props: EditConciergeFormProps) {
  const { showNotification } = useNotifications();
  const { concierge } = props;

  const [name, setName] = useState(concierge.name);
  const [imageUrl, setImageUrl] = useState(concierge.imageUrl);
  const [editConcierge] = useMutation(EDIT_CONCIERGE);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Id" value={concierge.id} disabled={true} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Created At"
                value={concierge.createdAt}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <MediaUploader
                allowedTypes={[MediaType.Image]}
                height="100px"
                media={imageUrl}
                uploadPath={`concierges/${concierge.id}/image`}
                onChangeFunction={(e) => setImageUrl(e || "")}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleEditConcierge}>Save</Button>
      </div>
    </>
  );

  function handleEditConcierge() {
    editConcierge({
      variables: {
        id: concierge.id,
        name,
        imageUrl,
      },
    })
      .then(() => {
        showNotification({
          message: "Concierge successfully edited",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Failed to save concierge",
          severity: "error",
        });
      });
  }
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

const EDIT_CONCIERGE = gql`
  mutation EditConciergeMutation($id: ID!, $name: String, $imageUrl: String) {
    editConcierge(input: { id: $id, name: $name, imageUrl: $imageUrl }) {
      success
    }
  }
`;
