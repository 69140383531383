import ContractRow from "./ContractRow";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Button } from "@mui/material";
import { Contract } from "../../../../types";
import { useState } from "react";
import UploadContract, { generateUniqueContractName } from "./UploadContract";
import ContractRowSkeleton from "./ContractRowSkeleton";

export default function ContractList({
  contracts,
  setContracts,
  companyId,
}: ContractListProps) {
  const [uploading, setUploading] = useState(false);

  return (
    <>
      {contracts.map((contract, i) => (
        <ContractRow
          key={i}
          contract={contract}
          setContract={(contract: Contract) =>
            setContract(setContracts, i, contract)
          }
          companyId={companyId}
          remove={() => removeContract(setContracts, i)}
        />
      ))}
      <div style={{ marginTop: "1rem" }}>
        {uploading ? (
          <ContractRowSkeleton />
        ) : (
          <UploadContract
            Button={
              <Button
                component="span"
                startIcon={<PostAddIcon />}
                variant="outlined"
              >
                Add Contract
              </Button>
            }
            companyId={companyId}
            updateContract={({ id, name, url }) => {
              addContract(setContracts, id, companyId, name, url);
            }}
            getName={(file: File) =>
              generateUniqueContractName(file, contracts)
            }
            setUploading={setUploading}
          />
        )}
      </div>
    </>
  );
}

type SetContracts = React.Dispatch<React.SetStateAction<Contract[]>>;

type ContractListProps = {
  contracts: Contract[];
  setContracts: SetContracts;
  companyId: string;
};

const setContract = (
  setContracts: SetContracts,
  index: number,
  contract: Contract
) =>
  setContracts((contracts) => [
    ...contracts.slice(0, index),
    contract,
    ...contracts.slice(index + 1),
  ]);

const removeContract = (setContracts: SetContracts, index: number) =>
  setContracts((contracts) => [
    ...contracts.slice(0, index),
    ...contracts.slice(index + 1),
  ]);

const addContract = (
  setContracts: SetContracts,
  id: string,
  companyId: string,
  name: string,
  url: string
) =>
  setContracts((contracts) => [
    ...contracts,
    {
      id,
      companyId,
      name,
      url,
    },
  ]);
