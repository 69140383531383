import { Stack } from "@mui/material";
import { useState } from "react";
import { startOfMonth } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import useGetItemSalesData, {
  fieldTranslations,
} from "./components/useGetItemSalesData";
import DataTable, { Column } from "../../../components/DataTable";
import DownloadCsvButton from "./components/DownloadCsvButton";

export const ITEMS_PER_PAGE = 10;

export function ItemSales() {
  const [page, setPage] = useState(0);
  const [date, setDate] = useState<Date | null>(startOfMonth(new Date()));

  const {
    itemSalesData,
    totalRows,
    fetchMore,
    loading,
    error,
  } = useGetItemSalesData({
    date: date!,
    offset: page * ITEMS_PER_PAGE,
    limit: 10,
  });

  if (error) return <>Error: {error}</>;

  const columns: Column[] = Object.entries(fieldTranslations).map(
    ([field, name]) => ({
      field,
      name,
      width: "100px",
    })
  );

  return (
    <Stack direction="column" spacing="1rem">
      <DataTable
        loading={loading}
        columns={columns}
        rows={itemSalesData}
        topBarLeadingExtras={
          <DatePicker
            value={date}
            onChange={setDate}
            openTo="month"
            views={["year", "month"]}
            slotProps={{
              field: { readOnly: true },
            }}
          />
        }
        topBarExtras={<DownloadCsvButton date={date!} />}
        pagination={{
          totalRows,
          pageSize: ITEMS_PER_PAGE,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * ITEMS_PER_PAGE,
                limit: ITEMS_PER_PAGE,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
    </Stack>
  );
}
