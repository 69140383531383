import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type AlertDialogProps = Pick<DialogProps, "open"> & {
  handleClose: () => void;
  confirmLabel?: string | JSX.Element;
  rejectLabel?: string | JSX.Element;
  title: string | JSX.Element;
  content: string | JSX.Element;
  onConfirm: (() => void) | (() => Promise<void>);
};

export default function AlertDialog({
  open,
  handleClose,
  onConfirm,
  title,
  content,
  confirmLabel,
  rejectLabel,
}: AlertDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {rejectLabel ?? "cancel"}
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            handleClose();
          }}
        >
          {confirmLabel ?? "confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
