import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

interface AutocompleteProps {
  value: any;
  setValue: any;
  options: string[];
  onAdd: any;
  label: string;
}

interface OptionType {
  inputValue?: string;
  description: string;
}
const filter = createFilterOptions<OptionType>();

export default function AutocompleteDialog({
  value,
  setValue,
  options,
  onAdd,
  label,
}: AutocompleteProps) {
  const [dialogValue, setDialogValue] = useState({
    description: "",
  });

  const [open, toggleOpen] = useState(false);

  const handleClose = () => {
    setDialogValue({
      description: "",
    });
    toggleOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onAdd(dialogValue.description);
    handleClose();
  };
  return (
    <>
      <Autocomplete
        value={value}
        onChange={(_, newValue) => {
          // open confirmation dialog if input is new
          if (typeof newValue === "string") {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                description: newValue.trim(),
              });
              setValue(newValue.trim());
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              description: newValue.inputValue.trim(),
            });
            setValue(newValue.inputValue.trim());
          } else {
            setValue(newValue?.description.trim());
          }
        }}
        filterOptions={(options, params) => {
          // add input to dropdown menu if input is new
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              description: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.description;
        }}
        options={options.map(
          (option) =>
            ({
              description: option,
            } as OptionType)
        )}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        renderInput={(params) => (
          <TextField
            style={{ marginTop: "12px" }}
            variant={"outlined"}
            {...params}
            label={label}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Are you sure you want to add a new value?</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              fullWidth={true}
              id="name"
              value={dialogValue.description}
              onChange={(event: any) =>
                setDialogValue({
                  ...dialogValue,
                  description: event.target.value,
                })
              }
              label="value"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
