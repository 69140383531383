import {
  OperationVariables,
  FetchMoreQueryOptions,
  ApolloQueryResult,
} from "@apollo/client";
import { Stack } from "@mui/system";
import { ParsedQuery } from "query-string";
import { useState } from "react";
import { GetBookingsInput, GetBookingsResponse } from "..";
import DataTable from "../../../../components/DataTable";
import DateRangePicker, {
  DateRangePickerProps,
} from "../../../../components/DaterangePicker";
import { OrderItemNotificationStack } from "./OrderItemNotificationStack";
import {
  renderViewBooking,
  renderBookingStatus,
  renderCustomer,
  renderPlan,
  renderDateTime,
  renderVenue,
  renderActions,
} from "./Renderer";
import TagSelect, { TagSelectProps } from "./TagSelect";

export default function BookingsDataTable({
  parsedQueryString,
  loading,
  rows,
  tagFilter,
  dateRangeFilter,
  createdDateRangeFilter,
  search,
  setSearch,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  pageSize,
  totalRows,
  fetchMore,
}: BookingsDataTableProps) {
  const Headers = {
    view: "View",
    id: "ID",
    created: "Created",
    status: "Status",
    customer: "Customer",
    plan: "Plan",
    dateTime: "Date/time",
    venue: "Venue",
    actions: "Actions",
  } as const;

  const [showColumns, setShowColumns] = useState<string[]>(
    Object.values(Headers).filter(
      (header) =>
        !(
          parsedQueryString.hideColumns ?? [Headers.dateTime, Headers.venue]
        ).includes(header)
    )
  );

  const columns = [
    { name: Headers.view, render: renderViewBooking },
    { name: Headers.id, field: "id" },
    {
      name: Headers.created,
      field: "createdAt",
      sortable: true,
      sortField: "created_at",
    },
    { name: Headers.status, render: renderBookingStatus },
    { name: Headers.customer, width: "70px", render: renderCustomer },
    { name: Headers.plan, width: "170px", render: renderPlan },
    {
      name: Headers.dateTime,
      render: renderDateTime,
      sortable: true,
      sortField: "reservation_datetime",
    },
    { name: Headers.venue, render: renderVenue },
    { name: Headers.actions, width: "100px", render: renderActions },
  ];

  return (
    <div className="bookings-container">
      <DataTable
        style={{ paddingTop: 8 }}
        loading={loading}
        order={{
          field: orderBy ?? "",
          direction: order ?? "ASC",
          setField: setOrderBy,
          setDirection: setOrder,
        }}
        columns={columns}
        showColumns={showColumns}
        setShowColumns={setShowColumns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        topBarLeadingExtras={
          <Stack direction="row" alignItems="center" spacing={1}>
            <TagSelect {...tagFilter} onChangeCallback={() => setPage(0)} />
            <Stack spacing={1}>
              <DateRangePicker
                {...dateRangeFilter}
                fromLabel="reservations from"
                untilLabel="reservations until"
              />
              <DateRangePicker
                {...createdDateRangeFilter}
                fromLabel="created from"
                untilLabel="created until"
              />
            </Stack>
          </Stack>
        }
        topBarExtras={<OrderItemNotificationStack />}
        pagination={{
          totalRows,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
    </div>
  );
}

type BookingsDataTableProps = {
  tagFilter: Omit<TagSelectProps, "onChangeCallback">;
  dateRangeFilter: Pick<
    DateRangePickerProps,
    "from" | "setFrom" | "until" | "setUntil"
  >;
  createdDateRangeFilter: Pick<
    DateRangePickerProps,
    "from" | "setFrom" | "until" | "setUntil"
  >;
  parsedQueryString: ParsedQuery<string>;
  loading: boolean;
  rows:
    | {
        [key: string]: any;
      }[]
    | null
    | undefined;
  orderBy: string | null;
  setOrderBy: React.Dispatch<React.SetStateAction<string | null>>;
  order: "ASC" | "DESC" | null;
  setOrder: React.Dispatch<React.SetStateAction<"ASC" | "DESC" | null>>;
  search: string | undefined;
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  totalRows: number;
  fetchMore: <
    TFetchData = GetBookingsResponse,
    TFetchVars extends OperationVariables = GetBookingsInput
  >(
    fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
      updateQuery?:
        | ((
            previousQueryResult: GetBookingsResponse,
            options: {
              fetchMoreResult: TFetchData;
              variables: TFetchVars;
            }
          ) => GetBookingsResponse)
        | undefined;
    }
  ) => Promise<ApolloQueryResult<TFetchData>>;
};
