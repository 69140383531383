import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, IconButton, Box } from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNotifications } from "../../../components/Notification"; // Adjust the import path
import PageSection from "../../../components/PageSection";
import Button2 from "../../../components/Button2";
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for delete button
import MediaUploader, { MediaType } from "../../../components/MediaUploader";

type Highlight = {
  id?: string;
  title: string;
  body: string;
  image: null | string;
};

type Props = {
  templateId: string;
};

const initialHighlight: Highlight = { title: "", body: "", image: null };

export default function ItemHighlightForm({ templateId }: Props) {
  const [highlights, setHighlights] = useState<Highlight[]>([initialHighlight]);
  const { showNotification } = useNotifications();

  // GraphQL: Mutations
  const [createHighlightItem] = useMutation(CREATE_HIGHLIGHT_ITEM_MUTATION);
  const [updateHighlightItem] = useMutation(UPDATE_HIGHLIGHT_ITEM_MUTATION);
  const [deleteHighlightItem] = useMutation(DELETE_HIGHLIGHT_ITEM_MUTATION);

  useEffect(() => { }, [])
  // Fetch existing highlights by templateId
  const { loading, error, data, refetch } = useQuery(GET_HIGHLIGHTS_BY_TEMPLATE_ID, {
    variables: { templateId },
    onCompleted: (data: any) => {
      if (data.getHighlightItemsByTemplate) {
        const existingHighlights = data.getHighlightItemsByTemplate.map(
          (highlight: Highlight) => ({
            id: highlight.id,
            title: highlight.title,
            body: highlight.body,
            image: highlight.image,
          })
        );
        setHighlights(existingHighlights);
      }
    },
  });

  const handleInputChange = (index: number, field: keyof Highlight, value: any) => {
    setHighlights((prevHighlights: any) =>
      prevHighlights.map((highlight: Highlight, idx: number) =>
        idx === index ? { ...highlight, [field]: value } : highlight
      )
    );
  };

  const handleImageUpload = (index: number, file: string | null) => {
    handleInputChange(index, "image", file);
  };

  // Add new highlight form
  const handleAddMore = () => {
    setHighlights([...highlights, { ...initialHighlight }]);
  };

  // Delete highlight
  const handleDeleteHighlight = async (index: number) => {
    const highlightToDelete = highlights[index];

    if (highlightToDelete.id) {
      try {
        await deleteHighlightItem({
          variables: { id: highlightToDelete.id },
        });
        showNotification({
          message: "Highlight deleted successfully",
          severity: "success",
        });
        refetch();
      } catch (err: any) {
        showNotification({
          message: err.message,
          severity: "error",
        });
        return;
      }
    }

    // Remove the highlight from the state
    setHighlights((prevHighlights: Highlight[]) => prevHighlights.filter((_, idx) => idx !== index));
  };

  // Modified handleSave function to handle create and update separately
  const handleSave = async () => {
    try {
      // Iterate over highlights and create or update based on whether it has an id
      for (const highlight of highlights) {
        const { id, title, body, image } = highlight;

        if (id) {
          // Update existing highlight
          await updateHighlightItem({
            variables: {
              id,
              input: {
                title,
                body,
                image,
                templateId,
              },
            },
          });
        } else {
          // Create new highlight
          await createHighlightItem({
            variables: {
              input: {
                title,
                body,
                image,
                templateId,
              },
            },
          });
        }
      }

      // Show success notification after all items are processed
      showNotification({
        message: "Highlights saved successfully",
        severity: "success",
      });
      refetch();
    } catch (err: any) {
      showNotification({
        message: err.message,
        severity: "error",
      });
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Grid container sx={{ marginTop: "20px" }}>
      <PageSection lg={12}>
        <Button2
          variant="contained"
          onClick={handleSave}  // Save action on button click
        >
          Save
        </Button2>
      </PageSection>

      {highlights.map((highlight: Highlight, index: number) => (
        <PageSection lg={12}>
          <IconButton sx={{ marginBottom: '16px' }} onClick={() => handleDeleteHighlight(index)}>
            <DeleteIcon />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item lg={8} key={index} sx={{
              padding: "16px",
              borderRadius: "8px",
              marginBottom: "8px",
              backgroundColor: "white",
            }}>
              <TextField
                label={`ハイライト${index + 1}のタイトル`} // Highlight title
                fullWidth
                value={highlight.title}
                onChange={(e) => handleInputChange(index, "title", e.target.value)}
                sx={{ marginBottom: "10px" }}
              />
              <TextField
                label={`ハイライト${index + 1}の本文`} // Highlight body
                fullWidth
                multiline
                rows={4}
                value={highlight.body}
                onChange={(e) => handleInputChange(index, "body", e.target.value)}
                sx={{ marginBottom: "10px" }}
              />
            </Grid>
            <Grid
              item
              lg={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                height: "auto",
              }}
            >
              <MediaUploader
                media={highlight.image ? highlight.image : null}
                allowedTypes={[MediaType.Image]}
                height="200px"

                uploadPath={`plans/templatecard/${highlight.id}`}
                onChangeFunction={(url: string | null) => {
                  handleImageUpload(index, url)
                }}
              />
            </Grid>

          </Grid>
        </PageSection>

      ))}

      {/* Add More Button */}
      <Grid item xs={12} sx={{ marginTop: "16px" }}>
        <Button
          variant="outlined"
          onClick={handleAddMore}
        >
          Add More Highlights
        </Button>
      </Grid>
    </Grid>
  );
}

const CREATE_HIGHLIGHT_ITEM_MUTATION = gql`
  mutation CreateHighlightItem($input: HighlightItemInput!) {
    createHighlightItem(input: $input) {
      id
      title
      body
      image
      templateId
    }
  }
`;

const UPDATE_HIGHLIGHT_ITEM_MUTATION = gql`
  mutation UpdateHighlightItem($id: ID!, $input: HighlightItemInput!) {
    updateHighlightItem(id: $id, input: $input) {
      id
      title
      body
      image
      templateId
    }
  }
`;

const DELETE_HIGHLIGHT_ITEM_MUTATION = gql`
  mutation DeleteHighlightItem($id: ID!) {
    deleteHighlightItem(id: $id) {
      success
    }
  }
`;

const GET_HIGHLIGHTS_BY_TEMPLATE_ID = gql`
  query GetHighlightItemsByTemplate($templateId: ID!) {
    getHighlightItemsByTemplate(templateId: $templateId) {
      id
      title
      body
      image
      templateId
    }
  }
`;
