import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { VenueBasicInfoInput } from "../../../types";

import BasicInfoForm from "../Form/BasicInfo";

export default function EditVenueComponent() {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<{ venueSchema: any; venue: any }>(
    VENUE_SCHEMA_QUERY,
    {
      variables: {
        id,
      },
    }
  );

  const [editVenueBasicInfo] = useMutation(EDIT_VENUE_BASIC_INFO);

  if (loading || error || !data) {
    return null;
  }

  return (
    <BasicInfoForm
      venue={data.venue}
      onSubmit={async (venue: VenueBasicInfoInput) => {
        await editVenueBasicInfo({ variables: { input: venue } });
      }}
    />
  );
}

const EDIT_VENUE_BASIC_INFO = gql`
  mutation EditVenueBasicInfo($input: EditVenueBasicInfoInput!) {
    editVenueBasicInfo(input: $input) {
      venue {
        id
      }
    }
  }
`;

const VENUE_SCHEMA_QUERY = gql`
  query VenueSchemaQuery($id: ID!) {
    venue(id: $id) {
      id
      name
      nameFurigana
      photos {
        imageUrl
      }
      description
      phoneNumber
      email
      notificationEmails
      invoiceEmails
      location {
        address
        googleMapsUrl
        googleMapsEmbedUrl
        directions
        nearestStations
      }
      numberOfSeats
      businessHours
      holidays
      smokingAllowance
      acceptedPaymentMethods
      dressCode
      limitedEntryForChildren
      babyChair
      kidChair
      wheelChairAccess
      breastFeedingRoom
      parking
      remarks
      internalRemarks
      postcode
      ebicaStoreId
      bookingSystems
      company {
        id
      }
      feeFreePeriod {
        startDate
        endDate
      }
    }
  }
`;
