import { contractBucketName } from "../../../../helpers/gcp";
import { Contract } from "../../../../types";
import { useDownloadContract } from "../../components/useDownloadContract";
import { Button } from "@mui/material";

export default function ContractPopperRow({
  contract,
}: ContractPopperRowProps) {
  const downloadContract = useDownloadContract({
    fileName: contract.name,
    filePath: contract.url,
    bucketName: contractBucketName,
  });
  return (
    <Button
      style={{
        width: "100%",
        justifyContent: "flex-start",
        display: "flex",
      }}
      onClick={downloadContract}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "block",
        }}
      >
        {contract.name}
      </div>
    </Button>
  );
}

type ContractPopperRowProps = {
  contract: Contract;
};
