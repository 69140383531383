import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import Notification, {
  NotificationData,
} from "../../../../../components/Notification";
import { GET_BOOKING } from "../..";

export default function RefundWithoutCancellation(props: {
  bookingId: string;
  open: boolean;
  closeDialog: () => void;
}) {
  const [refundPaymentMutation] = useMutation(REFUND_PAYMENT);
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );

  return (
    <>
      <Dialog open={props.open} onClose={props.closeDialog}>
        <DialogTitle>Refund Payment Without Cancellation</DialogTitle>
        <DialogContent>
          Are you sure you want to refund the payment WITHOUT cancelling the
          booking?
          <br />
          <br />
          This action will: <br />
          <ul>
            <li>Refund the payment</li>
            <li>Change booking payment status to REFUNDED</li>
            <li>
              Send an email to the customer that the payment has been refunded
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.closeDialog}>
            No
          </Button>
          <Button color="primary" onClick={refundPayment}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function refundPayment() {
    refundPaymentMutation({
      variables: { input: { bookingId: props.bookingId } },
      refetchQueries: [GET_BOOKING],
    })
      .then(() =>
        setNotification({
          message: `Payment of booking ${props.bookingId} was successfully refunded`,
          severity: "success",
        })
      )
      .catch((reason) => {
        setNotification({
          message: `Refund for booking ${props.bookingId} failed: ${reason}`,
          severity: "error",
        });
      });

    props.closeDialog();
  }
}

const REFUND_PAYMENT = gql`
  mutation refundPayment($input: RefundPaymentInput!) {
    refundPayment(input: $input) {
      success
    }
  }
`;
