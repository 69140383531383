import { styled } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { isSameDay } from "date-fns";

interface CustomPickerDayProps extends PickersDayProps<Date> {
  isStart: boolean;
  isEnd: boolean;
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(
  ({ theme, isSelected, isHovered, isStart, isEnd }) => ({
    width: "40px",
    borderRadius: 0,
    ...(isSelected && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(isHovered && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary[theme.palette.mode],
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary[theme.palette.mode],
      },
    }),
    ...(isStart && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...(isEnd && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  })
) as React.ComponentType<CustomPickerDayProps>;

type DayProps = PickersDayProps<Date> & {
  from?: Date | null;
  until?: Date | null;
  hoveredDay?: Date | null;
};

export function Day({ day, from, until, hoveredDay, ...rest }: DayProps) {
  const start = from ?? hoveredDay;
  const end = until ?? hoveredDay ?? from;

  const isSelected = !!from
    ? !!until
      ? from <= day && day <= until
      : day === from
    : false;

  const isHovered =
    !!hoveredDay && !!from && !until && from < day && day <= hoveredDay;

  return (
    <CustomPickersDay
      {...rest}
      day={day}
      disableMargin
      selected={false}
      isSelected={isSelected}
      isHovered={isHovered}
      isStart={isSameDay(day, start ?? new Date(0))}
      isEnd={isSameDay(day, end ?? new Date(0))}
    />
  );
}
