import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import Button from "../../../../components/Button2";
import { useNotifications } from "../../../../components/Notification";
import { default as TF } from "../../../../components/TextField";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";
import * as uuid from "uuid";

export default function AddNewCollectionImage() {
  const { showNotification } = useNotifications();
  const [collectionImageId, setCollectionImageId] = useState(null);
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [addCollectionImage] = useMutation(ADD_COLLECTION_IMAGE);
  const [id, setId] = useState("");
  useEffect(() => setId(uuid.v4()), [imageUrl]);

  if (collectionImageId)
    return <Navigate to={`/plans/collections/images/${collectionImageId}`} />;

  return (
    <>
      <div>
        <Button
          style={{ marginBottom: "15px" }}
          component={Link}
          to={`/plans/collections/images`}
        >
          Back to list
        </Button>
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <MediaUploader
                allowedTypes={[MediaType.Image]}
                height="200px"
                media={imageUrl}
                uploadPath={`plans/collections/images/${id}/image`}
                onChangeFunction={(e) => setImageUrl(e || "")}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleAddNewPlanCollectionImage}>
          Add New Collection Image
        </Button>
      </div>
    </>
  );

  function handleAddNewPlanCollectionImage() {
    if (!name)
      return showNotification({
        message: "Please input a name",
        severity: "error",
      });

    if (!imageUrl)
      return showNotification({
        message: "Please input an imageUrl",
        severity: "error",
      });

    addCollectionImage({ variables: { name, imageUrl } })
      .then((result) => {
        showNotification({
          message: "New collection image added",
          severity: "success",
        });
        setCollectionImageId(result.data.addCollectionImage.collectionImageId);
      })
      .catch((error) => {
        showNotification({
          message: `Failed to add new collection image! ${error.message}`,
          severity: "error",
        });
      });
  }
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

export const ADD_COLLECTION_IMAGE = gql`
  mutation AddCollectionImageMutation($name: String!, $imageUrl: String!) {
    addCollectionImage(input: { name: $name, imageUrl: $imageUrl }) {
      success
      collectionImageId
    }
  }
`;
