import { gql, useMutation } from "@apollo/client";
import { CardProps, GetOriginalValueProps } from "../../types";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { FieldName } from "../../../../../../types";
import { TextRenderer } from "../components/renderers";

type BasicInfoTextCardProps = Partial<CardProps> & {
  planId: string;
  fieldName: FieldName;
  text: string;
};

const BasicInfoTextCard = forwardRef((props: BasicInfoTextCardProps, ref) => {
  const [text, setText] = useState(props.text);

  const [editPlan] = useMutation<
    EditPlanBasicInfoResponse,
    EditPlanBasicInfoInput
  >(EDIT_BASIC_INFO_MUTATION);

  const [editActivity] = useMutation<
    EditActivityNameResponse,
    EditActivityNameInput
  >(EDIT_ACTIVITY_NAME_MUTATION);

  const persistChanges = async () => {
    const input: EditPlanBasicInfoInput["input"] = {
      id: props.planId,
      [props.fieldName]: text,
    };

    if (
      props.fieldName === FieldName.NAME &&
      props.originalData?.activities[0] !== undefined
    ) {
      const activityInput: EditActivityNameInput["input"] = {
        id: props.originalData.activities[0].id!,
        name: text,
      };
      editActivity({ variables: { input: activityInput } });
    }

    return await editPlan({ variables: { input } });
  };

  const getNewValue = () => ({ text });

  useImperativeHandle(ref, () => ({
    persistChanges,
    getNewValue,
  }));

  return (
    <TextRenderer text={text} setText={setText} mutable={!!props.mutable} />
  );
});

const getOriginalValue = ({ data, fieldName }: GetOriginalValueProps) => ({
  text: data[fieldName],
});

const basicInfoTextCard = {
  Content: BasicInfoTextCard,
  getOriginalValue,
};
export default basicInfoTextCard;

const EDIT_BASIC_INFO_MUTATION = gql`
  mutation EditPlanBasicInfoMutation($input: EditPlanBasicInfoInput!) {
    editPlanBasicInfo(input: $input) {
      plan {
        id
      }
    }
  }
`;

type EditPlanBasicInfoResponse = {
  plan: {
    id: string;
  };
};

type EditPlanBasicInfoInput = {
  input: {
    id: string;
    description?: string;
    remarks?: string;
    subtitle?: string;
    inclusions?: {
      category: string;
      items: {
        title: string;
        description: string;
        iconName: string;
      }[];
    }[];
  };
};

const EDIT_ACTIVITY_NAME_MUTATION = gql`
  mutation EditActivityNameMutation($input: EditActivityNameInput!) {
    editActivityName(input: $input) {
      name
    }
  }
`;

type EditActivityNameResponse = {
  name: string;
};

type EditActivityNameInput = {
  input: {
    id: string;
    name: string;
  };
};
