import { Button, Paper, ClickAwayListener, Popover } from "@mui/material";
import { useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import ContractPopperRow from "./ContractPopperRow";
import { Contract } from "../../../../types";

export default function ContractPopper({ contracts }: ContractPopperProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        disabled={!contracts.length}
      >
        <DescriptionIcon />
      </Button>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
          }}
        >
          <Paper
            style={{
              padding: "0.5rem",
              paddingRight: "1rem",
              maxWidth: "400px",
            }}
          >
            <ol>
              {contracts.map((contract, i) => {
                return (
                  <li key={i}>
                    <ContractPopperRow contract={contract} />
                  </li>
                );
              })}
            </ol>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </>
  );
}

type ContractPopperProps = {
  contracts: Contract[];
};
