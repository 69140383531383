import { IconButton, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMemo } from "react";
import SelectRow from "./SelectRow";
import DeleteIcon from "@mui/icons-material/Delete";
import s from "./SelectList.module.css";

export default function SelectList<T>({
  options,
  values,
  setValues,
  renderers,
}: SelectListProps<T>) {
  const filteredOptions = useMemo(
    () => options.filter((option) => !values.includes(option)),
    [options, values]
  );

  return (
    <div className={s.SelectList}>
      <div className={s.Header}>
        <Typography className={s.HeaderTitle}>{`Booking Systems${
          values.length === 0 ? " (利用していない)" : ""
        }`}</Typography>
        <IconButton
          disabled={filteredOptions.length === 0}
          onClick={() => addValue(setValues, filteredOptions[0])}
        >
          <AddIcon />
        </IconButton>
      </div>
      {values.map((value, i) => {
        const { SelectComponent, selectProps } = renderers?.get(value)! ?? {
          // default to standard SelectRow component if a specific renderer is not provided
          SelectComponent: SelectRow,
        };
        return (
          <Paper key={String(value)} className={s.SelectRowContainer}>
            <SelectComponent
              {...selectProps}
              options={filteredOptions}
              value={value}
              setValue={(value: T) => setValue(setValues, i, value)}
            />
            <IconButton
              onClick={() => {
                removeValue(setValues, i);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Paper>
        );
      })}
    </div>
  );
}

function setValue<T>(
  setValues: React.Dispatch<React.SetStateAction<T[]>>,
  index: number,
  value: T
) {
  setValues((values) => [
    ...values.slice(0, index),
    value,
    ...values.slice(index + 1),
  ]);
}

function removeValue<T>(
  setValues: React.Dispatch<React.SetStateAction<T[]>>,
  index: number
) {
  setValues((values) => [
    ...values.slice(0, index),
    ...values.slice(index + 1),
  ]);
}

function addValue<T>(
  setValues: React.Dispatch<React.SetStateAction<T[]>>,
  value: T
) {
  setValues((values) => [...values, value]);
}

export type SelectRenderer = {
  SelectComponent: (props: any) => JSX.Element;
  selectProps: any;
};

export type SelectListProps<T> = {
  options: T[];
  values: T[];
  setValues: React.Dispatch<React.SetStateAction<T[]>>;
  renderers?: Map<T, SelectRenderer>;
};
