import { gql, useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useNotifications } from "../../../components/Notification";
import AnnouncementForm from "../AnnouncementForm";
import persistAnnouncement from "../handlePersistAnnouncement";

export default function EditPartner() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_ANNOUNCEMENT, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const { showNotification } = useNotifications();
  const [editAnnouncement] = useMutation(EDIT_ANNOUNCEMENT);
  const [synopsis, setSynopsis] = useState(" ");
  const [content, setContent] = useState(" ");
  useEffect(() => {
    if (data) {
      setSynopsis(data.announcement.synopsis);
      setContent(data.announcement.content);
    }
  }, [data]);

  if (error) return <>Error! {error.message}</>;

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <AnnouncementForm
          content={content}
          synopsis={synopsis}
          setContent={setContent}
          setSynopsis={setSynopsis}
          handlePersistAnnouncement={() =>
            persistAnnouncement(
              synopsis,
              content,
              showNotification,
              editAnnouncement,
              navigate,
              "edit",
              id
            )
          }
          persistAnnouncementButtonText="Save Announcement Changes"
        />
      )}
    </>
  );
}

const GET_ANNOUNCEMENT = gql`
  query AnnouncementEditQuery($id: ID!) {
    announcement(id: $id) {
      id
      createdAt
      updatedAt
      synopsis
      content
    }
  }
`;

const EDIT_ANNOUNCEMENT = gql`
  mutation EditAnnouncementMutation(
    $id: ID!
    $synopsis: String!
    $content: String!
  ) {
    editAnnouncement(
      input: { id: $id, synopsis: $synopsis, content: $content }
    ) {
      success
      error
    }
  }
`;
