import { useMemo } from "react";
import { BookingSystem } from "../../../../types";
import EbicaSelect from "./EbicaSelect";
import SelectList, { SelectListProps, SelectRenderer } from "./SelectList";

export default function BookingSystemSelectList({
  ebicaStoreId,
  setEbicaStoreId,
  ...selectListProps
}: BookingSystemSelectListProps) {
  const bookingSystemRenderers = useMemo(() => {
    // setup special renderer in case the booking system requires extra input
    // e.g. Ebica requires the operator to input an ID
    return new Map<BookingSystem, SelectRenderer>([
      [
        BookingSystem.ebica,
        {
          SelectComponent: EbicaSelect,
          selectProps: { ebicaStoreId, setEbicaStoreId },
        },
      ],
    ]);
  }, [ebicaStoreId, setEbicaStoreId]);

  return <SelectList renderers={bookingSystemRenderers} {...selectListProps} />;
}

type BookingSystemSelectListProps = {
  ebicaStoreId: null | number;
  setEbicaStoreId: (value: null | number) => void;
} & SelectListProps<BookingSystem>;
