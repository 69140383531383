import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";

import PageSection from "../../../components/PageSection";
import { Frame } from "../../../types";
import { useNotifications } from "../../../components/Notification";
import Button from "../../../components/Button2";

export default function Stamps() {
  const { id } = useParams<{ id: string }>();
  const { showNotification } = useNotifications();

  const { data, loading, error } = useQuery<{ templateStamps: Frame[] }>(
    TEMPLATE_STAMPS_QUERY,
    {
      variables: { id },
      fetchPolicy: "network-only",
    }
  );

  const [frames, setFrames] = useState<Frame[]>([]);
  const [saveTemplateFrames] = useMutation(SAVE_TEMPLATE_FRAMES_MUTATION);

  useEffect(() => {
    if (data && data.templateStamps) setFrames(data.templateStamps);
  }, [data]);

  if (!data || !data.templateStamps || loading || error) {
    return null;
  }

  const { templateStamps } = data;

  function setFrameField<T>(fieldName: keyof Frame, index: number, value: T) {
    setFrames((frameToUpdate) => {
      const changeable = [...frameToUpdate];
      const toBeChanged = frameToUpdate[index];
      let newValue: any = {
        ...toBeChanged,
        [fieldName]: value,
      };

      changeable[index] = newValue;
      return changeable;
    });
  }

  return (
    <Grid container>
      {frames.length > 0 && (
        <PageSection lg={12}>
          <Button
            variant="contained"
            onClick={() => {
              console.log(frames);
              saveTemplateFrames({
                variables: {
                  input: {
                    frames: frames,
                  },
                },
              })
                .then(() => {
                  showNotification({
                    message: `Frames saved successfully`,
                    severity: "success",
                  });
                })
                .catch((err: any) => {
                  showNotification({
                    message: `Could not save frames, ${err.message}`,
                    severity: "error",
                  });
                });
            }}
          >
            Save
          </Button>
        </PageSection>
      )}
      {templateStamps.length > 0 ? (
        <Grid item xs={12}>
          {templateStamps.map((frame, index) => (
            <Card key={index} style={{ marginBottom: "1rem" }}>
              <CardHeader title={`Frame ${frame.index}`} />
              <Divider />
              <CardContent>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      id={`${index}_id`}
                      label="Frame Id"
                      value={frame.id}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id={`${index}_id`}
                      label="Our frame name"
                      helperText={`GM original is "${frame.name}"`}
                      value={
                        frames[index]?.overrideName || frame.overrideName || ""
                      }
                      onChange={(e: any) => {
                        setFrameField("overrideName", index, e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      id={`${index}_id`}
                      label="Frame type"
                      value={frame.type}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      id={`${index}_multiLines`}
                      label="Multi lines allowed"
                      value={frame.allowMultipleLines ? "YES" : "NO"}
                    />
                  </Grid>
                </Grid>

                <div
                  key={`placeholder_${index}`}
                  style={{ marginTop: "1rem" }}
                />
                <Grid container direction="row">
                  {frame.frameStamps &&
                    frame.frameStamps.map((frameStamp, idx) => {
                      return (
                        <PageSection key={index + idx} xs={6}>
                          <Grid container item spacing={3} direction="row">
                            <Tooltip title="Open edit menu in new tab">
                              <IconButton
                                component={Link}
                                to={`/items/stamps/${frameStamp.stamp.id}`}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Grid item xs={7}>
                              <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                                id={`${index}_id`}
                                label="Id"
                                value={frameStamp.stamp.id}
                              />
                              <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                                id={`${index}_name`}
                                label="Name"
                                value={frameStamp.stamp.name}
                              />
                              <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                                id={`${index}_display_name`}
                                label="Display name"
                                value={
                                  frameStamp.stamp.overrideDisplayName ??
                                  frameStamp.stamp.displayName
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                marginLeft: "1rem",
                              }}
                            >
                              {frameStamp.stamp.svgUrl ? (
                                <img
                                  width="180rem"
                                  src={frameStamp.stamp.svgUrl}
                                  alt="preview of stamp"
                                />
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </Grid>
                        </PageSection>
                      );
                    })}
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Grid>
      ) : (
        <div>This template has no associated stamps</div>
      )}
    </Grid>
  );
}

const TEMPLATE_STAMPS_QUERY = gql`
  query TemplateStampsQuery($id: ID!) {
    templateStamps(templateId: $id) {
      id
      index
      name
      overrideName
      type
      allowMultipleLines
      frameStamps {
        id
        stamp {
          id
          name
          svgUrl
          displayName
          overrideDisplayName
        }
      }
    }
  }
`;

const SAVE_TEMPLATE_FRAMES_MUTATION = gql`
  mutation SaveTemplateFramesMutation($input: SaveTemplateFramesInput!) {
    saveTemplateFrames(input: $input) {
      success
    }
  }
`;
