import { gql, useQuery } from "@apollo/client";
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import Select from "../../../components/Select";
import { Location } from "../../../types";

export default function Locations(props: {
  locations: Location[];
  setLocations: (locations: Location[]) => void;
  allLocationsFilter: boolean;
  setAllLocationsFilter: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { data, loading, error } = useQuery(LOCATIONS_QUERY, {
    fetchPolicy: "cache-first",
  });
  if (loading) return <>loading...</>;
  if (error) return <>ERROR: {error.message}</>;

  const selectableLocations = data ? data.locations.records : [];

  function changeFilterType(event: any) {
    const value = event.target.value === "true";
    props.setAllLocationsFilter!(value);
  }

  return (
    <>
      {props.allLocationsFilter !== undefined &&
        props.setAllLocationsFilter !== undefined && (
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Location filter rules</FormLabel>
            <RadioGroup
              row
              aria-label="tag-filter"
              name="tag-filter"
              value={String(props.allLocationsFilter)}
              onChange={changeFilterType}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="All locations must match"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Any matching location"
              />
            </RadioGroup>
          </FormControl>
        )}

      {props.locations.map((location: Location) => (
        <Chip
          key={location.id}
          style={{
            backgroundColor: "#c8a063",
            color: "#ffffff",
            fontSize: "18px",
            padding: "5px",
            margin: "2px",
          }}
          icon={<LocalOfferIcon style={{ color: "#fff" }} />}
          label={`${location.type}: ${location.nameEn} (${location.nameJa})`}
          onDelete={() =>
            props.setLocations(
              props.locations.filter((l) => l.id !== location.id)
            )
          }
        />
      ))}
      {props.locations.length > 0 && <br />}
      <Select
        style={{ marginTop: props.locations.length ? "20px" : "0px" }}
        label="Add new location"
        options={selectableLocations.map((l: Location) => ({
          id: l.id,
          name: `${l.type}: ${l.nameEn} (${l.nameJa})`,
        }))}
        onChange={(value: string) =>
          props.setLocations([
            ...props.locations,
            selectableLocations.find((sl: Location) => sl.id === value),
          ])
        }
      />
    </>
  );
}

const LOCATIONS_QUERY = gql`
  query LocationsQuery {
    locations {
      records {
        id
        type
        nameEn
        nameJa
      }
    }
  }
`;
