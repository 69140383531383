import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import Notification, {
  NotificationData,
} from "../../../../components/Notification";

export default function UnpublishPlanDialog(props: {
  plan?: { id: string; name: string } | null;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const [unpublishPlanMutation] = useMutation(UNPUBLISH_PLAN);
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );

  if (!props.plan) return null;

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
          Unpublish <b>{props.plan.name}</b>
        </DialogTitle>
        <DialogContent>
          Are you sure you want to unpublish the plan?
          <br />
          <br />
          This action will take the plan out of the website. <br />
          <br />
          Take into account that data cached in the browser or in the server
          will still make that customers can book the plan for some time. <br />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.onClose}>
            No
          </Button>
          <Button color="primary" onClick={unpublishPlan}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function unpublishPlan() {
    if (!props.plan) return;

    unpublishPlanMutation({
      variables: {
        input: {
          planId: props.plan.id,
        },
      },
    })
      .then(() => {
        props.onConfirm();
      })
      .catch((reason: Error) => {
        setNotification({
          message: `Unpublish plan failed! ${reason.message}`,
          severity: "error",
        });
      });

    props.onClose();
  }
}

const UNPUBLISH_PLAN = gql`
  mutation unpublishPlan($input: UnpublishPlanInput!) {
    unpublishPlan(input: $input) {
      success
    }
  }
`;
