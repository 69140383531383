import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../../../components/Button2";
import DataTable from "../../../../components/DataTable";
import { CollectionImage } from "../../../../types";

export default function CollectionImagesList() {
  const navigate = useNavigate();
  const location = useLocation();

  const pageSize = 10;
  const [page, setPage] = useState(defaultPage);
  useEffect(updateUrl, [page, navigate]);

  const { loading, error, data, fetchMore } = useQuery(GET_COLLECTION_IMAGES, {
    variables: { offset: page * pageSize, limit: pageSize },
  });

  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEdit },
    { name: "Id", field: "id" },
    { name: "Name", field: "name" },
    { name: "Created At", field: "createdAt" },
  ];
  const rows = data ? data.planCollectionImages.records : null;

  return (
    <DataTable
      loading={loading}
      columns={columns}
      rows={rows}
      topBarExtras={renderTopBarExtras()}
      pagination={{
        totalRows: data ? data.planCollectionImages.totalRecords : null,
        pageSize: pageSize,
        currentPage: page,
        onChangePage: (_, newPage) => {
          setPage(newPage);
          fetchMore({
            variables: {
              offset: newPage * pageSize,
              limit: pageSize,
            },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        },
      }}
    />
  );

  function getParsedQueryString() {
    return queryString.parse(location.search);
  }

  function defaultPage() {
    const parsedQueryString = getParsedQueryString();
    return parsedQueryString.page
      ? parseInt(parsedQueryString.page.toString()) - 1
      : 0;
  }

  function updateUrl() {
    const urlParams = { page: page + 1 };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderTopBarExtras() {
    return (
      <Button
        style={{ backgroundColor: "white", color: "#c8a063" }}
        variant="contained"
        component={Link}
        to="/plans/collections/images/new"
        startIcon={<AddIcon />}
      >
        New
      </Button>
    );
  }
}

function renderEdit(row: CollectionImage) {
  return (
    <Button
      size="small"
      component={Link}
      to={`/plans/collections/images/${row.id}`}
    >
      <EditIcon />
    </Button>
  );
}

const GET_COLLECTION_IMAGES = gql`
  query GetCollectionImagesQuery($offset: Int!, $limit: Int!) {
    planCollectionImages(offset: $offset, limit: $limit) {
      totalRecords
      records {
        id
        createdAt
        name
        imageUrl
      }
    }
  }
`;
