import { useQuery, gql, useMutation } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useEffect, useState } from "react";
import queryString from "query-string";

import Button from "../../../../components/Button2";
import DataTable from "../../../../components/DataTable";
import { useNotifications } from "../../../../components/Notification";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function ReviewsList() {
  const pageSize = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const { showNotification } = useNotifications();
  const parsedQueryString = queryString.parse(location.search);

  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );

  useEffect(() => {
    const urlParams = { page: page + 1 };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }, [page, navigate]);

  const [showHideReview] = useMutation(SHOW_HIDE_REVIEW);
  const { loading, error, data, fetchMore } = useQuery(GET_REVIEWS, {
    variables: { offset: page * pageSize, limit: pageSize },
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderViewReview },
    { name: "Created", field: "createdAt" },
    { name: "Booking", render: renderBooking },
    { name: "Rating", field: "rating" },
    { name: "Content", field: "content" },
    { name: "Has Images?", render: renderHasImages },
    { name: "Notified", render: renderPublishedEmailSent },
    { name: "Show?", render: renderReviewShown },
  ];
  const rows = data ? data.reviews.records : [];

  return (
    <DataTable
      loading={loading}
      columns={columns}
      rows={rows}
      pagination={{
        totalRows: data ? data.reviews.total : null,
        pageSize: pageSize,
        currentPage: page,
        onChangePage: (_, newPage) => {
          setPage(newPage);
          fetchMore({
            variables: {
              offset: newPage * pageSize,
              limit: pageSize,
            },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        },
      }}
    />
  );

  function renderViewReview(row: Review) {
    return (
      <Button component={Link} to={`/bookings/reviews/${row.id}`}>
        <VisibilityIcon />
      </Button>
    );
  }

  function renderBooking(row: Review) {
    return (
      <a style={{ color: "black" }} href={`/bookings/${row.bookingId}`}>
        {row.bookingId}
      </a>
    );
  }

  function renderHasImages(row: Review) {
    return row.images.length > 0 ? <>YES</> : <>NO</>;
  }

  function renderPublishedEmailSent(row: Review) {
    let Icon;
    if (row.publishedEmailSentAt)
      Icon = (
        <>
          <NotificationsActiveIcon style={{ color: "green" }} />
          <span>yes</span>
        </>
      );
    else if (row.show && !row.publishedEmailSentAt)
      Icon = (
        <>
          <NotificationsIcon style={{ color: "#c8a063" }} />
          <span>pending</span>
        </>
      );
    else
      Icon = (
        <>
          <NotificationsOffIcon style={{ color: "red" }} />
          <span>no</span>
        </>
      );
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "3.3rem",
          position: "relative",
          left: "-0.4rem",
        }}
      >
        {Icon}
      </div>
    );
  }

  function renderReviewShown(row: Review) {
    // if review is yet to be published, we add a confirmation screen for the operator
    const showReviewWithId = (show: boolean) => showReview(row.id, show);

    if (!(row.show || row.publishedEmailSentAt))
      return (
        <ShowSwitchWithConfirmation onConfirm={() => showReviewWithId(true)} />
      );

    return (
      <ShowSwitch
        checked={row.show}
        onChange={(event: any) => showReviewWithId(event.target.checked)}
      />
    );
  }

  function showReview(reviewId: string, show: boolean) {
    showHideReview({
      variables: { reviewId, show },
      refetchQueries: ["ReviewsListQuery"],
    }).then(() =>
      showNotification({ message: "Review updated", severity: "success" })
    );
  }
}

const ShowSwitchWithConfirmation = ({
  onConfirm: handlePublish,
}: {
  onConfirm: (show: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <ShowSwitch checked={false} onChange={handleOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Publish review?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to publish this review. Publishing a review will send
            an email to notify the partner.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="secondary" onClick={handlePublish}>
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ShowSwitch = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: { backgroundColor: "red" },
})(Switch);

const SHOW_HIDE_REVIEW = gql`
  mutation ShowHideReviewMutation($reviewId: ID!, $show: Boolean!) {
    showHideReview(input: { reviewId: $reviewId, show: $show }) {
      success
    }
  }
`;

const GET_REVIEWS = gql`
  query ReviewsListQuery($offset: Int!, $limit: Int!) {
    reviews(offset: $offset, limit: $limit) {
      total
      records {
        id
        createdAt
        publishedEmailSentAt
        bookingId
        rating
        content
        show
        images {
          id
        }
      }
    }
  }
`;

interface Review {
  id: string;
  createdAt: string;
  bookingId: string;
  rating: number;
  publishedEmailSentAt: string;
  show: boolean;
  images: { id: string }[];
}
