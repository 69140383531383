import React from 'react'
import { gql, useQuery } from "@apollo/client";
import Checkbox from '../../../components/Checkbox';
import { RoleType } from '../../../types';

type Props = {
  roleSelected: String[],
  setRoleSelected:(roles:String[]) => void
}

const UserRoleCheckbox = ({roleSelected,setRoleSelected}: Props) => {
  const { data, loading } = useQuery(GET_ROLES);

  const handleChangeCheckbox = (checked: Boolean,roleId:String) => {
    if(checked) {
      setRoleSelected([...roleSelected,roleId]);
    }else {
      const newRoles = roleSelected.filter(id => id !== roleId);
      setRoleSelected(newRoles);
    }
  }

  return (
    <div>
      <h3 style={{marginTop:0,marginBottom:8}}>Roles</h3>
      <div style={{marginLeft:-8}}>
      {data?.roles?.records?.map((role:RoleType) => (
        <Checkbox 
          label={role.name} 
          onChange={(checked:Boolean) => handleChangeCheckbox(checked,role.id)} 
          checked={roleSelected.includes(role.id)} 
          labelPlacement='end'
        />
      ))}
      </div>
    </div>
  )
}

export default UserRoleCheckbox

const GET_ROLES = gql`
  query RolesListQuery {
    roles{
      records {
        id
        name
        createdAt
      }
    }
  }
`;