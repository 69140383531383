export function FormatMoney(props: { amount: number; currencyCode: string }) {
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: props.currencyCode,
  }).format(props.amount);
}

export default function Money(props: { amount: number; currencyCode: string }) {
  return <span>{FormatMoney(props)}</span>;
}
