import { useRef, useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useNotifications } from "../Notification";
import useStyles from "./styles";
import { isVideo, isImage } from "../../helpers/check-type-from-url";
import {
  CloudinaryImage,
  CloudinaryTransformationParamKey,
} from "../Cloudinary";
import { Button, CircularProgress, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import useImageResolution from "../../hooks/useImageResolution";

export enum MediaType {
  Image,
  Video,
}

export default function MediaUploader(props: MediaUploaderProps) {
  const { showNotification } = useNotifications();
  const classes = useStyles();
  const inputFile = useRef<HTMLInputElement>(null);
  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION);
  const [media, setMedia] = useState(props.media);
  const [inputFileValue, setInputFileValue] = useState("");
  const [uploading, setUploading] = useState(false);
  useEffect(() => {
    setMedia(props.media);
  }, [props.media]);

  if (uploading)
    return (
      <div className={classes.loaderDiv}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.container}>
      <input
        type="file"
        hidden
        ref={inputFile}
        value={inputFileValue}
        onChange={handleUploadMedia}
      />
      {media ? renderMedia(media) : renderUploadButton()}
    </div>
  );

  function renderMedia(mediaUrl: string) {
    if (props.allowedTypes.includes(MediaType.Video) && isVideo(mediaUrl))
      return renderVideo(mediaUrl);
    if (props.allowedTypes.includes(MediaType.Image) && isImage(mediaUrl))
      return <RenderImage imageUrl={mediaUrl ?? ""} />;
    return <>UNKNOWN FILE</>;
  }

  function renderVideo(videoUrl: string) {
    return (
      <>
        <div className={classes.changeButton}>
          {renderEditButton()}
          {renderDeleteButton()}
        </div>
        <video
          width={props.width ? props.width : undefined}
          height={props.height ? props.height : undefined}
          controls
        >
          <source src={videoUrl} />
          Your browser does not support the video tag.
        </video>
      </>
    );
  }

  function RenderImage({ imageUrl }: { imageUrl: string }) {
    const resolution = useImageResolution(imageUrl);
    const transformations: CloudinaryTransformationParamKey[] = [];
    if (props.width) transformations.push(`w_${parseInt(props.width)}`);
    if (props.height) transformations.push(`h_${parseInt(props.height)}`);

    return (
      <>
        <div className={classes.changeButton}>{renderDeleteButton()}</div>
        <CloudinaryImage
          className={classes.image}
          src={imageUrl}
          width={props.width ? props.width : undefined}
          height={props.height ? props.height : undefined}
          onClick={clickInput}
          transformations={transformations}
        />
        <Box
          sx={{
            color:
              resolution?.width + resolution?.height <= 1000
                ? "#1976d2"
                : "red",
          }}
        >
          {resolution ? (
            <>
              <Box>{`${resolution?.width ? resolution?.width : "..."} x ${
                resolution?.height ? resolution?.height : "..."
              }`}</Box>
              {resolution?.width + resolution?.height <= 1000 ? (
                <Box>低解像度注意</Box>
              ) : (
                ""
              )}
            </>
          ) : (
            "Loading..."
          )}
        </Box>
      </>
    );
  }

  function renderUploadButton() {
    return (
      <Button
        className={classes.uploadButton}
        variant="contained"
        startIcon={<AddIcon />}
        onClick={clickInput}
      >
        Upload
      </Button>
    );
  }

  function renderEditButton() {
    return (
      <IconButton className={classes.uploadButton} onClick={clickInput}>
        <EditIcon />
      </IconButton>
    );
  }

  function renderDeleteButton() {
    return (
      <IconButton
        className={classes.deleteButton}
        onClick={() => changeMedia(null)}
      >
        <DeleteIcon />
      </IconButton>
    );
  }

  function clickInput() {
    if (inputFile.current) inputFile.current.click();
  }

  function handleUploadMedia(event: any) {
    setUploading(true);
    const file = event.target.files[0];

    if (!checkIfFileAllowedToBeUploaded(file)) {
      showNotification({
        message:
          "File not allowed. *(Image Size Limit: 10MB) *(Video size limit: 500MB & in .mp4 format)",
        severity: "error",
      });
      setUploading(false);
      return;
    }
    uploadFile({
      variables: { file: file, path: props.uploadPath },
    })
      .then((response) => {
        const fileLocation = response.data.uploadFile.fileLocation;
        changeMedia(fileLocation);

        setUploading(false);
        setInputFileValue(""); // Reset file input
        showNotification({ message: "Media uploaded", severity: "success" });
      })
      .catch((err: any) => {
        showNotification({
          message: `Uploading media failed, ${err}`,
          severity: "error",
        });
        setUploading(false);
      });
  }

  function checkIfFileAllowedToBeUploaded(file: any) {
    let allowedFile = false;
    const { size } = file;
    const videoMaxSize = 500000000;
    const imageMaxSize = 10000000;

    if (
      props.allowedTypes.includes(MediaType.Video) &&
      isVideo(file.name) &&
      size < videoMaxSize
    )
      allowedFile = true;
    if (
      props.allowedTypes.includes(MediaType.Image) &&
      isImage(file.name) &&
      size < imageMaxSize
    )
      allowedFile = true;
    if (!allowedFile) return false;
    return true;
  }

  function changeMedia(mediaUrl: string | null) {
    setMedia(mediaUrl);
    if (props.onChangeFunction) props.onChangeFunction(mediaUrl);
  }
}

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFileMutation($file: Upload!, $path: String!) {
    uploadFile(input: { file: $file, path: $path }) {
      succeeded
      fileLocation
    }
  }
`;

interface MediaUploaderProps {
  media: string | null;
  width?: string;
  height?: string;
  uploadPath: string;
  allowedTypes: MediaType[];
  onChangeFunction?: (mediaUrl: string | null) => void;
}
