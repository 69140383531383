import React from "react";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { BookingEditHistoryType } from ".";
import { EditStatus } from "../../../../types";
import { Header } from "../../../Plans/Edit/PartnerEdits/cards/components/Header";
import s from "./Card.module.css";
import BasicCard from "./BasicCard";
import BasicAddRemoveCard from "./BasicAddRemoveCard";

type Props = {
  item: BookingEditHistoryType;
};

const readableEditStatus = {
  [EditStatus.ADDED]: "Addition",
  [EditStatus.REMOVED]: "Removal",
  [EditStatus.TOUCHED]: "Edit",
  [EditStatus.UNTOUCHED]: "",
};

const getCard = (edit: BookingEditHistoryType) => {
  switch (edit.editStatus) {
    case EditStatus.TOUCHED:
      return <BasicCard edit={edit} />;
    case EditStatus.REMOVED:
      return <BasicAddRemoveCard edit={edit} />;
    case EditStatus.ADDED:
      return <BasicAddRemoveCard edit={edit} />;
    default:
      return "";
  }
};

const BookingEditHistoryCard = ({ item }: Props) => {
  return (
    <Card style={{ margin: "20px 0" }}>
      <CardHeader
        component={() => (
          <div className={s[item.editStatus]}>
            <Header
              status={readableEditStatus[item.editStatus]}
              fieldName={item.fieldName}
              date={item.createdAt}
            >
              <p style={{ margin: 0, fontSize: 20, padding: "10px 0" }}>
                {readableEditStatus[item.editStatus]} by:{" "}
                {item.backofficeUser.username}
              </p>
            </Header>
          </div>
        )}
      />
      <Divider />
      <CardContent>{getCard(item)}</CardContent>
    </Card>
  );
};

export default BookingEditHistoryCard;
