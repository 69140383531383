// simple functions to check if url is video or image
// only checks based on file extension, so might not be 100% correct

import path from "path";

export function isVideo(url: string) {
  const urlFileExtension = path.extname(url);
  return videoFileExtensions.includes(urlFileExtension.toLowerCase());
}

export function isImage(url: string) {
  const urlFileExtension = path.extname(url);
  return imageFileExtensions.includes(urlFileExtension.toLowerCase());
}

const videoFileExtensions = [".mp4"];

const imageFileExtensions = [
  ".jpg",
  ".jpeg",
  ".gif",
  ".png",
  ".apng",
  ".svg",
  ".avif",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".webp",
];
