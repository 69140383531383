import { useState } from "react";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useStyles from "./user-menu-styles";
import { Button, Menu, MenuItem } from "@mui/material";

export default function UserMenu() {
  const classes = useStyles();
  const [menuAchor, setMenuAnchor] = useState<Element | null>(null);

  const localStorageUser = localStorage.getItem("user");
  const user = localStorageUser ? JSON.parse(localStorageUser) : null;

  return (
    <div className={classes.userMenu}>
      <Button
        className={classes.userButton}
        size="large"
        startIcon={<AccountCircleIcon style={{ fontSize: "28px" }} />}
        onClick={(e: { currentTarget: Element }) =>
          setMenuAnchor(e.currentTarget)
        }
      >
        {user && user.name}
      </Button>
      <Menu
        anchorEl={menuAchor}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(menuAchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem component={Link} to="/user/change-password">
          <span className={classes.menuItemText}>Change Password</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.removeItem("user");
            setMenuAnchor(null);
            window.location.reload();
          }}
        >
          <span className={classes.menuItemText}>Logout</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
