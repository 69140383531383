import { useParams } from "react-router-dom";
import { useMutation, useQuery, gql } from "@apollo/client";

import { useNotifications } from "../../../components/Notification";
import ImageGalleryUploader from "../../../components/ImageGalleryUploader";
import { ImageType } from "../../../components/ImageGalleryUploader/index";

export default function Images() {
  const { showNotification } = useNotifications();
  const { id: templateId } = useParams<{ id: string }>();
  const [saveTemplateImages] = useMutation(SAVE_TEMPLATE_IMAGES_MUTATION);
  const { data, loading, error } = useQuery(TEMPLATE_IMAGES_QUERY, {
    variables: { id: templateId },
  });
  if (loading) return <>Loading...</>;
  if (error) return <>Something went wrong</>;

  return (
    <>
      <div>Recommended image size: 800×1600px</div>
      <ImageGalleryUploader
        images={data.templateImages.map(
          (image: { id: string; imageUrl: string }) => ({
            id: image.id,
            imageUrl: image.imageUrl,
          })
        )}
        uploadPath={`templates/${templateId}/images`}
        useImageCategory={false}
        saveImagesFunction={handleSaveImages}
        gridSizes={{ xs: 12, sm: 6, md: 3 }}
      />
    </>
  );

  function handleSaveImages(images: ImageType[]) {
    saveTemplateImages({ variables: { input: { templateId, images } } })
      .then(({ data }) => {
        if (data.saveTemplateImages.success)
          showNotification({
            message: "Item images saved",
            severity: "success",
          });
        else {
          throw new Error(data.saveTemplateImages.error);
        }
      })
      .catch((e: any) => {
        showNotification({
          message: `Saving item images failed, ${e}`,
          severity: "error",
        });
      });
  }
}

const TEMPLATE_IMAGES_QUERY = gql`
  query TemplateImagesQuery($id: ID!) {
    templateImages(templateId: $id) {
      id
      templateId
      imageUrl
      index
    }
  }
`;

const SAVE_TEMPLATE_IMAGES_MUTATION = gql`
  mutation SaveTemplateImagesMutation($input: SaveTemplateImagesInput) {
    saveTemplateImages(input: $input) {
      success
      error
    }
  }
`;
