import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { useState } from "react";

type ColumnSelectorProps = {
  options: string[];
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function ColumnSelector({
  options,
  columns,
  setColumns,
}: ColumnSelectorProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={"Columns"}>
        <IconButton onClick={handleClick}>
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => {
          const columnIndex = columns.findIndex((column) => column === option);
          const isSelected = columnIndex !== -1;
          return (
            <MenuItem
              key={option}
              selected={isSelected}
              onClick={() =>
                setColumns((columns) =>
                  isSelected
                    ? [
                        ...columns.slice(0, columnIndex),
                        ...columns.slice(columnIndex + 1),
                      ]
                    : [...columns, option]
                )
              }
            >
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
