import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CircularProgress } from "@mui/material";

import Notification, {
  NotificationData,
} from "../../../../components/Notification";
import { ActivityReservationType } from "./types";

export default function ConfirmReservationButton(
  props: ConfirmReservationButtonProps
) {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmVenueReservation] = useMutation(CONFIRM_VENUE_RESERVATION);
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );

  return (
    <>
      <Button
        style={{
          backgroundColor: "#4caf50",
          color: "#ffffff",
          marginRight: "1rem",
        }}
        variant="contained"
        startIcon={<CheckIcon />}
        onClick={() => setDialogOpen(true)}
        disabled={loading}
      >
        {loading ? <CircularProgress size={25} /> : "Confirm"}
      </Button>
      <ConfirmDialog />
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function closeDialog() {
    setDialogOpen(false);
  }

  function ConfirmDialog() {
    return (
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Confirm Venue Reservation</DialogTitle>
        <DialogContent>
          Are you sure you want to confirm the reservation?
          <br />
          <br />
          This action will:
          <ul>
            <li>Change reservation status to CONFIRMED</li>
            <li>Send confirmation email to venue</li>
            <li>
              If all reservations for booking are confirmed:
              <ul>
                <li>Change booking status to CONFIRMED</li>
                <li>Send confirmation email to customer</li>
              </ul>
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button color="primary" onClick={confirmReservation}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function confirmReservation() {
    setLoading(true);
    confirmVenueReservation({
      variables: { input: { activityReservationId: props.reservation.id } },
      refetchQueries: [
        "VenueReservationsListQuery",
        "BookingActivityReservationsQuery",
        "BookingQuery",
      ],
    })
      .then(() => {
        setNotification({
          message: `Venue Reservation was successfully confirmed`,
          severity: "success",
        });
        setLoading(false);
      })
      .catch((reason) => {
        setNotification({
          message: `Confirming venue reservation failed! ${reason}`,
          severity: "error",
        });
        setLoading(false);
      });

    closeDialog();
  }
}

const CONFIRM_VENUE_RESERVATION = gql`
  mutation confirmVenueReservation($input: ConfirmVenueReservationInput!) {
    confirmVenueReservation(input: $input) {
      success
    }
  }
`;

interface ConfirmReservationButtonProps {
  reservation: ActivityReservationType;
}
