import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNotifications } from "../../../components/Notification";

export default function DeleteRoleDialog({
  roleId,
  handleClose,
  refetch,
}: DeleteRoleDialogProps) {
  const [deleteRoleMutation] = useMutation(DELETE_ROLE_MUTATION);
  const { showNotification } = useNotifications();

  const deleteRole = () =>
    deleteRoleMutation({
      variables: {
        id: roleId!,
      },
    })
      .then((data: any) => {
        console.log(data?.data?.deleteRole?.success);
        if (!data?.data?.deleteRole?.success) {
          showNotification({
            message: data?.data?.deleteRole?.error,
            severity: "error",
          });
          return;
        }
        refetch();
        showNotification({
          message: "Role removed successfully.",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Failed to remove role!",
          severity: "error",
        });
      });

  return (
    <Dialog open={roleId !== null} onClose={handleClose}>
      <DialogTitle>{"Delete role?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you wish to delete this role?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            deleteRole();
            handleClose();
          }}
        >
          Delete Role
        </Button>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRoleMutation($id: ID!) {
    deleteRole(input: { id: $id }) {
      success
      error
    }
  }
`;

interface DeleteRoleDialogProps {
  handleClose: () => void;
  roleId: string | null;
  refetch: () => void;
}
