import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useNotifications } from "../../../components/Notification";
import { useNavigate } from "react-router-dom";
import { maxRows } from "../constants";
import AnnouncementForm from "../AnnouncementForm";
import persistAnnouncement from "../handlePersistAnnouncement";

export default function AddNewAnnouncement() {
  const { showNotification } = useNotifications();
  const [synopsis, setSynopsis] = useState("");
  const [content, setContent] = useState("");
  const [addAnnouncement] = useMutation(ADD_ANNOUNCEMENT);
  const navigate = useNavigate();

  const { error, data, loading } = useQuery(GET_TOTAL_ANNOUNCEMENTS, {
    variables: { offset: 0, limit: 0 },
  });
  if (loading) return <>Loading...</>;
  if (error) return <>Error! {error.message}</>;
  if (data.announcements.totalRecords >= maxRows) {
    showNotification({
      message: "Max announcements reached",
      severity: "error",
    });
    navigate("/announcements");
  }

  return (
    <AnnouncementForm
      synopsis={synopsis}
      content={content}
      setSynopsis={setSynopsis}
      setContent={setContent}
      handlePersistAnnouncement={() =>
        persistAnnouncement(
          synopsis,
          content,
          showNotification,
          addAnnouncement,
          navigate,
          "add"
        )
      }
      persistAnnouncementButtonText="Save New Announcement"
    />
  );
}

const ADD_ANNOUNCEMENT = gql`
  mutation AddAnnouncementMutation($synopsis: String!, $content: String!) {
    addAnnouncement(input: { synopsis: $synopsis, content: $content }) {
      success
      error
    }
  }
`;

const GET_TOTAL_ANNOUNCEMENTS = gql`
  query GetTotalAnnouncementsQuery($offset: Int!, $limit: Int!) {
    announcements(offset: $offset, limit: $limit) {
      totalRecords
    }
  }
`;
