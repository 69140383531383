import { useQuery, gql } from "@apollo/client";
import { Link, useParams } from "react-router-dom";

import Button from "../../../components/Button2";
import Form from "./form";

export default function EditCollection() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery(COLLECTION_QUERY, {
    variables: { id },
  });
  if (loading) return <>loading...</>;
  if (error) return <>ERROR: {error.message}</>;

  return (
    <>
      <Button
        style={{ marginBottom: "15px" }}
        component={Link}
        to={`/plans/collections`}
      >
        Back to list
      </Button>
      <Form collection={data.planCollection} />
    </>
  );
}

const COLLECTION_QUERY = gql`
  query PlanCollectionQuery($id: ID!) {
    planCollection(id: $id) {
      id
      name
      slug
      title
      description
      metaTitle
      listTitle
      listTitleDescription
      showInHome
      score
      bannerText
      bannerSrc
      isHomeContent
      homePriority
      allTags
      allLocations
      priceMinimum
      priceMaximum
      planCollectionImage {
        id
        name
        imageUrl
      }
      tags {
        id
        name

        tagCategory {
          id
          name
        }
      }

      locations {
        id
        type
        nameEn
        nameJa
      }
    }
  }
`;
