import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItemIcon,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextField from "../../../../components/TextField";
import { useState, useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { cloneDeep } from "lodash";
import { useNotifications } from "../../../../components/Notification";
import { icons } from "../../icons";
import { v4 as uuidv4 } from "uuid";

export default function WhatsIncluded(props: WhatsIncludedProps) {
  return (
    <Card>
      <CardHeader title={"What's Included"} />
      <Divider />
      <CardContent>
        <VerticalDragList {...props} />
      </CardContent>
    </Card>
  );
}

interface WhatsIncludedProps {
  onChange: Function;
  inclusions: {
    category: string;
    items: ItemType[];
  }[];
}
interface ItemType {
  id: string;
  title: string;
  description: string;
  iconName: string;
}

function VerticalDragList(props: WhatsIncludedProps) {
  const { showNotification } = useNotifications();
  const items = props.inclusions;
  const { onChange } = props;
  const grid = 10;

  const [categoryAssign, setCategoryAssign] = useState("");
  const [itemTitle, setItemTitle] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [selectedIcon, setIcon] = useState("GoPrimitiveDot");

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = [...list.items];

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return {
      category: list.category,
      items: result,
    };
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#77e2e0" : "#fff",
    border: "1px solid #ccc",
    borderRadius: 5,
    ...draggableStyle,
  });

  const getListStyle = () => ({
    padding: grid,
    flexDirection: "column",
  });

  function setInclusionItem(index: number, itemIndex: number, item: ItemType) {
    // deep clone required to modify nested properties
    const copy = cloneDeep(items);
    copy[itemIndex].items[index] = item;

    try {
      onChange(copy);
      showNotification({
        message:
          "Changes have been applied locally. Please don't forget to save.",
        severity: "success",
      });
    } catch (error: any) {
      showNotification({
        message: `Something went wrong. ${error.message}`,
        severity: "success",
      });
    }
  }

  const onDragEnd = useCallback(
    (result: any, category: string, onChange: Function) => {
      if (!result.destination) {
        return;
      }
      const reorderItems = reorder(
        items.find((f) => f.category === category),
        result.source.index,
        result.destination.index
      );
      const newList = items.map((item) => {
        if (item.category === category) {
          return reorderItems;
        }
        return item;
      });
      onChange(newList as any);
    },
    [items]
  );

  const removeItem = (i: any) => {
    const updatedList = items
      .map((item) => {
        let items = item.items.filter((l) => l.title !== i.title);
        return {
          ...item,
          items,
        };
      })
      .filter((u) => u.items.length > 0);
    onChange(updatedList as any);
  };

  return (
    <>
      <section
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {items.map((item, itemIndex) => {
          return (
            <DragDropContext
              key={itemIndex}
              onDragEnd={(result: any) =>
                onDragEnd(result, item.category, onChange)
              }
            >
              <Droppable droppableId={`droppable-${itemIndex}`}>
                {(provided: any) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle()}
                  >
                    <h3>
                      {itemIndex + 1}: {item.category}
                    </h3>
                    {item.items.map((i, index) => {
                      return (
                        <Draggable
                          key={i.title}
                          draggableId={i.title}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <IncludedCard
                                i={i}
                                index={index}
                                itemIndex={itemIndex}
                                removeItem={removeItem}
                                updateInclusionList={setInclusionItem}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          );
        })}
      </section>
      <section
        style={{
          marginTop: 20,
        }}
      >
        <FormControl
          variant="outlined"
          style={{
            width: 70,
            height: 55,
            marginRight: 20,
          }}
        >
          <InputLabel htmlFor="outlined-category-native-simple">
            Icon
          </InputLabel>
          <IconSelector selectedIcon={selectedIcon} setIcon={setIcon} />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-category-native-simple">
            Category
          </InputLabel>

          <Select
            native
            value={categoryAssign}
            onChange={(e: any) => setCategoryAssign(e.target.value)}
            label="Category"
            style={{
              height: 60,
            }}
            inputProps={{
              name: "category",
              id: "outlined-category-native-simple",
            }}
          >
            <option aria-label="None" value="" />
            <option value={"お食事"}>お食事</option>
            <option value={"特典ギフト"}>特典ギフト</option>
            <option value={"体験・移動"}>体験・移動</option>
            <option value={"含まれないもの"}>含まれないもの</option>
            <option value={"お席"}>お席</option>
          </Select>
        </FormControl>
        <TextField
          label="title"
          value={itemTitle}
          onChange={(e: any) => setItemTitle(e.target.value)}
        />
        <TextField
          label="description"
          value={itemDescription}
          onChange={(e: any) => setItemDescription(e.target.value)}
        />
        <Button
          style={{
            marginTop: 20,
          }}
          disabled={!itemTitle || !selectedIcon || !categoryAssign}
          variant="contained"
          color="primary"
          onClick={() => {
            if (items.find((item) => item.category === categoryAssign)) {
              let obj = {
                title: itemTitle,
                description: itemDescription,
                iconName: selectedIcon,
                id: uuidv4(),
              };
              let updatedList = items.map((item) => {
                if (item.category === categoryAssign) {
                  return {
                    ...item,
                    items: item.items.concat(obj as any),
                  };
                }
                return item;
              });
              onChange(updatedList);
              setItemTitle("");
              setItemDescription("");
            } else {
              let obj = {
                category: categoryAssign,
                items: [
                  {
                    title: itemTitle,
                    description: itemDescription,
                    iconName: selectedIcon,
                    id: uuidv4(),
                  },
                ],
              };
              let newList = items.concat(obj as any);
              onChange(newList);
              setItemTitle("");
              setItemDescription("");
            }
          }}
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </section>
    </>
  );
}

function IncludedCard({
  i,
  index,
  itemIndex,
  removeItem,
  updateInclusionList,
}: any) {
  const { showNotification } = useNotifications();
  const [isUnchanged, setIsUnchanged] = useState(true);
  const [itemTitle, setItemTitle] = useState(i.title);
  const [itemDescription, setItemDescription] = useState(i.description);
  const [selectedIcon, setIcon] = useState(i.iconName);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <IconSelector
        key={`selectedIcon-${itemIndex}-${index}`}
        identifier={`selectedIcon-${itemIndex}-${index}`}
        selectedIcon={selectedIcon}
        setIcon={setIcon}
        setChangeStatus={setIsUnchanged}
      />
      <div
        style={{
          flexDirection: "column",
          marginLeft: 10,
        }}
      >
        <TextField
          size="small"
          label={"Title"}
          value={itemTitle}
          onChange={(e: any) => {
            setItemTitle(e.target.value);
            setIsUnchanged(false);
          }}
        ></TextField>
        <TextField
          label={"Description"}
          multiline
          value={itemDescription}
          onChange={(e: any) => {
            setItemDescription(e.target.value);
            setIsUnchanged(false);
          }}
        ></TextField>

        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: "5px" }}
          onClick={() => {
            removeItem(i);
            showNotification({
              message: "Item was removed locally. Please don't forget to save.",
              severity: "success",
            });
            setIsUnchanged(true);
          }}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          disabled={isUnchanged}
          style={{ marginTop: "5px", marginLeft: "5px" }}
          onClick={() =>
            updateInclusionList(index, itemIndex, {
              id: i.id,
              title: itemTitle,
              description: itemDescription,
              iconName: selectedIcon,
            })
          }
        >
          Apply Change
        </Button>
      </div>
    </div>
  );
}

function IconSelector(props: IconSelectorProps) {
  return (
    <Select
      key={props.identifier || props.selectedIcon}
      value={props.selectedIcon}
      onChange={(e: any) => {
        props.setIcon(e.target.value);
        if (props.setChangeStatus) props.setChangeStatus(false);
      }}
      label="Icon"
    >
      {Array.from(icons).map(([value, icon]) => (
        <MenuItem value={value} key={value}>
          <ListItemIcon>{icon}</ListItemIcon>
        </MenuItem>
      ))}
    </Select>
  );
}

interface IconSelectorProps {
  selectedIcon: string;
  setIcon: React.Dispatch<React.SetStateAction<string>>;
  setChangeStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  identifier?: string;
}
