import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import Button from "../../../../components/Button2";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";
import TextField from "../../../../components/TextField";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocationSpecial } from "../../../../types";
import { useNotifications } from "../../../../components/Notification";

export default function EditLocationSpecialsForm(
  props: EditLocationSpecialsFormProps
) {
  const { showNotification } = useNotifications();
  const [saveLocationSpecials] = useMutation(SAVE_LOCATION_SPECIALS_MUTATION);
  const [locationSpecials, setLocationSpecials] = useState(
    props.locationSpecials
  );

  return (
    <>
      <Grid
        style={{ marginBottom: "10px" }}
        container
        justifyContent="flex-end"
      >
        <Button startIcon={<AddIcon />} onClick={handleAddLocationSpecial}>
          Add
        </Button>
      </Grid>
      <Grid style={{ marginBottom: "80px" }} container spacing={2}>
        {locationSpecials.map((special) => (
          <Grid key={special.index} item xs={12} md={6}>
            <Card>
              <CardContent>
                <IconButton
                  style={{ float: "right" }}
                  size="small"
                  onClick={() => handleRemove(special.index)}
                >
                  <DeleteIcon />
                </IconButton>
                <TextField
                  style={{ width: "50%" }}
                  label="Index"
                  value={special.index}
                  readOnly={true}
                />
                <TextField
                  label="Title"
                  value={special.title}
                  onChange={(e: any) =>
                    handleChange(special.index, "title", e.target.value)
                  }
                />
                <TextField
                  label="Link Text"
                  value={special.linkText}
                  onChange={(e: any) =>
                    handleChange(special.index, "linkText", e.target.value)
                  }
                />
                <TextField
                  style={{ marginBottom: "12px" }}
                  label="Url"
                  value={special.url}
                  onChange={(e: any) =>
                    handleChange(special.index, "url", e.target.value)
                  }
                />
                <MediaUploader
                  width="464px"
                  height="232px"
                  allowedTypes={[MediaType.Image]}
                  uploadPath={`locations/${props.locationId}/specials`}
                  media={special.imageUrl}
                  onChangeFunction={(value) =>
                    handleChange(special.index, "imageUrl", value)
                  }
                />
                <div style={{ marginTop: "10px", fontSize: "12px" }}>
                  Recommended size: 464x232px
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div className={"save-button"}>
        <Button onClick={handleSave}>Save</Button>
      </div>
    </>
  );

  function handleAddLocationSpecial() {
    let index = 0;
    if (locationSpecials.length > 0) {
      const highest = locationSpecials.reduce((prev, cur) =>
        prev.index > cur.index ? prev : cur
      );
      index = highest.index + 1;
    }

    setLocationSpecials([
      ...locationSpecials,
      {
        index: index,
        title: "",
        linkText: "",
        url: "",
        imageUrl: "",
      },
    ]);
  }

  function handleRemove(index: number) {
    setLocationSpecials(locationSpecials.filter((s) => s.index !== index));
  }

  function handleChange(index: number, field: string, value: any) {
    setLocationSpecials(
      locationSpecials.map((s) =>
        s.index === index ? { ...s, [field]: value } : s
      )
    );
  }

  function handleSave() {
    saveLocationSpecials({
      variables: {
        locationId: props.locationId,
        locationSpecials: removeEmptyProperties(locationSpecials),
      },
    })
      .then(() => {
        showNotification({
          message: `Specials were saved`,
          severity: "success",
        });
      })
      .catch((e) => {
        showNotification({
          message: `Saving specials failed!`,
          severity: "error",
        });
      });
  }

  function removeEmptyProperties(specials: LocationSpecial[]) {
    return specials.map((s) =>
      Object.entries(s).reduce(
        (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
        {}
      )
    );
  }
}

interface EditLocationSpecialsFormProps {
  locationId: string;
  locationSpecials: LocationSpecial[];
}

const SAVE_LOCATION_SPECIALS_MUTATION = gql`
  mutation SaveLocationSpecialsMutation(
    $locationId: ID!
    $locationSpecials: [LocationSpecialToSave!]!
  ) {
    saveLocationSpecials(
      input: { locationId: $locationId, locationSpecials: $locationSpecials }
    )
  }
`;
