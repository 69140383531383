import { IconButton } from "@mui/material";
import useTags from "../../../hooks/useTags";
import { Tag } from "../../../types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AlertDialog from "../../../components/AlertDialog";
import { useState } from "react";

type DeleteTagProps = Pick<Tag, "id">;

export default function DeleteTagButton({ id }: DeleteTagProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const { deleteTag } = useTags();

  return (
    <>
      <IconButton onClick={() => setOpenDialog(true)}>
        <DeleteForeverIcon />
      </IconButton>
      <AlertDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title="Delete Tag"
        content="Are you sure you want to delete this tag? This action cannot be undone"
        onConfirm={() => {
          deleteTag({
            variables: { id },
          });
        }}
      />
    </>
  );
}
