import { forwardRef, useImperativeHandle, useMemo } from "react";
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { TripleElementWithMediaRenderer } from "../components/renderers";
import { CardProps, GetOriginalValueProps, HighlightData } from "../../types";

type HighlightCardProps = Partial<CardProps> & {
  planId: string;
  fieldId: string;
  title: string;
  details: string;
  mediaUrl: string;
  mediaType: "photo" | "video";
  index: number;
  exclusive: boolean;
  highlights: HighlightData[];
  setHighlights: (highlights: HighlightData[]) => void;
};

const HighlightCard = forwardRef((props: HighlightCardProps, ref) => {
  const [title, setTitle] = useState(props.title);
  const [details, setDetails] = useState(props.details);

  const index = useMemo(() => Number(props.fieldId), [props]);

  const [mutation] = useMutation<EditHighlightResponse, EditHighlightInput>(
    EDIT_HIGHLIGHT_MUTATION
  );

  const persistChanges = async () => {
    const highlight = {
      index,
      title,
      details,
      media: {
        src: props.mediaUrl,
        type: props.mediaType,
      },
      exclusive: props.highlights.find((highlight) => highlight.index === index)
        ?.exclusive,
    } as HighlightData;

    const highlights = props.highlights.map((originalHighlight) =>
      originalHighlight.index === index ? highlight : originalHighlight
    );

    props.setHighlights(highlights);

    const input: EditHighlightInput["input"] = {
      id: props.planId,
      highlights,
    };

    return await mutation({ variables: { input } });
  };

  const getNewValue = () => ({ title, details, mediaUrl: props.mediaUrl });

  useImperativeHandle(ref, () => ({
    persistChanges,
    getNewValue,
  }));

  return (
    <TripleElementWithMediaRenderer
      mediaUrl={props.mediaUrl}
      mediaType={props.mediaType}
      topText={title}
      setTopText={setTitle}
      topLabel="title"
      bottomText={details}
      setBottomText={setDetails}
      bottomLabel="details"
      mutable={!!props.mutable}
    />
  );
});

const getOriginalValue = ({ data, fieldId }: GetOriginalValueProps) => {
  const highlight = (data.highlights as HighlightData[]).find(
    (highlight) => highlight.index === Number(fieldId)
  );
  return {
    ...highlight,
    mediaUrl: highlight?.media?.src,
    mediaType: highlight?.media?.type,
  };
};

const faqCard = {
  Content: HighlightCard,
  getOriginalValue,
};
export default faqCard;

const EDIT_HIGHLIGHT_MUTATION = gql`
  mutation EditHighlightMutation($input: SavePlanHighlightsInput!) {
    savePlanHighlights(input: $input) {
      plan {
        id
      }
    }
  }
`;

type EditHighlightResponse = {
  plan: {
    id: string;
  };
};

type EditHighlightInput = {
  input: {
    id: string;
    highlights: (Omit<HighlightData, "exclusive"> &
      Pick<Partial<HighlightData>, "exclusive">)[];
  };
};
