import { IconButton, Tooltip } from "@mui/material";
import BaseCard, { BaseCardProps, getCard } from "./BaseCard";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { ApplyEditDialog, DiscardEditDialog } from "./components/VerifyDialog";
import { PartnerPlanEdit, PartnerPlanEditAction } from "../../../../../types";
import { useNotifications } from "../../../../../components/Notification";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import {
  GET_PLAN_IDS_WITH_EDITS,
  GetPlanIdsWithEditsResponse,
} from "../../../../../components/Menu";

export type EditCardProps = Omit<
  BaseCardProps & { mutable: true; originalData: any },
  "mutable" | "headerContent" | "contentRef" | "originalValue"
> & {
  partnerEdits: PartnerPlanEdit[];
  setPartnerEdits: Dispatch<SetStateAction<PartnerPlanEdit[]>>;
};

const enum ModalType {
  Apply,
  Reject,
}

export default function EditCard({
  originalData,
  mutationProps,
  partnerEdits,
  setPartnerEdits,
  ...props
}: EditCardProps) {
  // This card is used for when the operators ought to be able to update the plan
  const apolloClient = useApolloClient();

  const [currentModal, setCurrentModal] = useState<ModalType | null>(null);
  const { showNotification } = useNotifications();
  const contentRef = useRef<{
    persistChanges: () => Promise<void>;
    getNewValue: () => any;
  }>();

  const fieldName = props.edit.fieldName;
  const newValue = JSON.parse(props.edit.newValue);
  const fieldId = newValue.fieldId;

  const { getOriginalValue } = getCard(fieldName);

  const originalValue = getOriginalValue({
    data: originalData,
    fieldName,
    fieldId,
  });

  const [verifyEdit] = useMutation<
    VerifyPartnerEditMutationResponse,
    VerifyPartnerEditMutationinput
  >(VERIFY_PARTNER_EDIT_MUTATION);

  const handleVerifyEdit = (action: keyof typeof PartnerPlanEditAction) => {
    verifyEdit({
      variables: {
        input: {
          id: props.edit.id,
          action,
          oldValue: JSON.stringify(originalValue),
          newValue:
            action === "ACCEPTED"
              ? JSON.stringify({
                  ...originalValue,
                  ...(contentRef.current?.getNewValue() ?? {}),
                })
              : undefined,
        },
      },
    });
    const updatedPartnerEdits = partnerEdits.filter(
      (edit) => edit.id !== props.edit.id
    );
    setPartnerEdits(updatedPartnerEdits);
    if (updatedPartnerEdits.length === 0) {
      // update sidebar announcement bell cache
      const cachedPlans = (apolloClient.cache.readQuery({
        query: GET_PLAN_IDS_WITH_EDITS,
      }) as GetPlanIdsWithEditsResponse).plansWithPendingEdits.plans;

      apolloClient.cache.writeQuery({
        query: GET_PLAN_IDS_WITH_EDITS,
        data: {
          plansWithPendingEdits: {
            plans: cachedPlans.filter((plan) => plan.id !== props.edit.planId!),
          },
        },
      });
    }
  };

  return (
    <>
      <BaseCard
        contentRef={contentRef}
        mutable={true}
        originalValue={originalValue}
        mutationProps={{ ...mutationProps, fieldName, fieldId }}
        {...props}
        headerContent={
          <div>
            <Tooltip title="Apply changes">
              <IconButton onClick={() => setCurrentModal(ModalType.Apply)}>
                <CheckCircleOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject changes">
              <IconButton onClick={() => setCurrentModal(ModalType.Reject)}>
                <HighlightOffOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
      />

      <ApplyEditDialog
        open={currentModal === ModalType.Apply}
        handleClose={() => setCurrentModal(null)}
        onConfirm={async () => {
          try {
            await contentRef.current?.persistChanges();
            handleVerifyEdit(PartnerPlanEditAction.ACCEPTED);
            showNotification({
              message: `Changes successfully saved`,
              severity: "success",
            });
          } catch (error: unknown) {
            showNotification({
              message: `Saving changes failed: ${error}`,
              severity: "error",
            });
          }
        }}
      />
      <DiscardEditDialog
        open={currentModal === ModalType.Reject}
        handleClose={() => setCurrentModal(null)}
        onConfirm={() => handleVerifyEdit(PartnerPlanEditAction.REJECTED)}
      />
    </>
  );
}

const VERIFY_PARTNER_EDIT_MUTATION = gql`
  mutation VerifyPartnerEditMutation($input: VerifyPartnerPlanEditInput!) {
    verifyPartnerPlanEdit(input: $input) {
      success
      error
    }
  }
`;

type VerifyPartnerEditMutationinput = {
  input: {
    id: string;
    action: keyof typeof PartnerPlanEditAction;
    oldValue: string;
    newValue?: string;
  };
};

type VerifyPartnerEditMutationResponse = {
  success: boolean;
  error: string;
};
