import { Select, MenuItem } from "@mui/material";
import { Key } from "react";

export default function SelectRow<T extends Key>({
  options,
  value,
  setValue,
}: SelectRowProps<T>) {
  return (
    <Select
      fullWidth
      value={value}
      onChange={(event) => setValue(event.target.value as T)}
    >
      {[value, ...options].map((option) => (
        <MenuItem value={option} key={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

export type SelectRowProps<T> = {
  options: T[];
  value: T;
  setValue: (value: T) => void;
};
