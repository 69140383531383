import { Card, CardContent, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";

import Button from "../../../../components/Button2";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";
import TextField from "../../../../components/TextField";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useNotifications } from "../../../../components/Notification";

export default function PlanQuestions() {
  const { id: planId } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery(PLAN_QUESTIONS_QUERY, {
    variables: { planId: planId },
  });
  if (loading) return <>loading...</>;
  if (error) return <>failed to load plan questions</>;

  const questions = data ? data.planQuestions : [];

  return <Questions questions={questions} />;
}

function Questions(props: { questions: QuestionI[] }) {
  const { id: planId } = useParams<{ id: string }>();
  const [questions, setQuestions] = useState<QuestionI[]>(props.questions);
  const [savePlanQuestions] = useMutation(SAVE_PLAN_QUESTIONS_MUTATION);
  const { showNotification } = useNotifications();

  function setQuestion(index: number, value: QuestionI) {
    let newQuestions = [...questions];
    if (value === null) newQuestions.splice(index, 1);
    if (value !== null) newQuestions[index] = value;
    setQuestions(newQuestions);
  }

  function addQuestion() {
    let newQuestions = [...questions];
    newQuestions.push({ question: "", answers: [] });
    setQuestions(newQuestions);
  }

  function handleSavePlanQuestions() {
    let emptyQuestions = questions.filter(
      (question) => question.question === ""
    );
    if (emptyQuestions.length > 0) {
      showNotification({
        message: `Saving question failed! Questions cannot be empty.`,
        severity: "error",
      });
      return;
    }
    savePlanQuestions({
      variables: { input: { planId: planId, questions: questions } },
    })
      .then(() => {
        showNotification({ message: "Questions saved", severity: "success" });
      })
      .catch((e: any) => {
        showNotification({
          message: `Saving questions failed! ${e}`,
          severity: "error",
        });
      });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              startIcon={<AddIcon />}
              color="secondary"
              onClick={() => addQuestion()}
            >
              Add new question
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<SaveIcon />}
              style={{ backgroundColor: "#c8a063", color: "white" }}
              onClick={() => handleSavePlanQuestions()}
            >
              Save questions
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {questions.map((question: QuestionI, index: number) => (
        <Grid key={index} item xs={12} md={6}>
          <Question
            index={index}
            question={question}
            setQuestion={setQuestion}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function Question(props: {
  index: number;
  question: QuestionI;
  setQuestion: any;
}) {
  function setQuestionText(text: string) {
    let updatedQuestion = { ...props.question };
    updatedQuestion.question = text;
    props.setQuestion(props.index, updatedQuestion);
  }

  function setAnswer(index: number, value: AnswerI | null) {
    let updatedQuestion = {
      ...props.question,
      answers: [...props.question.answers],
    };
    if (value === null) updatedQuestion.answers.splice(index, 1);
    if (value !== null) updatedQuestion.answers[index] = value;
    props.setQuestion(props.index, updatedQuestion);
  }

  function addAnswer() {
    let updatedQuestion = {
      ...props.question,
      answers: [...props.question.answers],
    };
    updatedQuestion.answers.push({ answer: "", imageUrl: null });
    props.setQuestion(props.index, updatedQuestion);
  }

  function removeQuestion() {
    props.setQuestion(props.index, null);
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item container xs={12} justifyContent="flex-end">
            <IconButton size="small" onClick={() => removeQuestion()}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Question"
              value={props.question.question}
              onChange={(e: Event) => setQuestionText(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {props.question.answers.length === 0 && (
                <span style={{ margin: "0px 10px", fontSize: "12px" }}>
                  If no answers, then customer can answer with a free text field
                </span>
              )}
              {props.question.answers.map((answer: AnswerI, index: number) => (
                <Grid key={index} item xs={12}>
                  <Answer index={index} answer={answer} setAnswer={setAnswer} />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  startIcon={<AddIcon />}
                  color="secondary"
                  onClick={() => addAnswer()}
                >
                  Add new answer
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Answer(props: { index: number; answer: AnswerI; setAnswer: any }) {
  const { id: planId } = useParams<{ id: string }>();

  function setAnswerText(text: string) {
    let updatedAnswer = { ...props.answer };
    updatedAnswer.answer = text;
    props.setAnswer(props.index, updatedAnswer);
  }

  function setAnswerImage(image: string | null) {
    let updatedAnswer = { ...props.answer };
    updatedAnswer.imageUrl = image;
    props.setAnswer(props.index, updatedAnswer);
  }

  function removeAnswer() {
    props.setAnswer(props.index, null);
  }

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={1}
        xs={2}
        style={{ marginTop: "auto", marginBottom: "auto" }}
      >
        <IconButton size="small" onClick={() => removeAnswer()}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        lg={8}
        xs={10}
        style={{ marginTop: "auto", marginBottom: "auto" }}
      >
        <TextField
          multiline={true}
          label="Answer"
          value={props.answer.answer}
          onChange={(e: Event) => setAnswerText(e.target.value)}
        />
      </Grid>
      <Grid
        item
        lg={3}
        xs={12}
        style={{ marginTop: "auto", marginBottom: "auto" }}
      >
        <MediaUploader
          width="60%"
          media={props.answer.imageUrl}
          uploadPath={`plans/${planId}/question-answers`}
          allowedTypes={[MediaType.Image]}
          onChangeFunction={setAnswerImage}
        />
      </Grid>
    </Grid>
  );
}

const PLAN_QUESTIONS_QUERY = gql`
  query PlanQuestionsQuery($planId: ID!) {
    planQuestions(planId: $planId) {
      question
      answers {
        answer
        imageUrl
      }
    }
  }
`;

const SAVE_PLAN_QUESTIONS_MUTATION = gql`
  mutation SavePlanQuestionsMutation($input: SavePlanQuestionsInput!) {
    savePlanQuestions(input: $input) {
      success
    }
  }
`;

interface QuestionI {
  question: string;
  answers: AnswerI[];
}

interface AnswerI {
  answer: string;
  imageUrl: string | null;
}

interface Event {
  target: {
    value: string;
  };
}
