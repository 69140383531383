import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import s from "./Row.module.css";

export function Row({ link, content }: RowProps) {
  return (
    <Button component={Link} to={link}>
      <div className={s.Row}>{content}</div>
    </Button>
  );
}

export type RowProps = {
  link: string;
  content: JSX.Element;
};
