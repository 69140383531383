import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "../../../components/Notification";
import { getPlatform } from "../../../helpers/get-user-platform";

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    backgroundColor: "#ffffff",
  },
  header: {
    backgroundColor: "#C8A063",
    color: "#ffffff",
  },
  button: {
    backgroundColor: "#C8A063",
    color: "#ffffff",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#C8A063",
    },
  },
  boxed: {
    marginLeft: "4rem",
    marginRight: "4rem",
    background: "#fff",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bold",
    padding: "30px",
    boxShadow: "inset #C8A063 0 0 0 5px",
  },
  list: {
    columns: "4 auto",
    listStyleType: "none",
    marginLeft: "auto",
    marginRight: "22.5%",
    maxWidth: "800px",
    textAlign: "start",
  },
});

const REQUIRED_KEYS = [
  "name",
  "email",
  "description",
  "phoneNumber",
  "address",
  "googleMapsUrl",
  "googleMapsEmbedUrl",
  "directions",
  "nearestStations",
  "acceptedPaymentMethods",
  "limitedEntryForChildren",
  "numberOfSeats",
  "businessHours",
  "holidays",
  "smokingAllowance",
  "dressCode",
  "babyChair",
  "kidChair",
  "wheelChairAccess",
  "breastFeedingRoom",
  "parking",
];

const OPTIONAL_KEYS = [
  "invoiceEmails",
  "notificationEmails",
  "nameFurigana",
  "remarks",
  "internalRemarks",
  "postcode",
];

function getMissingKeys(
  csvDataArray: Record<string, any>[],
  requiredKeys: string[],
  optionalKeys: string[]
): { missingKeys: string[]; unexpectedKeys: string[] } {
  const missingKeys: string[] = [];
  const unexpectedKeys: string[] = [];

  // Iterate through each object of the csvDataArray
  for (const originalObject of csvDataArray) {
    // Filter out any keys that are not specified as required
    const filteredObj = Object.keys(originalObject)
      .filter((key) => {
        // Keep track of keys that are neither required nor optional (= should not be a column)
        if (![...requiredKeys, ...optionalKeys].includes(key))
          unexpectedKeys.push(key);
        return requiredKeys.includes(key);
      })
      .reduce((obj: any, key: string) => {
        obj[key] = originalObject[key];
        return obj;
      }, {});

    // Check if the filtered object contains all of the specified keys
    if (Object.keys(filteredObj).length !== requiredKeys.length) {
      const missing = requiredKeys.filter(
        (key) => !Object.keys(filteredObj).includes(key)
      );
      missingKeys.push(...missing);
    }
  }

  // Return the array of unique missing keys
  return {
    missingKeys: [...new Set(missingKeys)],
    unexpectedKeys: [...new Set(unexpectedKeys)],
  };
}

export default function ImportVenue() {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [array, setArray] = useState<Array<Record<string, string>>>([]);
  const [importVenues] = useMutation(IMPORT_VENUES_MUTATION);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotifications();

  const fileReader = new FileReader();

  function resetImportProcess(e: React.FormEvent<HTMLFormElement>) {
    // reset input file, so user can attach new file
    setFile(undefined);
    // @ts-ignore
    e.target[0].value = null;

    setLoading(false);
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0]);
  };

  function csvToArray(inputString: string) {
    const platform = getPlatform().toLowerCase();
    const isWindows = /win/.test(platform);

    // for unix users it needs to be index of \n, for windows \r\n because of different CSV formats on saving
    const csvHeader = isWindows
      ? inputString.slice(0, inputString.indexOf("\r\n")).split(",")
      : inputString.slice(0, inputString.indexOf("\n")).split(",");

    let previousCharacter = "",
      row = [""],
      resultingMultiDimensionalArray = [row],
      columnIndex = 0,
      rowIndex = 0,
      isQuotedString = !0,
      currentCharacter;

    for (currentCharacter of inputString) {
      if ('"' === currentCharacter) {
        if (isQuotedString && currentCharacter === previousCharacter)
          row[columnIndex] += currentCharacter;
        isQuotedString = !isQuotedString;
      } else if ("," === currentCharacter && isQuotedString)
        currentCharacter = row[++columnIndex] = "";
      else if ("\n" === currentCharacter && isQuotedString) {
        if ("\r" === previousCharacter)
          row[columnIndex] = row[columnIndex].slice(0, -1);
        row = resultingMultiDimensionalArray[++rowIndex] = [
          (currentCharacter = ""),
        ];
        columnIndex = 0;
      } else row[columnIndex] += currentCharacter;
      previousCharacter = currentCharacter;
    }

    const csvRows = resultingMultiDimensionalArray.map((rowContent) =>
      rowContent.join(",")
    );

    // filter out the rows with only location key
    const newArray = csvRows
      .map((rowContent, i) => {
        // don't return first row (headers) and empty rows
        if (!rowContent || rowContent === " " || i === 0) return [];

        const values = rowContent.split(",");

        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {} as Record<string, any>);

        return obj;
      })
      .filter((venueObj) => Object.keys(venueObj).length > 1);

    return newArray;
  }

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (file) {
      fileReader.onload = function (event) {
        if (event && event.target) {
          const text = event.target.result as string;
          const csvDataArray = csvToArray(text);

          // check if column names match the expected format
          const validatedKeys = getMissingKeys(
            csvDataArray,
            REQUIRED_KEYS,
            OPTIONAL_KEYS
          );

          if (validatedKeys.missingKeys.length > 0) {
            showNotification({
              message: `The uploaded CSV file does not contain all required columns: ${validatedKeys.missingKeys}`,
              severity: "error",
            });
            resetImportProcess(e);
          }

          if (validatedKeys.unexpectedKeys.length > 0) {
            showNotification({
              message: `The uploaded CSV contains unexpected column names: ${validatedKeys.unexpectedKeys}`,
              severity: "error",
            });
            resetImportProcess(e);
          }
          // rewrite the venue rows to the format expected by the API
          const formattedVenueInput = csvDataArray.map((venue) => {
            venue.location = {
              address: venue.address,
              googleMapsUrl: venue.googleMapsUrl,
              googleMapsEmbedUrl: venue.googleMapsEmbedUrl,
              directions: venue.directions,
              nearestStations: [...venue.nearestStations.split(";")],
            };

            delete venue["address"];
            delete venue["googleMapsUrl"];
            delete venue["googleMapsEmbedUrl"];
            delete venue["directions"];
            delete venue["nearestStations"];

            return venue;
          });

          importVenues({
            variables: { input: { venues: formattedVenueInput } },
          })
            .then((res) => {
              showNotification({
                message: `Venues were imported successfully`,
                severity: "success",
              });
              setArray(res.data.importVenues.venues);
            })
            .catch((err: any) => {
              showNotification({
                message: `Importing venues failed! ${err}`,
                severity: "error",
              });
            })
            .finally(() => {
              resetImportProcess(e);
            });
        }
      };
      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h1>IMPORT VENUES </h1>
      <div className={styles.boxed}>
        For importing venues through a CSV file, please make sure the following
        column names are provided and contain a valid value. <br />
        In addition, the CSV file saved on a Windows device can only be imported
        from a Windows device and vice versa for Mac.
        <br />
        <br />
        The content follows the same rules compared to manually saving a venue.
        <br />
        invoiceEmails, notificationEmails, and nearestStations can contain
        multiple values. They should be separated by a <em>";"</em> character.
        <br />
        <br />
        The order does not matter, but the names are case sensitive. Input for
        <em>
          invoiceEmails, notificationEmails, nameFurigana, postcode, remarks and
          internalRemarks
        </em>{" "}
        is optional.
        <br />
        <br />
        You cannot use <em>","</em> comma character anywhere.
      </div>
      <ul className={styles.list}>
        <li>name</li>
        <li>email</li>
        <li>invoiceEmails</li>
        <li>notificationEmails</li>
        <li>phoneNumber</li>
        <li>description</li>
        <li>nameFurigana</li>
        <li>postcode</li>
        <li>address</li>
        <li>googleMapsUrl</li>
        <li>googleMapsEmbedUrl</li>
        <li>nearestStations</li>
        <li>acceptedPaymentMethods</li>
        <li>limitedEntryForChildren</li>
        <li>numberOfSeats</li>
        <li>businessHours</li>
        <li>holidays</li>
        <li>smokingAllowance</li>
        <li>dressCode</li>
        <li>babyChair</li>
        <li>kidChair</li>
        <li>wheelChairAccess</li>
        <li>breastFeedingRoom</li>
        <li>parking</li>
        <li>remarks</li>
        <li>internalRemarks</li>
      </ul>
      <form onSubmit={handleOnSubmit}>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <Button type="submit" className={styles.button} disabled={loading}>
          {loading ? "IMPORTING..." : "IMPORT CSV"}
        </Button>
      </form>

      <br />

      <Table>
        <TableHead>
          <TableRow className={styles.header}>
            {headerKeys.map((key) => (
              <TableCell key={key}>{key.toUpperCase()}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {array.map((item, index) => (
            <TableRow key={index}>
              {Object.values(item).map((val, index) => (
                <TableCell key={index}>{val}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

const IMPORT_VENUES_MUTATION = gql`
  mutation ImportVenuesMutation($input: ImportVenuesInput!) {
    importVenues(input: $input) {
      venues {
        id
        name
        description
        email
      }
    }
  }
`;
