import {
  IconButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Theme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "../../../../../components/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

export interface Activity {
  questionsByVenue?: string[];
}

type ActivityFormProps = {
  activity?: Activity;
  onChange: (activity: Activity) => void;
};

export default function ActivityForm(props: ActivityFormProps) {
  const classes = useStyles();

  const { activity, onChange } = props;
  const questionsByVenue = activity?.questionsByVenue || [];

  return (
    <Card>
      <CardHeader
        className={classes.cardTitle}
        title="Questions"
        action={
          <IconButton
            onClick={() =>
              onChange({ questionsByVenue: questionsByVenue.concat("") })
            }
          >
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container>
          {questionsByVenue?.map((q, index) => (
            <Grid
              key={index}
              item
              container
              xs={12}
              // align="center"
              alignItems="center"
            >
              <Grid item lg={10}>
                <TextField
                  label=""
                  value={q}
                  onChange={(e: any) => {
                    const copy = [...questionsByVenue];
                    copy[index] = e.target.value;

                    onChange({ questionsByVenue: copy });
                  }}
                />
              </Grid>
              <Grid item lg={2}>
                <IconButton
                  onClick={() => {
                    onChange({
                      questionsByVenue: questionsByVenue.filter(
                        (_, idx) => idx !== index
                      ),
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
