import {
  Paper,
  Grid,
  GridProps,
  Typography,
  LinearProgress,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  infoText: {
    color: theme.palette.info.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  warningText: {
    color: theme.palette.warning.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

type PageContentProps = GridProps & {
  title?: string;
  error?: string | null;
  warning?: string | null;
  info?: string | null;
  loading?: boolean;
};

function Contents(props: PageContentProps): any {
  const classes = useStyles();

  const { loading, error, warning, children, info } = props;
  const status = loading ? (
    <LinearProgress />
  ) : error ? (
    <Typography variant="h6" className={classes.errorText}>
      {error}
    </Typography>
  ) : warning ? (
    <Typography variant="h6" className={classes.warningText}>
      {warning}
    </Typography>
  ) : info ? (
    <Typography variant="h6" className={classes.infoText}>
      {info}
    </Typography>
  ) : null;

  return (
    <>
      {status}
      {children}
    </>
  );
}

export default function PageContent(props: PageContentProps) {
  const classes = useStyles();
  const { title } = props;

  return (
    <Grid item {...props}>
      <Paper variant="outlined" className={classes.container}>
        {title && <Typography variant="h6">{title}</Typography>}

        <Contents {...props} />
      </Paper>
    </Grid>
  );
}
