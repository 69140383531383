import { Grid, GridProps, Paper, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button, { GenericButtonProps } from "../Button";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
    margin: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));

type PageContentProps = GridProps & {
  title?: string;
  contents?: JSX.Element;
  buttons?: GenericButtonProps[];
};

export default function PageContent(props: PageContentProps) {
  const classes = useStyles();
  const { children, title } = props;

  return (
    <Paper className={classes.content} elevation={0}>
      <Grid container>
        <Grid item lg={8}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item lg={4}>
          {props.buttons &&
            props.buttons.map((b: GenericButtonProps) => (
              <Button {...b}></Button>
            ))}
        </Grid>
        {children}
      </Grid>
    </Paper>
  );
}
