import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import {
  BookingStatusColor,
  PaymentStatusColor,
  OrderStatusColor,
} from "../../helpers";
import Button from "../../../../components/Button2";
import ActionButtons from "./ActionButtons";

export function renderViewBooking(booking: { id: string }) {
  return (
    <Button variant="contained" component={Link} to={`/bookings/${booking.id}`}>
      <VisibilityIcon />
    </Button>
  );
}

export function renderBookingStatus(booking: {
  status: string;
  noShow: boolean;
  paymentStatus: string;
  productOrders?: [{ status: string }];
}) {
  const hasOpenUnshippedOrders = booking.productOrders?.some((po) => {
    return booking.status === "CONFIRMED" && po.status === "CONFIRMED"
      ? true
      : false;
  });

  const isOrdersShipped = booking.productOrders?.every((po) => {
    return booking.status === "CONFIRMED" && po.status === "SHIPPED"
      ? true
      : false;
  });

  const isOrdersCreated = booking.productOrders?.some((po) => {
    return booking.status === "CONFIRMED" && po.status === "CREATED"
      ? true
      : false;
  });

  return (
    <>
      {booking.noShow && (
        <>
          <Chip
            style={{
              backgroundColor: BookingStatusColor["NOSHOW"],
              color: "#ffffff",
              marginBottom: "2px",
            }}
            label="NO SHOW"
          />
          <br />
        </>
      )}
      <Chip
        style={{
          backgroundColor: BookingStatusColor[booking.status],
          color: "#ffffff",
        }}
        label={booking.status}
      />
      <br />
      <Chip
        style={{
          backgroundColor: PaymentStatusColor[booking.paymentStatus],
          color: "#ffffff",
          marginTop: "2px",
        }}
        label={booking.paymentStatus}
      />
      {booking.productOrders &&
        booking.productOrders.length > 0 &&
        (booking.status === "CONFIRMED" ? (
          <>
            <br />
            <Chip
              style={{
                backgroundColor: isOrdersCreated
                  ? "#660066"
                  : !isOrdersCreated &&
                    hasOpenUnshippedOrders &&
                    !isOrdersShipped
                  ? OrderStatusColor["ACTION_REQUIRED"]
                  : OrderStatusColor["NO_ACTION_REQUIRED"],
                color: "#ffffff",
                marginTop: "2px",
              }}
              label={
                isOrdersCreated
                  ? "MISSING_GIFTMALL_ID"
                  : !isOrdersCreated &&
                    hasOpenUnshippedOrders &&
                    !isOrdersShipped
                  ? "MISSING_TRACKING_NO"
                  : "SHIPPED"
              }
            />
          </>
        ) : (
          <>
            <br />
            <Chip
              style={{
                backgroundColor: "#f5429e",
                color: "#ffffff",
                marginTop: "2px",
              }}
              label="INCLUDES ITEM SET"
            />
          </>
        ))}
    </>
  );
}

export function renderCustomer(booking: {
  familyName: string;
  givenName: string;
  familyNameFurigana: string;
  givenNameFurigana: string;
  email: string;
}) {
  return (
    <>
      {booking.familyName} {booking.givenName}
      <br />
      {booking.familyNameFurigana} {booking.givenNameFurigana}
      <br />
      {booking.email}
    </>
  );
}

export function renderPlan(booking: {
  plan: { id: string; name: string };
  reservationDatetime: string;
}) {
  return (
    <>
      {booking.plan.id}
      <br />
      {booking.plan.name}
      <br />
      <b>{booking.reservationDatetime}</b>
    </>
  );
}

export function renderActions(booking: {
  id: string;
  status: string;
  paymentStatus: string;
  email: string;
}) {
  return <ActionButtons booking={booking} />;
}

export function renderDateTime(booking: { reservationDatetime: string }) {
  return <>{booking.reservationDatetime}</>;
}

export function renderVenue(booking: {
  plan: { mainVenue: { name: string } };
}) {
  return <>{booking.plan.mainVenue.name}</>;
}
