import { Card, CardHeader, CardContent, Theme } from "@mui/material";
import VenueSelection from "../../../../../components/VenueSelection";
import TextField from "../../../../../components/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

interface Activity {
  id?: string;
  name?: string;
  courseMenu?: string;
  coursePhotos?: {
    activityId: string;
    id: string;
    src: string;
    photoIndex: number;
  }[];
  detailsForVenue?: string;
  venueId?: string;
}

type ActivityFormProps = {
  activity?: Activity;
  onChange: (activity: Activity) => void;
  planId?: string;
};

export default function ActivityForm(props: ActivityFormProps) {
  const classes = useStyles();

  const { activity, onChange } = props;
  const isNewActivity = activity?.id === undefined;
  const { name, venueId, detailsForVenue } = activity || {};
  return (
    <Card style={{ marginLeft: 8 }}>
      <CardHeader title="Basic Information" className={classes.cardTitle} />
      <CardContent>
        <VenueSelection
          required
          disabled={!isNewActivity}
          onVenueSelected={(venue) => {
            onChange({ venueId: venue?.id });
          }}
          venueId={venueId}
        />

        <TextField
          multiline
          label="Details for venue"
          value={detailsForVenue || ""}
          onChange={(e: any) => {
            onChange({ detailsForVenue: e.target.value });
          }}
        />
      </CardContent>
    </Card>
  );
}
