import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import TextField from "../../../../components/TextField";

function CancellationForm(props: {
  cancelledBy: Agent | unknown;
  setCancelledBy: (agent: Agent | unknown) => void;
  cancellationReason: string;
  setCancellationReason: (reason: string) => void;
  cancellationFee: number | null;
  setCancellationFee: (fee: number | null) => void;
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <InputLabel>Cancelled By</InputLabel>
          <Select
            label="Cancelled By"
            value={props.cancelledBy}
            onChange={(event) => props.setCancelledBy(event.target.value)}
          >
            <MenuItem value={"customer"}>Customer</MenuItem>
            <MenuItem value={"operator"}>Operator</MenuItem>
            <MenuItem value={"venue"}>Venue</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          style={{ marginTop: "0px" }}
          multiline={true}
          label={"Cancellation Reason"}
          value={props.cancellationReason}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.setCancellationReason(event.target.value);
          }}
        />
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[1-9]*" }}
          label={"Cancellation Fee"}
          helperText="Only input a number if there is a cancellation fee"
          value={props.cancellationFee || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.setCancellationFee(Number(event.target.value));
          }}
        />
      </Grid>
    </Grid>
  );
}

export default CancellationForm;

export type Agent = "customer" | "operator" | "venue";
