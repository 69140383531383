import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import PageContent from "../../../components/PageContent";
import { PlanDraft } from "../../../types";
import { STOREFRONT_URL } from "../../../app.config";
import Overview from "./Overview";
import { useNotifications } from "../../../components/Notification";

export default function PlanOverview() {
  const { id } = useParams<{ id: string }>();
  const { showNotification } = useNotifications();

  const { data, loading, error } = useQuery<{ planDraft: PlanDraft }>(
    PLAN_QUERY,
    {
      variables: {
        id,
      },
    }
  );

  const [publishPlan] = useMutation<PublishPlanResponse, PublishPlanInput>(
    PUBLISH_PLAN
  );

  if (loading || error || !data || !data.planDraft) {
    return <PageContent></PageContent>;
  }

  const { planDraft } = data;
  return (
    <>
      <a
        href={`${STOREFRONT_URL}/draft/${planDraft.plan.id}?draftId=${planDraft.id}`}
        target="_blank"
        rel="noreferrer"
      >
        Open in Storefront
      </a>
      <Overview
        publishButton={{
          label: "Publish",
          severity: "danger",
          onClick: () => {
            publishPlan({
              variables: {
                input: { planDraftId: planDraft.id },
              },
            })
              .then(() => {
                showNotification({
                  severity: "success",
                  message: "Plan was published",
                });
              })
              .catch((err) =>
                showNotification({
                  severity: "error",
                  message: err.message,
                })
              );
          },
        }}
        title={`Draft created on ${planDraft.publishedAt}`}
        plan={planDraft.plan}
      />
    </>
  );
}

const PLAN_QUERY = gql`
  query PlanQuery($id: ID!) {
    planDraft(id: $id) {
      id
      publishedAt
      plan {
        id
        name
        subtitle
        timezone
        areaName
        tags {
          name
          tagCategory {
            name
          }
        }
        description
        remarks
        photos {
          imageUrl
        }
        highlights {
          index
          title
          details
          exclusive
          media {
            src
            type
          }
        }
        activities {
          name
          courseMenu
          venue {
            id
            name
            description
            phoneNumber
            email
            numberOfSeats
            businessHours
            holidays
            smokingAllowance
            acceptedPaymentMethods
            dressCode
            limitedEntryForChildren
            babyChair
            kidChair
            wheelChairAccess
            breastFeedingRoom
            parking
            remarks
            photos {
              imageUrl
            }
            location {
              address
              googleMapsUrl
              googleMapsEmbedUrl
              directions
              nearestStations
            }
          }
          contract {
            commissionRate
          }
          priceTypes {
            name
            unitType
            amount
            contractedPrice
            minAttendees
            maxAttendees
            currencyCode
          }
          seatOptions {
            id
            title
            details
            photos {
              src
            }
            maxAttendees
            minAttendees
            price
          }
        }
      }
    }
  }
`;

type PublishPlanInput = {
  input: {
    planDraftId: string;
  };
};

type PublishPlanResponse = {
  publishedAt: string;
};

const PUBLISH_PLAN = gql`
  mutation PublishPlanMutation($input: PublishPlanInput!) {
    publishPlan(input: $input) {
      publishedAt
    }
  }
`;
