import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import HighlightsForm from "./Highlights";

type Highlight = {
  index: number;
  title: string;
  details: string;
  exclusive: boolean;
  media?: {
    src: string;
    type: string;
  };
};

type PlanHighlightsRecord = {
  id: string;
  highlights: Highlight[];
};

export default function Highlights() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<{ plan: PlanHighlightsRecord }>(
    PLAN_HIGHLIGHTS_QUERY,
    {
      variables: { id },
      fetchPolicy: "network-only",
    }
  );

  if (!data || !data.plan || loading || error) {
    return null;
  }
  const { plan } = data;

  return (
    <HighlightsForm
      highlights={plan.highlights.map((h) => ({
        index: h.index,
        title: h.title,
        details: h.details,
        exclusive: h.exclusive,
        media: {
          src: h.media?.src,
          type: h.media?.type,
        },
      }))}
      planId={plan.id}
    />
  );
}

const PLAN_HIGHLIGHTS_QUERY = gql`
  query PlanHighlightsQuery($id: ID!) {
    plan(id: $id) {
      id
      highlights {
        index
        title
        details
        exclusive
        media {
          src
          type
        }
      }
    }
  }
`;
