const SentryError = () => {
  return (
    <>
      <button
        type="button"
        onClick={() => {
          throw new Error("Sentry Backoffice Frontend Error");
        }}
      >
        Throw error
      </button>
    </>
  );
};

export default SentryError;
