import { gql, useQuery } from "@apollo/client";
import TextField from "../TextField";
import { Concierge } from "../../types";
import { MenuItem } from "@mui/material";

type ConciergesSelectionProps = {
  concierges?: Concierge[];
  conciergeId?: string;
  required?: boolean;
  disabled?: boolean;
  onConciergeSelected: (concierge: ConciergesRecord | undefined) => void;
};

type ConciergesRecord = {
  id: string;
  name: string;
};

export default function ConciergesSelection(props: ConciergesSelectionProps) {
  const { data } = useQuery<{
    concierges: { records: ConciergesRecord[] };
  }>(LIST_CONCIERGES_QUERY);

  const foundConcierges =
    data && data.concierges && data.concierges.records
      ? data.concierges.records
      : [];

  if (foundConcierges.length === 0) {
    return (
      <TextField select label="Concierge" value="0">
        <MenuItem value="0">Loading concierges</MenuItem>
      </TextField>
    );
  }

  return (
    <TextField
      select
      required={props.required}
      disabled={props.disabled}
      label="Concierge"
      value={props.conciergeId}
      onChange={(e: any) => {
        props.onConciergeSelected(
          foundConcierges.find((v) => v.id === e.target.value)
        );
      }}
    >
      {foundConcierges.map((concierge) => (
        <MenuItem key={concierge.id} value={concierge.id}>
          {concierge.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

const LIST_CONCIERGES_QUERY = gql`
  query ConciergesQuery {
    concierges {
      records {
        id
        name
      }
    }
  }
`;
