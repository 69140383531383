import { Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

type HeaderProps = {
  status: string;
  fieldName: string;
  date: string;
};
export function Header({
  status,
  fieldName,
  date,
  children,
}: React.PropsWithChildren<HeaderProps>) {
  const title = `${status} · ${fieldName} · ${format(
    new Date(date),
    "yyyy-MM-dd HH:mm"
  )}`;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <Typography style={{ flexGrow: 1 }} variant="h6">
        {title}
      </Typography>
      {children}
    </div>
  );
}
