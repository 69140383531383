import React, { useState, useMemo } from "react";
import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button2";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RoleType } from "../../../types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import useStyles from "./styles";
import { useQuery, gql } from "@apollo/client";
import queryString from "query-string";
import DeleteRoleDialog from "./DeleteRoleDialog";

type Props = {};

const RolesList = (props: Props) => {
  const classes = useStyles();
  const pageSize = 10;
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const [deleteRoleId, setDeleteRoleId] = useState<string | null>(null);

  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );

  const { loading, error, data, refetch, fetchMore } = useQuery<
    RoleQueryResult,
    RoleQueryInput
  >(GET_ROLES, {
    variables: { offset: page * pageSize, limit: pageSize },
    notifyOnNetworkStatusChange: true,
  });

  const rows = useMemo(() => (data ? data.roles.records : []), [data]);
  const totalRows = useMemo(() => (data ? data.roles.totalRecords : 0), [data]);

  const columns = [
    { name: "", render: renderEdit },
    { name: "id", field: "id" },
    { name: "name", field: "name" },
    { name: "Created At", field: "createdAt" },
    { name: "", render: renderDeleteButton },
  ];

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        topBarExtras={renderTopBarExtras()}
        pagination={{
          totalRows: totalRows ?? null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
      <DeleteRoleDialog
        roleId={deleteRoleId}
        refetch={refetch}
        handleClose={() => setDeleteRoleId(null)}
      />
    </>
  );

  function renderDeleteButton(row: RoleType) {
    return (
      <IconButton
        className={classes.deleteButton}
        onClick={() => setDeleteRoleId(row.id)}
      >
        <DeleteIcon />
      </IconButton>
    );
  }
};

export default RolesList;

function renderTopBarExtras() {
  return (
    <Button
      style={{ backgroundColor: "white", color: "#c8a063" }}
      variant="contained"
      component={Link}
      to="/roles/new"
      startIcon={<AddIcon />}
    >
      New
    </Button>
  );
}

function renderEdit(row: RoleType) {
  return (
    <Button size="small" component={Link} to={`/roles/${row.id}`}>
      <EditIcon />
    </Button>
  );
}

type RoleQueryResult = {
  roles: {
    totalRecords: number;
    records: RoleType[];
  };
};

type RoleQueryInput = {
  offset: number;
  limit: number;
};

const GET_ROLES = gql`
  query RolesListQuery($offset: Int!, $limit: Int!) {
    roles(offset: $offset, limit: $limit) {
      records {
        id
        name
        createdAt
      }
      totalRecords
    }
  }
`;
