import { endOfMonth } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import DatePicker from "react-datepicker";

export default function MonthYearRangePicker({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: MonthYearRangePickerProps) {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <p>from</p>
      <DatePicker
        selected={startDate}
        dateFormat="dd/MM/yyyy"
        onChange={(date: Date) => setStartDate(zonedTimeToUtc(date, "UTC"))}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        showMonthYearPicker
      />
      <p>until</p>
      <DatePicker
        selected={endDate}
        dateFormat="dd/MM/yyyy"
        onChange={(date: Date) =>
          setEndDate(endOfMonth(zonedTimeToUtc(date, "UTC")))
        }
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showMonthYearPicker
      />
    </div>
  );
}

export type MonthYearRangePickerProps = {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
};
