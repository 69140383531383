import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import { Location } from "../../../../types";
import EditLocationForm from "./form";

export default function BasicInfo() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery(GET_LOCATION_QUERY, {
    variables: { id: id },
  });
  if (loading) return <CircularProgress />;
  if (error) return <>{error.message}</>;

  const location: Location = data ? data.location : null;

  return <EditLocationForm location={location} />;
}

const GET_LOCATION_QUERY = gql`
  query GetLocationQuery($id: ID!) {
    location(id: $id) {
      id
      createdAt
      type
      nameEn
      nameJa
      slug
      pageTitle
      pageDescription
      thumbnailImageUrl
      imageUrl
      videoUrl
      isHomeContent
      homePriority

      parent {
        id
        nameEn
        nameJa
      }
    }
  }
`;
