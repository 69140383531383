import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Button, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "../../../../components/TextField";
import PageSection from "../../../../components/PageSection";
import { useNotifications } from "../../../../components/Notification";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";
import { isVideo } from "../../../../helpers/check-type-from-url";
import Checkbox from "../../../../components/Checkbox";

type HighlightsFormProps = {
  highlights: Highlight[];
  planId: string;
};

type Highlight = {
  index?: number;
  title?: string;
  details?: string;
  exclusive?: boolean;
  media: {
    src?: string;
    type?: string;
  };
};

export default function HighlightsForm(props: HighlightsFormProps) {
  const { showNotification } = useNotifications();

  const defaultVal = props.highlights.map((highlight) => {
    return {
      ...highlight,
      exclusive: highlight.exclusive ? highlight.exclusive : false,
    };
  });

  const [highlights, setHighlights] = useState<Highlight[]>(defaultVal);

  const [savePlanHighlights] = useMutation(SAVE_PLAN_HIGHLIGHTS_MUTATION);

  function setHighlightField<T>(
    fieldName: keyof Highlight,
    index: number,
    value: T
  ) {
    setHighlights((highlights) => {
      const changeable = [...highlights];
      const tobechanged = highlights[index];
      let newValue: any = {
        ...tobechanged,
        [fieldName]: value,
      };

      changeable[index] = newValue;
      return changeable;
    });
  }

  return (
    <Grid container>
      {highlights.map((h, index) => (
        <PageSection key={index} lg={12}>
          <IconButton
            onClick={() => {
              setHighlights((highlights) =>
                highlights.filter((_, idx) => idx !== index)
              );
            }}
          >
            <DeleteIcon />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item lg={8}>
              <TextField
                required
                id={`${index}_index`}
                label="Index"
                value={h.index}
                onChange={(e: any) => {
                  const num = Number(e.target.value) || 0;

                  setHighlightField("index", index, num);
                }}
              />
              <TextField
                required
                id={`${index}_title`}
                label="Title"
                value={h.title}
                onChange={(e: any) => {
                  setHighlightField("title", index, e.target.value);
                }}
              />
              <TextField
                required
                multiline
                label="Details"
                value={h.details}
                onChange={(e: any) => {
                  setHighlightField("details", index, e.target.value);
                }}
              />
              <Checkbox
                label="exclusive"
                checked={h.exclusive ? h.exclusive : false}
                onChange={(checked: boolean) =>
                  setHighlightField("exclusive", index, checked)
                }
              />
            </Grid>
            <Grid
              item
              lg={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                height: "auto",
              }}
            >
              <MediaUploader
                media={h.media.src ? h.media.src : null}
                allowedTypes={[MediaType.Image, MediaType.Video]}
                width={"350px"}
                uploadPath={`plans/${props.planId}/highlights`}
                onChangeFunction={(url: string | null) => {
                  setHighlightField("media", index, {
                    src: url,
                    type: url ? (isVideo(url) ? "video" : "photo") : "photo",
                  });
                }}
              />
            </Grid>
          </Grid>
        </PageSection>
      ))}

      <PageSection lg={12}>
        <Button
          variant="outlined"
          onClick={() => {
            savePlanHighlights({
              variables: {
                input: {
                  id: props.planId,
                  highlights,
                },
              },
            })
              .then(() => {
                showNotification({
                  message: `Highlights saved successfully`,
                  severity: "success",
                });
              })
              .catch((err: any) => {
                let { graphQLErrors, networkError } = err;
                if (graphQLErrors.length > 0) {
                  showNotification({
                    message: err.message,
                    severity: "error",
                  });
                } else {
                  showNotification({
                    message: `Please check all required fields, including a media file, ${
                      networkError.result.errors[0].extensions.code
                    } - ${networkError.result.errors[0].message.substring(
                      0,
                      501
                    )}`,
                    severity: "error",
                  });
                }
              });
          }}
        >
          Save
        </Button>
        <IconButton
          onClick={() => {
            setHighlights((highlights) =>
              highlights.concat({ media: {}, exclusive: false })
            );
          }}
        >
          <AddIcon />
        </IconButton>
      </PageSection>
    </Grid>
  );
}
const SAVE_PLAN_HIGHLIGHTS_MUTATION = gql`
  mutation SavePlanHighlightsMutation($input: SavePlanHighlightsInput!) {
    savePlanHighlights(input: $input) {
      plan {
        id
      }
    }
  }
`;
