import {
  Chip,
  Divider,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import FilterList from "@mui/icons-material/FilterList";
import { useCallback, useMemo, useState } from "react";
import { BookingStatus, PaymentStatus, ItemStatus } from "../../../../types";

export const noShowTag = "NO_SHOW";
export type NoShowTag = typeof noShowTag;

const noShowTags = [noShowTag];
const bookingStatusTags = Object.keys(BookingStatus);
const paymentStatusTags = Object.keys(PaymentStatus);
const itemStatusTags = Object.keys(ItemStatus);

export default function TagSelect({
  noShow,
  setNoShow,
  bookingStatus,
  setBookingStatus,
  paymentStatus,
  setPaymentStatus,
  itemStatus,
  setItemStatus,
  onChangeCallback,
}: TagSelectProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  type TagMenuItem<T> = {
    tag: T;
    setFn: (x: T) => void;
  };

  const selectedTags = useMemo(
    () =>
      [
        [noShow, setNoShow],
        [bookingStatus, setBookingStatus],
        [paymentStatus, setPaymentStatus],
        [itemStatus, setItemStatus],
      ]
        .map(([tag, setFn]) => (tag ? { tag, setFn } : null))
        .filter(Boolean) as TagMenuItem<string | null>[],
    [
      noShow,
      setNoShow,
      bookingStatus,
      setBookingStatus,
      paymentStatus,
      setPaymentStatus,
      itemStatus,
      setItemStatus,
    ]
  );

  const getMenuItems = useCallback(
    (tags: string[], setFn: (x: any) => void) =>
      tags.map((tag) => (
        <MenuItem
          key={tag}
          value={tag}
          onClick={() => {
            setFn((prevOption: any) => (prevOption === tag ? null : tag));
            onChangeCallback();
          }}
          selected={selectedTags.map((x) => x.tag).includes(tag)}
        >
          {tag}
        </MenuItem>
      )),
    [onChangeCallback, selectedTags]
  );

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        {selectedTags.map((selected) => (
          <Chip
            key={selected.tag}
            onDelete={() => {
              selected.setFn(null);
              onChangeCallback();
            }}
            label={selected.tag}
          />
        ))}
        <Tooltip title="Status filter" placement="top">
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              setAnchorEl(event.currentTarget)
            }
          >
            <FilterList />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ListSubheader>Attendance Status</ListSubheader>
        {getMenuItems(noShowTags, setNoShow)}
        <Divider />
        <ListSubheader>Booking Status</ListSubheader>
        {getMenuItems(bookingStatusTags, setBookingStatus)}
        <Divider />
        <ListSubheader>Payment Status</ListSubheader>
        {getMenuItems(paymentStatusTags, setPaymentStatus)}
        {setItemStatus !== undefined && (
          <span>
            <Divider />
            <ListSubheader>Item Status</ListSubheader>
            {getMenuItems(itemStatusTags, setItemStatus)}
          </span>
        )}
      </Menu>
    </div>
  );
}

export type TagSelectProps = {
  noShow: NoShowTag | null;
  setNoShow: (noShow: NoShowTag | null) => void;
  bookingStatus: BookingStatus | null;
  setBookingStatus: (bookingStatus: BookingStatus | null) => void;
  paymentStatus: PaymentStatus | null;
  setPaymentStatus: (paymentStatus: PaymentStatus | null) => void;
  itemStatus?: ItemStatus | null;
  setItemStatus?: (itemStatus: ItemStatus | null) => void;
  onChangeCallback: (...args: any[]) => void;
};
