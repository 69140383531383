import {
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "../../../../components/TextField";
import AutocompleteDialog from "../../../../components/AutocompleteDialog";
import { PlanTemplate } from "../../../../types";
import { useState } from "react";

export type TemplateGridItemProps = {
  planTemplate: PlanTemplate;
  setHowToGive: (howToGive: string) => void;
  setWhenToGive: (whenToGive: string) => void;
  howToOptions: string[];
  whenToOptions: string[];
  addHowToOption: (newOption: string) => void;
  addWhenToOption: (newOption: string) => void;
  handleDelete: () => void;
};

export default function TemplateItemForm({
  planTemplate,
  setHowToGive,
  setWhenToGive,
  howToOptions,
  whenToOptions,
  addHowToOption,
  addWhenToOption,
  handleDelete,
}: TemplateGridItemProps) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  return (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item lg={6} key={planTemplate.template.id + 1}>
        <TextField
          label="Display name"
          value={
            planTemplate.template.overrideDisplayName ??
            planTemplate.template.displayName
          }
          size="small"
          readOnly
          key={planTemplate.id + "a"}
        />
      </Grid>

      <Grid item lg={6} key={planTemplate.id + 2}>
        <TextField
          multiline={true}
          label="Description"
          value={planTemplate.template.description || ""}
          size="small"
          readOnly
          key={planTemplate.id + "b"}
        />
      </Grid>

      <Grid item lg={6} key={planTemplate.id + 3}>
        <AutocompleteDialog
          value={planTemplate.whenToGive}
          setValue={setWhenToGive}
          options={whenToOptions}
          onAdd={addWhenToOption}
          label="When to give the item"
        />
      </Grid>

      <Grid item lg={6} key={planTemplate.id + 4}>
        <AutocompleteDialog
          value={planTemplate.howToGive}
          setValue={setHowToGive}
          options={howToOptions}
          onAdd={addHowToOption}
          label="How to give the item"
        />
      </Grid>

      <Grid item lg={4} key={planTemplate.id + 5}>
        <TextField
          label="Shop URL"
          value={planTemplate.template.giftmallUrl}
          size="small"
          readOnly
          key={planTemplate.id + "e"}
          onClick={(_e: any) => {
            if (!planTemplate.template.giftmallUrl) return;
            window
              .open(planTemplate.template.giftmallUrl, "_blank", "noopener")
              ?.focus();
          }}
        />
      </Grid>

      <DeletePlanItemDialog
        open={openDialog}
        setOpen={setOpenDialog}
        planTemplate={planTemplate}
        key={planTemplate.id + "f"}
        handleDelete={handleDelete}
      />
      <Grid item lg={2} key={planTemplate.id + 6}>
        <IconButton
          key={planTemplate.id + "g"}
          onClick={() => setOpenDialog(true)}
        >
          <DeleteIcon key={planTemplate.id + "h"} />
        </IconButton>
        <IconButton
          key={planTemplate.id + "i"}
          onClick={() => {
            window
              .open(
                `/items/edit/${planTemplate.templateId}`,
                "_blank",
                "noopener"
              )
              ?.focus();
          }}
        >
          <EditIcon key={planTemplate.id + "j"} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

function DeletePlanItemDialog(props: {
  planTemplate: PlanTemplate | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleDelete: any;
}) {
  return props.open ? (
    <Dialog open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle>Delete Plan Item</DialogTitle>
      <DialogContent>
        This action will delete the plan item:{" "}
        {props.planTemplate!.template.overrideDisplayName ??
          props.planTemplate!.template.displayName}
        <br />
        <br />
        All attached item cards will be deleted as well.
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            props.handleDelete();
            props.setOpen(false);
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
}
