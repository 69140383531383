import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { format } from "date-fns";
import NewRecurrenceRule from "./NewRecurrenceRule";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  TextField,
  Divider,
  Grid,
  Card,
  CardContent,
  CardHeader,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Notification, { NotificationData } from "../Notification";
import getDates from "../../helpers/get-dates-from-date-range";
import MultiTimeSelector from "./MultiTimeSelector";
import {
  BookablePeriod,
  CutoffTime,
  LockableMoment,
  RecurrenceRule,
  Schedule,
} from "../../types";
import { ClosedDatesList, OpenDatesList } from "./DateList";
import RecurrenceRuleRow from "./RecurrenceRuleRow";

const toRecurrenceRuleInput = (r: RecurrenceRule): RecurrenceRuleInput => {
  const { weekdays, holidays, hours, minutes } = r;
  return { weekdays, holidays, hours, minutes };
};

const withoutEmpty = function <T extends BookablePeriod & CutoffTime>(v: T): T {
  const { hours, months } = v;

  return {
    ...(hours && { hours }),
    ...(months && { months }),
  } as T;
};

type ScheduleProps = {
  resource: {
    id: string;
    schedule?: Schedule;
  };
};

function filterDates(dates: string[], from: string, to: string) {
  const fromDate = new Date(from).setHours(0, 0, 0, 0);
  const toDate = new Date(to).setHours(23, 59, 59, 999);
  return dates.filter((dateStr) => {
    const date = new Date(dateStr).getTime();
    return date <= fromDate || date >= toDate;
  });
}

function removeDatesInRange(
  datesArray: string[],
  fromDate: string,
  toDate: string
) {
  const from = new Date(fromDate);
  const to = new Date(toDate);

  return datesArray.filter((date) => {
    const currentDate = new Date(date);
    return currentDate < from || currentDate > to;
  });
}

function ViewSchedule(props: ScheduleProps) {
  const { id, schedule } = props.resource;

  const [recurrenceRules, setRecurrenceRules] = useState<RecurrenceRule[]>(
    schedule && schedule.recurrenceRules ? schedule.recurrenceRules : []
  );
  const [saveSchedule] = useMutation<SaveScheduleResponse, SaveScheduleInput>(
    SAVE_SCHEDULE
  );

  const [bookablePeriod, setBookablePeriod] = useState<BookablePeriod>(
    schedule?.bookablePeriod || { months: 6 }
  );
  const [cutoffTime, setCutoffTime] = useState<CutoffTime>(
    schedule?.cutoffTime || { hours: 48 }
  );
  const [closedDates, setClosedDates] = useState<LockableMoment[]>(
    schedule?.closedDates || []
  );
  const [closedHolidays, setClosedHolidays] = useState<string[]>(
    schedule?.closedHolidays || []
  );
  const [closedTimeSlots, setClosedTimeSlots] = useState<string[]>(
    schedule?.closedTimeSlots || []
  );
  const [openDates, setOpenDates] = useState<string[]>(
    schedule?.openDates || []
  );
  const [openTimeSlots, setOpenTimeSlots] = useState<string[]>(
    schedule?.openTimeSlots || []
  );
  const [closedDate, setClosedDate] = useState<string>("");
  const [closedDateRangeFrom, setClosedDateRangeFrom] = useState<string>("");
  const [closedDateRangeUntil, setClosedDateRangeUntil] = useState<string>("");
  const [closedTime, setClosedTime] = useState<string>("");
  const [toggleClosedHolidays, setToggleClosedHolidays] = useState<string[]>(
    []
  );
  const [openDate, setOpenDate] = useState<string>("");
  const [openTimes, setOpenTimes] = useState<string[]>([""]);
  const [timesMissing, setTimesMissing] = useState<boolean>(true);
  const [triggerReset, setTriggerReset] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationData | "">("");

  const enoughData =
    bookablePeriod.months && cutoffTime.hours && recurrenceRules.length > 0;

  return (
    <>
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification("")}
        />
      )}
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Card>
                <CardHeader title="New recurrence rule" />
                <CardContent>
                  <NewRecurrenceRule
                    onAdded={(newRecurrenceRule: RecurrenceRule) => {
                      setRecurrenceRules((rrules: RecurrenceRule[]) =>
                        rrules.concat(newRecurrenceRule)
                      );
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Card>
                <CardHeader title="Close dates" />
                <CardContent>
                  <TextField
                    size="small"
                    variant="outlined"
                    style={{ marginRight: 6 }}
                    type="date"
                    value={closedDate}
                    defaultValue={closedDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setClosedDate(event.target.value);
                    }}
                  />
                  <TextField
                    size="small"
                    variant="outlined"
                    type="time"
                    value={closedTime}
                    defaultValue={closedTime}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setClosedTime(event.target.value);
                    }}
                    inputProps={{
                      step: 900, // 15 min
                    }}
                  />
                </CardContent>
                <CardContent>
                  <TextField
                    label="Closed from"
                    size="small"
                    variant="outlined"
                    style={{ marginRight: 6 }}
                    type="date"
                    error={
                      Boolean(closedDateRangeFrom === "") &&
                      Boolean(closedDateRangeUntil !== "")
                    }
                    value={closedDateRangeFrom}
                    defaultValue={closedDateRangeFrom}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setClosedDateRangeFrom(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Closed until"
                    size="small"
                    variant="outlined"
                    type="date"
                    error={
                      Boolean(closedDateRangeUntil === "") &&
                      Boolean(closedDateRangeFrom !== "")
                    }
                    value={closedDateRangeUntil}
                    defaultValue={closedDateRangeUntil}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setClosedDateRangeUntil(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </CardContent>
                <CardContent>
                  <ButtonGroup color="primary">
                    {["祝日", "祝前日"].map((holiday, i) => (
                      <Button
                        key={i}
                        variant={
                          toggleClosedHolidays.includes(holiday)
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setToggleClosedHolidays((holidays) =>
                            holidays.includes(holiday)
                              ? holidays.filter((x) => x !== holiday)
                              : [...holidays, holiday]
                          );
                        }}
                      >
                        {holiday}
                      </Button>
                    ))}
                  </ButtonGroup>
                </CardContent>
                <CardContent>
                  <Button
                    disabled={
                      !(
                        Boolean(closedDate) ||
                        (Boolean(closedDateRangeFrom) &&
                          Boolean(closedDateRangeUntil)) ||
                        toggleClosedHolidays.length > 0
                      )
                    }
                    onClick={() => {
                      if (toggleClosedHolidays.length > 0) {
                        setClosedHolidays((closedHolidays) => [
                          ...new Set([
                            ...closedHolidays,
                            ...toggleClosedHolidays,
                          ]),
                        ]);
                        setToggleClosedHolidays([]);
                      }
                      if (closedDateRangeFrom && closedDateRangeUntil) {
                        if (
                          new Date(closedDateRangeFrom) >=
                          new Date(closedDateRangeUntil)
                        ) {
                          setNotification({
                            severity: "error",
                            message:
                              "'Closed from' must be set to a date before 'Closed until'.",
                          });
                          return;
                        }
                        if (new Date(closedDateRangeFrom) < new Date()) {
                          setNotification({
                            severity: "error",
                            message:
                              "'Closed from' must be set to a date later than today.",
                          });
                          return;
                        }
                        const datesToClose: Date[] = getDates(
                          new Date(closedDateRangeFrom),
                          new Date(closedDateRangeUntil)
                        );

                        const stringDatesToClose = datesToClose.map(
                          (element: Date) => {
                            return format(element, "yyyy-MM-dd");
                          }
                        );

                        const newClosedDates = [
                          ...new Set([
                            ...closedDates,
                            ...stringDatesToClose.map((moment) => ({
                              moment,
                              locked,
                            })),
                          ]),
                        ];
                        setClosedDates(newClosedDates);
                        setOpenTimeSlots(
                          filterDates(
                            openTimeSlots,
                            closedDateRangeFrom,
                            closedDateRangeUntil
                          )
                        );
                        setOpenDates(
                          removeDatesInRange(openDates, closedDate, closedDate)
                        );
                        setClosedDateRangeFrom("");
                        setClosedDateRangeUntil("");
                      }
                      if (closedDate) {
                        if (
                          openDates.includes(closedDate) ||
                          openTimeSlots.includes(
                            `${closedDate}T${closedTime}:00`
                          )
                        ) {
                          setNotification({
                            severity: "error",
                            message:
                              "This date or time is already set as open date. Please remove it from the open list first.",
                          });
                          return;
                        }
                        if (closedTime) {
                          setClosedTimeSlots((closedTimeSlots) =>
                            closedTimeSlots
                              .concat(`${closedDate}T${closedTime}:00`)
                              .filter(
                                (date, index, self) =>
                                  self.findIndex((date2) => date === date2) ===
                                  index
                              )
                          );
                        } else {
                          setClosedDates((closedDates) =>
                            closedDates
                              .concat({ moment: closedDate, locked })
                              .filter(
                                (date, index, self) =>
                                  self.findIndex((date2) => date === date2) ===
                                  index
                              )
                          );
                        }
                        setOpenTimeSlots(
                          filterDates(openTimeSlots, closedDate, closedDate)
                        );
                        setOpenDates(
                          removeDatesInRange(openDates, closedDate, closedDate)
                        );
                        setClosedDate("");
                        setClosedTime("");
                      }
                    }}
                  >
                    Add
                  </Button>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={locked}
                        onChange={({ target: { checked } }) =>
                          setLocked(checked)
                        }
                      />
                    }
                    label="Lock"
                    style={{ float: "right" }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Card>
                <CardHeader title="Special open dates" />
                <CardContent>
                  <TextField
                    size="small"
                    variant="outlined"
                    style={{ marginBottom: 12 }}
                    type="date"
                    value={openDate}
                    defaultValue={openDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setOpenDate(event.target.value);
                    }}
                  />
                  <MultiTimeSelector
                    triggerReset={triggerReset}
                    setTriggerReset={setTriggerReset}
                    setTimesMissing={setTimesMissing}
                    setOpenTimes={setOpenTimes}
                  />
                  <Button
                    disabled={!Boolean(openDate) && timesMissing}
                    onClick={() => {
                      // allowing to open dates (without time) caused unpredictable open times for that date
                      // openTimes must not contain falsy values (like "")
                      if (
                        openDate &&
                        openDate.length > 1 &&
                        openTimes &&
                        !timesMissing
                      ) {
                        if (
                          closedDates
                            .map(({ moment }) => moment)
                            .includes(openDate) ||
                          openTimes.some((timeSlot) =>
                            closedTimeSlots.includes(
                              `${openDate}T${timeSlot}:00`
                            )
                          )
                        ) {
                          setNotification({
                            severity: "error",
                            message:
                              "This date or time is already set as closed date. Please remove it from the closed list first.",
                          });
                          return;
                        }
                        setOpenTimeSlots((ots) => {
                          return openTimes
                            .flatMap((openTime) => {
                              return ots.concat(`${openDate}T${openTime}:00`);
                            })
                            .filter(
                              (date, index, self) =>
                                self.findIndex((date2) => date === date2) ===
                                index
                            );
                        });
                        setOpenDate("");
                        setOpenTimes([""]);
                        setTriggerReset(true);
                      } else {
                        setNotification({
                          severity: "error",
                          message: "Please select a date AND time(s) to open",
                        });
                      }
                    }}
                  >
                    Add
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6}>
          <Card>
            <CardHeader title="Current" />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Weekdays</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Minutes</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recurrenceRules.flatMap((rule) => {
                    return [
                      (rule.holidays ?? []).length > 0 && (
                        <RecurrenceRuleRow
                          dates={rule.holidays!}
                          hours={rule.hours}
                          minutes={rule.minutes}
                          setRecurrenceRules={setRecurrenceRules}
                        />
                      ),
                      rule.weekdays.length > 0 && (
                        <RecurrenceRuleRow
                          dates={rule.weekdays}
                          hours={rule.hours}
                          minutes={rule.minutes}
                          setRecurrenceRules={setRecurrenceRules}
                        />
                      ),
                    ];
                  })}
                  {/* {recurrenceRules.map((rrule: RecurrenceRule, idx: number) => (
                    <TableRow key={idx}>
                      {rrule.holidays && rrule.holidays?.length > 0 ? (
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            {closedHolidays.some((holiday) =>
                              rrule.holidays?.includes(holiday)
                            ) && (
                              <Tooltip title="Closed holiday overrides recurrence rule">
                                <WarningIcon />
                              </Tooltip>
                            )}
                            {rrule.holidays.join(", ")}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell>{rrule.weekdays.join(", ")}</TableCell>
                      )}
                      <TableCell>
                        {rrule.hours.map(prependZero).join(", ")}
                      </TableCell>
                      <TableCell>
                        {rrule.minutes.map(prependZero).join(", ")}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            setRecurrenceRules(
                              (recurrenceRules: RecurrenceRule[]) =>
                                recurrenceRules.filter(
                                  (_, idx2) => idx !== idx2
                                )
                            );
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>

              <Grid
                container
                alignItems="center"
                style={{ marginTop: 12, marginBottom: 12 }}
              >
                <Grid item lg={4}>
                  <Typography variant="subtitle1">
                    Cutoff time in hours:
                  </Typography>
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    size="small"
                    variant="outlined"
                    style={{ marginRight: 6, width: 50 }}
                    inputProps={{ inputMode: "numeric" }}
                    defaultValue={cutoffTime.hours}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const strHours = event.target.value;

                      const hours = Number(strHours);

                      setCutoffTime({ hours });
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <Typography variant="subtitle1">
                    Bookable period in months:
                  </Typography>
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    size="small"
                    variant="outlined"
                    style={{ marginRight: 6, width: 50 }}
                    inputProps={{ inputMode: "numeric" }}
                    defaultValue={bookablePeriod.months}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const strMonths = event.target.value;

                      const months = Number(strMonths);

                      setBookablePeriod({ months });
                    }}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                alignItems="flex-start"
                style={{ marginTop: 12, marginBottom: 12 }}
              >
                <Grid item lg={6}>
                  <Typography variant="subtitle1">Closed dates:</Typography>
                  <ClosedDatesList
                    closedHolidays={closedHolidays}
                    setClosedHolidays={setClosedHolidays}
                    closedDates={closedDates}
                    setClosedDates={setClosedDates}
                    closedTimeSlots={closedTimeSlots}
                    setClosedTimeSlots={setClosedTimeSlots}
                  />
                </Grid>
                <Grid item lg={6}>
                  <Typography variant="subtitle1">
                    Special open dates:
                  </Typography>
                  <OpenDatesList
                    openDates={openDates}
                    setOpenDates={setOpenDates}
                    openTimeSlots={openTimeSlots}
                    setOpenTimeSlots={setOpenTimeSlots}
                  />
                </Grid>
              </Grid>
              <Button
                disabled={!enoughData}
                onClick={() => {
                  saveSchedule({
                    variables: {
                      input: {
                        resourceId: id,
                        schedule: {
                          recurrenceRules: recurrenceRules.map(
                            toRecurrenceRuleInput
                          ),
                          cutoffTime: withoutEmpty(cutoffTime),
                          bookablePeriod: withoutEmpty(bookablePeriod),
                          closedTimeSlots,
                          closedDates,
                          closedHolidays,
                          openTimeSlots,
                          openDates,
                        },
                      },
                    },
                  })
                    .then(() => {
                      setNotification({
                        severity: "success",
                        message: "Schedule was saved",
                      });
                    })
                    .catch((err) =>
                      setNotification({
                        severity: "error",
                        message: err.message,
                      })
                    );
                }}
              >
                Save
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

type RecurrenceRuleInput = {
  weekdays: string[];
  holidays?: string[];
  hours: number[];
  minutes: number[];
};

type ScheduleInput = {
  recurrenceRules: RecurrenceRuleInput[];
  cutoffTime: CutoffTime;
  bookablePeriod: BookablePeriod;
  closedTimeSlots: string[];
  closedHolidays: string[];
  closedDates: LockableMoment[];
  openTimeSlots: string[];
  openDates: string[];
};

type SaveScheduleInput = {
  input: {
    resourceId: string;
    schedule: ScheduleInput;
  };
};

type SaveScheduleResponse = {
  schedule: Schedule;
  savedAt: string;
};

const SAVE_SCHEDULE = gql`
  mutation SaveScheduleMutation($input: SaveScheduleInput!) {
    saveSchedule(input: $input) {
      savedAt
      schedule {
        recurrenceRules {
          weekdays
          hours
          minutes
        }
      }
    }
  }
`;

export default ViewSchedule;
