export const BookingStatusColor: BookingStatusColorType = {
  CREATED: "#000000",
  REQUESTED: "#2196f3",
  CONFIRMED: "#4caf50",
  REJECTED: "#f44336",
  CANCELLED: "#9c27b0",
  NOSHOW: "#ff9a9a",
};

export const PaymentStatusColor: PaymentStatusColorType = {
  NOT_YET_PAID: "#000000",
  PAID: "#4caf50",
  REFUNDED: "#9c27b0",
};

export const OrderStatusColor: OrderStatusColorType = {
  ACTION_REQUIRED: "#FFA500",
  NO_ACTION_REQUIRED: "#D3D3D3",
};

interface BookingStatusColorType {
  [key: string]: string | undefined;
}

interface PaymentStatusColorType {
  [key: string]: string | undefined;
}

interface OrderStatusColorType {
  [key: string]: string | undefined;
}
