import { v4 as uuid } from "uuid";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Paper,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import TextField from "../../../../../components/TextField";
import ImageGalleryUploader from "../../../../../components/ImageGalleryUploader";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

export type SeatOption = {
  id?: string;
  title?: string;
  details?: string;
  photos: { src: string }[];
  minAttendees?: number;
  maxAttendees?: number;
  active?: boolean;
  price?: number;
};

type Activity = {
  seatOptions?: SeatOption[];
};

type SeatOptionsFormProps = {
  planId: string;
  activity?: Activity;
  onChange: (activity: Activity) => void;
};

type Fn<T> = (value: T) => T;

function isFunction<T>(value: T | Fn<T>): value is Fn<T> {
  return typeof value === "function";
}

export default function SeatOptionsForm(props: SeatOptionsFormProps) {
  const { activity, onChange } = props;
  const seatOptions = activity?.seatOptions || [];

  function setSeatOptionsField<T>(
    fieldName: keyof SeatOption,
    index: number,
    value: T | Fn<T>
  ) {
    const changeable = [...seatOptions];
    const tobechanged = seatOptions[index];
    if (!tobechanged) return;
    const currentValue = (tobechanged[fieldName] as unknown) as T;
    const newValue = isFunction(value) ? value(currentValue) : value;

    const seatOption = {
      ...tobechanged,
      [fieldName]: newValue,
    };

    changeable[index] = seatOption;
    onChange({ seatOptions: changeable });
  }

  const removeSeat = (s: SeatOption) => {
    let seatList = seatOptions.filter((a) => a.id !== s.id);

    onChange({ seatOptions: seatList });
  };

  return (
    <Card>
      <CardHeader
        title="Seat options"
        action={
          <IconButton
            onClick={() => {
              onChange({
                seatOptions: seatOptions.concat({
                  id: uuid(),
                  active: false,
                  price: 0,
                  photos: [],
                }),
              });
            }}
          >
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        {seatOptions.map((s, index) => (
          <Grid key={index} container spacing={2}>
            <Grid item lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={s.active ?? false}
                    onChange={() =>
                      setSeatOptionsField("active", index, !s.active)
                    }
                  />
                }
                label={"Active Seat Option?"}
              />
              <TextField
                required
                multiline
                label="Title"
                value={s.title}
                onChange={(e: any) => {
                  setSeatOptionsField("title", index, e.target.value);
                }}
              />
              <TextField
                required
                multiline
                label="Details"
                value={s.details}
                onChange={(e: any) => {
                  setSeatOptionsField("details", index, e.target.value);
                }}
              />
              <TextField
                required
                multiline
                label="Min. attendees"
                value={s.minAttendees}
                onChange={(e: any) => {
                  setSeatOptionsField(
                    "minAttendees",
                    index,
                    Number(e.target.value) || undefined
                  );
                }}
              />
              <TextField
                required
                multiline
                label="Max. attendees"
                value={s.maxAttendees}
                onChange={(e: any) => {
                  setSeatOptionsField(
                    "maxAttendees",
                    index,
                    Number(e.target.value) || undefined
                  );
                }}
              />
              <TextField
                id={`${s.id}_price`}
                label="Price (optional additional fee)"
                value={s.price || 0}
                onChange={(e: any) => {
                  setSeatOptionsField("price", index, Number(e.target.value));
                }}
                startAdornment={"¥"}
              />
            </Grid>

            <Grid
              item
              lg={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                height: "auto",
              }}
            >
              <Paper
                elevation={0}
                style={{
                  width: "auto",
                  height: "auto",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <ImageGalleryUploader
                  key={s.id}
                  images={s.photos.map((p) => ({ id: "", imageUrl: p.src }))}
                  uploadPath={`plans/${props.planId}/seat-options/${s.id}`}
                  allowDnd={false}
                  gridSizes={{ xs: 12, md: 4 }}
                  onChangeFunction={(photos) => {
                    setSeatOptionsField(
                      "photos",
                      index,
                      photos.map((p) => ({ src: p.imageUrl }))
                    );
                  }}
                />
              </Paper>
            </Grid>
            <Button
              style={{
                marginLeft: 10,
              }}
              variant="contained"
              color="secondary"
              onClick={() => removeSeat(s)}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
            <Grid item xs={12}>
              <Divider
                style={{ width: "auto", marginTop: 12, marginBottom: 12 }}
              />
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
}
