import { Card, CardHeader, CardContent, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextField from "../../../../../components/TextField";

const useStyles = makeStyles((theme: Theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

export interface Activity {
  offset?: {
    minutes?: number;
    hours?: number;
  };
}

type ActivityFormProps = {
  activity?: Activity;
  onChange: (activity: Activity) => void;
};

export default function ActivityForm(props: ActivityFormProps) {
  const classes = useStyles();
  const { offset } = props.activity || {};
  const { minutes, hours } = offset || { minutes: 0, hours: 0 };

  return (
    <Card>
      <CardHeader title="Offset" className={classes.cardTitle} />
      <CardContent>
        <TextField
          label="Offset in minutes"
          value={minutes || 0}
          onChange={(e: any) => {
            props.onChange({
              offset: { ...offset, minutes: Number(e.target.value) },
            });
          }}
        />
        <TextField
          label="Offset in hours"
          value={hours || 0}
          onChange={(e: any) => {
            props.onChange({
              offset: { ...offset, hours: Number(e.target.value) },
            });
          }}
        />
      </CardContent>
    </Card>
  );
}
