import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import DataTabs from "../../../../components/DataTabs";
import Basic from "./Basic";

function ViewStamp() {
  const { id: stampId } = useParams<{
    id: string;
  }>();
  const { loading, error, data } = useQuery(GET_STAMP, {
    variables: { id: stampId },
  });

  if (loading) return <>loading...</>;
  if (error) return <>Error! {error.message}</>;

  const tabs = [
    { title: "Basic", content: <Basic stamp={data.stamp} /> },
  ];

  return <DataTabs tabs={tabs} />;
}

const GET_STAMP = gql`
  query StampQuery($id: ID!) {
    stamp(stampId: $id) {
      id
      name
      displayName
      overrideDisplayName
      svgUrl
    } 
  }
`;

export default ViewStamp;
