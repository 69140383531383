import { IconButton, Popover } from "@mui/material";
import { useState } from "react";
import { icons } from "../../../../../icons";
import s from "./IconPicker.module.css";

export function IconPicker({
  iconName,
  setIconName,
  disabled,
}: IconPickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const icon = icons.get(iconName);
  const styledIcon = (
    <div
      className={s.IconPicker}
      onClick={
        disabled
          ? () => {}
          : (event: React.MouseEvent<HTMLDivElement>) =>
              setAnchorEl(event.currentTarget)
      }
    >
      {icon ? <div className={s.Icon}>{icon}</div> : <div>no icon</div>}
    </div>
  );

  return (
    <>
      {styledIcon}
      <IconPickerPopover
        open={Boolean(anchorEl)}
        handleClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        setIconName={setIconName}
      />
    </>
  );
}

type IconPickerProps = {
  iconName: string;
  setIconName: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
};

function IconPickerPopover({
  open,
  handleClose,
  anchorEl,
  setIconName,
}: IconPickerPopoverProps) {
  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "center", horizontal: "right" }}
      transformOrigin={{ vertical: "center", horizontal: "left" }}
    >
      <div className={s.IconPickerPopper}>
        {[...icons].map(([iconName, icon]) => (
          <div
            className={s.IconContainer}
            key={iconName}
            onClick={() => {
              setIconName(iconName);
              handleClose();
            }}
          >
            <IconButton className={s.Icon}>{icon}</IconButton>
          </div>
        ))}
      </div>
    </Popover>
  );
}

type IconPickerPopoverProps = {
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLDivElement | null;
  setIconName: (iconName: string) => void;
};
