import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useNotifications } from "../../../components/Notification";
import { Location } from "../../../types";

export default function DeleteLocationButton(props: { location: Location }) {
  const { showNotification } = useNotifications();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteLocation] = useMutation(DELETE_LOCATION_MUTATION);

  return (
    <>
      <IconButton size="small" onClick={() => setDialogOpen(true)}>
        <DeleteIcon />
      </IconButton>
      {dialogOpen && <ConfirmDialog />}
    </>
  );

  function ConfirmDialog() {
    const { data, loading, error } = useQuery(GET_LOCATION_USAGE, {
      variables: { locationId: props.location.id },
      fetchPolicy: "no-cache",
    });
    if (error) throw Error(error.message);
    if (loading)
      return (
        <Dialog open={dialogOpen}>
          <CircularProgress />
        </Dialog>
      );

    const locationChildren = data.location.children;
    const locationPlans = data.plansByFilter.records;
    const locationCollections = data.planCollections.records;

    let notRemovable = null;
    if (locationCollections.length > 0)
      notRemovable = (
        <>
          Can't remove. Location is being used by collections:
          <br />
          <ul>
            {locationCollections.map((lc: any) => (
              <li key={lc.id}>
                <b>{lc.id}</b>
                <br />
                {lc.name} ({lc.slug})
              </li>
            ))}
          </ul>
        </>
      );
    if (locationPlans.length > 0)
      notRemovable = (
        <>
          Can't remove. Location is being used by plans:
          <br />
          <ul>
            {locationPlans.map((lp: any) => (
              <li key={lp.id}>
                <b>{lp.id}</b>
                <br />
                {lp.name}
              </li>
            ))}
          </ul>
        </>
      );
    if (locationChildren.length > 0)
      notRemovable = (
        <>
          Can't remove. Location is being used by other locations: <br />
          <ul>
            {locationChildren.map((lc: Location) => (
              <li key={lc.id}>
                <b>{lc.id}</b>
                <br />
                {lc.type}: {lc.nameEn} ({lc.nameJa})
              </li>
            ))}
          </ul>
        </>
      );

    return (
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          Removing {props.location.nameEn} ({props.location.nameJa})
        </DialogTitle>
        <DialogContent style={{ paddingBottom: "20px" }}>
          {notRemovable || (
            <>
              Are you sure you want to remove {props.location.type}:{" "}
              {props.location.nameEn} ({props.location.nameJa})?
            </>
          )}
        </DialogContent>
        {!notRemovable && (
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>No</Button>
            <Button onClick={handleRemoveLocation}>Yes</Button>
          </DialogActions>
        )}
        {notRemovable && (
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }

  function handleRemoveLocation() {
    deleteLocation({
      variables: { id: props.location.id },
      refetchQueries: ["LocationsQuery"],
    })
      .then(() => {
        showNotification({
          message: `Location was removed`,
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: `Removing location failed!`,
          severity: "error",
        });
      });
    setDialogOpen(false);
  }
}

const GET_LOCATION_USAGE = gql`
  query GetLocationUsage($locationId: ID!) {
    location(id: $locationId) {
      children {
        id
        type
        nameEn
        nameJa
      }
    }
    plansByFilter(filter: { locationIds: [$locationId] }) {
      records {
        id
        name
      }
    }
    planCollections(locationIds: [$locationId]) {
      records {
        id
        name
        slug
      }
    }
  }
`;

const DELETE_LOCATION_MUTATION = gql`
  mutation DeleteLocationMutation($id: ID!) {
    deleteLocation(input: { id: $id })
  }
`;
