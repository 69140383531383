import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import Button from "../../../components/Button2";
import { BackofficeUser, BackofficeUserRole, Partner } from "../../../types";
import EditBackofficeUserForm from "./form";

export default function EditBackofficeUser() {
  const id = useParams<{ id: string }>().id!;

  const { loading, error, data,refetch } = useQuery<
    GetUserAndPartnerOptionsResponse,
    GetUserAndPartnerOptionsInput
  >(GET_USER_AND_PARTNER_OPTIONS, { variables: { id } });
  const user = useMemo(() => data?.user, [data]);
  const partnerOptions = useMemo(
    () =>
      (data?.partners.records ?? []).map((partner) => ({
        id: partner.id,
        label: partner.name,
      })),
    [data]
  );

  if (error) return <>Error! {error.message}</>;

  return (
    <>
      <div>
        <Button style={{ marginBottom: "15px" }} component={Link} to={`/users`}>
          Back to list
        </Button>
      </div>
      {loading || !user ? (
        <CircularProgress />
      ) : (
        <EditBackofficeUserForm user={user} partnerOptions={partnerOptions} refetch={refetch}/>
      )}
    </>
  );
}

type GetUserAndPartnerOptionsInput = Pick<BackofficeUser, "id">;
type GetUserAndPartnerOptionsResponse = {
  user: BackofficeUser;
  partners: {
    records: Partner[];
  };
  backofficeUserRoles: {
    records: BackofficeUserRole[];
  };
};
const GET_USER_AND_PARTNER_OPTIONS = gql`
  query BackofficeUserEditQuery($id: ID!) {
    user(id: $id) {
      id
      username
      usersEditable
      createdAt
      lastUpdatedAt
      lastLoggedInAt
      partner {
        id
        name
      }
      backofficeUserRoles {
        id
        roleId
      }
    }
    partners(offset: 0, limit: 0) {
      records {
        id
        name
      }
    }
  }
`;
