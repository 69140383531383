import { CSVLink } from "react-csv";
import { FormatMoney } from "./Money";
import { format, endOfMonth } from "date-fns";
import { Button, Tooltip } from "@mui/material";
import { ReservationWithCosts } from "./types";
import { useCallback, useMemo } from "react";

const total = (
  reservations: ReservationWithCosts[],
  fn: (r: ReservationWithCosts) => number
) => reservations.map(fn).reduce((total, item) => total + item, 0);

export default function DownloadCostsCSV(props: {
  hasPaidReservations: boolean;
  reservations: ReservationWithCosts[];
  date: Date;
  venue: { id: string; name: string };
}) {
  const getCsvData = useCallback(() => {
    const reservationsForReport = props.reservations.filter(
      (r) =>
        (r.status === "RESERVED" && r.paymentMethod !== "ON_SITE_PAYMENT") ||
        (r.status === "CANCELLED" && r.paymentMethod !== "ON_SITE_PAYMENT")
    );

    const reservationsWithoutOnSite = props.reservations.filter(
      (r) => r.paymentMethod !== "ON_SITE_PAYMENT"
    );

    const currencyCode = props.reservations[0].costs.currencyCode;
    const totalCommission = total(
      reservationsWithoutOnSite,
      (r) => r.costs.commission
    );

    const totalForVenue = total(
      reservationsWithoutOnSite,
      (r) => r.costs.venuePaymentAmount
    );

    return [
      { E: "お支払通知書" },
      { L: format(new Date(), "yyyy-MM-dd") },
      {
        E: `支払通知金額：${FormatMoney({
          amount: totalForVenue,
          currencyCode,
        })}(税込)`,
      },
      { A: `振込期日: ${format(endOfMonth(new Date()), "yyyy-MM-dd")}` },
      { A: `金額確定月: ${format(props.date, "yyyy-MM")}` },
      { A: "お振込先: " },
      {},
      {},
      {},
      {},
      { A: "■体験予約" },
      {
        A: "催行日",
        B: "予約ID",
        C: "予約者名",
        D: "プラン名",
        E: "アクティビティ名",
        F: "予約人数",
        G: "単価(税込)",
        H: "支払金額",
        I: "座席料金",
        J: "キャンセル適用率等",
        K: "手数料",
        L: "合計(税込)",
      },
      ...reservationsForReport.map((r) => ({
        A: format(new Date(r.datetime), "yyyy-MM-dd"),
        B: r.booking.id,
        C: `${r.booking.familyName} ${r.booking.givenName}`,
        D: r.planName,
        E: r.activityName,
        F: r.items
          .map((item) => `${item.priceType.name}:${item.quantity}`)
          .join("\n"),
        G: r.items
          .map(
            (item) =>
              `${item.priceType.name}:${FormatMoney({
                amount: item.priceType.contractedPrice,
                currencyCode: item.priceType.currencyCode,
              })}`
          )
          .join("\n"),

        H: FormatMoney({
          amount: r.costs.contractedAmount,
          currencyCode,
        }),
        I: FormatMoney({
          amount: r.costs.seatPrice,
          currencyCode,
        }),
        J:
          r.costs.venuePaymentRate === 100
            ? ""
            : `${r.costs.venuePaymentRate}%`,
        K: FormatMoney({
          amount: r.costs.commission,
          currencyCode,
        }),
        L: FormatMoney({
          amount: r.costs.venuePaymentAmount + r.costs.commission,
          currencyCode,
        }),
      })),
      {
        J: "小計",
        K: FormatMoney({
          amount: totalCommission,
          currencyCode,
        }),
        L: FormatMoney({
          amount: totalCommission + totalForVenue,
          currencyCode,
        }),
      },
      {},
      {
        K: "利用者支払金額合計",
        L: FormatMoney({
          amount: totalCommission + totalForVenue,
          currencyCode,
        }),
      },
      {
        K: "手数料合計",
        L: FormatMoney({
          amount: -totalCommission,
          currencyCode,
        }),
      },
      {
        K: "総計",
        L: FormatMoney({
          amount: totalForVenue,
          currencyCode,
        }),
      },
    ];
  }, [props.date, props.reservations]);

  const downloadButton = useMemo(
    () => (
      <Tooltip title={props.hasPaidReservations ? "" : "No paid reservations"}>
        <span>
          <Button disabled={!props.hasPaidReservations} variant="outlined">
            Download CSV
          </Button>
        </span>
      </Tooltip>
    ),
    [props.hasPaidReservations]
  );

  return props.hasPaidReservations ? (
    <CSVLink
      headers={[
        { key: "A", label: "" },
        { key: "B", label: "" },
        { key: "C", label: "" },
        { key: "D", label: "" },
        { key: "E", label: "" },
        { key: "F", label: "" },
        { key: "G", label: "" },
        { key: "H", label: "" },
        { key: "I", label: "" },
        { key: "J", label: "" },
        { key: "K", label: "" },
        { key: "L", label: "" },
      ]}
      data={props.hasPaidReservations ? getCsvData() : ""}
      filename={`${props.venue.name}-${format(props.date, "yyyy-MM")}`}
      style={{ textDecoration: "none" }}
    >
      {downloadButton}
    </CSVLink>
  ) : (
    <div>{downloadButton}</div>
  );
}
