import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import Button from "../../../../components/Button2";
import DataTable from "../../../../components/DataTable";
import { Template, PlanTemplate, PlanTemplateCard } from "../../../../types";
import { useNotifications } from "../../../../components/Notification";
import { v4 as uuid } from "uuid";

export default function TemplateTable(props: {
  assignedTemplates: PlanTemplate[];
  onAdd: React.Dispatch<React.SetStateAction<PlanTemplate[]>>;
  planId: string;
  failedValidation: boolean;
}) {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const { showNotification } = useNotifications();

  const { data, loading, error, fetchMore } = useQuery(TEMPLATES_QUERY, {
    variables: {
      offset: page * pageSize,
      limit: pageSize,
      search: search,
    },
  });

  if (error) return <>ERROR: {error.message}</>;

  const rows = data ? data.templates.records : null;
  return (
    <DataTable
      loading={loading}
      columns={[
        { name: "Display Name", render: renderDisplayName },
        { name: "URL", render: renderURL },
        { name: "", render: renderAdd },
      ]}
      rows={rows}
      pagination={{
        totalRows: data ? data.templates.totalRecords : null,
        pageSize: pageSize,
        currentPage: page,
        onChangePage: (_, newPage) => {
          setPage(newPage);
          fetchMore({
            variables: {
              offset: newPage * pageSize,
              limit: pageSize,
            },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        },
      }}
      onSearch={(e) => setSearch(e.target.value)}
      searchValue={search}
    />
  );

  function renderDisplayName(row: {
    displayName: string;
    overrideDisplayName: string;
  }) {
    return (
      <div key={row.displayName + 1}>
        {row.overrideDisplayName ?? row.displayName}
      </div>
    );
  }

  function renderURL(row: { giftmallUrl: string }) {
    return (
      <a
        key={row.giftmallUrl + 2}
        href={row.giftmallUrl}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {row.giftmallUrl}
      </a>
    );
  }

  function renderAdd(row: Template) {
    return (
      <div style={{ textAlign: "right" }} key={row.id + 3}>
        <Button
          key={row.id + 4}
          size="small"
          onClick={() => {
            if (props.failedValidation) {
              showNotification({
                message: `Could not add item to plan! Items cannot be added to a plan that only supports on-site payment.`,
                severity: "error",
              });
              return;
            }
            const objectToAdd = {
              id: uuid(),
              templateId: row.id,
              planId: props.planId,
              whenToGive: row.defaultWhenToGive,
              howToGive: row.defaultHowToGive,
              cards: [] as PlanTemplateCard[],
              template: {
                id: row.id,
                displayName: row.displayName,
                overrideDisplayName: row.overrideDisplayName,
                giftmallUrl: row.giftmallUrl,
                name: row.name,
                withPaperbag: row.withPaperbag,
                commissionRate: row.commissionRate,
              },
            };

            const dupes = props.assignedTemplates.filter((planTemplate) => {
              return planTemplate.template.id === objectToAdd.template.id;
            });

            if (dupes.length === 0) {
              props.onAdd([
                ...props.assignedTemplates,
                objectToAdd as PlanTemplate,
              ]);
            } else {
              showNotification({
                message: "This item is already assigned to the plan",
                severity: "error",
              });
            }
          }}
        >
          Add Item
        </Button>
      </div>
    );
  }
}

const TEMPLATES_QUERY = gql`
  query TemplatesQuery($offset: Int!, $limit: Int!, $search: String) {
    templates(filter: { offset: $offset, limit: $limit, search: $search }) {
      totalRecords
      records {
        id
        displayName
        overrideDisplayName
        name
        defaultHowToGive
        defaultWhenToGive
        giftmallUrl
        withPaperbag
        commissionRate
      }
    }
  }
`;
