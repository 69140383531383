import { useEffect } from "react";
import TextField from "../../../../components/TextField";
import { BookingSystem } from "../../../../types";
import SelectRow, { SelectRowProps } from "./SelectRow";

const EbicaSelect = ({
  ebicaStoreId,
  setEbicaStoreId,
  ...selectRowProps
}: EbicaSelectProps) => {
  useEffect(() => {
    return () => {
      setEbicaStoreId(null);
    };
  }, [setEbicaStoreId]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <SelectRow {...selectRowProps} />
        <div>
          <TextField
            required
            label="Ebica Store ID"
            value={ebicaStoreId || 0}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              if (/^\d{0,5}$/.test(value))
                setEbicaStoreId(value ? Number(value) : null);
            }}
            inputProps={{
              maxLength: 5,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EbicaSelect;

type EbicaSelectProps = {
  ebicaStoreId: number | null;
  setEbicaStoreId: (ebicaStoreId: number | null) => void;
} & SelectRowProps<BookingSystem>;
