import { gql, useQuery } from "@apollo/client";
import { Pagination, PartnerPlanEdit } from "../../../../types";
import { TripleState } from "../../components/BasicInfoForm/MultiStateCheckbox/TripleStateCheckbox";

export default function useGetPartnerEdits(variables: PartnerEditQueryInput) {
  return useQuery<PartnerEditQueryResponse, PartnerEditQueryInput>(
    PARTNER_EDITS_QUERY,
    {
      variables,
      fetchPolicy: "network-only",
    }
  );
}

export type PartnerEditQueryInput = {
  planId: string;
  pagination?: Pagination;
  isVerified?: boolean;
  isAccepted?: TripleState;
};

type PartnerEditQueryResponse = {
  partnerEdits: { totalRecords: number; records: PartnerPlanEdit[] };
};

const PARTNER_EDITS_QUERY = gql`
  query PartnerEditsQuery(
    $planId: ID!
    $pagination: Pagination
    $isVerified: Boolean
    $isAccepted: Boolean
  ) {
    partnerEdits(
      planId: $planId
      pagination: $pagination
      isVerified: $isVerified
      isAccepted: $isAccepted
    ) {
      totalRecords
      records {
        id
        planId
        partnerId
        fieldName
        editStatus
        newValue
        oldValue
        createdAt
        verifiedAt
        action
      }
    }
  }
`;
