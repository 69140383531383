import { Button, Grid, Checkbox, FormControlLabel } from "@mui/material";
import TemplateCardForm from "./templateCardForm";
import TemplateItemForm, { TemplateGridItemProps } from "./templateItemForm";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuid } from "uuid";
import { PlanTemplateCard } from "../../../../types";
import { FetchResult, gql, MutationFunctionOptions, OperationVariables, useMutation, useQuery } from "@apollo/client";
import { NotificationData, useNotifications } from "../../../../components/Notification";

// GraphQL query to fetch highlights by template ID
const GET_HIGHLIGHT_ITEMS_BY_TEMPLATE_ID = gql`
  query GetHighlightItemsByTemplateId($templateId: ID!) {
    getHighlightItemsByTemplate(templateId: $templateId) {
      id
      title
      body
      image
    }
  }
`;

type TemplateFormProps = {
  setPlanTemplateCards: (cards: PlanTemplateCard[], status?: boolean) => void;
} & TemplateGridItemProps;

export default function TemplateForm({
  planTemplate,
  setPlanTemplateCards,
  setHowToGive,
  setWhenToGive,
  howToOptions,
  whenToOptions,
  addHowToOption,
  addWhenToOption,
  handleDelete,
}: TemplateFormProps) {
  // Fetch plan template cards
  const { loading, error, refetch } = useQuery(PLAN_TEMPLATE_CARD_QUERY, {
    variables: { planTemplateId: planTemplate.id },
    notifyOnNetworkStatusChange: true,
  });

  // Fetch highlight items by template ID (planTemplate.id)
  const {
    data: highlightData,
    loading: highlightLoading,
    error: highlightError,
  } = useQuery(GET_HIGHLIGHT_ITEMS_BY_TEMPLATE_ID, {
    variables: { templateId: planTemplate.template.id }, // This is the template_id in highlight items
  });

  const [deletePlanTemplateCard] = useMutation(DELETE_PLAN_TEMPLATE_CARD_MUTATION);
  const { showNotification } = useNotifications();

  const handleAddCard = () => {
    setPlanTemplateCards([
      ...planTemplate.cards,
      {
        id: uuid(),
        planTemplateId: planTemplate.id,
        title: "",
        description: "",
        imageUrl: "",
        index: planTemplate.cards.length > 0
          ? planTemplate.cards.length + 1
          : 1,
        fromHighlight: false
      },
    ]);
  };

  const handleDeleteCard = (index: number) => {
    deleteCard(
      planTemplate.cards,
      setPlanTemplateCards,
      deletePlanTemplateCard,
      showNotification,
      index
    );
  };

  // Conditionally render the checkbox based on the existence of highlights
  const hasHighlights = highlightData?.getHighlightItemsByTemplate?.length > 0;

  // Handle checkbox click to append highlight items to planTemplate.cards
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && hasHighlights) {
      const newCards = highlightData.getHighlightItemsByTemplate.map((highlight: any, idx: number) => ({
        id: uuid(),
        planTemplateId: planTemplate.id,
        title: highlight.title,
        description: highlight.body,
        imageUrl: highlight.image,
        index: planTemplate.cards.length + idx + 1,
        fromHighlight: true
      }));

      setPlanTemplateCards([...planTemplate.cards, ...newCards], true);
    } else {
      const filteredCards = planTemplate.cards.filter((card: any) => !card.fromHighlight);

      setPlanTemplateCards(filteredCards, false);
    }
  };


  if (loading) return <>Loading...</>;
  if (error)
    return (
      <div onClick={() => refetch()}>Failed to load item cards. Try again?</div>
    );

  return (
    <Grid>
      <TemplateItemForm
        planTemplate={planTemplate}
        setHowToGive={setHowToGive}
        setWhenToGive={setWhenToGive}
        howToOptions={howToOptions}
        whenToOptions={whenToOptions}
        addHowToOption={addHowToOption}
        addWhenToOption={addWhenToOption}
        handleDelete={handleDelete}
      />

      {highlightLoading ? (
        <p>Loading highlights...</p>
      ) : hasHighlights && (
        <FormControlLabel
          label="デフォルトのハイライトを表示する"
          control={<Checkbox
            defaultChecked={planTemplate.highlightDefaultFlg}
            onChange={handleCheckboxChange} />}
        />
      )}

      <br />
      <Button
        variant="contained"
        style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
        startIcon={<AddIcon />}
        onClick={handleAddCard}
      >
        Add Card
      </Button>
      {planTemplate.cards.map((card, index) => (
        <TemplateCardForm
          key={card.id}
          card_id={card.id}
          index={card.index}
          setIndex={(newIndex: number) =>
            updateCard(planTemplate.cards, setPlanTemplateCards, index, "index", newIndex)
          }
          title={card.title}
          setTitle={(newTitle: string) =>
            updateCard(planTemplate.cards, setPlanTemplateCards, index, "title", newTitle)
          }
          description={card.description}
          setDescription={(newDescription: string) =>
            updateCard(planTemplate.cards, setPlanTemplateCards, index, "description", newDescription)
          }
          imageUrl={card.imageUrl}
          setImageUrl={(newImageUrl: string) =>
            updateCard(planTemplate.cards, setPlanTemplateCards, index, "imageUrl", newImageUrl)
          }
          handleDelete={() => handleDeleteCard(index)}
        />
      ))}
    </Grid>
  );
}

const deleteCard = (
  planTemplateCards: PlanTemplateCard[],
  setPlanTemplateCards: (cards: PlanTemplateCard[]) => void,
  deletePlanTemplateCard: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
  showNotification: (notification: NotificationData) => void,
  index: number
) => {
  deletePlanTemplateCard({
    variables: { input: { planTemplateCard: planTemplateCards[index] } },
  })
    .then(() => {
      setPlanTemplateCards([
        ...planTemplateCards.slice(0, index),
        ...planTemplateCards.slice(index + 1),
      ]);
      showNotification({
        message: "Card was removed successfully.",
        severity: "success",
      });
    })
    .catch((e: any) => {
      showNotification({
        message: `Deleting card failed! ${e.message}`,
        severity: "error",
      });
    });
};

const updateCard = (
  planTemplateCards: PlanTemplateCard[],
  setPlanTemplateCards: (cards: PlanTemplateCard[]) => void,
  index: number,
  field: string,
  value: string | number
) => {

  setPlanTemplateCards([
    ...planTemplateCards.slice(0, index),
    { ...planTemplateCards[index], [field]: value, fromHighlight: false },
    ...planTemplateCards.slice(index + 1),
  ]);
};

const PLAN_TEMPLATE_CARD_QUERY = gql`
  query PlanTemplateCardQuery($planTemplateId: ID!) {
    planTemplateCards(planTemplateId: $planTemplateId) {
      planTemplateId
      planTemplateCards {
        id
        planTemplateId
        title
        description
        imageUrl
        index
        fromHighlight
      }
    }
  }
`;

const DELETE_PLAN_TEMPLATE_CARD_MUTATION = gql`
  mutation DeletePlanTemplateCardMutation($input: DeletePlanTemplateCardInput!) {
    deletePlanTemplateCard(input: $input) {
      success
    }
  }
`;
