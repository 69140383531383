import { gql, useMutation } from "@apollo/client";
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import AutocompleteVenueSelector from "../../../components/AutocompleteVenueSelector";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { useGlobalContext } from "../../../GlobalContext";
import { Partner } from "../../../types";

interface EditPartnerFormProps {
  partner: Partner;
  userOptions: { id: string; label: string }[];
}

export default function EditPartnerForm({
  partner,
  userOptions,
}: EditPartnerFormProps) {
  const { showNotification } = useNotifications();
  const { usersEditable } = useGlobalContext();

  const [name, setName] = useState(partner.name);
  const [email, setEmail] = useState(partner.email);
  const [partnerVenues, setPartnerVenues] = useState(partner.venues ?? []);
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [editPartner] = useMutation(EDIT_PARTNER);
  const [user, setUser] = useState(
    partner.user
      ? { id: partner.user?.id, label: partner.user?.username }
      : null
  );
  const [connectedToAllVenues, setConnectedToAllVenues] = useState(
    partner.connectedToAllVenues
  );

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Id" value={partner.id} disabled={true} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Created At"
                value={partner.createdAt}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last Updated At"
                value={partner.lastUpdatedAt || ""}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              {usersEditable && (
                <Autocomplete
                  value={user}
                  readOnly={!usersEditable}
                  onChange={(_: unknown, newValue) => {
                    setUser(newValue);
                  }}
                  options={userOptions}
                  renderInput={(params) => (
                    <TextField {...params} label="Backoffice user" />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Logged In"
                value={partner.lastLoggedIn || ""}
                disabled={true}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={1}>
            <AutocompleteVenueSelector
              disabled={!!connectedToAllVenues}
              venues={partnerVenues}
              setVenues={setPartnerVenues}
            />
            {user && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!connectedToAllVenues}
                      onChange={(event) =>
                        setConnectedToAllVenues(event.target.checked)
                      }
                    />
                  }
                  label="All"
                />
              </FormGroup>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <CardHeader title="Reset password" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                type="password"
                label="New Password"
                value={newPassword}
                onChange={(e: any) => setNewPassword(e.target.value)}
                helperText="Keep empty if you dont want to change the password"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="password"
                label="Repeat new password"
                value={repeatNewPassword}
                onChange={(e: any) => setRepeatNewPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleEditPartner}>Save</Button>
      </div>
    </>
  );

  function handleEditPartner() {
    if (newPassword && newPassword !== repeatNewPassword)
      return showNotification({
        message: "New passwords are not equal",
        severity: "error",
      });

    editPartner({
      variables: {
        id: partner.id,
        name,
        email,
        venueIds: partnerVenues.map((pv) => pv.id),
        newPassword,
        userId: user?.id,
        connectedToAllVenues,
      },
    })
      .then(() => {
        showNotification({
          message: "Partner successfully edited",
          severity: "success",
        });
      })
      .catch((error) => {
        showNotification({
          message: `Failed to save partner ${error.message}`,
          severity: "error",
        });
      });
  }
}

function TextField(props: TextFieldProps) {
  return <MuiTextField {...props} fullWidth style={{ marginTop: "0px" }} />;
}

const EDIT_PARTNER = gql`
  mutation EditPartnerMutation(
    $id: ID!
    $name: String
    $email: String
    $venueIds: [ID!]
    $newPassword: String
    $userId: ID
    $connectedToAllVenues: Boolean
  ) {
    editPartner(
      input: {
        id: $id
        name: $name
        email: $email
        venueIds: $venueIds
        newPassword: $newPassword
        userId: $userId
        connectedToAllVenues: $connectedToAllVenues
      }
    ) {
      success
    }
  }
`;
