import { useEffect, useState } from "react";

interface Resolution {
  width: number;
  height: number;
}

function useImageResolution(url: string): Resolution {
  const [resolution, setResolution] = useState<Resolution>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      setResolution({ width: img.width, height: img.height });
    };

    img.onerror = () => {
      console.error("Failed to load image");
    };
  }, [url]);

  return resolution;
}

export default useImageResolution;
