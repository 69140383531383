import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
} from "@mui/material";
import { useCallback, useState } from "react";
import TextField from "../../../../components/TextField";

type OverwriteTextFieldProps<T> = {
  identityValue: T;
  defaultValue?: T;
  overwriteValue: T | null;
  setOverwriteValue: React.Dispatch<React.SetStateAction<T | null>>;
} & Parameters<typeof TextField>[0];

export default function OverwriteTextField<T>(
  props: OverwriteTextFieldProps<T>
) {
  const [shouldOverwrite, setShouldOverwrite] = useState(
    props.overwriteValue !== null
  );

  const onClickCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      setShouldOverwrite(isChecked);
      props.setOverwriteValue(
        isChecked ? props.defaultValue ?? props.identityValue : null
      );
    },
    [props]
  );

  const value =
    (shouldOverwrite ? props.overwriteValue : props.defaultValue) ??
    props.identityValue;

  return (
    <TextField
      {...props}
      value={value}
      readOnly={!shouldOverwrite}
      InputProps={
        shouldOverwrite
          ? {}
          : {
              style: {
                backgroundColor: "rgba(0,0,0,0.15)",
              },
            }
      }
      endAdornment={
        <OverwriteCheckbox
          checked={shouldOverwrite}
          onChange={onClickCheckbox}
        />
      }
    />
  );
}

function OverwriteCheckbox(props: CheckboxProps) {
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox {...props} />} label="Overwrite" />
    </FormGroup>
  );
}
