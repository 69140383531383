import { makeStyles } from "@mui/styles";

export default makeStyles({
  role_detail_button: {
    position: "fixed",
    bottom: "0px",
    left: "200px",
    backgroundColor: "#fff",
    width: "100%",
    padding: "10px 0px 10px 20px",
  },
});
