import { gql, useQuery } from "@apollo/client";
import {
  Autocomplete,
  AutocompleteProps,
  Chip,
  TextField,
} from "@mui/material";
import { useMemo } from "react";
import { Venue } from "../../types";

type AutocompleteVenueSelectorProps = {
  venues: Venue[];
  setVenues: (venues: Venue[]) => void;
} & Partial<AutocompleteProps<any, any, any, any>>;

export default function AutocompleteVenueSelector({
  venues,
  setVenues,
  ...autocompleteProps
}: AutocompleteVenueSelectorProps) {
  const { data, loading } = useQuery<GetVenuesResponse>(GET_VENUES);

  const venueOptions = useMemo(() => data?.venues.records ?? [], [data]);

  return (
    <Autocomplete
      fullWidth
      multiple
      loading={loading}
      value={venues}
      onChange={(_, newValue) => setVenues(newValue as Venue[])}
      options={venueOptions}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      renderInput={(params) => <TextField {...params} label="Venues" />}
      renderTags={(value, getTagProps) =>
        value.map(({ id, name }, index) => (
          <Chip
            label={name}
            {...getTagProps({ index })}
            onClick={() => window.open(`/venues/${id}`, "_blank")}
          />
        ))
      }
      {...autocompleteProps}
    />
  );
}

type GetVenuesResponse = {
  venues: {
    total: number;
    records: Pick<Venue, "id" | "name">[];
  };
};
const GET_VENUES = gql`
  query VenuesQuery {
    venues {
      total
      records {
        id
        name
      }
    }
  }
`;
