import { gql, useMutation, useQuery } from "@apollo/client";
import { Card, CardContent, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { default as TF } from "../../../components/TextField";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Checkbox from "../../../components/Checkbox";
import AutocompleteDialog from "../../../components/AutocompleteDialog";

export default function AddNewItem() {
  const { showNotification } = useNotifications();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setTemplateDescription] = useState<string>("");
  const [defaultHowToGive, setDefaultHowToGive] = useState<string>("");
  const [defaultWhenToGive, setDefaultWhenToGive] = useState<string>("");
  const [giftmallUrl, setGiftmallUrl] = useState<string>("");
  const [commissionRate, setTemplateCommissionRate] = useState<number>(0);
  const [withPaperbag, setTemplateWithPaperbag] = useState<boolean>(false);
  const [howToOptions, setHowToOptions] = useState<string[]>([]);
  const [whenToOptions, setWhenToOptions] = useState<string[]>([]);
  const [itemId, setItemId] = useState(null);
  const [createTemplate] = useMutation(ADD_ITEM);

  const {
    loading: giftingOptionsLoading,
    error: giftingOptionsError,
    data: giftingOptionsData,
  } = useQuery(GET_GIFTING_DEFAULTS);

  useEffect(() => {
    if (itemId) {
      setTimeout(() => {
        navigate(`/items/edit/${itemId}`);
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    setHowToOptions(giftingOptionsData?.giftingDefaults.howToGive ?? []);
    setWhenToOptions(giftingOptionsData?.giftingDefaults.whenToGive ?? []);
  }, [giftingOptionsData, giftingOptionsLoading, giftingOptionsError]);

  return (
    <>
      <div>
        <Button style={{ marginBottom: "15px" }} component={Link} to={`/items`}>
          Back to list
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Template Info" />
            <Divider />
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <Checkbox
                label="Use Paperbag"
                checked={withPaperbag}
                onChange={() => setTemplateWithPaperbag(!withPaperbag)}
              />
              <TextField
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                size="small"
                label={"Name"}
              />
              <TextField
                label="Shop URL"
                size="small"
                value={giftmallUrl}
                onChange={(e: any) => setGiftmallUrl(e.target.value)}
                onClick={(_e: any) => {
                  if (!giftmallUrl) return;
                  window.open(giftmallUrl, "_blank", "noopener")?.focus();
                }}
              />
              <TextField
                required
                label={"Display name"}
                value={displayName}
                onChange={(e: any) => {
                  setDisplayName(e.target.value);
                }}
              />
              <TextField
                multiline={true}
                label="Description"
                value={description}
                onChange={(e: any) => {
                  setTemplateDescription(e.target.value);
                }}
              />
              <AutocompleteDialog
                value={defaultHowToGive}
                options={howToOptions}
                onAdd={(newOption: string) =>
                  setWhenToOptions((howToOptions) => [
                    ...howToOptions,
                    newOption,
                  ])
                }
                setValue={setDefaultHowToGive}
                label="Default how to give"
              />
              <AutocompleteDialog
                value={defaultWhenToGive}
                options={whenToOptions}
                onAdd={(newOption: string) =>
                  setWhenToOptions((whenToOptions) => [
                    ...whenToOptions,
                    newOption,
                  ])
                }
                setValue={setDefaultWhenToGive}
                label="Default when to give"
              />
              <TextField
                required
                label="Commission rate"
                value={commissionRate}
                type="number"
                size="small"
                onChange={(e: any) => {
                  if (e.target.value >= 0) {
                    setTemplateCommissionRate(
                      Number(parseFloat(e.target.value).toFixed(2))
                    );
                  }
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleAddNewItem}>Add New Item</Button>
      </div>
    </>
  );

  function handleAddNewItem() {
    if (!name)
      return showNotification({
        message: "Please input a name",
        severity: "error",
      });
    if (!displayName)
      return showNotification({
        message: "Please input an display name",
        severity: "error",
      });
    if (!giftmallUrl)
      return showNotification({
        message: "Please input an giftmallUrl",
        severity: "error",
      });

    const data = {
      name,
      displayName,
      description,
      withPaperbag,
      commissionRate,
      defaultHowToGive,
      defaultWhenToGive,
      giftmallUrl,
    };

    createTemplate({ variables: { ...data } })
      .then((result) => {
        console.log(result);

        showNotification({ message: "New item added", severity: "success" });
        setItemId(result.data.createTemplate.id);
      })
      .catch((error) => {
        showNotification({
          message: `Failed to add new item! ${error.message}`,
          severity: "error",
        });
      });
  }
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

const GET_GIFTING_DEFAULTS = gql`
  query GiftingDefaultsQuery {
    giftingDefaults {
      howToGive
      whenToGive
    }
  }
`;

const ADD_ITEM = gql`
  mutation AddItemMutation(
    $name: String!
    $displayName: String!
    $description: String!
    $withPaperbag: Boolean!
    $commissionRate: Float!
    $defaultHowToGive: String!
    $defaultWhenToGive: String!
    $giftmallUrl: String!
  ) {
    createTemplate(
      input: {
        name: $name
        displayName: $displayName
        description: $description
        withPaperbag: $withPaperbag
        commissionRate: $commissionRate
        defaultHowToGive: $defaultHowToGive
        defaultWhenToGive: $defaultWhenToGive
        giftmallUrl: $giftmallUrl
      }
    ) {
      id
    }
  }
`;
