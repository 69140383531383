import { makeStyles } from "@mui/styles";

export default makeStyles({
  userMenu: {
    textAlign: "center",
  },

  userButton: {
    color: "white",
    textTransform: "none",
    fontSize: "16px",
  },

  menuItemText: {
    width: "100%",
    textAlign: "center",
  },
});
