import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorIcon from "@mui/icons-material/Error";
import { CircularProgress } from "@mui/material";
import useGetItemSalesData, { fieldTranslations } from "./useGetItemSalesData";
import { useMemo } from "react";
import { CSVLink } from "react-csv";
import { format } from "date-fns";

type DownloadCsvButtonProps = { date: Date };
export default function DownloadCsvButton({ date }: DownloadCsvButtonProps) {
  const { itemSalesData, loading, error } = useGetItemSalesData({
    date: date!,
  });

  const headers = useMemo(() => Object.values(fieldTranslations), []);

  const rows = useMemo(() => itemSalesData.map((row) => Object.values(row)), [
    itemSalesData,
  ]);

  const disabled = useMemo(() => loading || !!error, [loading, error]);

  return (
    <CSVLink
      headers={headers}
      data={rows}
      filename={`お祝いアイテム-${format(date, "yyyy-MM-dd")}.csv`}
    >
      <Button
        disabled={disabled}
        endIcon={
          loading ? (
            <CircularProgress size={20} />
          ) : error ? (
            <ErrorIcon />
          ) : (
            <DownloadIcon />
          )
        }
        variant="contained"
      >
        download csv
      </Button>
    </CSVLink>
  );
}
