import { gql, useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../components/Button2";
import DataTable from "../../../components/DataTable";
import { Company } from "../../../types";
import ContractPopper from "./ContractPopper";
import { IconButton } from "@mui/material";
import DeleteCompanyDialog from "./DeleteCompanyDialog";

export function CompanyList() {
  const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
    null
  );

  const navigate = useNavigate();
  const location = useLocation();
  const pageSize = 10;

  const parsedQueryString = queryString.parse(location.search);

  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );

  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );

  useEffect(updateUrl, [page, search, navigate]);

  let { loading, error, data, refetch, fetchMore } = useQuery<
    CompanyQueryResult,
    CompanyQueryInput
  >(GET_COMPANIES, {
    variables: { offset: page * pageSize, limit: pageSize, search },
  });

  const rows = useMemo(() => (data ? data.companies.records : []), [data]);
  const totalRows = useMemo(() => (data ? data.companies.totalRecords : 0), [
    data,
  ]);

  const columns = [
    { name: "", render: renderEditCompany },
    { name: "ID", field: "id" },
    { name: "Name", field: "name" },
    { name: "Contracts", render: renderContractUrls },
    { name: ".", render: renderDeleteCompany },
  ];

  if (error) return <>Error! {error.message}</>;

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult!,
            });
          },
        }}
        topBarExtras={renderTopBarExtras()}
      />

      <DeleteCompanyDialog
        companyId={companyIdToDelete}
        handleClose={() => setCompanyIdToDelete(null)}
        refetch={refetch}
      />
    </>
  );

  function renderContractUrls(company: Company) {
    return <ContractPopper contracts={company.contracts} />;
  }

  function renderEditCompany(company: Company) {
    return (
      <Button
        variant="contained"
        component={Link}
        to={`/companies/${company.id}`}
      >
        <EditIcon />
      </Button>
    );
  }

  function renderDeleteCompany(company: Company) {
    return (
      <IconButton onClick={() => setCompanyIdToDelete(company.id)}>
        <DeleteIcon />
      </IconButton>
    );
  }

  function renderTopBarExtras() {
    return (
      <Button
        style={{ backgroundColor: "white", color: "#c8a063" }}
        variant="contained"
        component={Link}
        to="/companies/new"
        startIcon={<AddIcon />}
      >
        New
      </Button>
    );
  }

  function updateUrl() {
    const urlParams = { page: page + 1, search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }
}

type CompanyQueryResult = {
  companies: {
    totalRecords: number;
    records: Company[];
  };
};

type CompanyQueryInput = {
  offset: number;
  limit: number;
  search: string | null;
};

const GET_COMPANIES = gql`
  query GetCompaniesQuery($offset: Int!, $limit: Int!, $search: String) {
    companies(offset: $offset, limit: $limit, search: $search) {
      totalRecords
      records {
        id
        name
        contracts {
          url
          name
        }
      }
    }
  }
`;
