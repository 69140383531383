import { LinearProgress, Paper, Stack } from "@mui/material";
import Button from "../../../components/Button2";
import { addMonths, format, startOfMonth } from "date-fns";
import { gql, useLazyQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const lastMonth = startOfMonth(addMonths(new Date(), -1));

export default function ExportVenues() {
  const [date, setDate] = useState<Date>(lastMonth);

  const variables = useMemo(
    () => ({
      month: format(date, "yyyy-MM-dd"),
    }),
    [date]
  );

  const [getVenueDataLink, { loading, error }] = useLazyQuery<
    GetVenueDataLinkResponse,
    GetVenueDataLinkInput
  >(GET_VENUE_DATA_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (data) =>
      window.open(data.exportVenueDataToCloudStorage, "_blank"),
  });

  return (
    <Paper style={{ padding: "1rem" }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <DatePicker
          label="Month and Year"
          value={date}
          onChange={(event) => {
            if (!event) return;
            setDate(event);
          }}
          views={["year", "month"]}
          openTo="month"
        />
        <Button
          onClick={() =>
            getVenueDataLink({
              variables,
            })
          }
          disabled={loading}
          fullWidth
          variant="contained"
        >
          download venue data
        </Button>
      </Stack>
      {loading && <LinearProgress />}
      {error && <h1>Error: {error}</h1>}
    </Paper>
  );
}

type GetVenueDataLinkInput = { month: string };
type GetVenueDataLinkResponse = { exportVenueDataToCloudStorage: string };
const GET_VENUE_DATA_LINK = gql`
  query GetVenueData($month: String!) {
    exportVenueDataToCloudStorage(month: $month)
  }
`;
