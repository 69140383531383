export default function getDates (startDate: Date, endDate: Date) {
    const dates = []
    let currentDate = startDate
    const addDays = function (this: Date, days:any) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }