import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SendIcon from "@mui/icons-material/Send";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Notification, {
  NotificationData,
} from "../../../../components/Notification";

function ActionButtons(props: {
  booking: { id: string; status: string; paymentStatus: string; email: string };
}) {
  const { id: bookingId, status, paymentStatus, email } = props.booking;

  const [menuAnchorElement, setMenuAnchorElement] = useState<null | Element>(
    null
  );
  const [emailToResend, setEmailToResend] = useState<null | EmailType>(null);
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );
  const [resendCustomerEmail] = useMutation(RESEND_CUSTOMER_EMAIL);

  return (
    <>
      <Grid container spacing={1}>
        {status !== "CREATED" ? (
          <Grid item xs={12}>
            <Button
              size={"small"}
              variant="contained"
              startIcon={<SendIcon />}
              endIcon={<ArrowDropDownIcon />}
              onClick={(event: { currentTarget: Element }) => {
                setMenuAnchorElement(event.currentTarget);
              }}
            >
              Resend Email
            </Button>
          </Grid>
        ) : null}
        <Menu
          open={Boolean(menuAnchorElement)}
          anchorEl={menuAnchorElement}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => setMenuAnchorElement(null)}
        >
          {status === "REQUESTED" ? (
            <MenuItem
              onClick={() => setEmailToResend("CUSTOMER_REQUEST_RECEIVED")}
            >
              Resend Request Received Email
            </MenuItem>
          ) : null}
          {status === "REJECTED" ? (
            <MenuItem onClick={() => setEmailToResend("CUSTOMER_REJECTION")}>
              Resend Rejection Email
            </MenuItem>
          ) : null}
          {status === "CANCELLED" ? (
            <MenuItem onClick={() => setEmailToResend("CUSTOMER_CANCELLATION")}>
              Resend Cancellation Email
            </MenuItem>
          ) : null}
          {status === "CONFIRMED" && paymentStatus !== "REFUNDED" ? (
            <MenuItem onClick={() => setEmailToResend("CUSTOMER_CONFIRMATION")}>
              Resend Confirmation Email
            </MenuItem>
          ) : null}
          {paymentStatus === "REFUNDED" &&
          status !== "CANCELLED" &&
          status !== "REJECTED" ? (
            <MenuItem
              onClick={() => setEmailToResend("CUSTOMER_PAYMENT_REFUNDED")}
            >
              Resend Payment Refunded Email
            </MenuItem>
          ) : null}
        </Menu>
      </Grid>
      <ResendDialog />
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function closeDialog() {
    setEmailToResend(null);
  }

  function ResendDialog() {
    if (emailToResend === null) return <></>;

    setMenuAnchorElement(null);

    return (
      <Dialog open={emailToResend !== null} onClose={closeDialog}>
        <DialogTitle>Resend Email</DialogTitle>
        <DialogContent>
          Are you sure you want to resend the <b>{EmailNames[emailToResend]}</b>{" "}
          to <b>{email}</b>?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button color="primary" onClick={() => resendEmail(emailToResend)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function resendEmail(emailType: EmailType) {
    resendCustomerEmail({
      variables: { input: { bookingId, emailType } },
    })
      .then(() =>
        setNotification({
          message: `${EmailNames[emailType]} was successfully sent again to ${email}`,
          severity: "success",
        })
      )
      .catch((reason) => {
        setNotification({
          message: `Resending ${EmailNames[emailType]} failed! ${reason}`,
          severity: "error",
        });
      });

    closeDialog();
  }
}

export default ActionButtons;

const RESEND_CUSTOMER_EMAIL = gql`
  mutation ResendCustomerEmail($input: ResendCustomerEmailInput!) {
    resendCustomerEmail(input: $input) {
      success
    }
  }
`;

const EmailNames: Record<EmailType, string> = {
  CUSTOMER_REQUEST_RECEIVED: "Request Received Email",
  CUSTOMER_CONFIRMATION: "Confirmation Email",
  CUSTOMER_REJECTION: "Rejection Email",
  CUSTOMER_PAYMENT_REFUNDED: "Payment Refunded Email",
  CUSTOMER_CANCELLATION: "Cancellation Email",
};

type EmailType =
  | "CUSTOMER_REQUEST_RECEIVED"
  | "CUSTOMER_CONFIRMATION"
  | "CUSTOMER_REJECTION"
  | "CUSTOMER_PAYMENT_REFUNDED"
  | "CUSTOMER_CANCELLATION";
