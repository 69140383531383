import { useNavigate } from "react-router-dom";
import { CompanyForm } from "../components/CompanyForm";

export function NewCompany() {
  const navigate = useNavigate();
  const onSaveCallback = (companyId: string) => {
    navigate(`/companies/${companyId}`);
  };

  return <CompanyForm onSaveCallback={onSaveCallback} />;
}
