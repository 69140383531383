import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import React, { useState, Dispatch, SetStateAction } from "react";

import DataTabs from "../../../components/DataTabs";
import Basic from "./Basic";
import ActivityReservations from "./ActivityReservations";
import Products from "./Products";
import QuestionAnswers from "./QuestionAnswers";
import { ItemType } from "./ActivityReservations/Items/Item";
import BookingEditHistories from "./BookingEditHistories";
import PaidOptionForm from "./PaidOptionForm";

export type NewPlanType = {
  id: string;
  name: string;
  venue?: {
    id: string;
    name: string;
  };
  activityName: string;
};

export type SeatOptionType = {
  id: string;
  title: string;
  price: number;
};

export type ViewBookingContextValue = {
  newPlan: NewPlanType | null;
  setNewPlan: Dispatch<SetStateAction<any>>;
  setActivityId: Dispatch<SetStateAction<any>>;
  activityId: string | null;
  setSeatOption: Dispatch<SetStateAction<any>>;
  seatOption: SeatOptionType | null;
  setPriceTypes: Dispatch<SetStateAction<any>>;
  priceTypes: ItemType[] | null;
  setBookingPrice: Dispatch<SetStateAction<any>>;
  bookingPrice: number | null;
  clearData: () => void;
  productPrice: number;
};

export const ViewBookingContext = React.createContext<ViewBookingContextValue>({
  newPlan: null,
  setNewPlan: () => {},
  activityId: null,
  setActivityId: () => {},
  seatOption: null,
  setSeatOption: () => {},
  priceTypes: null,
  setPriceTypes: () => {},
  bookingPrice: null,
  setBookingPrice: () => {},
  clearData: () => {},
  productPrice: 0,
});

function ViewBooking() {
  const { id: bookingId } = useParams<{
    id: string;
    paymentStatus: string;
  }>();
  const [newPlan, setNewPlan] = useState(null);
  const [activityId, setActivityId] = useState(null);
  const [seatOption, setSeatOption] = useState(null);
  const [priceTypes, setPriceTypes] = useState(null);
  const [bookingPrice, setBookingPrice] = useState<number | null>(null);

  const { loading, error, data, refetch: refetchBooking } = useQuery(
    GET_BOOKING,
    {
      variables: { id: bookingId },
    }
  );

  function clearData() {
    setActivityId(null);
    setNewPlan(null);
    setSeatOption(null);
    setPriceTypes(null);
    setBookingPrice(null);
  }

  if (loading) return <>Loading...</>;
  if (error) return <>Error! {error.message}</>;

  const tabs = [
    {
      title: "Basic",
      content: <Basic booking={data.booking} refetchBooking={refetchBooking} />,
    },
    {
      title: "Activities",
      content: (
        <ActivityReservations
          bookingId={data.booking.id}
          paymentStatus={data.booking.paymentStatus}
          paymentMethod={data.booking.paymentIntent.method}
          refetchBooking={refetchBooking}
        />
      ),
    },
    {
      title: "Paid Option",
      content: <PaidOptionForm />,
    },
    {
      title: "Edit Histories",
      content: <BookingEditHistories bookingId={data.booking.id} />,
    },
  ];

  if (data.bookingPlanQuestionAnswers.length > 0)
    tabs.push({
      title: "Question Answers",
      content: <QuestionAnswers data={data.bookingPlanQuestionAnswers} />,
    });
  if (data.booking.productOrderId)
    tabs.push({
      title: "Products",
      content: <Products bookingId={bookingId!} />,
    });

  return (
    <ViewBookingContext.Provider
      value={{
        newPlan,
        setNewPlan,
        activityId,
        setActivityId,
        seatOption,
        setSeatOption,
        priceTypes,
        setPriceTypes,
        bookingPrice,
        setBookingPrice,
        clearData,
        productPrice: data?.getBookingProductsPrice?.price ?? 0,
      }}
    >
      <DataTabs tabs={tabs} />
    </ViewBookingContext.Provider>
  );
}

export function useViewBookingContext() {
  return React.useContext(ViewBookingContext);
}

export const GET_BOOKING = gql`
  query BookingQuery($id: ID!) {
    booking(id: $id) {
      id
      status
      paymentStatus
      familyName
      givenName
      familyNameFurigana
      givenNameFurigana
      email
      phoneNumber
      reservationDatetime
      createdAt
      confirmedAt
      rejectedAt
      paidAt
      refundedAt
      paymentAmount
      paymentCurrency
      paymentIntentId
      cancelledAt
      cancelledBy
      cancellationReason
      cancellationFee
      noShow
      notes
      plan {
        id
        name
        basePrice
        mainVenue {
          id
          name
          nameFurigana
          description
          phoneNumber
          email
          notificationEmails
          numberOfSeats
          businessHours
          holidays
          smokingAllowance
          acceptedPaymentMethods
          dressCode
          limitedEntryForChildren
          babyChair
          kidChair
          wheelChairAccess
          breastFeedingRoom
          parking
          remarks
          internalRemarks
          postcode
          location {
            address
            googleMapsUrl
            googleMapsEmbedUrl
            directions
            nearestStations
          }
        }
      }
      paymentIntent {
        method
      }
      productOrderId
    }
    bookingPlanQuestionAnswers(bookingId: $id) {
      question
      answer
      imageUrl
    }
    getBookingPriceTypePrice(bookingId: $id) {
      price
    }
    getSeatOptionPrice(bookingId: $id) {
      price
    }
    getBookingProductsPrice(bookingId: $id) {
      price
    }
  }
`;

export default ViewBooking;
