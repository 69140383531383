import React,{useEffect} from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { AccessPermission } from "./types";

export type GlobalContextValue = {
  isLoggedIn: boolean;
  usersEditable: boolean;
  accessPermissions: AccessPermission[];
  setIsLoggedIn: (value: boolean) => void;
  setUsersEditable: (value: boolean) => void;
  setAccessPermissions:(value: AccessPermission[]) => void;
};

export const GlobalContext = React.createContext<GlobalContextValue>({
  isLoggedIn: false,
  usersEditable: false,
  accessPermissions: [],
  setIsLoggedIn: () => {},
  setUsersEditable: () => {},
  setAccessPermissions: () => {},
});

export function GlobalContextProvider({ children }: any) {
  const user = localStorage.getItem("user")
  const [isLoggedIn, setIsLoggedIn] = React.useState(user ? true : false);
  const [accessPermissions, setAccessPermissions] = React.useState(
    user ? JSON.parse(user!).accessPermissions : []
  );

  const [usersEditable, setUsersEditable] = React.useState(false);
  const [getAccessPermisstions, {data }] = useLazyQuery(GET_USER_ACCESS_PERMISSIONS);

  useEffect(() => {
    if(user) {
      getAccessPermisstions({ variables: { id:JSON.parse(user).id } })
    }
  }, [])
  
  useEffect(() => {
    if(data?.user && user) {
      localStorage.setItem("user",JSON.stringify({
        ...JSON.parse(user!),
        accessPermissions:data?.user?.accessPermissions
      }))
      setAccessPermissions(data?.user?.accessPermissions)
    }
  },[data?.user])

  return (
    <GlobalContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        usersEditable,
        setUsersEditable,
        setAccessPermissions,
        accessPermissions
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  return React.useContext(GlobalContext);
}

const GET_USER_ACCESS_PERMISSIONS = gql`
  query BackofficeUserAccessPermissionQuery($id: ID!) {
    user(id: $id) {
      accessPermissions{
        name
        path
      }
    }
  }
`;
