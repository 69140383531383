import Grid from "@mui/material/Grid";

import Answer, { AnswerType } from "./Answer";

function Answers(props: { answers: AnswerType[] }) {
  const answers = props.answers;

  return (
    <Grid container spacing={3}>
      {answers.map((item: AnswerType, index: number) => (
        <Grid key={index} item xs={12}>
          <Answer data={item} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Answers;
