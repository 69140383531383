import { makeStyles } from "@mui/styles";

export default makeStyles({
  header_option: {
    display: "flex",
    alignItems: "center",
    margin: 0,
  },
  header_option_title: {
    marginBottom: 0,
    marginTop: 0,
  },
  chid_option_container: {
    marginBottom: "16px",
    marginLeft: -12,
    marginTop: -5,
  },
});
