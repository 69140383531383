import { makeStyles } from "@mui/styles";

export default makeStyles({
  search_box: {
    display: "flex",
    alignItems: "center",
    paddingTop: "5px",
  },
  activities_info_box: {
    width: "100%",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
  },
});
