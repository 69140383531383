import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import TextField from "../../../components/TextField";

export default function NewCollection() {
  const { showNotification } = useNotifications();

  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);

  const [createCollection] = useMutation(CREATE_COLLECTION);

  if (id) return <Navigate to={`/plans/collections/${id}`} />;

  return (
    <>
      <Button
        style={{ marginBottom: "15px" }}
        component={Link}
        to={`/plans/collections`}
      >
        Back to list
      </Button>
      <Grid container item xs={12} md={6}>
        <Card style={{ width: "100%" }}>
          <CardHeader title="New Collection" />
          <CardContent>
            <TextField
              style={{ marginTop: "0px" }}
              label="Name"
              onChange={(e: any) => setName(e.target.value)}
            />
            <TextField
              label="Slug"
              onChange={(e: any) => setSlug(e.target.value)}
            />
            <Button
              style={{ marginTop: "20px" }}
              onClick={handleCreateCollection}
            >
              Save
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </>
  );

  function handleCreateCollection() {
    if (!name) {
      showNotification({ message: "Please fill in a name", severity: "error" });
      return;
    }
    if (!slug) {
      showNotification({ message: "Please fill in a slug", severity: "error" });
      return;
    }

    createCollection({ variables: { name: name, slug: slug } })
      .then((result) => {
        showNotification({
          message: "Collection Created",
          severity: "success",
        });
        setId(result.data.createPlanCollection.id);
      })
      .catch((error) =>
        showNotification({
          message: `Creating Collection Failed!: ${error.message}`,
          severity: "error",
        })
      );
  }
}

const CREATE_COLLECTION = gql`
  mutation CreatePlanCollectionMutation($name: String!, $slug: String!) {
    createPlanCollection(input: { name: $name, slug: $slug }) {
      id
    }
  }
`;
