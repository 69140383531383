import { useState } from "react";
import { NotificationButton } from "../NotificationButton";
import { Stack } from "./Stack";
import { RowProps } from "./Row";
import { ClickAwayListener, Popper } from "@mui/material";

export function NotificationStack(props: NotificationStackProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <NotificationButton
          numNotifications={props.rows.length}
          active={open}
          handleClick={handleClick}
        />
        <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
          <Stack {...props} />
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

type NotificationStackProps = {
  header: string;
  rows: RowProps[];
};
