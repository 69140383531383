import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./App";

//force deploy

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn:
      "https://e39cd7dffe3341b1b12edf3221a31183@o1047916.ingest.sentry.io/6035276",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}


ReactDOM.render(<App />, document.getElementById("root"));
