import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import TextField from "../../../../../components/TextField";

function Answer(props: { data: AnswerType }) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              readOnly={true}
              label={"Question"}
              value={props.data.question}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              readOnly={true}
              label={"Answer"}
              value={props.data.answer}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Answer;

export type AnswerType = {
  question: string;
  answer: string;
};
