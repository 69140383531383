import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import Button from "../../../components/Button2";
import { useGlobalContext } from "../../../GlobalContext";
import { Partner, BackofficeUser } from "../../../types";
import EditPartnerForm from "./form";

export default function EditPartner() {
  const id = useParams<{ id: string }>().id!;
  const { usersEditable } = useGlobalContext();

  const {
    loading: partnerLoading,
    error: partnerError,
    data: partnerData,
  } = useQuery<GetPartnerResponse, GetPartnerInput>(GET_PARTNER, {
    variables: { id },
  });
  const partner = useMemo(() => partnerData?.partner, [partnerData]);

  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = useQuery<GetUserOptionsResponse>(GET_USER_OPTIONS, {
    skip: !usersEditable,
  });

  const userOptions = useMemo(
    () =>
      usersData?.users.records.map((user) => ({
        id: user.id,
        label: user.username,
      })) ?? [],
    [usersData]
  );

  [partnerError, usersError].forEach((error) => {
    if (error) return <>Error! {error.message}</>;
  });

  return (
    <>
      <div>
        <Button
          style={{ marginBottom: "15px" }}
          component={Link}
          to={`/partners`}
        >
          Back to list
        </Button>
      </div>
      {partnerLoading || usersLoading || !partner ? (
        <CircularProgress />
      ) : (
        <EditPartnerForm partner={partner} userOptions={userOptions} />
      )}
    </>
  );
}

type GetPartnerInput = Pick<Partner, "id">;
type GetPartnerResponse = {
  partner: Partner;
};
const GET_PARTNER = gql`
  query PartnerEditQuery($id: ID!) {
    partner(id: $id) {
      id
      createdAt
      lastUpdatedAt
      name
      email
      lastLoggedIn
      connectedToAllVenues
      venues {
        id
        name
      }
      user {
        id
        username
      }
    }
  }
`;

type GetUserOptionsResponse = {
  users: {
    records: BackofficeUser[];
  };
};
const GET_USER_OPTIONS = gql`
  query UserOptions {
    users {
      records {
        id
        username
      }
    }
  }
`;
