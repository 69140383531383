import { useState } from "react";
import { Button, IconButton, Popover, Stack, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { DateField } from "@mui/x-date-pickers";
import { parse } from "date-fns";
import Calendar from "./Calender";

export type DateRangePickerProps = {
  from: string | null;
  setFrom: React.Dispatch<React.SetStateAction<string | null>>;
  until: string | null;
  setUntil: React.Dispatch<React.SetStateAction<string | null>>;
  fromLabel?: string;
  untilLabel?: string;
};

export default function DateRangePicker(props: DateRangePickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = () => {
    const el = document.getElementById("from_date");
    if (el) setAnchorEl(el);
  };
  const openPopover = Boolean(anchorEl);

  const fromDate = !!props.from
    ? parse(props.from, "yyyy-MM-dd", new Date())
    : null;

  const untilDate = !!props.until
    ? parse(props.until, "yyyy-MM-dd", new Date())
    : null;

  return (
    <>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems={"center"}
        style={{ width: "320px" }}
      >
        <DateField
          style={{ flex: 1 }}
          onClick={handleClick}
          id="from_date"
          label={props.fromLabel ?? "From Date"}
          value={fromDate}
          slots={{ textField: TextField, clearButton: Button }}
          slotProps={{
            textField: () => ({
              size: "small",
              InputProps: {
                sx: { paddingRight: 0 },
                endAdornment: props.from && (
                  <IconButton
                    onClick={() => {
                      props.setFrom(null);
                      props.setUntil(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              },
            }),
          }}
        />
        <span>-</span>
        <DateField
          style={{ flex: 1 }}
          label={props.untilLabel ?? "Until Date"}
          value={untilDate}
          onClick={handleClick}
          slots={{ textField: TextField }}
          slotProps={{
            textField: () => ({
              size: "small",
              InputProps: {
                sx: { paddingRight: 0 },
                endAdornment: props.until && (
                  <IconButton
                    onClick={() => {
                      props.setFrom(null);
                      props.setUntil(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              },
            }),
          }}
        />
      </Stack>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Calendar
          from={fromDate}
          setFrom={props.setFrom}
          until={untilDate}
          setUntil={props.setUntil}
          closePopover={() => setAnchorEl(null)}
        />
      </Popover>
    </>
  );
}
