import { gql, useQuery } from "@apollo/client";
import { Card, CardContent } from "@mui/material";
import Select from "../../../../components/Select";
import TextField from "../../../../components/TextField";

export default function BasicInfo1(props: BasicInfo1Props) {
  const { data, loading, error } = useQuery(GET_LOCATION_AREAS, {
    variables: { type: "area" },
  });
  if (error) return <>{error.message}</>;

  return (
    <Card>
      <CardContent>
        <TextField
          required
          label="Name"
          value={props.name.value}
          onChange={(e: any) => {
            props.name.set(e.target.value);
          }}
        />
        <TextField
          required
          label="Timezone"
          value={props.timezone.value}
          onChange={(e: any) => {
            props.timezone.set(e.target.value);
          }}
        />
        <TextField
          label="Area name"
          value={props.areaName.value || ""}
          onChange={(e: any) => {
            props.areaName.set(e.target.value);
          }}
        />
        {loading ? (
          "loading..."
        ) : (
          <Select
            required={true}
            style={{ marginTop: "10px" }}
            label="Location"
            value={props.location.value}
            options={data.locations.records.map((l: any) => ({
              id: l.id,
              name: `${l?.nameEn} (${l?.nameJa}) - ${l?.parent?.nameEn} (${l?.parent?.nameJa})`,
            }))}
            onChange={(v) => props.location.set(v)}
          />
        )}
        <TextField
          multiline
          label="Description"
          value={props.description.value || ""}
          onChange={(e: any) => {
            props.description.set(e.target.value);
          }}
        />
      </CardContent>
    </Card>
  );
}

const GET_LOCATION_AREAS = gql`
  query GetLocationAreas($type: LocationType!) {
    locations(type: $type) {
      totalRecords
      records {
        id
        nameEn
        nameJa
        parent {
          nameEn
          nameJa
        }
      }
    }
  }
`;

interface BasicInfo1Props {
  name: { value: string; set: (value: string) => void };
  timezone: { value: string; set: (value: string) => void };
  areaName: { value: string; set: (value: string) => void };
  location: { value: string; set: (value: string) => void };
  description: { value: string; set: (value: string) => void };
}
