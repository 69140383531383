import { gql, useMutation } from "@apollo/client";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { CoursePhoto, EditStatus } from "../../../../../../types";
import { MediaRenderer } from "../components/renderers";
import { CardProps, GetOriginalValueProps } from "../../types";

type CoursePhotoCardProps = Partial<CardProps> & {
  planId: string;
  activityId: string;
  fieldId: string;
  editStatus: EditStatus;
  src: string;
  photoIndex: number;
  coursePhotos: CoursePhoto[];
  setCoursePhotos: React.Dispatch<React.SetStateAction<CoursePhoto[]>>;
};

const CoursePhotoCard = forwardRef((props: CoursePhotoCardProps, ref) => {
  // TODO: add image uploader to component that uses setPhotoSrc
  // eslint-disable-next-line
  const [photoSrc, _setPhotoSrc] = useState(props.src);

  const [editMutation] = useMutation<SavePhotosResponse, SavePhotosInput>(
    EDIT_PHOTO_MUTATION
  );

  const [deleteMutation] = useMutation<DeletePhotoResponse, DeletePhotoInput>(
    DELETE_PHOTO_MUTATION
  );

  const persistChanges = async () => {
    if (props.editStatus === EditStatus.REMOVED) {
      const input: DeletePhotoInput["input"] = {
        id: props.fieldId,
      };

      return deleteMutation({ variables: { input } }).then((res) => {
        props.setCoursePhotos((coursePhotos) =>
          coursePhotos.filter(
            (photo) => photo.id !== res.data?.deleteCoursePhoto.coursePhoto.id
          )
        );
      });
    } else {
      const input: SavePhotosInput["input"] = {
        coursePhoto: {
          id: props.fieldId,
          activityId: props.activityId,
          photoIndex: props.coursePhotos.length,
          src: photoSrc,
        },
      };

      return await editMutation({ variables: { input } }).then((res) => {
        const newCoursePhoto = res.data?.saveCoursePhoto.coursePhoto;
        if (newCoursePhoto)
          props.setCoursePhotos((coursePhotos) => [
            ...coursePhotos,
            newCoursePhoto,
          ]);
      });
    }
  };

  const getNewValue = () => ({ photoSrc });

  useImperativeHandle(ref, () => ({
    persistChanges,
    getNewValue,
  }));

  return (
    <MediaRenderer
      mediaUrl={photoSrc}
      style={{
        display: "inline-block",
        height: "200px",
      }}
    />
  );
});

const getOriginalValue = ({ data, fieldId }: GetOriginalValueProps) =>
  data.activities[0].coursePhotos?.find(
    (photo: CoursePhoto) => photo.id === fieldId
  );

const courseMenuPhotoCard = {
  Content: CoursePhotoCard,
  getOriginalValue,
};
export default courseMenuPhotoCard;

const EDIT_PHOTO_MUTATION = gql`
  mutation SaveCoursePhotoMutation($input: SaveCoursePhotoInput!) {
    saveCoursePhoto(input: $input) {
      coursePhoto {
        id
      }
      error
    }
  }
`;

type SavePhotosResponse = {
  saveCoursePhoto: {
    coursePhoto: CoursePhoto;
    error: string;
  };
};

type SavePhotosInput = {
  input: {
    coursePhoto: CoursePhoto;
  };
};

const DELETE_PHOTO_MUTATION = gql`
  mutation DeleteCoursePhotoMutation($input: DeleteCoursePhotoInput!) {
    deleteCoursePhoto(input: $input) {
      coursePhoto {
        id
      }
      error
    }
  }
`;

type DeletePhotoResponse = {
  deleteCoursePhoto: {
    coursePhoto: CoursePhoto;
    error: string;
  };
};

type DeletePhotoInput = {
  input: {
    id: string;
  };
};
