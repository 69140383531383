import { gql } from "@apollo/client";
import { Tooltip } from "@mui/material";
import { format } from "date-fns-tz";
import { useMemo } from "react";
import { DownloadPdf } from "./DownloadPDF";

export function DownloadPaymentNotice({
  venue,
  date,
  hasPaidReservations,
}: DownloadPaymentNoticeProps) {
  const noCompany = venue.company === null;
  const noBankAccount = venue.company?.bankAccount === undefined;

  const variables: GetPaymentNoticeInput = useMemo(
    () => ({
      month: format(date!, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", {
        timeZone: "GMT",
      }),
      venueId: venue.id,
    }),
    [date, venue.id]
  );

  return (
    <Tooltip
      title={
        noCompany
          ? "No company is assigned to this venue"
          : noBankAccount
          ? "No bankaccount is assigned to this company"
          : !hasPaidReservations
          ? "No paid reservations"
          : ""
      }
    >
      <span>
        <DownloadPdf
          venue={venue}
          date={date}
          pdfType="お支払通知書"
          query={GET_PAYMENT_NOTICE}
          variables={variables}
          buttonProps={{
            disabled: noCompany || noBankAccount || !hasPaidReservations,
          }}
        >
          Download Payment Notice PDF
        </DownloadPdf>
      </span>
    </Tooltip>
  );
}

type DownloadPaymentNoticeProps = {
  venue: {
    id: string;
    name: string;
    company?: {
      id: string;
      bankAccount?: {
        id: string;
      };
    };
  };
  date: Date;
  hasPaidReservations: boolean;
};

const GET_PAYMENT_NOTICE = gql`
  query GetPaymentNotice($venueId: String!, $month: DateTime!) {
    fetchOrGeneratePaymentNoticePdf(
      input: { venueId: $venueId, month: $month, forceOverwrite: true }
    ) {
      pdfData
      error
    }
  }
`;

type GetPaymentNoticeInput = {
  venueId: string;
  month: string;
  forceOverwrite?: Boolean;
};
