import React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { PlanFAQ } from "../../../../types";
import { Grid, Button } from "@mui/material";
import FAQItem from "./FAQItem";
import { v4 as uuid } from "uuid";
import { useNotifications } from "../../../../components/Notification";

interface Props {}

const FAQsList: React.FC<Props> = () => {
  const { showNotification } = useNotifications();
  const { id: planId } = useParams<{ id: string }>();
  const [savePlanFAQ] = useMutation(SAVE_PLAN_FAQ_MUTATION);
  const [deletePlanFAQ] = useMutation(DELETE_PLAN_FAQ_MUTATION);
  const { data, loading, error } = useQuery(PLAN_FAQS_QUERY, {
    variables: { id: planId },
    notifyOnNetworkStatusChange: true,
  });
  const faqs: PlanFAQ[] = React.useMemo(
    () => (!!data && !error ? data.plan.faqs : []),
    [data, error]
  );
  const [faqsState, setFAQsState] = React.useState(faqs || []);
  React.useEffect(() => {
    setFAQsState(faqs || []);
  }, [faqs]);
  const onAddFAQ = () => {
    let newPlanFAQs = [...faqsState];
    newPlanFAQs.push({
      id: uuid(),
      planId,
      question: "",
      answer: "",
      imageUrl: undefined,
      priority: faqsState.length,
    });
    setFAQsState(newPlanFAQs);
  };
  const onSaveFAQ = () => {
    Promise.all(
      faqsState.map((faq: PlanFAQ, priority: number) =>
        savePlanFAQ({
          variables: {
            input: {
              planFAQ: {
                id: faq.id,
                planId: faq.planId,
                question: faq.question,
                answer: faq.answer,
                priority,
                imageUrl: faq.imageUrl,
              },
            },
          },
        })
      )
    )
      .then(() => {
        showNotification({
          message: `FAQ saved successfully`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `Could not save FAQ. Please make sure to fill all necessary fields, ${err}`,
          severity: "error",
        });
      });
    Promise.all(
      faqs
        .filter(
          (faq: PlanFAQ) =>
            faqsState.filter((sfaq: PlanFAQ) => sfaq.id === faq.id).length === 0
        )
        .map((faq: PlanFAQ) =>
          deletePlanFAQ({
            variables: {
              input: {
                id: faq.id,
              },
            },
          })
        )
    );
  };
  const onChangeFAQ = (
    faq: PlanFAQ | null,
    beforeIndex: number,
    afterIndex?: number
  ) => {
    let newPlanFAQs = [...faqsState];
    if (faq === null) {
      newPlanFAQs.splice(beforeIndex, 1);
      newPlanFAQs.forEach(
        (newFAQ: PlanFAQ, index: number) =>
          (newPlanFAQs[index] = { ...newFAQ, priority: index })
      );
    } else if (
      faq !== null &&
      (beforeIndex === afterIndex || afterIndex === undefined)
    ) {
      faq.priority = beforeIndex;
      newPlanFAQs[beforeIndex] = faq;
    } else if (
      faq !== null &&
      beforeIndex !== afterIndex &&
      !!afterIndex !== undefined
    ) {
      const prevFAQ: any = newPlanFAQs[afterIndex!];
      newPlanFAQs[beforeIndex] = { ...prevFAQ, priority: beforeIndex };
      newPlanFAQs[afterIndex!] = { ...faq, priority: afterIndex! };
    }
    newPlanFAQs = newPlanFAQs.sort((a, b) =>
      a.priority > b.priority ? 1 : -1
    );
    setFAQsState(newPlanFAQs);
  };
  if (loading) return <>loading...</>;
  if (error) return <>failed to load plan questions</>;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              startIcon={<AddIcon />}
              color="secondary"
              style={{ color: "#c8a063", backgroundColor: "#ffffff" }}
              onClick={onAddFAQ}
            >
              Add new FAQ
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<SaveIcon />}
              style={{ backgroundColor: "#c8a063", color: "white" }}
              onClick={onSaveFAQ}
            >
              Save FAQs
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        You can set text embed url by using "+" like
        "https://oiwai.anny.gift+お祝い体験Anny"
      </Grid>
      {faqsState.map((faq: PlanFAQ, index: number) => (
        <Grid key={index} item xs={12} md={6}>
          <FAQItem
            index={index}
            faqsLength={faqsState.length}
            faq={faq}
            onChangeFAQ={onChangeFAQ}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const PLAN_FAQS_QUERY = gql`
  query PlanFAQQuery($id: ID!) {
    plan(id: $id) {
      id
      faqs {
        id
        question
        answer
        priority
        imageUrl
        planId
      }
    }
  }
`;

const SAVE_PLAN_FAQ_MUTATION = gql`
  mutation SavePlanFAQMutation($input: SavePlanFAQInput!) {
    savePlanFAQ(input: $input) {
      planFAQ {
        id
      }
    }
  }
`;

const DELETE_PLAN_FAQ_MUTATION = gql`
  mutation DeletePlanFAQMutation($input: DeletePlanFAQInput!) {
    deletePlanFAQ(input: $input) {
      result
    }
  }
`;

export default FAQsList;
