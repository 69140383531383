import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { Grid, LinearProgress } from "@mui/material";
import { Review } from "../../../../types";

export default function ReviewImagesTab() {
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useQuery(GET_REVIEW, {
    variables: { id },
  });

  const review: Review | null = data ? data.review : null;
  if (loading) return <LinearProgress />;
  if (error) return <>{error.message}</>;

  return (
    <Grid container spacing={3}>
      {review?.images?.map((i: any) => (
        <Grid item xs={6}>
          <img
            style={{ width: "100%", border: "1px solid black" }}
            src={i.imageUrl}
            alt=""
          />
        </Grid>
      ))}
    </Grid>
  );
}

const GET_REVIEW = gql`
  query ViewReviewQuery($id: String!) {
    review(id: $id) {
      id
      createdAt
      bookingId
      planId
      reviewAt
      content
      rating
      show
      images {
        imageUrl
      }
    }
  }
`;
