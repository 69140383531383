import { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";

import MediaUploader, { MediaType } from "../../../components/MediaUploader";
import TextField from "../../../components/TextField";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button2";
import TagsSelection from "../../../components/TagsSelection";
import { useNotifications } from "../../../components/Notification";
import Plans from "./plans";
import { Collection, Event, PlanCollectionImage } from "./types";

import "./form.css";
import Locations from "./locations";
import Prices from "./prices";

export default function Form(props: { collection: Collection }) {
  const { showNotification } = useNotifications();

  const id = props.collection.id;
  const [name, setName] = useState(props.collection.name);
  const [slug, setSlug] = useState(props.collection.slug);
  const [title, setTitle] = useState(props.collection.title);
  const [description, setDescription] = useState(props.collection.description);
  const [metaTitle, setMetaTitle] = useState(props.collection.metaTitle);
  const [listTitle, setListTitle] = useState(props.collection.listTitle);
  const [listTitleDescription, setListTitleDescription] = useState(
    props.collection.listTitleDescription
  );
  const [score, setScore] = useState(props.collection.score);
  const [showInHome, setShowInHome] = useState(props.collection.showInHome);
  // TODO: bannerSrc not needed anymore after completing change to use relation to plan collection image
  const [bannerSrc, setBannerSrc] = useState<string | null>(
    props.collection.bannerSrc
  );
  const [bannerText, setBannerText] = useState(props.collection.bannerText);
  const [tags, setTags] = useState(props.collection.tags);
  const [locations, setLocations] = useState(props.collection.locations);

  const [isHomeContent, setIsHomeContent] = useState<boolean>(
    props.collection.isHomeContent
  );
  const [homePriority, setHomePriority] = useState<number | null>(
    props.collection.homePriority
  );
  const [allTagsFilter, setAllTagsFilter] = useState<boolean>(
    props.collection.allTags
  );
  const [allLocationsFilter, setAllLocationsFilter] = useState<boolean>(
    props.collection.allLocations
  );
  const [planCollectionImageId, setPlanCollectionImageId] = useState<
    string | undefined
  >(props.collection.planCollectionImage?.id);
  const [showPricesFilter, setShowPricesFilter] = useState<boolean>(
    Boolean(
      props.collection.priceMinimum !== null &&
        props.collection.priceMaximum !== null
    )
  );
  const [priceMinimum, setPriceMinimum] = useState<number | null>(
    props.collection.priceMinimum
  );
  const [priceMaximum, setPriceMaximum] = useState<number | null>(
    props.collection.priceMaximum
  );

  const { data, loading, error } = useQuery<{
    planCollectionImages: { records: PlanCollectionImage[] };
  }>(PLAN_COLLECTION_IMAGES_QUERY, {
    fetchPolicy: "cache-first",
  });

  const [editCollection] = useMutation(EDIT_COLLECTION_MUTATION);

  if (error) return <>ERROR: {error.message}</>;

  return (
    <>
      <Grid style={{ marginBottom: "100px" }} container spacing={2}>
        <Grid item lg={6} sm={12}>
          <Card style={{ marginBottom: "20px" }}>
            <CardContent>
              {bannerSrc && (
                <MediaUploader
                  width={"200px"}
                  allowedTypes={[MediaType.Image]}
                  uploadPath={`celebrations/${id}/banner`}
                  media={bannerSrc}
                  onChangeFunction={(v) => {
                    setBannerSrc(v);
                    // on deleting the image, remove relation to plan collection image
                    if (v === null) setPlanCollectionImageId(undefined);
                  }}
                />
              )}
              {error && <>Error: {error}</>}
              {loading ? (
                <>Loading...</>
              ) : (
                !error &&
                data?.planCollectionImages?.records &&
                data?.planCollectionImages?.records?.length > 0 && (
                  <>
                    <InputLabel shrink htmlFor="bannerSrc">
                      Collection Image
                    </InputLabel>
                    <Select
                      id="bannerSrc"
                      value={bannerSrc}
                      style={{ width: "250px" }}
                      onChange={(e) => {
                        setBannerSrc(String(e.target.value));
                        const selectedImage = data?.planCollectionImages.records.find(
                          (o) => o.imageUrl === e.target.value
                        );
                        if (selectedImage)
                          setPlanCollectionImageId(selectedImage?.id);
                      }}
                    >
                      {data?.planCollectionImages?.records.map((v) => (
                        <MenuItem value={v.imageUrl} key={v.id}>
                          {v.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )
              )}
              <TextField
                label="banner text"
                value={bannerText}
                onChange={(e: Event) => setBannerText(e.target.value)}
              />
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <TextField label="id" disabled={true} value={id} />
              <TextField
                label="name"
                value={name}
                onChange={(e: Event) => setName(e.target.value)}
              />
              <TextField
                label="slug"
                value={slug}
                onChange={(e: Event) => setSlug(e.target.value)}
              />
              <TextField
                multiline={true}
                label="title"
                value={title}
                onChange={(e: Event) => setTitle(e.target.value)}
              />
              <TextField
                multiline={true}
                label="description"
                value={description}
                onChange={(e: Event) => setDescription(e.target.value)}
              />
              <TextField
                multiline={true}
                label="meta title"
                value={metaTitle}
                onChange={(e: Event) => setMetaTitle(e.target.value)}
              />
              <TextField
                multiline={true}
                label="list title"
                value={listTitle}
                onChange={(e: Event) => setListTitle(e.target.value)}
              />
              <TextField
                multiline={true}
                label="list title description"
                value={listTitleDescription}
                onChange={(e: Event) => setListTitleDescription(e.target.value)}
              />
              <TextField
                type="number"
                label="score"
                value={score}
                onChange={(event) => setScore(Number(event.target.value))}
              />
              <Checkbox
                style={{ marginTop: "13px" }}
                label="show in home"
                checked={showInHome}
                onChange={(v) => setShowInHome(v)}
              />
              <Checkbox
                style={{ marginTop: "13px" }}
                label="show in「プランの特徴」section of home"
                checked={isHomeContent}
                onChange={(v) => setIsHomeContent(v)}
              />
              <TextField
                type="number"
                label="priority in「プランの特徴」section of home"
                value={homePriority}
                onChange={(event) =>
                  setHomePriority(Number(event.target.value) || null)
                }
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Card style={{ marginBottom: "20px" }}>
            <CardHeader title="Tags" />
            <CardContent>
              <TagsSelection
                tags={tags}
                setTags={setTags}
                allTagsFilter={allTagsFilter}
                setAllTagsFilter={setAllTagsFilter}
              />
            </CardContent>
          </Card>
          <Card style={{ marginBottom: "20px" }}>
            <CardHeader title="Locations" />
            <CardContent>
              <Locations
                locations={locations}
                setLocations={setLocations}
                allLocationsFilter={allLocationsFilter}
                setAllLocationsFilter={setAllLocationsFilter}
              />
            </CardContent>
          </Card>
          <Card style={{ marginBottom: "20px" }}>
            <CardHeader title="Prices" />
            <CardContent>
              <Prices
                priceMinimum={priceMinimum}
                setPriceMinimum={setPriceMinimum}
                priceMaximum={priceMaximum}
                setPriceMaximum={setPriceMaximum}
                showPricesFilter={showPricesFilter}
                setShowPricesFilter={setShowPricesFilter}
              />
            </CardContent>
          </Card>
          <Plans
            tagIds={tags.map((t) => t.id)}
            locationIds={locations.map((l) => l.id)}
            allTags={allTagsFilter}
            allLocations={allLocationsFilter}
            priceMinimum={priceMinimum}
            priceMaximum={priceMaximum}
          />
        </Grid>
      </Grid>
      <div className="save-button">
        <Button onClick={handleSave}>Save</Button>
      </div>
    </>
  );

  function handleSave() {
    if (
      (priceMaximum === null && priceMinimum !== null) ||
      (priceMinimum === null && priceMaximum !== null)
    ) {
      showNotification({
        message: `If you set a price minimum or a price maximum, you must also set the other, `,
        severity: "error",
      });
      return;
    }
    editCollection({
      variables: {
        planCollectionId: id,
        name: name,
        slug: slug,
        title: title,
        metaTitle: metaTitle,
        listTitle: listTitle,
        listTitleDescription: listTitleDescription,
        bannerText: bannerText,
        bannerSrc: bannerSrc,
        description: description,
        showInHome: showInHome,
        score: score,
        tagIds: tags.map((t) => t.id),
        locationIds: locations.map((l) => l.id),
        isHomeContent,
        homePriority,
        allTags: allTagsFilter,
        allLocations: allLocationsFilter,
        priceMinimum,
        priceMaximum,
        planCollectionImageId,
      },
    })
      .then(() => {
        showNotification({
          message: "Plan collection saved",
          severity: "success",
        });
      })
      .catch(() => {
        if (isHomeContent && !homePriority) {
          showNotification({
            message: `Please input 'Priority in「プランの特徴」' if you checked 'Show in「プランの特徴」'`,
            severity: "error",
          });
        } else {
          showNotification({
            message: `Editing location failed!`,
            severity: "error",
          });
        }
      });
  }
}

const PLAN_COLLECTION_IMAGES_QUERY = gql`
  query PlanCollectionImagesQuery {
    planCollectionImages {
      records {
        id
        name
        imageUrl
      }
    }
  }
`;

const EDIT_COLLECTION_MUTATION = gql`
  mutation EditPlanCollection(
    $planCollectionId: ID!
    $name: String!
    $slug: String!
    $title: String
    $metaTitle: String
    $listTitle: String
    $listTitleDescription: String
    $bannerText: String
    $bannerSrc: String
    $description: String
    $showInHome: Boolean!
    $score: Int!
    $tagIds: [Int!]
    $locationIds: [String!]
    $isHomeContent: Boolean!
    $homePriority: Int
    $allTags: Boolean!
    $allLocations: Boolean!
    $priceMinimum: Int
    $priceMaximum: Int
    $planCollectionImageId: ID
  ) {
    editPlanCollection(
      input: {
        planCollectionId: $planCollectionId
        name: $name
        slug: $slug
        title: $title
        description: $description
        metaTitle: $metaTitle
        listTitle: $listTitle
        listTitleDescription: $listTitleDescription
        showInHome: $showInHome
        score: $score
        bannerText: $bannerText
        bannerSrc: $bannerSrc
        tagIds: $tagIds
        locationIds: $locationIds
        isHomeContent: $isHomeContent
        homePriority: $homePriority
        allTags: $allTags
        allLocations: $allLocations
        priceMinimum: $priceMinimum
        priceMaximum: $priceMaximum
        planCollectionImageId: $planCollectionImageId
      }
    )
  }
`;
