import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  drawerPaper: {
    background: "#c8a063",
    color: "#ffffff",
  },

  logo: {
    marginTop: "10px",
    textAlign: "center",
  },

  menuList: {
    width: "200px",
  },

  listItemIcon: {
    color: "#ffffff",
  },

  subMenuItem: {
    paddingLeft: "32px",
  },

  subMenuList: {
    paddingTop: "0px",
  },

  userMenuDiv: {
    position: "absolute",
    bottom: "20px",
    width: "100%",
  },
});

export default useStyles;
