import { useCallback, useMemo, useState } from "react";
import TextField from "../../../components/TextField";
import TextFieldList from "../../../components/TextFieldList";
import PageSection from "../../../components/PageSection";
import { Typography, Grid, Button, CircularProgress } from "@mui/material";
import { useNotifications } from "../../../components/Notification";
import Select from "../../../components/Select";
import { gql, useQuery } from "@apollo/client";
import {
  BookingSystem,
  VenueBasicInfo,
  VenueBasicInfoInput,
} from "../../../types";
import BookingSystemSelectList from "./components/BookingSystemSelectList";
import FeeFreePeriodPicker from "./components/FeeFreePeriodPicker";
import { zonedTimeToUtc } from "date-fns-tz";

function removeEmptyFields<T>(obj: T): T {
  const filteredEntries = Object.entries(obj).filter(([_, v]) => Boolean(v));
  return Object.fromEntries(filteredEntries) as T;
}

type BasicInfoFormProps = {
  venue?: VenueBasicInfo;
  onSubmit: (venue: VenueBasicInfoInput) => Promise<void>;
};

export default function BasicInfoForm(props: BasicInfoFormProps) {
  const { venue } = props;

  const NOTE_LENGTH = 1000;

  const { showNotification } = useNotifications();

  const [name, setName] = useState<string>(venue?.name || "");
  const [nameFurigana, setNameFurigana] = useState<string>(
    venue?.nameFurigana || ""
  );
  const [description, setDescription] = useState<string>(
    venue?.description || ""
  );
  const [ebicaStoreId, setEbicaStoreId] = useState<number | null>(
    venue?.ebicaStoreId || null
  );

  const [bookingSystems, setBookingSystems] = useState<BookingSystem[]>(
    venue?.bookingSystems || []
  );

  const [phoneNumber, setPhoneNumber] = useState<string>(
    venue?.phoneNumber || ""
  );

  const [email, setEmail] = useState<string>(venue?.email || "");
  const [notificationEmails, setNotificationEmails] = useState<string>(
    venue?.notificationEmails || ""
  );
  const [invoiceEmails, setInvoiceEmails] = useState<string>(
    venue?.invoiceEmails || ""
  );

  const [companyId, setCompanyId] = useState<string>(venue?.company?.id || "");

  const [address, setAddress] = useState<string>(
    venue?.location?.address || ""
  );
  const [postcode, setPostcode] = useState<string>(venue?.postcode || "");
  const [googleMapsUrl, setGoogleMapsUrl] = useState<string>(
    venue?.location?.googleMapsUrl || ""
  );
  const [googleMapsEmbedUrl, setGoogleMapsEmbedUrl] = useState<string>(
    venue?.location?.googleMapsEmbedUrl || ""
  );
  const [directions, setDirections] = useState<string>(
    venue?.location?.directions || ""
  );
  const [nearestStations, setNearestStations] = useState<string[]>(
    venue?.location?.nearestStations || []
  );
  const [notes, setNotes] = useState<string>(venue?.internalRemarks || "");

  const [numberOfSeats, setNumberOfSeats] = useState<string>(
    venue?.numberOfSeats || ""
  );
  const [businessHours, setBusinessHours] = useState<string>(
    venue?.businessHours || ""
  );

  const [holidays, setHolidays] = useState<string>(venue?.holidays || "");
  const [smokingAllowance, setSmokingAllowance] = useState<string>(
    venue?.smokingAllowance || ""
  );
  const [dressCode, setDressCode] = useState<string>(venue?.dressCode || "");
  const [babyChair, setBabyChair] = useState<string>(venue?.babyChair || "");
  const [kidChair, setKidChair] = useState<string>(venue?.kidChair || "");
  const [wheelChairAccess, setWheelChairAccess] = useState<string>(
    venue?.wheelChairAccess || ""
  );
  const [breastFeedingRoom, setBreastFeedRoom] = useState<string>(
    venue?.breastFeedingRoom || ""
  );
  const [parking, setParking] = useState<string>(venue?.parking || "");
  const [remarks, setRemarks] = useState<string>(venue?.remarks || "");

  const [
    feeFreePeriodStartDate,
    setFeeFreePeriodStartDate,
  ] = useState<Date | null>(
    venue?.feeFreePeriod
      ? zonedTimeToUtc(new Date(venue.feeFreePeriod.startDate), "UTC")
      : null
  );
  const [feeFreePeriodEndDate, setFeeFreePeriodEndDate] = useState<Date | null>(
    venue?.feeFreePeriod
      ? zonedTimeToUtc(new Date(venue.feeFreePeriod.endDate), "UTC")
      : null
  );

  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState<string>(
    venue?.acceptedPaymentMethods || ""
  );
  const [
    limitedEntryForChildren,
    setLimitedEntryForChildren,
  ] = useState<string>(venue?.limitedEntryForChildren || "");

  const [charactersLeft, setCharactersLeft] = useState<number>(
    NOTE_LENGTH - notes?.length
  );

  const isZenkakuKana = useCallback((s: string): boolean => {
    return !!s.match(/^[ァ-ヶ０-９0-9ー　 ]*$/);
  }, []);

  const isPostalCode = useCallback((s: string): boolean => {
    return !!s.match(/^[0-9]{3}-[0-9]{4}$/);
  }, []);

  const { data, loading, error } = useQuery(COMPANIES_QUERY);
  const companies: { id: string; name: string }[] = useMemo(
    () => data?.companies.records ?? [],
    [data]
  );

  if (loading) return <CircularProgress />;
  if (error) return <p>{error.message}</p>;

  return (
    <Grid container>
      <Grid item lg={6}>
        <PageSection>
          <Typography variant="h6">Basic</Typography>
          <TextField
            required
            label="Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
          />
          <TextField
            label="フリガナ"
            value={nameFurigana}
            onChange={(e: any) => {
              setNameFurigana(e.target.value);
            }}
          />
          {nameFurigana &&
            nameFurigana.length > 0 &&
            !isZenkakuKana(nameFurigana) && (
              <div style={{ color: "red" }}>Value is not カタカナ</div>
            )}
          <TextField
            required
            multiline
            label="Description"
            value={description}
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
          />
          <BookingSystemSelectList
            ebicaStoreId={ebicaStoreId}
            setEbicaStoreId={setEbicaStoreId}
            options={Object.values(BookingSystem)}
            values={bookingSystems}
            setValues={setBookingSystems}
          />
        </PageSection>
        <PageSection>
          <Typography variant="h6">Details</Typography>
          <TextField
            required
            label="Number of seats"
            value={numberOfSeats}
            onChange={(e: any) => {
              setNumberOfSeats(e.target.value);
            }}
          />
          <TextField
            required
            multiline
            label="Business hours"
            value={businessHours}
            onChange={(e: any) => {
              setBusinessHours(e.target.value);
            }}
          />
          <TextField
            required
            label="Holidays"
            value={holidays}
            onChange={(e: any) => {
              setHolidays(e.target.value);
            }}
          />
          <TextField
            required
            label="Smoking allowance"
            value={smokingAllowance}
            onChange={(e: any) => {
              setSmokingAllowance(e.target.value);
            }}
          />
          <TextField
            required
            label="Accepted payment methods"
            value={acceptedPaymentMethods}
            onChange={(e: any) => {
              setAcceptedPaymentMethods(e.target.value);
            }}
          />
          <TextField
            required
            multiline
            label="Dress code"
            value={dressCode}
            onChange={(e: any) => {
              setDressCode(e.target.value);
            }}
          />
          <TextField
            required
            multiline
            label="Limited entry for children"
            value={limitedEntryForChildren}
            onChange={(e: any) => {
              setLimitedEntryForChildren(e.target.value);
            }}
          />
          <TextField
            required
            multiline
            label="Wheel chair access"
            value={wheelChairAccess}
            onChange={(e: any) => {
              setWheelChairAccess(e.target.value);
            }}
          />
          <TextField
            required
            multiline
            label="Baby chair"
            value={babyChair}
            onChange={(e: any) => {
              setBabyChair(e.target.value);
            }}
          />
          <TextField
            required
            multiline
            label="Kid chair"
            value={kidChair}
            onChange={(e: any) => {
              setKidChair(e.target.value);
            }}
          />
          <TextField
            required
            label="Breastfeeding room"
            value={breastFeedingRoom}
            onChange={(e: any) => {
              setBreastFeedRoom(e.target.value);
            }}
          />
          <TextField
            required
            label="Parking"
            value={parking}
            onChange={(e: any) => {
              setParking(e.target.value);
            }}
          />
          <TextField
            multiline
            label="Remarks"
            value={remarks}
            onChange={(e: any) => {
              setRemarks(e.target.value);
            }}
          />
          <div
            style={{
              marginTop: "12px",
            }}
          >
            <FeeFreePeriodPicker
              startDate={feeFreePeriodStartDate}
              setStartDate={setFeeFreePeriodStartDate}
              endDate={feeFreePeriodEndDate}
              setEndDate={setFeeFreePeriodEndDate}
            />
          </div>
        </PageSection>
      </Grid>
      <Grid item lg={6}>
        <PageSection>
          <Typography variant="h6">Contact info</Typography>
          <TextField
            required
            label="Phone number"
            value={phoneNumber}
            onChange={(e: any) => {
              setPhoneNumber(e.target.value);
            }}
          />
          <TextField
            required
            label="Email"
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            label="Notification Emails"
            helperText="For notification emails you can set multiple emails, divided by a comma (,)"
            value={notificationEmails}
            onChange={(e: any) => {
              setNotificationEmails(e.target.value);
            }}
          />
          <TextField
            label="Invoice Emails"
            helperText="For invoice emails you can set multiple emails, divided by a comma (,)"
            value={invoiceEmails}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInvoiceEmails(event.target.value);
            }}
          />
          <Select
            label="Company"
            required
            style={{ marginTop: "12px" }}
            options={companies}
            value={companyId}
            onChange={(value: string) => {
              setCompanyId(value);
            }}
          />
        </PageSection>
        <PageSection>
          <Typography variant="h6">Location</Typography>
          <TextField
            required
            label="Address"
            value={address}
            onChange={(e: any) => {
              setAddress(e.target.value);
            }}
          />
          <TextField
            label="Postal code (ex: 000-0000)"
            value={postcode}
            onChange={(e: any) => {
              setPostcode(e.target.value);
            }}
            helperText={"NNN-NNNN format"}
          />
          {postcode && postcode.length > 0 && !isPostalCode(postcode) && (
            <div style={{ color: "red" }}>
              Format is wrong. It should be like "000-0000"
            </div>
          )}
          <TextField
            required
            multiline
            label="Directions"
            value={directions}
            onChange={(e: any) => {
              setDirections(e.target.value);
            }}
          />
          <TextFieldList
            label="Nearest stations"
            values={nearestStations}
            onChange={(values) => setNearestStations(values)}
          />
          <TextField
            required
            label="Google maps url"
            value={googleMapsUrl}
            onChange={(e: any) => {
              setGoogleMapsUrl(e.target.value);
            }}
          />
          <TextField
            required
            label="Google maps embed url"
            value={googleMapsEmbedUrl}
            onChange={(e: any) => {
              setGoogleMapsEmbedUrl(e.target.value);
            }}
          />
        </PageSection>
        <PageSection>
          <Typography variant="h6">Memo</Typography>
          <TextField
            placeholder="This text will appear nowhere on the storefront."
            multiline
            label="Internal remarks"
            value={notes}
            onChange={(e: any) => {
              setCharactersLeft(NOTE_LENGTH - e.target.value.length);
              setNotes(e.target.value);
            }}
          />
          <small>{charactersLeft} characters left.</small>
        </PageSection>
      </Grid>
      <PageSection lg={12}>
        <Button
          variant="outlined"
          disabled={
            (!!nameFurigana &&
              nameFurigana.length > 0 &&
              !isZenkakuKana(nameFurigana)) ||
            (!!postcode && postcode.length > 0 && !isPostalCode(postcode))
          }
          onClick={() => {
            props
              .onSubmit(
                removeEmptyFields({
                  id: props.venue?.id,
                  name,
                  nameFurigana,
                  description,
                  phoneNumber,
                  email,
                  notificationEmails,
                  invoiceEmails,
                  location: {
                    address,
                    googleMapsUrl,
                    googleMapsEmbedUrl,
                    directions,
                    nearestStations,
                  },
                  numberOfSeats,
                  businessHours,
                  holidays,
                  smokingAllowance,
                  acceptedPaymentMethods,
                  dressCode,
                  limitedEntryForChildren,
                  babyChair,
                  kidChair,
                  wheelChairAccess,
                  breastFeedingRoom,
                  parking,
                  remarks,
                  postcode,
                  internalRemarks: notes,
                  ebicaStoreId,
                  companyId,
                  bookingSystems,
                  ...(feeFreePeriodStartDate || feeFreePeriodEndDate
                    ? {
                        feeFreePeriod: {
                          startDate: feeFreePeriodStartDate,
                          endDate: feeFreePeriodEndDate,
                        },
                      }
                    : {}),
                }) as VenueBasicInfoInput
              )
              .then(() => {
                showNotification({
                  message: "Basic info saved successfully",
                  severity: "success",
                });
              })
              .catch((err: any) => {
                let message: string = `Could not save form. ${err.message}`;
                if (err.networkError)
                  message = `Could not save form. Please make sure to fill all required fields, ${
                    err.networkError.result.errors[0].extensions.code
                  } - ${err.networkError.result.errors[0].message.substring(
                    0,
                    501
                  )}`;
                showNotification({
                  message,
                  severity: "error",
                });
              });
          }}
        >
          Save
        </Button>
      </PageSection>
    </Grid>
  );
}

const COMPANIES_QUERY = gql`
  query CompaniesQuery {
    companies {
      records {
        id
        name
      }
    }
  }
`;
