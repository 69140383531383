import React from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Review } from "../../../../types";
import { Grid, Button } from "@mui/material";
import ReviewItem from "./ReviewItem";
import { format } from "date-fns";
import { useNotifications } from "../../../../components/Notification";

interface Props {}

const ReviewsList: React.FC<Props> = () => {
  const { showNotification } = useNotifications();
  const { id: planId } = useParams<{ id: string }>();
  const { data, loading, error, fetchMore } = useQuery(PLAN_REVIEWS_QUERY, {
    variables: { id: planId, limit: 30, offset: 0 },
    notifyOnNetworkStatusChange: true,
  });
  const reviews: Review[] = React.useMemo(
    () => (!!data && !error ? data.plan.reviews : []),
    [data, error]
  );
  const [reviewsState, setReviewsState] = React.useState(reviews || []);
  React.useEffect(() => {
    setReviewsState(reviews || []);
  }, [reviews]);
  const onAddReview = () => {
    let newReviews = [...reviewsState];
    let formattedCurrentDate = format(new Date(), "yyyy-MM-dd");
    newReviews.unshift({
      id: "",
      planId,
      content: "",
      reviewAt: formattedCurrentDate,
      rating: 0,
      show: true,
      createdAt: "",
    });
    setReviewsState(newReviews);
  };
  const onChangeReview = (review: Review | null, index: number) => {
    let newReviews = [...reviewsState];
    if (review === null) newReviews.splice(index, 1);
    if (review !== null) newReviews[index] = review;
    setReviewsState(newReviews);
  };
  if (loading) return <>loading...</>;
  if (error) return <>failed to load plan questions</>;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              startIcon={<AddIcon />}
              style={{ backgroundColor: "#c8a063", color: "white" }}
              onClick={onAddReview}
            >
              Add new review
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {reviewsState.map((review: Review, index: number) => (
        <Grid key={index} item xs={12} md={6}>
          <ReviewItem
            index={index}
            review={review}
            onChangeReview={onChangeReview}
            key={index}
            changeNotification={showNotification}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item>
            <Button
              startIcon={<AddIcon />}
              color="primary"
              onClick={() =>
                fetchMore({
                  variables: { id: planId, limit: 30, offset: reviews.length },
                  updateQuery: (prevResult, { fetchMoreResult }) => {
                    if (fetchMoreResult.plan.reviews.length === 0)
                      return prevResult;
                    let sumResult = { ...prevResult };
                    let sumPlanResult = { ...prevResult.plan };
                    const reviews = [
                      ...prevResult.plan.reviews,
                      ...fetchMoreResult.plan.reviews,
                    ];
                    sumPlanResult.reviews = reviews;
                    sumResult.plan = sumPlanResult;
                    return sumResult;
                  },
                })
              }
            >
              Fetch more
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PLAN_REVIEWS_QUERY = gql`
  query PlanReviewQuery($id: ID!, $limit: Int!, $offset: Int) {
    plan(id: $id) {
      id
      reviews(limit: $limit, offset: $offset) {
        id
        content
        rating
        planId
        reviewAt
        comment {
          id
          reviewId
          conciergeId
          comment
        }
      }
    }
  }
`;

export default ReviewsList;
