import { useEffect, useState } from "react";
import queryString from "query-string";
import { useQuery, gql } from "@apollo/client";
import { Link, useNavigate, useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";

import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button2";

export default function CollectionsList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 20;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );

  useEffect(() => {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }, [page, search, navigate]);

  const { loading, error, data, fetchMore } = useQuery(GET_COLLECTIONS, {
    variables: { offset: page * pageSize, limit: pageSize, search: search },
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEditCollection },
    { name: "ID", field: "id" },
    { name: "Name", field: "name" },
    { name: "Slug", field: "slug" },
    { name: "Plans", render: renderPlans },
    { name: "Score", field: "score" },
  ];
  const rows = data ? data.planCollections.records : null;

  return (
    <DataTable
      loading={loading}
      columns={columns}
      rows={rows}
      topBarExtras={renderTopBarExtras()}
      onSearch={(event) => {
        setSearch(event.target.value);
        setPage(0);
      }}
      searchValue={search}
      pagination={{
        totalRows: data ? data.planCollections.totalRecords : null,
        pageSize: pageSize,
        currentPage: page,
        onChangePage: (_, newPage) => {
          setPage(newPage);
          fetchMore({
            variables: {
              offset: newPage * pageSize,
              limit: pageSize,
              search: search,
            },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        },
      }}
    />
  );

  function renderTopBarExtras() {
    return (
      <>
        <Button
          style={{ backgroundColor: "#c8a063", color: "white" }}
          variant="contained"
          component={Link}
          to="/plans/collections/new"
          startIcon={<AddIcon />}
        >
          New
        </Button>
        <Button
          style={{
            backgroundColor: "#c8a063",
            color: "white",
            marginLeft: "1rem",
          }}
          variant="contained"
          component={Link}
          to="/plans/collections/import"
          startIcon={<PublishIcon />}
        >
          Import
        </Button>
      </>
    );
  }

  function renderEditCollection(collection: { id: string }) {
    return (
      <Button
        size="small"
        component={Link}
        to={`/plans/collections/${collection.id}`}
      >
        <EditIcon />
      </Button>
    );
  }

  function renderPlans(collection: { numPlans: number }) {
    return <>{collection.numPlans}</>;
  }
}

const GET_COLLECTIONS = gql`
  query PlanCollectionsQuery($offset: Int, $limit: Int, $search: String) {
    planCollections(offset: $offset, limit: $limit, search: $search) {
      totalRecords
      records {
        id
        name
        slug
        showInHome
        score
        numPlans
      }
    }
  }
`;
