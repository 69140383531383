import { Card, CardContent } from "@mui/material";
import Checkbox from "../../../../components/Checkbox";

export default function Flags(props: FlagsProps) {
  return (
    <Card>
      <CardContent style={{ padding: "16px" }}>
        <Checkbox
          label="Exclusive"
          checked={props.exclusive.value}
          onChange={(checked: boolean) => props.exclusive.set(checked)}
        />
      </CardContent>
    </Card>
  );
}

interface FlagsProps {
  exclusive: { value: boolean; set: (value: boolean) => void };
}
