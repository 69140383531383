import { makeStyles } from "@mui/styles";

export default makeStyles({
  primaryButton: {
    color: "#ffffff",
    backgroundColor: "#c8a063",
    "&:hover": {
      backgroundColor: "#a37939",
    },
  },
  secondaryButton: {
    color: "#c8a063",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
});
