import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";

export default function Video(props: VideoProps) {
  return (
    <Card>
      <CardHeader title={"Video"} />
      <Divider />
      <CardContent>
        <MediaUploader
          width={"80%"}
          uploadPath={`plans/${props.planId}/videos`}
          allowedTypes={[MediaType.Video]}
          media={props.video.value}
          onChangeFunction={(videoUrl: string | null) => {
            props.video.set(videoUrl);
          }}
        />
      </CardContent>
    </Card>
  );
}

interface VideoProps {
  planId: string;
  video: { value: string | null; set: (value: string | null) => void };
}
