import { gql } from "@apollo/client";
import { Tag } from "../../../types";

export type EditTagMutationInput = Partial<Pick<Tag, "id">> &
  Pick<Tag, "name" | "tagCategoryId">;

export type EditTagMutationResponse = {
  editTag: {
    tag: Tag;
    originalTag: Tag;
  };
};

export const EDIT_TAG_MUTATION = gql`
  mutation EditTagMutation($id: Int, $name: String!, $tagCategoryId: Int!) {
    editTag(id: $id, name: $name, tagCategoryId: $tagCategoryId) {
      tag {
        id
        name
        tagCategoryId
      }
      originalTag {
        tagCategoryId
      }
    }
  }
`;
