import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextField from "../../../components/TextField";

export default function Prices(props: {
  priceMinimum: number | null;
  setPriceMinimum: (price: number | null) => void;
  priceMaximum: number | null;
  setPriceMaximum: (price: number | null) => void;
  showPricesFilter: boolean;
  setShowPricesFilter: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  function changeFilterType(event: any) {
    const value = event.target.value === "true";
    if (value === false) {
      props.setPriceMinimum(null);
      props.setPriceMaximum(null);
    }
    props.setShowPricesFilter!(value);
  }

  return (
    <>
      {props.showPricesFilter !== undefined &&
        props.setShowPricesFilter !== undefined && (
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Price filter rules</FormLabel>
            <RadioGroup
              row
              aria-label="tag-filter"
              name="tag-filter"
              value={String(props.showPricesFilter)}
              onChange={changeFilterType}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Filter by price"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Don't filter by price"
              />
            </RadioGroup>
          </FormControl>
        )}

      {props.showPricesFilter && (
        <>
          <TextField
            type="number"
            label="priceMinimum in JPY"
            value={props.priceMinimum}
            onChange={(event) =>
              props.setPriceMinimum(Number(event.target.value))
            }
          />
          <TextField
            type="number"
            label="priceMaximum in JPY"
            value={props.priceMaximum}
            onChange={(event) =>
              props.setPriceMaximum(Number(event.target.value))
            }
          />
        </>
      )}
    </>
  );
}
