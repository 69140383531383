import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Notification, {
  NotificationData,
} from "../../../../components/Notification";
import { VenueReservationType } from "../types";

export default function RejectReservationButton(
  props: RejectReservationButtonProps
) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rejectVenueReservation] = useMutation(REJECT_VENUE_RESERVATION);
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );

  return (
    <>
      <Button
        style={{ backgroundColor: "#f44336", color: "#ffffff" }}
        variant="contained"
        startIcon={<ClearIcon />}
        onClick={() => setDialogOpen(true)}
      >
        Reject
      </Button>
      <RejectDialog />
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function closeDialog() {
    setDialogOpen(false);
  }

  function RejectDialog() {
    return (
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Reject Venue Reservation</DialogTitle>
        <DialogContent>
          Are you sure you want to reject the reservation?
          <br />
          <br />
          This action will:
          <ul>
            <li>Change reservation status to REJECTED</li>
            <li>Change booking status to REJECTED</li>
            <li>Send rejection email to customer AND venue</li>
            <li>
              Change paid status of on-site payment bookings to NOT_YET_PAID
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button color="primary" onClick={rejectReservation}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function rejectReservation() {
    rejectVenueReservation({
      variables: { input: { activityReservationId: props.reservation.id } },
      refetchQueries: ["VenueReservationsListQuery"],
    })
      .then(() => {
        setNotification({
          message: `Venue reservation was successfully rejected`,
          severity: "success",
        });
      })
      .catch((reason) => {
        setNotification({
          message: `Rejecting venue reservation failed! ${reason}`,
          severity: "error",
        });
      });

    closeDialog();
  }
}

const REJECT_VENUE_RESERVATION = gql`
  mutation rejectVenueReservation($input: RejectVenueReservationInput!) {
    rejectVenueReservation(input: $input) {
      success
    }
  }
`;

interface RejectReservationButtonProps {
  reservation: VenueReservationType;
}
