import { useMemo, useState } from "react";
import { Grid, Button, Card, Box } from "@mui/material";
import { useNotifications } from "../../../../components/Notification";

import BasicInfo1 from "./BasicInfo1";
import BasicInfo2 from "./BasicInfo2";
import Tags from "./Tags";
import ConciergeComment from "./ConciergeComment";
import WhatsIncluded from "./WhatsIncluded";
import { Tag, Inclusions, BookingType, Venue } from "../../../../types";
import Video from "./Video";
import Flags from "./Flags";
import PaymentMethods from "./PaymentMethods";
import CopyPlanButton from "./CopyPlanButton";
import { removeDuplicates } from "../../../../helpers/remove-duplicates";

export default function BasicInfoForm(props: BasicInfoFormProps) {
  const { showNotification } = useNotifications();

  const { plan } = props;

  const [name, setName] = useState(plan?.name || "");
  const [bookingType, setBookingType] = useState(
    plan?.bookingType || BookingType.REQUEST
  );
  const [description, setDescription] = useState(plan?.description || "");
  const [remarks, setRemarks] = useState(plan?.remarks || "");
  const [
    cancellationPolicyDescription,
    setCancellationPolicyDescription,
  ] = useState(plan?.cancellationPolicyDescription || "");
  const [paymentMethodDescription, setPaymentMethodDescription] = useState(
    plan?.paymentMethodDescription || ""
  );
  const [subtitle, setSubtitle] = useState(plan?.subtitle || "");
  const [timezone, setTimezone] = useState(plan?.timezone || "Asia/Tokyo");
  const [areaName, setAreaName] = useState(plan?.areaName || "");
  const [locationId, setLocationId] = useState(plan?.locationId || "");
  const [basePrice, setBasePrice] = useState<number | undefined>(
    plan?.basePrice || 0
  );
  const [basePriceName, setBasePriceName] = useState(plan?.basePriceName || "");
  const score = useMemo(() => plan?.score, [plan?.score]);
  const [manualScore, setManualScore] = useState(plan?.manualScore ?? null);
  const [tags, setTags] = useState(removeDuplicates((plan?.tags ?? []).filter(tag => tag !== null)));
  const [conciergeId, setConciergeId] = useState(plan?.conciergeId || "");
  const [conciergeComment, setConciergeComment] = useState(
    plan?.conciergeComment || ""
  );
  const [videoUrl, setVideoUrl] = useState(plan?.videoUrl || null);

  const [exclusive, setExclusive] = useState<boolean>(plan?.exclusive || false);
  const [onSitePayment, setOnSitePayment] = useState<boolean>(
    plan?.onSitePayment || false
  );
  const [amazonPay, setAmazonPay] = useState<boolean>(plan?.amazonPay || false);
  const [stripe, setStripe] = useState<boolean>(plan?.stripe || false);

  const [includedList, setIncludedList] = useState(plan?.inclusions || []);

  return (
    <Grid container spacing={2}>
      <Grid item lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BasicInfo1
              name={{ value: name, set: setName }}
              timezone={{ value: timezone, set: setTimezone }}
              areaName={{ value: areaName, set: setAreaName }}
              location={{ value: locationId, set: setLocationId }}
              description={{ value: description, set: setDescription }}
            />
          </Grid>
          {plan?.id && (
            <Grid item xs={12}>
              <Video
                planId={plan.id}
                video={{ value: videoUrl, set: setVideoUrl }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PaymentMethods
              onSitePayment={{ value: onSitePayment, set: setOnSitePayment }}
              stripe={{ value: stripe, set: setStripe }}
              amazonPay={{ value: amazonPay, set: setAmazonPay }}
            />
          </Grid>
          <Grid item xs={12}>
            <Flags exclusive={{ value: exclusive, set: setExclusive }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BasicInfo2
              basePrice={{ value: basePrice, set: setBasePrice }}
              basePriceName={{ value: basePriceName, set: setBasePriceName }}
              score={score}
              manualScore={{ value: manualScore, set: setManualScore }}
              subtitle={{ value: subtitle, set: setSubtitle }}
              remarks={{ value: remarks, set: setRemarks }}
              bookingType={{ value: bookingType, set: setBookingType }}
              cancellationPolicyDescription={{
                value: cancellationPolicyDescription,
                set: setCancellationPolicyDescription,
              }}
              paymentMethodDescription={{
                value: paymentMethodDescription,
                set: setPaymentMethodDescription,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Tags tags={{ value: tags, set: setTags }} />
          </Grid>

          <Grid item xs={12}>
            <WhatsIncluded
              onChange={setIncludedList}
              inclusions={includedList}
            />
          </Grid>

          <Grid item xs={12}>
            <ConciergeComment
              planId={plan?.id}
              conciergeId={{ value: conciergeId, set: setConciergeId }}
              comment={{ value: conciergeComment, set: setConciergeComment }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card style={{ padding: "10px" }}>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={handleSubmit}>
              Save
            </Button>
            {plan && <CopyPlanButton id={plan.id!} />}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );

  function handleSubmit() {
    const onSiteOnlyPayment = onSitePayment && !stripe && !amazonPay;

    if (
      props.plan?.planTemplates &&
      props.plan?.planTemplates.length > 0 &&
      onSiteOnlyPayment
    ) {
      showNotification({
        message: `Please check the payment methods! While this plan is connected to items, it must support online payment methods.`,
        severity: "error",
      });
      return;
    }

    if (
      !props.plan?.mainVenue?.ebicaStoreId &&
      (bookingType === BookingType.INSTANT ||
        bookingType === BookingType.AVAILABILITIES)
    ) {
      showNotification({
        message: `Plans of booking type 'instant' and 'availabilities' require the related venue ${props.plan?.mainVenue.name} to have an 'ebica store id' set.`,
        severity: "error",
      });
      return;
    }
    const planJsonFields = removeEmptyFields({
      id: plan?.id,
      timezone,
      name,
      description,
      subtitle,
      remarks,
      cancellationPolicyDescription,
      paymentMethodDescription,
      areaName,
      tagIds: tags ? tags.map((t) => t.id) : [],
      locationId,
      bookingType,
    });

    props
      .onSubmit(
        emptyStringsToNull({
          ...planJsonFields,
          conciergeId,
          conciergeComment,
          videoUrl,
          basePrice,
          basePriceName,
          exclusive,
          onSitePayment,
          stripe,
          amazonPay,
          manualScore,
          inclusions: includedList,
        })
      )
      .then(() => {
        showNotification({
          message: "Basic info saved successfully",
          severity: "success",
        });
      })
      .catch((err: any) => {
        if (err.networkError) {
          const error = err.networkError.result.errors[0];

          showNotification({
            message: `Please make sure to fill out required fields, ${error.message.substring(
              0,
              2000
            )}`,
            severity: "error",
          });
        } else {
          showNotification({
            message: err.message,
            severity: "error",
          });
        }
      });
  }
}

const removeEmptyFields = (obj: any) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => Boolean(v)));

function emptyStringsToNull(object: any) {
  return JSON.parse(
    JSON.stringify(object, (_key: string, value: any) =>
      String(value) === "" ? null : value
    )
  );
}

export type PlanBasicInfo = {
  id?: string;
  name: string;
  timezone: string;
  subtitle?: string;
  description?: string;
  areaName?: string;
  locationId: string;
  remarks?: string;
  cancellationPolicyDescription?: string;
  paymentMethodDescription?: string;
  score?: number;
  manualScore: number | null;
  conciergeId?: string;
  conciergeComment?: string;
  videoUrl?: string;
  basePrice?: number;
  basePriceName?: string;
  exclusive?: boolean;
  onSitePayment: boolean;
  stripe: boolean;
  amazonPay: boolean;
  tags: Tag[];
  inclusions?: Inclusions[];
  planTemplates: [id: string];
  mainVenue: Venue;
  bookingType: BookingType;
};

type BasicInfoFormProps = {
  plan?: PlanBasicInfo;
  onSubmit: (plan: PlanBasicInfo) => Promise<void>;
};
