import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Select,
  InputLabel,
  Button,
  CardHeader,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import TextField from "../../../../components/TextField";
import { Review } from "../../../../types";
import { useMutation, gql } from "@apollo/client";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationData } from "../../../../components/Notification";
import { v4 as uuid } from "uuid";
import ConciergesSelection from "../../../../components/ConciergeSelection";

interface Props {
  review: Review;
  index: number;
  onChangeReview: (review: Review | null, index: number) => void;
  changeNotification: (notification: NotificationData) => void;
}

const ReviewItem: React.FC<Props> = React.memo(
  ({ review, index, onChangeReview, changeNotification }) => {
    const [addPlanReview] = useMutation(ADD_PLAN_REVIEW_MUTATION);
    const [saveReview] = useMutation(SAVE_REVIEW_MUTATION);
    const [deleteReview] = useMutation(DELETE_REVIEW_MUTATION);
    const [saveReviewConciergeComment] = useMutation(
      SAVE_REVIEW_CONCIERGE_COMMENT_MUTATION
    );
    const [deleteReviewConciergeComment] = useMutation(
      DELETE_REVIEW_CONCIERGE_COMMENT_MUTATION
    );
    const [stateReview, setStateReview] = useState<Review>(review);
    const [edited, setEdited] = useState(false);
    const [saved, setSaved] = useState(false);

    useEffect(() => {
      setStateReview(review);
    }, [review, review.comment]);
    const onChangeContent = (e: any) => {
      let newReview = { ...stateReview };
      newReview.content = e.target.value;
      setStateReview(newReview);
      setEdited(true);
    };
    const onChangeRating = (e: any) => {
      let newReview = { ...stateReview };
      newReview.rating = Number(e.target.value) ?? 0;
      setStateReview(newReview);
      setEdited(true);
    };
    const onChangeReviewDate = (selectedDate: any) => {
      let newReview = { ...stateReview };
      newReview.reviewAt = selectedDate;
      setStateReview(newReview);
      setEdited(true);
    };
    const onChangeConciergeId = (e: any) => {
      let newReview = { ...stateReview };
      if (newReview.comment && !!e) {
        let newComment = { ...newReview.comment };
        newComment.conciergeId = e || "";
        newReview.comment = newComment;
      } else if (newReview.comment && !e) {
        let newComment = { ...newReview.comment };
        newComment.conciergeId = e || "";
        newReview.comment = newComment;
      }
      setStateReview(newReview);
    };
    const onChangeComment = (e: any) => {
      let newReview = { ...stateReview };
      if (newReview.comment && !!e) {
        let newComment = { ...newReview.comment };
        newComment.comment = e.target.value;
        newReview.comment = newComment;
      }
      setStateReview(newReview);
    };
    const onSaveReview = () => {
      if (stateReview.content.length < 1) {
        changeNotification({
          message: `Cannot save review without content and rating!`,
          severity: "error",
        });
        return;
      }
      if (!!stateReview.id && stateReview.id.length > 0) {
        saveReview({
          variables: {
            input: {
              review: {
                id: stateReview.id,
                planId: stateReview.planId,
                content: stateReview.content,
                reviewAt: stateReview.reviewAt.toString(),
                rating: stateReview.rating,
              },
            },
          },
        })
          .then(() => {
            setEdited(false);
            setSaved(true);
            onChangeReview(stateReview, index);
            changeNotification({
              message: `Review has been saved!`,
              severity: "success",
            });
          })
          .catch(() => {
            changeNotification({
              message: `Review could not be saved!`,
              severity: "error",
            });
          });
      } else {
        addPlanReview({
          variables: {
            input: {
              review: {
                planId: stateReview.planId,
                content: stateReview.content,
                reviewAt: stateReview.reviewAt,
                rating: stateReview.rating,
              },
            },
          },
        })
          .then(() => {
            setEdited(false);
            setSaved(true);
            onChangeReview(stateReview, index);
            changeNotification({
              message: `Review has been saved!`,
              severity: "success",
            });
          })
          .catch(() => {
            changeNotification({
              message: `Review could not be saved!`,
              severity: "error",
            });
          });
      }
    };

    const onDeleteReview = () => {
      deleteReview({
        variables: {
          input: {
            id: stateReview.id,
          },
        },
      })
        .then(() => {
          changeNotification({
            message: `Review has been removed!`,
            severity: "success",
          });
          onChangeReview(null, index);
        })
        .catch(() => {
          changeNotification({
            message: `Review could not be removed!`,
            severity: "error",
          });
        });
    };

    const onAddComment = () => {
      if (!!stateReview.comment || !stateReview.id) return;
      let newReview = { ...stateReview };
      newReview.comment = {
        id: "",
        conciergeId: "",
        comment: "",
        reviewId: stateReview.id,
      };
      setStateReview(newReview);
    };

    const onSaveComment = () => {
      if (!stateReview.comment) return;
      if (!stateReview.comment.id) {
        let newReview = { ...stateReview };
        newReview.comment!.id = uuid();
        setStateReview(newReview);
      }
      saveReviewConciergeComment({
        variables: {
          input: {
            comment: {
              ...stateReview.comment,
            },
          },
        },
      })
        .then(() => {
          changeNotification({
            message: `Review's concierge comment has been saved!`,
            severity: "success",
          });
        })
        .catch(() => {
          changeNotification({
            message: `Review's concierge comment could not be saved!`,
            severity: "error",
          });
        });
    };

    const onDeleteComment = () => {
      if (!stateReview.comment) return;
      let newReview = { ...stateReview };
      if (!!newReview.comment?.id) {
        deleteReviewConciergeComment({
          variables: {
            input: {
              id: newReview.comment?.id,
            },
          },
        })
          .then(() => {
            newReview.comment = undefined;
            setStateReview(newReview);
            changeNotification({
              message: `Review's concierge comment has been removed!`,
              severity: "success",
            });
          })
          .catch(() => {
            changeNotification({
              message: `Review's concierge comment could not be removed!`,
              severity: "error",
            });
          });
      }
    };

    useEffect(() => {
      if ((review.id === null || review.id.length === 0) && !saved)
        setEdited(true);
    }, [review, saved]);

    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent="flex-end">
              <IconButton size="small" onClick={onDeleteReview}>
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <InputLabel htmlFor="outlined-rating-native-simple">
                Rating
              </InputLabel>
              <Select
                native
                value={stateReview.rating}
                onChange={onChangeRating}
                label="Rating"
                style={{
                  height: 60,
                }}
                inputProps={{
                  name: "rating",
                  id: "outlined-rating-native-simple",
                }}
              >
                <option aria-label="None" value={0} />
                <option value={0}>0</option>
                <option value={0.5}>0.5</option>
                <option value={1}>1</option>
                <option value={1.5}>1.5</option>
                <option value={2}>2</option>
                <option value={2.5}>2.5</option>
                <option value={3}>3</option>
                <option value={3.5}>3.5</option>
                <option value={4}>4</option>
                <option value={4.5}>4.5</option>
                <option value={5}>5</option>
              </Select>
            </Grid>
            <Grid item xs={10}>
              <InputLabel htmlFor="review-datepicker">Review Date</InputLabel>
              <div id="datepicker-wrapper" style={{ marginTop: "5%" }}>
                <DatePicker
                  id="review-datepicker"
                  onChange={onChangeReviewDate}
                  selected={new Date(stateReview.reviewAt) || new Date()}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline={true}
                label="Content"
                value={stateReview.content}
                onChange={onChangeContent}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<SaveIcon />}
                style={{ backgroundColor: "#c8a063", color: "white" }}
                onClick={onSaveReview}
              >
                Save
              </Button>
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  display: "inline",
                  color: "red",
                }}
              >
                {edited && "未保存"}
              </div>
              {!stateReview.comment && (
                <Button
                  startIcon={<AddIcon />}
                  style={{
                    backgroundColor: "#c8a063",
                    color: "white",
                    display: "inline",
                  }}
                  onClick={onAddComment}
                >
                  Add comment
                </Button>
              )}
            </Grid>
            {stateReview.comment && (
              <>
                <CardHeader title="Concierge Comment" />
                <Grid item xs={12}>
                  <ConciergesSelection
                    required
                    onConciergeSelected={(concierge) => {
                      onChangeConciergeId(concierge?.id);
                    }}
                    conciergeId={stateReview.comment?.conciergeId}
                  />
                  <TextField
                    required
                    multiline={true}
                    label="Comment"
                    value={stateReview.comment.comment}
                    onChange={onChangeComment}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    startIcon={<SaveIcon />}
                    style={{ backgroundColor: "#c8a063", color: "white" }}
                    onClick={onSaveComment}
                  >
                    Save Comment
                  </Button>
                  <Button
                    startIcon={<ClearIcon />}
                    style={{
                      backgroundColor: "#c8a063",
                      color: "white",
                      marginLeft: "10px",
                    }}
                    onClick={onDeleteComment}
                  >
                    Delete Comment
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

const ADD_PLAN_REVIEW_MUTATION = gql`
  mutation AddPlanReviewMutation($input: AddPlanReviewInput!) {
    addPlanReview(input: $input) {
      review {
        id
      }
    }
  }
`;

const SAVE_REVIEW_MUTATION = gql`
  mutation SaveReviewMutation($input: SaveReviewInput!) {
    saveReview(input: $input) {
      review {
        id
      }
    }
  }
`;

const DELETE_REVIEW_MUTATION = gql`
  mutation DeleteReviewMutation($input: DeleteReviewInput!) {
    deleteReview(input: $input) {
      result
    }
  }
`;

const SAVE_REVIEW_CONCIERGE_COMMENT_MUTATION = gql`
  mutation SaveReviewConciergeCommentMutation(
    $input: SaveReviewConciergeCommentInput!
  ) {
    saveReviewConciergeComment(input: $input) {
      comment {
        id
      }
    }
  }
`;

const DELETE_REVIEW_CONCIERGE_COMMENT_MUTATION = gql`
  mutation DeleteReviewConciergeCommentMutation(
    $input: DeleteReviewConciergeCommentInput!
  ) {
    deleteReviewConciergeComment(input: $input) {
      result
    }
  }
`;

export default ReviewItem;
