import { useParams, Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Typography, Box, Button } from "@mui/material";
import { FormatMoney } from "../../../../components/Money";
import useTags from "../../../../hooks/useTags";
import { STOREFRONT_URL } from "../../../../app.config";
import useStyles from "./styles";
import { useNotifications } from "../../../../components/Notification";

export default function HeaderPlan(props: any) {
  const { id } = useParams<{ id: string }>();
  const { getFilteredTags } = useTags();
  const chooseName: any = getFilteredTags("お祝いシーン")?.shift();
  const { showNotification } = useNotifications();

  const classes = useStyles();
  const { data, loading, error } = useQuery(PLAN_BASIC_INFO_QUERY, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (loading) return <>loading...</>;
  if (error) return <>{error.message}</>;

  const renderTextFromPlanName = (data: any) => {
    let name = "お祝いシーン";

    const tagsName = data?.tags?.map((item: any) => item?.name).join(" ");

    if (
      data?.name?.includes("アニバーサリー") ||
      tagsName?.includes("誕生日") ||
      tagsName?.includes("結婚記念日・記念日")
    ) {
      name = "アニバーサリー";
    } else if (
      data?.name?.includes("プロポーズ") ||
      tagsName?.includes("プロポーズ")
    ) {
      name = "プロポーズ";
    } else {
      if (tagsName?.length > 0) {
        if (tagsName?.includes("顔合わせ・結納")) {
          {
            name = "顔合わせ・結納";
          }
        } else if (tagsName?.includes("接待・会食")) {
          name = "接待・会食";
        } else if (tagsName?.includes("お祝い")) {
          name = "お祝い";
        } else if (tagsName?.includes("お食い初め")) {
          name = "お食い初め";
        } else if (
          tagsName?.includes("還暦") ||
          tagsName?.includes("古希") ||
          tagsName?.includes("喜寿") ||
          tagsName?.includes("米寿") ||
          tagsName?.includes("長寿")
        ) {
          name = "長寿お祝い";
        }
      } else {
        name = chooseName?.name ?? name;
      }
    }

    return name;
  };

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxContent}>
        <Typography className={classes.titleName}>
          {renderTextFromPlanName(data?.plan)}
        </Typography>
        {data?.plan?.mainVenue?.name && (
          <Typography sx={{ fontSize: "14px" }}>
            {data?.plan?.mainVenue?.name}
            <Link
              style={{
                color: "#333",
                marginLeft: "4px",
                textDecoration: "none",
              }}
              to={`/venues/${data?.plan?.mainVenue?.id}`}
            >
              (
              <span
                style={{
                  color: "#1976d2",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
              >
                Venueページ編集
              </span>
              )
            </Link>
          </Typography>
        )}

        <Typography sx={{ fontSize: "14px" }}>
          <strong>代表価格</strong>:{" "}
          <span style={{ opacity: 0.7 }}>
            {data?.plan?.activities?.length > 0 &&
            data.plan.activities[0]?.priceTypes?.length > 0
              ? FormatMoney({
                  amount: data.plan.activities[0].priceTypes[0]?.amount || 0, // Kiểm tra sự tồn tại của amount
                  currencyCode: "JPY",
                })
              : "0"}
          </span>
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>
          <strong>スターテス</strong>:{" "}
          {data?.plan?.isPublished ? "公開中" : "下書き"}
        </Typography>
      </Box>
      {data?.plan?.isPublished ? (
        <Button
          className={classes.buttonLink}
          onClick={() => {
            window.open(
              `${STOREFRONT_URL}/celebration-plans/${data?.plan?.id}`
            );
          }}
        >
          公開中ページを見る
        </Button>
      ) : (
        <Button
          className={classes.button}
          onClick={() => {
            if (data?.plan?.drafts.length > 0) {
              window.open(
                `${STOREFRONT_URL}/draft/${data?.plan?.id}?draftId=${data?.plan?.drafts[0].id}`
              );
            } else {
              showNotification({
                message: `ドラフトはまだ作成されていません。作成してください。`,
                severity: "error",
              });
            }
          }}
        >
          最新の下書きを見る
        </Button>
      )}
    </Box>
  );
}

const PLAN_BASIC_INFO_QUERY = gql`
  query PlanBasicInfoQuery($id: ID!) {
    plan(id: $id) {
      id
      name
      basePrice
      isPublished
      exclusive
      conciergeId
      inclusions {
        category
        items {
          id
          title
          description
          iconName
        }
      }
      tags {
        id
        name
        tagCategory {
          id
          name
        }
      }
      planTemplates {
        id
      }
      mainVenue {
        id
        name
        ebicaStoreId
      }
      drafts(last: 1) {
        id
        publishedAt
      }
      activities {
        id
        priceTypes {
          id
          name
          amount
        }
      }
    }
  }
`;
