import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useState } from "react";

import { Plan } from "../../../../../types";
import CopyPlanDialog from "./CopyPlanDialog";

type CopyPlanButtonProps = Pick<Plan, "id">;

export default function CopyPlanButton({ id }: CopyPlanButtonProps) {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        style={{ backgroundColor: "white", color: "#c8a063" }}
        onClick={() => setOpenDialog(true)}
      >
        Copy Plan
      </Button>
      <CopyPlanDialog
        id={id}
        isOpen={openDialog}
        closeDialog={() => setOpenDialog(false)}
      />
    </>
  );
}
