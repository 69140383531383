import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";

import Item, { ItemType } from "./Item";
import { ActivityReservationType } from "../../ActionButtons/types";
import TextField from "../../../../../components/TextField";
import { useViewBookingContext } from "../..";

interface ItemsProps {
  items: ItemType[];
  setItems: React.Dispatch<React.SetStateAction<ItemType[]>>;
  readOnly?: boolean;
  reservation: ActivityReservationType;
}

function Items({ items, setItems, readOnly, reservation }: ItemsProps) {
  const { newPlan } = useViewBookingContext();

  return (
    <Card style={{ backgroundColor: "white" }}>
      <CardHeader title="Course Info" />
      <Divider />
      <CardContent>
        <TextField
          readOnly
          fullWidth
          label={"Course name"}
          value={
            newPlan?.activityName
              ? newPlan?.activityName
              : reservation.activity.name
          }
        />
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          {items.map((item: ItemType, index: number) => (
            <Grid key={index} item xs={12} md={6}>
              <Item
                data={item}
                setQuantity={setQuantity(setItems, index)}
                readOnly={readOnly}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

const setQuantity = (
  setItems: React.Dispatch<React.SetStateAction<ItemType[]>>,
  index: number
) => {
  return (quantity: number) =>
    setItems((items) => {
      const adjustedItems = [...items];
      adjustedItems[index] = {
        ...adjustedItems[index],
        quantity: Math.max(quantity, 0),
      };
      return adjustedItems;
    });
};

export default Items;
