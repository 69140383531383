import { gql, useQuery } from "@apollo/client";

import { Grid } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { BookingStatus } from "../../types";
import { addDays, subDays, format } from "date-fns";
import { InfoCard } from "./InfoCard";

export default function Dashboard() {
  const { data, loading, error } = useQuery<{
    getDashboardStatistics: {
      abandonedBookings: number;
      bookingsToday: number;
      bookingsYesterday: number;
      pendingBookings: number;
      pendingReservations: number;
      plansToday: number;
      plansYesterday: number;
      reviewsToday: number;
      reviewsYesterday: number;
    };
  }>(HOME_DASHBOARD_QUERY);

  if (!data || !data.getDashboardStatistics || loading || error) {
    return null;
  }

  const {
    abandonedBookings,
    bookingsToday,
    bookingsYesterday,
    pendingBookings,
    pendingReservations,
    plansToday,
    plansYesterday,
    reviewsToday,
    reviewsYesterday,
  } = data.getDashboardStatistics;

  return (
    <Grid container spacing={1}>
      <InfoCard
        title={"Bookings today"}
        avatar={{
          icon: <ReceiptIcon />,
          color: "green",
        }}
        content={bookingsToday}
        subContent={`${bookingsYesterday} yesterday`}
      />
      <InfoCard
        title="Reviews today"
        avatar={{ icon: <ChatBubbleIcon />, color: "green" }}
        content={reviewsToday}
        subContent={`${reviewsYesterday} yesterday`}
        link="bookings/reviews?page=1"
      />
      <InfoCard
        title="Plans today"
        avatar={{ icon: <AssignmentIcon />, color: "green" }}
        content={plansToday}
        subContent={`${plansYesterday} yesterday`}
      />
      <InfoCard
        title="Pending reservations"
        avatar={{ icon: <ReceiptIcon />, color: "orange" }}
        content={pendingReservations}
      />
      <InfoCard
        title={"Pending bookings"}
        avatar={{
          icon: <ReceiptIcon />,
          color: "orange",
        }}
        content={pendingBookings}
        link={`/bookings?page=1&bookingStatus=${
          BookingStatus.REQUESTED
        }&orderBy=reservation_datetime&order=ASC&reservationFrom=${format(
          new Date(),
          "yyyy-MM-dd"
        )}&reservationUntil=${format(
          addDays(new Date(), 4),
          "yyyy-MM-dd"
        )}&hideColumns[]=[]`}
      />
      <InfoCard
        title={"Abandoned bookings"}
        avatar={{
          icon: <ReceiptIcon />,
          color: "red",
        }}
        content={abandonedBookings}
        link={`/bookings?page=1&bookingStatus=${
          BookingStatus.REQUESTED
        }&orderBy=created_at&order=ASC&createdUntil=${format(
          subDays(new Date(), 5),
          "yyyy-MM-dd"
        )}&hideColumns[]=[]`}
      />
    </Grid>
  );
}

const HOME_DASHBOARD_QUERY = gql`
  query HomeDashboardQuery {
    getDashboardStatistics {
      abandonedBookings
      bookingsToday
      bookingsYesterday
      pendingBookings
      pendingReservations
      plansToday
      plansYesterday
      reviewsToday
      reviewsYesterday
    }
  }
`;
