import { Card, CardContent, Grid } from "@mui/material";
import TextField from "../../../../components/TextField";

export default function QuestionAnswers(props: {
  data: BookingPlanQuestionAnswer[];
}) {
  return (
    <Grid container spacing={2}>
      {props.data.map((answer: BookingPlanQuestionAnswer, index: number) => (
        <Grid key={index} item xs={12} md={6}>
          <Card>
            <CardContent>
              <TextField
                label="Question"
                value={answer.question}
                readOnly={true}
              />
              <TextField label="Answer" value={answer.answer} readOnly={true} />
              {answer.imageUrl && (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <img
                    style={{ height: "200px" }}
                    src={answer.imageUrl}
                    alt=""
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

interface BookingPlanQuestionAnswer {
  question: string;
  answer: string;
  imageUrl?: string;
}
