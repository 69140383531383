import { gql, useMutation, useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import Select from "../../../components/Select";
import TextField from "../../../components/TextField";
import { Location, LocationType } from "../../../types";

export default function NewLocation() {
  const { showNotification } = useNotifications();

  const [redirect, setRedirect] = useState(false);
  const [type, setType] = useState(LocationType.prefecture);
  const [nameEn, setNameEn] = useState("");
  const [nameJa, setNameJa] = useState("");
  const [parentId, setParentId] = useState("");

  const [createLocation] = useMutation(CREATE_LOCATION);
  const { data, error } = useQuery(GET_PARENT_LOCATIONS, {
    variables: { type: getParentType(type) },
  });
  if (error) return <>Error: {error.message}</>;

  const parentLocations = data ? data.locations.records : [];

  if (redirect) return <Navigate to="/locations" />;

  return (
    <>
      <Button
        style={{ marginBottom: "15px" }}
        variant="contained"
        component={Link}
        to={`/locations`}
      >
        Back to list
      </Button>
      <Grid container item xs={12} md={6}>
        <Card style={{ width: "100%" }}>
          <CardHeader title="New Location" />
          <CardContent>
            <Select
              label="Type"
              required
              value={type}
              options={[
                { id: LocationType.prefecture, name: "prefecture" },
                { id: LocationType.city, name: "city" },
                { id: LocationType.area, name: "area" },
              ]}
              onChange={(value) => setType(value)}
            />
            <TextField
              label="English Name"
              required
              value={nameEn}
              onChange={(e: any) => setNameEn(e.target.value)}
            />
            <TextField
              label="Japanese Name"
              required
              value={nameJa}
              onChange={(e: any) => setNameJa(e.target.value)}
            />
            {type !== LocationType.prefecture && (
              <Select
                style={{ marginTop: "12px" }}
                required
                label="Parent Location"
                value={parentId}
                options={parentLocations.map((pl: Location) => ({
                  id: pl.id,
                  name:
                    pl.type === LocationType.city
                      ? `${pl.nameEn} (${pl.nameJa}), ${pl.parent.nameEn} (${pl.parent.nameJa})`
                      : `${pl.nameEn} (${pl.nameJa})`,
                }))}
                onChange={(value) => setParentId(value)}
              />
            )}
            <Button
              style={{ marginTop: "20px" }}
              onClick={handleCreateLocation}
            >
              Save
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </>
  );

  function getParentType(type: LocationType) {
    if (type === LocationType.prefecture) return null;
    if (type === LocationType.city) return LocationType.prefecture;
    if (type === LocationType.area) return LocationType.city;
    return;
  }

  function handleCreateLocation() {
    if (!nameEn || !nameJa) {
      showNotification({
        message: "Please provide a Japanese and English name",
        severity: "error",
      });
      return;
    }
    if (type !== "prefecture" && !parentId) {
      showNotification({
        message: "Please select a parent location",
        severity: "error",
      });
      return;
    }
    createLocation({
      variables: {
        type: type,
        nameEn: nameEn,
        nameJa: nameJa,
        parentId: parentId,
      },
    })
      .then(() => {
        showNotification({
          message: "New Location Created",
          severity: "success",
        });

        setRedirect(true);
      })
      .catch(() => {
        showNotification({
          message: "Creating New Location Failed!",
          severity: "error",
        });
      });
  }
}

const GET_PARENT_LOCATIONS = gql`
  query ParentLocationsQuery($type: LocationType) {
    locations(type: $type) {
      records {
        id
        type
        nameEn
        nameJa

        parent {
          nameEn
          nameJa
        }
      }
    }
  }
`;

const CREATE_LOCATION = gql`
  mutation CreateLocationMutation(
    $type: LocationType!
    $nameEn: String!
    $nameJa: String!
    $parentId: ID
  ) {
    createLocation(
      input: {
        type: $type
        nameEn: $nameEn
        nameJa: $nameJa
        parentId: $parentId
      }
    ) {
      id
    }
  }
`;
