import { MultiStateCheckbox } from ".";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export type TripleState = boolean | undefined;

const statesAndIcons = [
  {
    state: undefined,
    icon: null,
  },
  {
    state: true,
    icon: CheckIcon,
  },
  {
    state: false,
    icon: CloseIcon,
  },
];

export function TripleStateCheckbox({
  state,
  setState,
  label,
}: TripleStateCheckboxProps) {
  return (
    <MultiStateCheckbox<TripleState>
      label={label}
      state={state}
      setState={setState}
      statesAndIcons={statesAndIcons}
    />
  );
}

type TripleStateCheckboxProps = {
  state: TripleState;
  setState: (state: TripleState) => void;
  label: string;
};
