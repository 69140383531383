import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import Button from "../../../../components/Button2";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";
import { useNotifications } from "../../../../components/Notification";
import { default as TF } from "../../../../components/TextField";
import { CollectionImage } from "../../../../types";
import { ADD_COLLECTION_IMAGE } from "../New";

interface EditCollectionImageFormProps {
  collectionImage: CollectionImage;
}

export default function EditCollectionImageForm(
  props: EditCollectionImageFormProps
) {
  const { showNotification } = useNotifications();
  const { collectionImage } = props;

  const [name, setName] = useState(collectionImage.name);
  const [imageUrl, setImageUrl] = useState(collectionImage.imageUrl);
  const [editCollectionImage] = useMutation(EDIT_COLLECTION_IMAGE, {
    refetchQueries: [
      { query: ADD_COLLECTION_IMAGE, variables: { offset: 0, limit: 10 } },
    ],
  });

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Id"
                value={collectionImage.id}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Created At"
                value={collectionImage.createdAt}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <MediaUploader
                allowedTypes={[MediaType.Image]}
                height="100px"
                media={imageUrl}
                uploadPath={`plans/collections/images/${collectionImage.id}/image`}
                onChangeFunction={(e) => setImageUrl(e || "")}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleEditCollectionImage}>Save</Button>
      </div>
    </>
  );

  function handleEditCollectionImage() {
    editCollectionImage({
      variables: {
        id: collectionImage.id,
        name,
        imageUrl,
      },
      refetchQueries: ["GetCollectionImagesQuery", "CollectionImageEditQuery"],
    })
      .then(() => {
        showNotification({
          message: "Collection image successfully edited",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Failed to save collection image",
          severity: "error",
        });
      });
  }
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

const EDIT_COLLECTION_IMAGE = gql`
  mutation EditCollectionImageMutation(
    $id: ID!
    $name: String
    $imageUrl: String
  ) {
    editCollectionImage(input: { id: $id, name: $name, imageUrl: $imageUrl }) {
      success
    }
  }
`;
