import { gql, useMutation } from "@apollo/client";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { EditStatus } from "../../../../../../types";
import { TripleElementWithMediaRenderer } from "../components/renderers";
import {
  CardProps,
  FaqData,
  GetOriginalValueProps,
  PaidItemData,
} from "../../types";

type PaidItemCardProps = Partial<CardProps> & {
  planId: string;
  fieldId: string;
  name: string;
  description: string;
  imageUrl: string;
  status: number;
  price: number;
  faqs: FaqData[];
  setFaqs: (faqs: FaqData[]) => void;
  editStatus: EditStatus;
};

const PaidItemCard = forwardRef((props: PaidItemCardProps, ref) => {
  const [name, setName] = useState(props.name);
  const [price, setPrice] = useState(props?.price?.toString());
  const [status, setStatus] = useState(props.status);
  const [description, setDescription] = useState(props.description);
  const [imageUrl, _setImageUrl] = useState(props.imageUrl);

  const [editMutation] = useMutation<
    EditPlanPaidItemResponse,
    EditPlanPaidItemInput
  >(EDIT_PLAN_PAID_ITEM_MUTATION);

  const [deleteMutation] = useMutation<
    DeletePlanPaidItemResponse,
    DeletePlanPaidItemInput
  >(DELETE_PLAN_PAID_ITEM_MUTATION);

  const persistChanges = async () => {
    if (props.editStatus === EditStatus.REMOVED) {
      const input: DeletePlanPaidItemInput["input"] = {
        id: props.fieldId,
      };

      return await deleteMutation({ variables: { input } });
    } else {
      const input: EditPlanPaidItemInput["input"] = {
        planPaidItem: {
          id: props.fieldId,
          planId: props.planId,
          name,
          description,
          imageUrl,
          status,
          price: Number(price),
        },
      };

      return await editMutation({ variables: { input } });
    }
  };

  const getNewValue = () => ({ description, name, price, status, imageUrl });

  useImperativeHandle(ref, () => ({
    persistChanges,
    getNewValue,
  }));

  return (
    <>
      <TripleElementWithMediaRenderer
        mediaUrl={imageUrl}
        topText={name}
        setTopText={setName}
        topLabel="name"
        bottomText={description}
        setBottomText={setDescription}
        bottomLabel="description"
        priceLabel="price"
        mutable={!!props.mutable}
        option={status}
        setOption={setStatus}
        price={price}
        setPrice={setPrice}
      />
    </>
  );
});

const getOriginalValue = ({ data, fieldId }: GetOriginalValueProps) =>
  data.planPaidItems.find((item: PaidItemData) => item.id === fieldId);

const paidItemCard = {
  Content: PaidItemCard,
  getOriginalValue,
};
export default paidItemCard;

const EDIT_PLAN_PAID_ITEM_MUTATION = gql`
  mutation EditFaqMutation($input: SavePlanPaidItemInput!) {
    savePlanPaidItem(input: $input) {
      planPaidItem {
        id
      }
    }
  }
`;

type EditPlanPaidItemResponse = {
  plan: {
    id: string;
  };
};

type EditPlanPaidItemInput = {
  input: {
    planPaidItem: {
      id?: string;
      planId: string;
      name: string;
      description: string;
      imageUrl: string;
      status: number;
      price: number;
    };
  };
};

const DELETE_PLAN_PAID_ITEM_MUTATION = gql`
  mutation DeletePlanPaidItemMutation($input: DeletePlanPaidItemInput!) {
    deletePlanPaidItemPartner(input: $input) {
      result
    }
  }
`;

type DeletePlanPaidItemResponse = {
  result: boolean;
};

type DeletePlanPaidItemInput = {
  input: {
    id: string;
  };
};
