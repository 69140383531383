import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { RequiredTextfield } from "../RequiredTextfield";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUpload from "@mui/icons-material/CloudUpload";
import GetApp from "@mui/icons-material/GetApp";
import { useState } from "react";
import DeleteContractDialog from "./DeleteContractDialog";
import { Contract } from "../../../../types";
import { useDownloadContract } from "../useDownloadContract";
import { useNotifications } from "../../../../components/Notification";
import { contractBucketName } from "../../../../helpers/gcp";
import UploadContract from "./UploadContract";

export default function ContractRow({
  contract,
  setContract,
  companyId,
  remove,
}: ContractRowProps) {
  const [uploading, setUploading] = useState(false);
  const { showNotification } = useNotifications();
  const [openModal, setOpenModal] = useState(false);
  const downloadContract = useDownloadContract({
    fileName: contract.name,
    filePath: contract.url,
    bucketName: contractBucketName,
  });

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <RequiredTextfield
          label={"contract name"}
          value={contract.name}
          setValue={(fileName: string) => {
            setContract({ ...contract, name: fileName });
          }}
        />
        <Tooltip title="download">
          <IconButton
            onClick={async () => {
              try {
                await downloadContract();
              } catch (err) {
                showNotification({
                  message: `Could not download contract: ${
                    (err as Error).message
                  }`,
                  severity: "error",
                });
              }
            }}
          >
            <GetApp />
          </IconButton>
        </Tooltip>
        {uploading ? (
          <CircularProgress />
        ) : (
          <UploadContract
            Button={
              <Tooltip title="upload">
                <span>
                  <IconButton>
                    <CloudUpload />
                  </IconButton>
                </span>
              </Tooltip>
            }
            companyId={companyId}
            updateContract={({ id, name, url }) => {
              setContract({ companyId, id, name, url });
            }}
            getName={() => contract.name}
            setUploading={setUploading}
          />
        )}
        <Tooltip title="delete">
          <IconButton onClick={() => setOpenModal(true)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
      <DeleteContractDialog
        open={openModal}
        handleClose={() => setOpenModal(false)}
        remove={remove}
      />
    </>
  );
}

type ContractRowProps = {
  contract: Contract;
  setContract: (contract: Contract) => void;
  companyId: string;
  remove: () => void;
};
