import { Button, ButtonProps, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    danger: {
      color: "red",
      borderColor: "red",
    },
  })
);

export type GenericButtonProps = ButtonProps & {
  severity?: "danger" | "default";
  label: string;
};

export default function GenericButton(props: GenericButtonProps) {
  const classes = useStyles();

  const classBySeverity: Record<string, string> = {
    danger: classes.danger,
    default: classes.default,
  };

  const severity: string = props.severity || "default";

  return (
    <Button variant="outlined" className={classBySeverity[severity]} {...props}>
      {props.label}
    </Button>
  );
}
