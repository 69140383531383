import { Typography } from "@mui/material";
import { useMemo } from "react";
import { NotificationStack } from "../../../../components/NotificationStack";
import s from "./PartnerEditNotificationStack.module.css";

export function PartnerEditNotificationStack({
  rowData,
}: PartnerEditNotificationStackProps) {
  const rows = useMemo(
    () =>
      rowData.map((row) => ({
        link: `/plans/${row.id}/partnerEdits`,
        content: (
          <>
            <Typography className={s.RegularText}>{row.venueName}</Typography>
            <Typography className={s.RegularText}>{row.id}</Typography>
            <Typography className={s.BoldText}>{row.name}</Typography>
          </>
        ),
      })),
    [rowData]
  );

  return (
    <NotificationStack
      header={"These plans have pending partner edits"}
      rows={rows}
    />
  );
}

type PartnerEditNotificationStackProps = {
  rowData: { id: string; name: string; venueName: string }[];
};
