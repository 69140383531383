import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import TextField from "../../../../components/TextField";
import Button from "../../../../components/Button2";
import { useNotifications } from "../../../../components/Notification";
import { Font } from "../../../../types";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";

export default function Basic(props: { font: Font }) {
  const font = props.font;

  const { showNotification } = useNotifications();
  const [overrideDisplayName, setOverrideDisplayName] = useState<string>(
    font.overrideDisplayName ?? (font.displayName || "")
  );
  const [previewImage, setPreviewImage] = useState<string>(
    font?.imageUrl || ""
  );

  const [saveFont] = useMutation(SAVE_FONT_MUTATION);

  function changeImage(image: string | null) {
    if (typeof image === "string") {
      setPreviewImage(image);
    } else {
      setPreviewImage("");
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent style={{ padding: "10px" }}>
            <Button onClick={handleSaveFont}> Save </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Font Info" />
          <Divider />
          <CardContent>
            <TextField readOnly size="small" label={"ID"} value={font.id} />
            <TextField readOnly size="small" label={"Name"} value={font.name} />
            <TextField
              required
              label={"Display name"}
              value={overrideDisplayName}
              onChange={(e: any) => {
                setOverrideDisplayName(e.target.value);
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Preview" />
          <Divider />
          <CardContent>
            <MediaUploader
              width="80%"
              media={previewImage}
              uploadPath={`fonts/${font.id}/preview`}
              allowedTypes={[MediaType.Image]}
              onChangeFunction={changeImage}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  function handleSaveFont() {
    saveFont({
      variables: {
        id: font.id,
        overrideDisplayName: overrideDisplayName,
        imageUrl: previewImage,
      },
    })
      .then(() => {
        showNotification({
          message: `Font was edited`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `Editing font failed! ${err}`,
          severity: "error",
        });
      });
  }
}

const SAVE_FONT_MUTATION = gql`
  mutation SaveFontMutation(
    $id: ID!
    $overrideDisplayName: String!
    $imageUrl: String!
  ) {
    saveFont(
      input: {
        id: $id
        overrideDisplayName: $overrideDisplayName
        imageUrl: $imageUrl
      }
    ) {
      success
    }
  }
`;
