import { Grid } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import BasicInfoForm from "../components/BasicInfoForm";

export default function NewPlan() {
  const navigate = useNavigate();
  const [addNewPlan] = useMutation(ADD_NEW_PLAN_MUTATION);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ fontSize: "26px" }}>
        <span>New Plan</span>
      </Grid>
      <Grid item xs={12}>
        <BasicInfoForm
          onSubmit={async (plan) => {
            await addNewPlan({ variables: { input: plan } }).then(
              (response) => {
                navigate(`/plans/${response.data.addNewPlan.plan.id}/edit`);
              }
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

const ADD_NEW_PLAN_MUTATION = gql`
  mutation AddNewPlanMutation($input: AddNewPlanInput!) {
    addNewPlan(input: $input) {
      plan {
        id
      }
    }
  }
`;
