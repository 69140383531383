import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Select,
  InputLabel,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "../../../../components/TextField";
import { PlanFAQ } from "../../../../types";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";

interface Props {
  faq: PlanFAQ;
  index: number;
  faqsLength: number;
  onChangeFAQ: (
    faq: PlanFAQ | null,
    beforeIndex: number,
    afterIndex?: number
  ) => void;
}

const FAQItem: React.FC<Props> = React.memo(
  ({ faq, onChangeFAQ, faqsLength, index }: Props) => {
    const [statePlanFAQ, setStatePlanFAQ] = useState<PlanFAQ>(faq);
    useEffect(() => {
      setStatePlanFAQ(faq);
    }, [faq]);
    const onChangeQuestion = (e: any) => {
      let newPlanFAQ = { ...statePlanFAQ };
      newPlanFAQ.question = e.target.value;
      setStatePlanFAQ(newPlanFAQ);
      onChangeFAQ(newPlanFAQ, newPlanFAQ.priority);
    };
    const onChangeAnswer = (e: any) => {
      let newPlanFAQ = { ...statePlanFAQ };
      newPlanFAQ.answer = e.target.value;
      setStatePlanFAQ(newPlanFAQ);
      onChangeFAQ(newPlanFAQ, newPlanFAQ.priority);
    };
    const onChangeImageUrl = (e: any) => {
      let newPlanFAQ = { ...statePlanFAQ };
      newPlanFAQ.imageUrl = e;
      setStatePlanFAQ(newPlanFAQ);
      onChangeFAQ(newPlanFAQ, newPlanFAQ.priority);
    };
    const onChangePriority = (e: any) => {
      let newPlanFAQ = { ...statePlanFAQ };
      newPlanFAQ.priority = Number(e.target.value) ?? 0;
      setStatePlanFAQ(newPlanFAQ);
      onChangeFAQ(newPlanFAQ, index, newPlanFAQ.priority);
    };
    const onDeletePlanFAQ = () => {
      onChangeFAQ(null, statePlanFAQ.priority);
    };

    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent="flex-end">
              <IconButton size="small" onClick={onDeletePlanFAQ}>
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="outlined-rating-native-simple">
                Priority
              </InputLabel>
              <Select
                native
                value={statePlanFAQ.priority}
                onChange={onChangePriority}
                label="Priority"
                style={{
                  height: 60,
                }}
                inputProps={{
                  name: "priority",
                  id: "outlined-priority-native-simple",
                }}
              >
                {[...Array(faqsLength)].map((_, i) => (
                  <option value={i} key={i}>
                    {i + 1}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline={true}
                label="Question"
                value={statePlanFAQ.question}
                onChange={onChangeQuestion}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline={true}
                label="Answer"
                value={statePlanFAQ.answer}
                onChange={onChangeAnswer}
              />
            </Grid>
            {statePlanFAQ.planId && statePlanFAQ.id && (
              <Grid item xs={12}>
                <div style={{ textAlign: "center" }}>
                  <MediaUploader
                    allowedTypes={[MediaType.Image]}
                    height="100px"
                    media={statePlanFAQ.imageUrl ?? null}
                    uploadPath={`plans/${statePlanFAQ.planId}/faqs/${statePlanFAQ.id}`}
                    onChangeFunction={onChangeImageUrl}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

export default FAQItem;
