import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  useMutation,
} from "@apollo/client";
import React from "react";
import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  NotificationData,
  useNotifications,
} from "../../../../components/Notification";
import { contractBucketName } from "../../../../helpers/gcp";
import { Contract } from "../../../../types";

export default function UploadContract({
  companyId,
  updateContract,
  getName,
  setUploading,
  Button,
}: UploadContractProps) {
  const { showNotification } = useNotifications();
  const [uploadContract] = useMutation<
    UploadContractResponse,
    UploadContractInput
  >(UPLOAD_CONTRACT_MUTATION);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = (e: React.MouseEvent) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        accept="application/pdf"
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleFileChange(
            e,
            companyId,
            updateContract,
            getName,
            setUploading,
            uploadContract,
            showNotification
          )
        }
        style={{ display: "none" }}
      />
      <div onClick={handleButtonClick}>{Button}</div>
    </>
  );
}

type UploadContractProps = {
  companyId: string;
  updateContract: ({
    id,
    name,
    url,
  }: {
    id: string;
    name: string;
    url: string;
  }) => void;
  getName: (file: File) => string;
  setUploading: (uploading: boolean) => void;
  Button: JSX.Element;
};

const uploadContract = async (
  contract: File,
  storageName: string,
  fileName: string,
  companyId: string,
  uploadFile: UploadContractMutation
) => {
  const path = `${companyId}`;
  const metadata = JSON.stringify({
    contentDisposition: `attachment; filename="${fileName}.pdf"`,
  });

  const variables = {
    input: {
      file: contract,
      path,
      fileName: storageName,
      bucketName: contractBucketName,
      metadata,
      isTemporary: true,
    },
  };

  const { data, errors } = await uploadFile({
    variables,
  });

  if (errors) throw errors[0];
  if (!data!.uploadFile.succeeded) throw Error("cause unknown");

  return data!.uploadFile.fileLocation.split("/").slice(4).join("/");
};

const handleFileChange = async (
  { target }: React.ChangeEvent<HTMLInputElement>,
  companyId: string,
  updateContract: ({
    id,
    name,
    url,
  }: {
    id: string;
    name: string;
    url: string;
  }) => void,
  getName: (file: File) => string,
  setUploading: (uploading: boolean) => void,
  uploadFile: UploadContractMutation,
  showNotification: (notification: NotificationData) => void
) => {
  setUploading(true);
  try {
    const file = target.files![0];

    const name = getName(file);

    const id = uuidv4();
    const url = await uploadContract(file, id, name, companyId, uploadFile);

    updateContract({
      id,
      url,
      name,
    });

    showNotification({
      message: `Uploading contract successful`,
      severity: "success",
    });
  } catch (e) {
    const error = e as Error;
    showNotification({
      message: `Uploading contract failed: ${error.message}`,
      severity: "error",
    });
  } finally {
    setUploading(false);
  }
};

export const generateUniqueContractName = (
  file: File,
  contracts: Contract[]
) => {
  const nameSplitByDot = file.name.split(".");
  const nameWithoutExtension =
    nameSplitByDot.length === 1
      ? nameSplitByDot[0]
      : nameSplitByDot.slice(0, nameSplitByDot.length - 1).join(".");
  return addUniqueSuffixToContractName(nameWithoutExtension, contracts);
};

const addUniqueSuffixToContractName = (
  contractName: string,
  contracts: Contract[]
) => {
  const zero = 9311;
  const twenty = zero + 20;

  const contractNamesStartingWithCompany = contracts
    .map(({ name: fileName }) => fileName)
    .filter((fileName) => fileName.startsWith(contractName));

  const orderedNumberSuffixes = contractNamesStartingWithCompany
    // get the last char and check if it is ⓪|①|②|...
    .map((fileName) => fileName.charAt(fileName.length - 1).codePointAt(0))
    .filter(
      (codePoint) =>
        codePoint !== undefined && codePoint >= zero + 1 && codePoint <= twenty
    )
    .sort()
    .reverse() as number[];

  if (contractNamesStartingWithCompany.includes(contractName))
    orderedNumberSuffixes.push(zero);

  const highestExistingNumberSuffix = orderedNumberSuffixes[0];
  if (highestExistingNumberSuffix === twenty)
    return `${contractName}${uuidv4()}`;

  const suffix =
    highestExistingNumberSuffix === twenty
      ? uuidv4()
      : `${
          (highestExistingNumberSuffix !== undefined &&
            String.fromCodePoint(highestExistingNumberSuffix + 1)) ||
          ""
        }`;

  return `${contractName}${suffix}`;
};

const UPLOAD_CONTRACT_MUTATION = gql`
  mutation UploadContractMutation($input: uploadFileInput!) {
    uploadFile(input: $input) {
      succeeded
      fileLocation
    }
  }
`;

type UploadContractResponse = {
  uploadFile: {
    succeeded: boolean;
    fileLocation: string;
  };
};

type UploadContractInput = {
  input: {
    file: any;
    path: string;
    fileName: string;
    bucketName?: string;
    metadata?: string;
    isTemporary?: boolean;
  };
};

type UploadContractMutation = (
  options?:
    | MutationFunctionOptions<UploadContractResponse, UploadContractInput>
    | undefined
) => Promise<FetchResult<UploadContractResponse, any, any>>;
