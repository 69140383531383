import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Company } from "../../../types";
import { CompanyForm } from "../components/CompanyForm";

export function EditCompany() {
  const { id } = useParams<{ id: string }>();

  type CompanyQueryResult = {
    company: Company;
  };

  const COMPANY_QUERY = gql`
    query CompanyQuery($id: ID!) {
      company(id: $id) {
        id
        name
        email
        createdAt
        sendToCompanyEmail
        venues {
          id
          name
        }
        bankAccount {
          id
          bank
          holder
          number
          recipient
          type
        }
        contracts {
          companyId
          id
          name
          url
        }
      }
    }
  `;

  const { loading, error, data } = useQuery<CompanyQueryResult>(COMPANY_QUERY, {
    variables: {
      id,
    },
  });

  const company = useMemo(
    () => (data === undefined ? undefined : data.company),
    [data]
  );

  if (loading) return <CircularProgress />;
  if (error) return <p>{error.message}</p>;

  return <CompanyForm company={company} />;
}
