import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import EditLocationCollectionsForm from "./form";

export default function Collections() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery(GET_LOCATION_COLLECTIONS_QUERY, {
    variables: { locationId: id },
  });
  if (loading) return <CircularProgress />;
  if (error) return <>{error.message}</>;

  const locationCollections = data ? data.locationCollections.records : null;
  const collections = data ? data.planCollections.records : null;
  return (
    <EditLocationCollectionsForm
      locationId={id!}
      locationCollections={locationCollections}
      collections={collections}
    />
  );
}

const GET_LOCATION_COLLECTIONS_QUERY = gql`
  query GetLocationCollectionsQuery($locationId: ID!) {
    locationCollections(locationId: $locationId) {
      totalRecords
      records {
        index
        planCollectionId
      }
    }
    planCollections(locationIds: [$locationId]) {
      records {
        id
        name
        slug
      }
    }
  }
`;
