import { useGlobalContext } from "../../GlobalContext";
import GetRoutes from "../../Routes";
import { NotificationBar } from "../Notification";

export default function Content() {
  const { isLoggedIn } = useGlobalContext();
    
  return (
    <div
      style={{
        marginTop: "15px",
        marginLeft: isLoggedIn ? "220px" : "20px",
        marginRight: "20px",
      }}
    >
      <GetRoutes />
      <NotificationBar />
    </div>
  );
}
