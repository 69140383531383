import { Typography } from "@mui/material";
import BaseCard, { BaseCardProps } from "./BaseCard";
import s from "./Card.module.css";

type HistoryCardProps = Pick<BaseCardProps, "edit">;

export default function HistoryCard({ edit }: HistoryCardProps) {
  // This card is used when operators need to see the historic value for logging purposes
  const originalValue = JSON.parse(edit.oldValue);

  return (
    <BaseCard
      edit={edit}
      mutable={false}
      originalValue={originalValue}
      headerContent={
        <div
          style={{
            display: "flex",
            gap: "1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
          className={s.Action}
        >
          <Typography variant="h6" fontWeight="bold">
            {edit?.action ?? "VERIFIED"}
          </Typography>
          <Typography variant="h6">
            {
              // slice off the seconds
              (edit?.verifiedAt ?? "").slice(0, -3)
            }
          </Typography>
        </div>
      }
    />
  );
}
