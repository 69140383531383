import { gql, useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useNotifications } from "../../components/Notification";
import { BackofficeUser } from "../../types";

export default function DeleteUserDialog(props: {
  user: BackofficeUser;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const { showNotification } = useNotifications();
  const [deleteActivityMutation] = useMutation(DELETE_USER_MUTATION);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        Delete <b>{props.user.username}</b>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete the user?
        <br />
        <br />
        This action will remove the user from backoffice.
        <br />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          No
        </Button>
        <Button color="primary" onClick={deleteActivity}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  function deleteActivity() {
    deleteActivityMutation({
      variables: {
        input: {
          id: props.user.id,
        },
      },
    })
      .then(() => {
        showNotification({
          message: `The user ${props.user.username} was successfully deleted.`,
          severity: "success",
        });
        props.onConfirm();
      })
      .catch((reason: Error) => {
        showNotification({
          message: `To delete user failed! ${reason.message}`,
          severity: "error",
        });
      });

    props.onClose();
  }
}

const DELETE_USER_MUTATION = gql`
  mutation DeleteUserMutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      success
    }
  }
`;
