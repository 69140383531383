import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { DialogName } from "../types";

export default function RefundDialog(props: {
  reservationStatus: string;
  open: boolean;
  setOpenDialog: (name: DialogName) => void;
  closeDialog: () => void;
}) {
  return (
    <Dialog open={props.open} onClose={props.closeDialog}>
      <DialogTitle>Refund Payment</DialogTitle>
      <DialogContent>
        Do you also want to cancel the reservation and booking?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setOpenDialog("REFUND_WITHOUT_CANCELLATION");
          }}
        >
          No
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (
              props.reservationStatus === "NOT_YET_RESERVED" ||
              props.reservationStatus === "RESERVED"
            ) {
              props.setOpenDialog("CANCEL_RESERVATION");
            } else {
              props.setOpenDialog("CANCEL_WITH_REFUND");
            }
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
