import Icon from "../../components/Icon";
import { FaGlassCheers, FaHiking, FaBabyCarriage, FaCar } from "react-icons/fa";
import { GiPresent, GiBeerStein, GiChickenOven } from "react-icons/gi";
import { GoPrimitiveDot } from "react-icons/go";
import { MdLocalActivity } from "react-icons/md";
import { RiRestaurantLine, RiCakeLine } from "react-icons/ri";

export const icons = new Map([
  ["GoPrimitiveDot", <GoPrimitiveDot />],
  ["RiRestaurantLine", <RiRestaurantLine />],
  ["RiCakeLine", <RiCakeLine />],
  ["GiPresent", <GiPresent />],
  ["MdLocalActivity", <MdLocalActivity />],
  ["FaGlassCheers", <FaGlassCheers />],
  ["GiBeerStein", <GiBeerStein />],
  ["FaHiking", <FaHiking />],
  ["FaBabyCarriage", <FaBabyCarriage />],
  ["FaCar", <FaCar />],
  ["GiChickenOven", <GiChickenOven />],
  ["Bar", <Icon name={"Bar"} />],
  ["Conference", <Icon name={"Conference"} />],
  ["DiningTable", <Icon name={"DiningTable"} />],
  ["Dinner", <Icon name={"Dinner"} />],
  ["Terrace", <Icon name={"Terrace"} />],
  ["TerraceB", <Icon name={"TerraceB"} />],
  ["VIP", <Icon name={"VIP"} />],
]);
