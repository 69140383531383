import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../../components/Button2";
import DataTable from "../../../components/DataTable";
import { Partner } from "../../../types";

export default function PartnersList() {
  const navigate = useNavigate();
  const location = useLocation();

  const pageSize = 10;

  const parsedQueryString = queryString.parse(location.search);

  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );

  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );

  useEffect(updateUrl, [page, search, navigate]);

  const { loading, error, data, fetchMore } = useQuery(GET_PARTNERS, {
    variables: { offset: page * pageSize, limit: pageSize, search },
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEdit },
    { name: "Created At", field: "createdAt" },
    { name: "Name", field: "name" },
    { name: "Email", field: "email" },
    { name: "Last Logged In", field: "lastLoggedIn" },
  ];
  const rows = data ? data.partners.records : null;

  return (
    <DataTable
      loading={loading}
      columns={columns}
      rows={rows}
      topBarExtras={renderTopBarExtras()}
      onSearch={(event) => {
        setSearch(event.target.value);
        setPage(0);
      }}
      searchValue={search}
      pagination={{
        totalRows: data ? data.partners.totalRecords : null,
        pageSize: pageSize,
        currentPage: page,
        onChangePage: (_, newPage) => {
          setPage(newPage);
          fetchMore({
            variables: {
              offset: newPage * pageSize,
              limit: pageSize,
              search,
            },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        },
      }}
    />
  );

  function updateUrl() {
    const urlParams = { page: page + 1, search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderTopBarExtras() {
    return (
      <Button
        style={{ backgroundColor: "white", color: "#c8a063" }}
        variant="contained"
        component={Link}
        to="/partners/new"
        startIcon={<AddIcon />}
      >
        New
      </Button>
    );
  }
}

function renderEdit(row: Partner) {
  return (
    <Button size="small" component={Link} to={`/partners/${row.id}`}>
      <EditIcon />
    </Button>
  );
}

const GET_PARTNERS = gql`
  query GetPartnersQuery($offset: Int!, $limit: Int!, $search: String) {
    partners(offset: $offset, limit: $limit, search: $search) {
      totalRecords
      records {
        id
        createdAt
        name
        email
        lastLoggedIn
      }
    }
  }
`;
