import { default as MuiTextField } from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import type { OutlinedTextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";

const TextField = (props: TextFieldProps) => {
  let propsForMuiTextField = { ...props };
  // these props give error if passed to muitextfield
  delete propsForMuiTextField.endAdornment;
  delete propsForMuiTextField.startAdornment;

  // if no id set, use label
  const id = props.hasOwnProperty("id")
    ? props.id
    : String(props.label ?? "")
        .replaceAll(" ", "-")
        .toLowerCase();

  return (
    <MuiTextField
      {...propsForMuiTextField}
      id={id}
      style={{
        width: "100%",
        marginTop: "12px",
        ...props.style,
      }}
      variant="outlined"
      InputProps={{
        style: { color: props.fontcolor ? props.fontcolor : undefined },
        readOnly: props.readOnly,
        endAdornment: props.endAdornment ? (
          <InputAdornment position="end">{props.endAdornment}</InputAdornment>
        ) : undefined,
        startAdornment: props.startAdornment ? (
          <InputAdornment position="start">
            {props.startAdornment}
          </InputAdornment>
        ) : undefined,
        ...props.InputProps,
      }}
    >
      {props.children}
    </MuiTextField>
  );
};

export default TextField;

interface TextFieldProps extends Omit<MuiTextFieldProps, "variant"> {
  fontcolor?: string;
  readOnly?: boolean;
  endAdornment?: JSX.Element | string;
  startAdornment?: JSX.Element | string;
}
