import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { RoleType } from "../../../types";
import RoleEditForm from "./RoleEditForm";

export default function EditRole() {
  const { id } = useParams<{ id: string }>();

  type RoleQueryResult = {
    role: RoleType;
  };

  const ROLE_QUERY = gql`
    query RoleQuery($id: ID!) {
      role(id: $id) {
        id
        name
        createdAt
        accessPermissions {
          id
          name
          path
        }
      } 
    }
  `;

  const { loading, error, data,refetch } = useQuery<RoleQueryResult>(ROLE_QUERY, {
    variables: {
      id,
    },
  });

  const role = useMemo(
    () => (data === undefined ? undefined : data.role),
    [data]
  );

  if (loading) return <CircularProgress />;
  if (error) return <p>{error.message}</p>;

  return <RoleEditForm role={role} refetch={refetch}/>;
}
