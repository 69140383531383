import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import Button from "../../../../components/Button2";
import { CollectionImage } from "../../../../types";
import EditCollectionImageForm from "./form";

export default function EditCollectionImage() {
  const { id } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery(GET_COLLECTION_IMAGE, {
    variables: { id },
  });
  if (error) return <>Error! {error.message}</>;
  const collectionImage: CollectionImage | null = data
    ? data.planCollectionImage
    : null;

  return (
    <>
      <div>
        <Button
          style={{ marginBottom: "15px" }}
          component={Link}
          to={`/plans/collections/images`}
        >
          Back to list
        </Button>
      </div>
      {loading || !collectionImage ? (
        <CircularProgress />
      ) : (
        <EditCollectionImageForm collectionImage={collectionImage} />
      )}
    </>
  );
}

const GET_COLLECTION_IMAGE = gql`
  query CollectionImageEditQuery($id: ID!) {
    planCollectionImage(id: $id) {
      id
      createdAt
      name
      imageUrl
    }
  }
`;
