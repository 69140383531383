import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import PageSection from "../../../../components/PageSection";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

// Define TypeScript interfaces for the query and mutation data structure
interface PlanPaidItem {
  id: string;
  name: string;
  description: string;
  price: number;
}

interface BookingPaidItem {
  id: string;
  bookingId: string;
  quantity: number;
  price: number;
  planPaidItemId: string;
  planPaidItem: PlanPaidItem;
}

interface GetBookingPaidItemsData {
  getBookingPaidItems: BookingPaidItem[];
}

interface GetBookingPaidItemsVars {
  bookingId: string;
}

interface UpdateBookingPaidItemInput {
  id: string;
  quantity: number;
  price: number;
}

interface UpdateBookingPaidItemData {
  updateBookingPaidItem: {
    id: string;
    bookingId: string;
    planPaidItemId: string;
    quantity: number;
    price: number;
  };
}

// Define the GraphQL query
const GET_BOOKING_PAID_ITEMS = gql`
  query GetBookingPaidItems($bookingId: ID!) {
    getBookingPaidItems(bookingId: $bookingId) {
      id
      bookingId
      quantity
      price
      planPaidItemId
      planPaidItem {
        name
        description
        id
        price
      }
    }
  }
`;

// Define the mutation for updating BookingPaidItem
const UPDATE_BOOKING_PAID_ITEM = gql`
  mutation UpdateBookingPaidItem($input: UpdateBookingPaidItemInput!) {
    updateBookingPaidItem(input: $input) {
      id
      quantity
      price
    }
  }
`;

export default function PaidOptionForm() {
  const { id: bookingId } = useParams<{ id: string | undefined }>();

  // Fetch booking paid items using the useQuery hook
  const { loading, error, data } = useQuery<GetBookingPaidItemsData, GetBookingPaidItemsVars>(
    GET_BOOKING_PAID_ITEMS,
    {
      variables: { bookingId: bookingId || "" },  // Ensure bookingId is a string or empty if undefined
    }
  );

  // Set up mutation to update booking paid items
  const [updateBookingPaidItem] = useMutation<UpdateBookingPaidItemData, { input: UpdateBookingPaidItemInput }>(
    UPDATE_BOOKING_PAID_ITEM
  );



  // Extract booking paid items from the response
  const bookingPaidList = data?.getBookingPaidItems || [];

  // State to track updated quantities
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

  // Event handler for updating quantity in the state
  const handleQuantityChange = (item: BookingPaidItem, e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value);
    setQuantities({
      ...quantities,
      [item.id]: newQuantity,
    });
  };

  // Handle loading and error states
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <Grid container spacing={2}>
      {/* Paid Options Form */}
      {bookingPaidList.map((item: BookingPaidItem) => (
        <PageSection key={item.id} lg={12}>
          <Grid container spacing={2}>
            {/* Paid Option Name */}
            <Grid item xs={8}>
              <TextField
                label="Paid Option Name"
                fullWidth
                value={item.planPaidItem.name}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Quantity"
                type="number"
                fullWidth
                value={quantities[item.id] ?? item.quantity}
                onChange={(e: any) => handleQuantityChange(item, e)}  // Call the mutation when quantity changes
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
              />
            </Grid>
          </Grid>
        </PageSection>
      ))}
    </Grid>
  );
}
