import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function VerifyDialog({
  open,
  handleClose,
  onConfirm,
  title,
  text,
}: EditDialogProps) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm();
            }}
          >
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
};

type EditDialogProps = DialogProps & {
  text: string;
  title: string;
};

export function DiscardEditDialog(props: DialogProps) {
  const title = "Discard partner edits";
  const text = `Are you sure you finished processing the edit requested by the
  partner?

  If you discard the edit it will disappear from this page.
  `;
  return <VerifyDialog {...props} title={title} text={text} />;
}

export function ApplyEditDialog(props: DialogProps) {
  const title = "Apply partner edits";
  const text = `Are you sure you finished processing the edit requested by the
  partner?

  If you apply the edit the plan will be altered and the edit will disappear from this page.
  `;

  return <VerifyDialog {...props} title={title} text={text} />;
}
