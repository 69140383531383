import Grid from "@mui/material/Grid";

import ConfirmReservationButton from "./ConfirmReservationButton";
import RejectReservationButton from "./RejectReservationButton";
import CancelReservationButton from "./CancelReservationButton";
import ResendEmailsButton from "./ResendEmailsButton";
import { VenueReservationType } from "../types";

export default function ActionButtons(props: ActionButtonsProps) {
  if (props.reservation.booking.paymentStatus === "NOT_YET_PAID") return <></>;

  return (
    <>
      {props.reservation.status === "NOT_YET_RESERVED" && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ConfirmReservationButton reservation={props.reservation} />
          </Grid>
          <Grid item xs={6}>
            <RejectReservationButton reservation={props.reservation} />
          </Grid>
          <Grid item xs={12}>
            <ResendEmailsButton reservation={props.reservation} />
          </Grid>
        </Grid>
      )}
      {props.reservation.status === "RESERVED" && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CancelReservationButton reservation={props.reservation} />
          </Grid>
          <Grid item xs={12}>
            <ResendEmailsButton reservation={props.reservation} />
          </Grid>
        </Grid>
      )}
      {props.reservation.status === "CANCELLED" && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ResendEmailsButton reservation={props.reservation} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

interface ActionButtonsProps {
  reservation: VenueReservationType;
}
