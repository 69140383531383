import { Skeleton } from "@mui/material";

export default function ContractRowSkeleton() {
  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <Skeleton variant="rectangular" height={60} style={{ flexGrow: 1 }} />
      {Array.from({ length: 3 }).map(() => (
        <Skeleton variant="circular" width={32} height={32} />
      ))}
    </div>
  );
}
