import { NavigateFunction } from "react-router-dom";

export default async function handlePersistAnnouncement(
  synopsis: string,
  content: string,
  showNotification: any,
  announcementMutation: any,
  navigate: NavigateFunction,
  action: "add" | "edit",
  id?: string
) {
  if (!synopsis)
    return showNotification({
      message: "Please input a synopsis",
      severity: "error",
    });

  if (!content)
    return showNotification({
      message: "Please input content",
      severity: "error",
    });

  try {
    await announcementMutation({ variables: { id, synopsis, content } });
    showNotification({
      message: `announcement ${action}ed`,
      severity: "success",
    });
    navigate("/announcements");
  } catch (error: any) {
    showNotification({
      message: `Failed to ${action} new announcement! ${error.message}`,
      severity: "error",
    });
  }
}
