import { Button as MuiButton, ButtonProps } from "@mui/material";
import useStyles from "./styles";

export default function Button(props: AoButtonProps) {
  const classes = useStyles();

  let propsForButton = { ...props };
  const className = propsForButton.className;
  delete propsForButton.className;

  return (
    <MuiButton
      className={`${
        props.color === "secondary"
          ? classes.secondaryButton
          : classes.primaryButton
      } ${className}`}
      variant="contained"
      {...propsForButton}
    >
      {propsForButton.children}
    </MuiButton>
  );
}

interface AoButtonProps extends ButtonProps<any> {
  color?: "primary" | "secondary";
  disabled?: boolean;
}
