import TextField from "../../../components/TextField";
import type { OutlinedTextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";

export function RequiredTextfield(
  props: React.PropsWithChildren<RequiredTextfieldProps<string>>
) {
  const { label, value, setValue, children, ...rest } = props;
  const isEmpty = !Boolean(value);
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      required
      label={label}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      }}
      error={isEmpty}
      placeholder="required"
      {...rest}
    >
      {children}
    </TextField>
  );
}

type RequiredTextfieldProps<T> = {
  label: string;
  value: T;
  setValue: (value: T) => void | React.Dispatch<React.SetStateAction<T>>;
} & Omit<MuiTextFieldProps, "variant">;
