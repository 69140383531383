import {
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import Button from "../../components/Button2";
import { default as TF } from "../../components/TextField";
import { synopsis_character_limit } from "./constants";
import ReactMarkdown from "react-markdown";

export interface AnnouncementFormProps {
  synopsis: string;
  setSynopsis: any;
  content: string;
  setContent: any;
  handlePersistAnnouncement: any;
  persistAnnouncementButtonText: string;
}
function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

export default function AnnouncementForm({
  synopsis,
  setSynopsis,
  content,
  setContent,
  handlePersistAnnouncement,
  persistAnnouncementButtonText,
}: AnnouncementFormProps) {
  return (
    <>
      <div>
        <Button
          style={{ marginBottom: "15px" }}
          component={Link}
          to={`/announcements`}
        >
          Back to list
        </Button>
      </div>
      <Card style={{ marginBottom: "2rem" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="Synopsis"
                value={synopsis}
                onChange={(e: any) => setSynopsis(e.target.value)}
                inputProps={{ maxLength: synopsis_character_limit }}
                helperText={`${synopsis.length}/${synopsis_character_limit}`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Content"
                value={content}
                multiline
                rows={16}
                onChange={(e: any) => setContent(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper
          elevation={0}
          style={{
            padding: "1rem",
            backgroundColor: "#e3e3e3",
          }}
        >
          <Typography variant="h5">{synopsis}</Typography>
          <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <ReactMarkdown>{content}</ReactMarkdown>
        </Paper>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handlePersistAnnouncement}>
          {persistAnnouncementButtonText}
        </Button>
      </div>
    </>
  );
}
