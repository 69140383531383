import { useParams } from "react-router-dom";
import { useMutation, useQuery, gql } from "@apollo/client";

import { useNotifications } from "../../../../components/Notification";
import ImageGalleryUploader from "../../../../components/ImageGalleryUploader";
import { ImageType } from "../../../../components/ImageGalleryUploader/index";
import { PhotoCategory } from "../../../../types";

export default function Photos() {
  const { showNotification } = useNotifications();
  const { id: planId } = useParams<{ id: string }>();
  const [savePlanPhotos] = useMutation(SAVE_PLAN_PHOTOS_MUTATION);
  const { data, loading, error } = useQuery<QueryResponse>(PLAN_PHOTOS_QUERY, {
    variables: { id: planId },
  });

  if (loading) return <>loading...</>;
  if (error) return <>something went wrong</>;
  const photos = data ? data.plan.photos : [];

  return (
    <ImageGalleryUploader
      images={photos}
      uploadPath={`plans/${planId}/photos`}
      saveImagesFunction={handleSavePhotos}
      useImageCategory={true}
      gridSizes={{ xs: 12, sm: 6, md: 3 }}
    />
  );

  function handleSavePhotos(photos: ImageType[]) {
    savePlanPhotos({ variables: { input: { id: planId, photos: photos } } })
      .then(() =>
        showNotification({
          message: "Plan photos saved",
          severity: "success",
        })
      )
      .catch((e: any) =>
        showNotification({
          message: `Saving plan photos failed, ${e}`,
          severity: "error",
        })
      );
  }
}

const PLAN_PHOTOS_QUERY = gql`
  query PlanPhotosQuery($id: ID!) {
    plan(id: $id) {
      photos {
        id
        imageUrl
        category
      }
    }
  }
`;

const SAVE_PLAN_PHOTOS_MUTATION = gql`
  mutation SavePlanPhotosMutation($input: SavePlanPhotosInput!) {
    savePlanPhotos(input: $input) {
      plan {
        id
      }
    }
  }
`;

interface QueryResponse {
  plan: {
    photos: {
      id: string;
      imageUrl: string;
      category: PhotoCategory;
    }[];
  };
}
