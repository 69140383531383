import * as React from "react";
import Checkbox from "../../../components/Checkbox";
import { Grid } from "@mui/material";
import { ProtectedRoute, protectedRoutes } from "../../../Routes";
import { AccessPermisstionType } from "../Edit/RoleEditForm";
import useStyles from "./styles";

export interface Props {
  accessPermission: AccessPermisstionType[];
  setAccessPermission: (value: AccessPermisstionType[]) => void;
}

export default function RoleAccessPermission({
  setAccessPermission,
  accessPermission,
}: Props) {
  const classes = useStyles();
  const optionRoutesGroup = getRouteOptions(protectedRoutes);

  const checkRouteMatch = (route: AccessPermisstionType): boolean => {
    const routeMatch = accessPermission.find(
      (item: AccessPermisstionType) => item.path === route.path
    );
    return Boolean(routeMatch);
  };

  const hanldeAccessPermissionChange = (
    checked: boolean,
    route: AccessPermisstionType
  ) => {
    if (checked) {
      const subRoutes: AccessPermisstionType[] = [];
      if (route.childrenPaths) {
        route.childrenPaths.forEach((path, index) => {
          subRoutes.push({
            path: path,
            name: route.name + ` Sub route ${index + 1}`,
          });
        });
      }
      setAccessPermission([...accessPermission, ...subRoutes, route]);
    } else {
      const newAccessPermission = accessPermission.filter(
        (item: AccessPermisstionType) =>
          item.path !== route.path && !route.childrenPaths?.includes(item.path)
      );
      setAccessPermission(newAccessPermission);
    }
  };

  const hanldeCheckAllAccessPermissions = (
    checked: boolean,
    routes: AccessPermisstionType[]
  ) => {
    const notHasRoutes = routes.filter((route: AccessPermisstionType) => {
      return !accessPermission.find(
        (item) =>
          item.path === route.path || route.childrenPaths?.includes(item.path)
      );
    });
    if (checked) {
      const subRoutes: AccessPermisstionType[] = [];
      notHasRoutes.forEach((route) => {
        if (route.childrenPaths) {
          route.childrenPaths.forEach((path, index) => {
            subRoutes.push({
              path: path,
              name: route.name + ` Sub route ${index + 1}`,
            });
          });
        }
      });
      setAccessPermission([...accessPermission, ...subRoutes, ...notHasRoutes]);
    } else {
      const allRoutesDelete = flattenPaths(routes);
      const newAccessPermission = accessPermission.filter(
        (item: AccessPermisstionType) => !allRoutesDelete.includes(item.path)
      );
      setAccessPermission(newAccessPermission);
    }
  };

  return (
    <>
      {optionRoutesGroup.map((group: any) => (
        <Grid item xs={3}>
          <div className={classes.header_option}>
            <h4 className={classes.header_option_title}>{group.name}</h4>
            <Checkbox
              label=""
              labelPlacement="end"
              onChange={(e: boolean) =>
                hanldeCheckAllAccessPermissions(e, group.options)
              }
              checked={isCheckAll(accessPermission, group.options)}
            />
          </div>
          <Grid container xs={12} className={classes.chid_option_container}>
            {group?.options?.map((route: AccessPermisstionType) => (
              <Checkbox
                label={route?.name}
                labelPlacement="end"
                onChange={(e: boolean) =>
                  hanldeAccessPermissionChange(e, route)
                }
                checked={checkRouteMatch(route)}
              />
            ))}
          </Grid>
        </Grid>
      ))}
    </>
  );
}

function getRouteOptions(routers: ProtectedRoute[]) {
  let routerList = routers.filter((router: ProtectedRoute) =>
    Boolean(router.name)
  );
  const groupedRoutes = routerList.reduce((acc: any, route: ProtectedRoute) => {
    const group = route.group || "Orther";
    if (!acc[group]) {
      acc[group] = { name: group, options: [] };
    }
    acc[group].options.push(route);
    return acc;
  }, {});
  return Object.values(groupedRoutes);
}

function isCheckAll(
  accessPermission: AccessPermisstionType[],
  options: Pick<AccessPermisstionType, "name" | "path">[]
): boolean {
  let result = true;
  for (let index = 0; index < options.length; index++) {
    const option = accessPermission.find(
      (item) => item.path === options[index].path
    );
    if (!option) {
      result = false;
      break;
    }
  }
  return result;
}

function flattenPaths(arr: AccessPermisstionType[]) {
  return arr.reduce((acc: string[], item: AccessPermisstionType) => {
    acc.push(item.path); // add the current path
    if (item.childrenPaths) {
      item.childrenPaths.forEach((child) => acc.push(child));
    }
    return acc;
  }, []);
}
