import { useParams } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import Photos from "./Photos";
import Schedule from "./Schedule";
import DataTabs from "../../../components/DataTabs";

const TabIndices: Record<string, number> = {
  basicInfo: 0,
  photos: 1,
  schedule: 2,
  overview: 3,
};

export default function EditVenue() {
  const { tab } = useParams<{ tab: string }>();
  const tabIndex = TabIndices[tab!] || 0;

  return (
    <DataTabs
      selectedTab={tabIndex}
      tabs={[
        {
          title: "Basic info",
          content: <BasicInfo />,
        },
        {
          title: "Photos",
          content: <Photos />,
        },
        {
          title: "Schedule",
          content: <Schedule />,
        },
      ]}
    />
  );
}
