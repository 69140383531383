import { Link } from "react-router-dom";
import Button from "../../../components/Button2";
import DataTabs from "../../../components/DataTabs";
import BasicInfo from "./BasicInfo";
import Specials from "./Specials";
import Collections from "./Collections";

export default function EditLocation() {
  return (
    <>
      <Button
        style={{ marginBottom: "15px" }}
        component={Link}
        to={`/locations`}
      >
        Back to list
      </Button>
      <DataTabs
        selectedTab={0}
        tabs={[
          { title: "Location", content: <BasicInfo /> },
          { title: "Specials", content: <Specials /> },
          { title: "Collections", content: <Collections /> },
        ]}
      />
    </>
  );
}
