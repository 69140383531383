import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
  useQuery,
} from "@apollo/client";
import { createContext, useCallback, useMemo } from "react";
import { useNotifications } from "../../components/Notification";
import { useGlobalContext } from "../../GlobalContext";
import { Tag, TagCategory } from "../../types";
import {
  DeleteTagMutationInput,
  DeleteTagMutationResponse,
  DELETE_TAG_MUTATION,
} from "./queries/deleteTagMutation";
import {
  EditTagMutationInput,
  EditTagMutationResponse,
  EDIT_TAG_MUTATION,
} from "./queries/editTagMutation";
import {
  GetTagCategoriesAndTagsQueryInput,
  GetTagCategoriesAndTagsQueryResponse,
  GET_TAG_CATEGORIES_AND_TAGS_QUERY,
} from "./queries/getTagCategoriesAndTagsQuery";

type TagsContext = {
  tags: Tag[];
  getFilteredTags: (filter: string) => Tag[];
  tagCategories: TagCategory[];
  tagNames: Set<string>;
  editTag: (
    options?:
      | MutationFunctionOptions<
          EditTagMutationResponse,
          EditTagMutationInput,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<
    FetchResult<
      EditTagMutationResponse,
      Record<string, any>,
      Record<string, any>
    >
  >;
  editTagResponse: MutationResult<EditTagMutationResponse>;
  deleteTag: (
    options?:
      | MutationFunctionOptions<
          DeleteTagMutationResponse,
          DeleteTagMutationInput,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<
    FetchResult<
      DeleteTagMutationResponse,
      Record<string, any>,
      Record<string, any>
    >
  >;
  deleteTagResponse: MutationResult<DeleteTagMutationResponse>;
};

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagsContext = createContext<TagsContext>(undefined);

export default function TagsProvider({
  children,
}: React.PropsWithChildren<any>) {
  const { showNotification } = useNotifications();
  const { isLoggedIn } = useGlobalContext();

  const getTags = useQuery<
    GetTagCategoriesAndTagsQueryResponse,
    GetTagCategoriesAndTagsQueryInput
  >(GET_TAG_CATEGORIES_AND_TAGS_QUERY, {
    skip: !isLoggedIn,
  });

  const [editTagMutation, editTagResponse] = useMutation<
    EditTagMutationResponse,
    EditTagMutationInput
  >(EDIT_TAG_MUTATION);

  const editTag = useCallback(
    async (args: Parameters<typeof editTagMutation>[0]) => {
      const result = await editTagMutation({
        ...args,
        update: (cache, result) => {
          const { tag, originalTag } = result.data!.editTag;

          cache.updateQuery<
            GetTagCategoriesAndTagsQueryResponse,
            GetTagCategoriesAndTagsQueryInput
          >(
            {
              query: GET_TAG_CATEGORIES_AND_TAGS_QUERY,
            },
            (data) => ({
              tagCategories: (data?.tagCategories ?? []).map((tagCategory) =>
                tagCategory.id === tag.tagCategoryId
                  ? {
                      ...tagCategory,
                      tags: tagCategory.tags!.find(
                        (otherTag) => otherTag.id === tag.id
                      )
                        ? tagCategory.tags!.map((otherTag) =>
                            otherTag.id === tag.id ? tag : otherTag
                          )
                        : [tag, ...tagCategory.tags!],
                    }
                  : tagCategory.id === originalTag.tagCategoryId
                  ? {
                      ...tagCategory,
                      tags: (tagCategory.tags ?? []).filter(
                        (otherTag) => otherTag.id !== tag.id
                      ),
                    }
                  : tagCategory
              ),
            })
          );
        },
      });

      if (result.errors?.[0]) {
        showNotification({
          severity: "error",
          message: `failed to save tag: ${result.errors[0].message}`,
        });
      } else {
        showNotification({
          severity: "success",
          message: `saved tag ${result.data?.editTag.tag.name}`,
        });
      }

      return result;
    },
    [editTagMutation, showNotification]
  );

  const [deleteTagMutation, deleteTagResponse] = useMutation<
    DeleteTagMutationResponse,
    DeleteTagMutationInput
  >(DELETE_TAG_MUTATION);

  const deleteTag = useCallback(
    async (args: Parameters<typeof deleteTagMutation>[0]) => {
      const result = await deleteTagMutation({
        ...args,
        update: (cache, result) => {
          const resultTag = result.data!.deleteTag;
          cache.updateQuery<
            GetTagCategoriesAndTagsQueryResponse,
            GetTagCategoriesAndTagsQueryInput
          >(
            {
              query: GET_TAG_CATEGORIES_AND_TAGS_QUERY,
            },
            (data) => ({
              tagCategories: (data?.tagCategories ?? []).map((tagCategory) =>
                tagCategory.id === resultTag.tagCategoryId
                  ? {
                      ...tagCategory,
                      tags: (tagCategory.tags ?? []).filter(
                        (tag) => tag.id !== resultTag.id
                      ),
                    }
                  : tagCategory
              ),
            })
          );
        },
      });

      if (result.errors?.[0]) {
        showNotification({
          severity: "error",
          message: `failed to delete tag: ${result.errors[0].message}`,
        });
      } else {
        showNotification({
          severity: "success",
          message: `deleted tag ${result.data?.deleteTag.name}`,
        });
      }

      return result;
    },
    [deleteTagMutation, showNotification]
  );

  const tagCategories = useMemo(() => getTags.data?.tagCategories ?? [], [
    getTags.data,
  ]);

  const tags = useMemo(
    () =>
      tagCategories.flatMap((tagCategory) =>
        (tagCategory.tags ?? []).map((tag) => ({ ...tag, tagCategory }))
      ),
    [tagCategories]
  );

  const getFilteredTags = useCallback(
    (filter: string) =>
      tags.filter(
        (tag) =>
          tag.name.toLowerCase().includes(filter.toLowerCase()) ||
          tag.tagCategory.name.toLowerCase().includes(filter.toLowerCase()) ||
          String(tag.id) === filter
      ),
    [tags]
  );

  const tagNames = useMemo(() => new Set(tags.map((tag) => tag.name)), [tags]);

  return (
    <TagsContext.Provider
      value={{
        tags,
        getFilteredTags,
        tagCategories,
        tagNames,
        editTag,
        editTagResponse,
        deleteTag,
        deleteTagResponse,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
}
