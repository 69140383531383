import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import TextField from "../../../components/TextField";

type ConfirmationSelectProps<T> = {
  label: string;
  value: T;
  setValue: (value: T) => void;
  values: T[];
  fontColor?: string;
  readOnly?: boolean;
};

export function ConfirmationSelect<T extends string>({
  label,
  value,
  setValue,
  values,
  fontColor,
  readOnly,
}: ConfirmationSelectProps<T>) {
  const [openDialog, setOpenDialog] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  return (
    <>
      <TextField
        select
        SelectProps={{
          open: openSelect,
          onClick: () => {
            if (readOnly) return;
            if (!(openDialog || openSelect)) {
              setOpenDialog(true);
            }
            setOpenSelect(false);
          },
          onChange: (event) => {
            setValue((event.target.value as unknown) as T);
          },
        }}
        label={label}
        value={value}
        fontcolor={fontColor}
      >
        {values.map((option, i) => {
          return (
            <MenuItem value={option} key={i}>
              {option}
            </MenuItem>
          );
        })}
      </TextField>

      <Dialog open={openDialog}>
        <DialogTitle>{`Edit ${label} field?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to edit a field that usually shouldn't be changed.
            <br />
            Please consider whether this change is necessary before proceeding.
            <br />
            Contact the Development team in case of doubt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setOpenSelect(true);
            }}
            style={{ fontWeight: 100 }}
          >
            Continue
          </Button>
          <Button
            style={{ fontWeight: 700 }}
            onClick={() => setOpenDialog(false)}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
