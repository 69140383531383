import { gql, useQuery } from "@apollo/client";
import { Card, CardContent, Chip, IconButton, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TagIcon from "@mui/icons-material/Tag";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CancelIcon from "@mui/icons-material/Cancel";

import DataTable from "../../../components/DataTable";
import { Location } from "../../../types";
import { useEffect, useState } from "react";

export default function Plans(props: {
  tagIds: number[];
  locationIds: string[];
  allTags: boolean;
  allLocations: boolean;
  priceMinimum?: number | null;
  priceMaximum?: number | null;
}) {
  const plansPerPage = 10;
  const [page, setPage] = useState(0);
  const { data, loading, error } = useQuery(GET_PLANS_BY_FILTER, {
    variables: {
      tagIds: props.tagIds,
      locationIds: props.locationIds,
      allTags: props.allTags,
      allLocations: props.allLocations,
      priceMinimum: props.priceMinimum,
      priceMaximum: props.priceMaximum,
      offset: page * plansPerPage,
      limit: plansPerPage,
    },
  });

  const [totalRows, setTotalRows] = useState(0);
  const [publishedPlansCount, setPublishedPlansCount] = useState(0);

  useEffect(() => setPage(0), [props]);
  useEffect(() => {
    if (data === undefined) return;
    setTotalRows(data.plansByFilter.totalCount);
    setPublishedPlansCount(data.plansByFilter.totalPublished);
  }, [data]);

  if (error) return <>{error.message}</>;

  const columns = [
    { name: "", render: renderIsPublished },
    { name: "name", field: "name" },
    { name: "tags", render: renderTags },
    { name: "location", render: renderLocation },
  ];

  const rows = data ? data.plansByFilter.records : [];
  const totalPages = Math.ceil(totalRows / plansPerPage);

  return (
    <Card>
      <CardContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ flexGrow: 1, fontWeight: 800 }}>
            Plans ({totalRows}) ({publishedPlansCount}) published
          </Typography>
          {!!totalRows && (
            <Typography>
              {page * plansPerPage + 1} -{" "}
              {Math.min(totalRows, (page + 1) * plansPerPage + 1)} of{" "}
              {totalRows}
            </Typography>
          )}

          <IconButton
            disabled={page === 0 || totalRows === 0}
            onClick={() => setPage((page) => Math.max(0, page - 1))}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            disabled={page === totalPages - 1 || totalRows === 0}
            onClick={() =>
              setPage((page) => Math.min(totalPages - 1, page + 1))
            }
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
      </CardContent>
      <CardContent>
        <DataTable loading={loading} columns={columns} rows={rows} />
      </CardContent>
    </Card>
  );

  function renderIsPublished(plan: Plan) {
    return plan.isPublished ? (
      <CheckCircleIcon style={{ color: "green" }} />
    ) : (
      <CancelIcon style={{ color: "red" }} />
    );
  }

  function renderTags(plan: Plan) {
    return (
      <>
        {plan.tags.map((t, i) => (
          <Chip
            key={i}
            size="small"
            style={{ fontSize: "10px", margin: "2px" }}
            icon={<TagIcon />}
            label={`${t.tagCategory.name}: ${t.name}`}
          />
        ))}
      </>
    );
  }

  function renderLocation(plan: Plan) {
    if (!plan.location) return <></>;

    return (
      <Chip
        key={plan.location.id}
        size="small"
        style={{ fontSize: "10px", margin: "2px" }}
        icon={<LocationOnIcon />}
        label={`${plan.location.nameEn} (${plan.location.nameJa})`}
      />
    );
  }
}

const GET_PLANS_BY_FILTER = gql`
  query GetPlansByFilter(
    $tagIds: [Int!]
    $locationIds: [ID!]
    $allTags: Boolean!
    $allLocations: Boolean!
    $priceMinimum: Int
    $priceMaximum: Int
    $offset: Int
    $limit: Int
  ) {
    plansByFilter(
      filter: {
        tagIds: $tagIds
        locationIds: $locationIds
        allTags: $allTags
        allLocations: $allLocations
        priceMinimum: $priceMinimum
        priceMaximum: $priceMaximum
        offset: $offset
        limit: $limit
      }
    ) {
      totalCount
      totalPublished
      records {
        name
        isPublished
        tags {
          id
          name
          tagCategory {
            name
          }
        }
        location {
          id
          nameEn
          nameJa
        }
      }
    }
  }
`;

interface Plan {
  name: string;
  isPublished: boolean;
  tags: Tag[];
  location: Location;
}

interface Tag {
  id: number;
  name: string;
  tagCategory: TagCategory;
}

interface TagCategory {
  name: string;
}
