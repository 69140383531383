import {
  ImgHTMLAttributes,
  ReactNode,
  useState,
  createContext,
  useContext,
} from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  errorBox: {
    borderColor: theme.palette.error.light,
    borderWidth: 1,
    borderStyle: "solid",
    textAlign: "center",
    fontSize: 10,
  },
}));

export const CloudinaryContext = createContext({
  cloudName: "",
  autoUploadMappingFolder: "",
});

export function CloudinaryProvider({
  children,
  ...props
}: CloudinaryProviderProps) {
  return (
    <CloudinaryContext.Provider value={props}>
      {children}
    </CloudinaryContext.Provider>
  );
}

export function CloudinaryImage({
  src,
  transformations,
  ...props
}: CloudinaryImageProps) {
  const classes = useStyles();
  const { cloudName } = useContext(CloudinaryContext);
  const stringifiedParams = cloudinaryImageDefaultTransformations
    .concat(transformations || [])
    .join(",");

  const [error, setError] = useState<boolean>(false);

  const imageSrc = src
    ?.replace(
      "https://storage.googleapis.com/",
      `https://res.cloudinary.com/${cloudName}/image/upload/${stringifiedParams}/`
    )
    .replace("staging-public-omedetaro", "omedetaro-staging")
    .replace("production-public-omedetaro", "omedetaro");

  return error ? (
    <div
      style={{ height: props.height || 100, width: props.width || 100 }}
      className={classes.errorBox}
    >
      {imageSrc}
    </div>
  ) : (
    <img
      loading="lazy"
      src={imageSrc}
      {...props}
      alt=""
      onError={(e: any) => {
        e.preventDefault();
        setError(true);
      }}
    />
  );
}

export type CloudinaryTransformationParamKey =
  | `w_${number}`
  | `h_${number}`
  | "q_auto"
  | "f_auto";

const cloudinaryImageDefaultTransformations = ["q_auto", "f_auto"];

interface CloudinaryProviderProps {
  cloudName: string;
  autoUploadMappingFolder: string;
  children: ReactNode;
}

interface CloudinaryImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  transformations?: Array<CloudinaryTransformationParamKey>;
}
