import { gql, useMutation } from "@apollo/client";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { default as TF } from "../../../components/TextField";
import { Card, CardHeader, CardContent, Divider, Grid } from "@mui/material";
import { RoleType } from "../../../types";
import { v4 as uuid } from "uuid";
import RoleAccessPermission from "../components/RoleAccessPermission";
import { compareArrays } from "../../../helpers/compare-arrays";
import useStyles from "../styles";

type RoleEditFormProps = {
  role: RoleType | undefined;
  refetch: () => void;
};

export default function RoleEditForm({ role, refetch }: RoleEditFormProps) {
  const classes = useStyles();
  const { showNotification } = useNotifications();
  const [name, setName] = useState<string>(role?.name ?? "");
  const [accessPermission, setAccessPermission] = useState<
    AccessPermisstionType[]
  >([]);
  const [editRole] = useMutation<EditRoleMutationResult, EditRoleMutationInput>(
    EDIT_ROLE
  );
  const roleId = useMemo(() => (role !== undefined ? role.id : uuid()), [role]);

  const handleEditRole = () => {
    if (!name)
      return showNotification({
        message: "Please input a name",
        severity: "error",
      });

    if (accessPermission.length === 0)
      return showNotification({
        message: "Please select access permission.",
        severity: "error",
      });
    const currentAccessPermissions = getDefaultAccessPermisstions(
      role?.accessPermissions
    ).map((i) => i.path);
    const newAccessPermissions = accessPermission.map((i) => i.path);
    console.log(currentAccessPermissions);
    console.log(newAccessPermissions);
    const isChangeRole = !compareArrays(
      newAccessPermissions,
      currentAccessPermissions
    );
    editRole({
      variables: {
        input: {
          id: roleId,
          name: name,
          ...(isChangeRole && { accessPermissions: accessPermission }),
        },
      },
    })
      .then(() => {
        refetch();
        showNotification({
          message: "Role edited successfully.",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Failed to edit role!",
          severity: "error",
        });
      });
  };

  useEffect(() => {
    if (role) {
      setAccessPermission(
        getDefaultAccessPermisstions(role?.accessPermissions)
      );
    }
  }, [role]);

  return (
    <div style={{ paddingBottom: "80px" }}>
      <div>
        <Button style={{ marginBottom: "15px" }} component={Link} to={`/roles`}>
          Back to list
        </Button>
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "24px" }}>
        <CardHeader title={"Access permissions"} />
        <Divider />
        <CardContent style={{ padding: "16px" }}>
          <Grid container spacing={1}>
            <RoleAccessPermission
              accessPermission={accessPermission}
              setAccessPermission={setAccessPermission}
            />
          </Grid>
        </CardContent>
      </Card>
      <div className={classes.role_detail_button}>
        <Button onClick={handleEditRole}>Edit Role</Button>
      </div>
    </div>
  );
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

function getDefaultAccessPermisstions(
  AccessPermisstions: AccessPermisstionType[] | undefined
) {
  if (!AccessPermisstions) return [];
  return AccessPermisstions.map((item) => ({
    name: item.name,
    path: item.path,
  }));
}

export type AccessPermisstionType = {
  id?: string;
  name: string;
  path: string;
  childrenPaths?: string[];
  group?: string;
};

type EditRoleMutationInput = {
  input: {
    id: string;
    name: string;
    accessPermissions?: AccessPermisstionType[];
  };
};

type EditRoleMutationResult = {
  success: boolean;
  error?: string;
};

const EDIT_ROLE = gql`
  mutation EditRoleMutation($input: EditRoleInput!) {
    editRole(input: $input) {
      success
      error
    }
  }
`;
