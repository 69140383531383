export function FormatMoney({
  amount,
  currencyCode = "JPY",
}: {
  amount: number;
  currencyCode: string;
}) {
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: currencyCode,
  }).format(amount);
}

export default function Money(props: { amount: number; currencyCode: string }) {
  return <span>{FormatMoney(props)}</span>;
}
