import { DateCalendar } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useState } from "react";
import { Day } from "./Day";

type CalenderProps = {
  from: Date | null;
  setFrom: React.Dispatch<React.SetStateAction<string | null>>;
  until: Date | null;
  setUntil: React.Dispatch<React.SetStateAction<string | null>>;
  closePopover: () => void;
};

export default function Calendar(props: CalenderProps) {
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null);

  const { from, until } = props;

  return (
    <DateCalendar
      disableHighlightToday
      value={from}
      onChange={(newDate) => dateChange({ ...props, newDate })}
      slots={{ day: Day }}
      slotProps={{
        day: ({ day }) =>
          ({
            from,
            until,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(day),
            onPointerLeave: () => setHoveredDay(null),
          } as any),
      }}
    />
  );
}

type DateChangeArgs = CalenderProps & { newDate: Date | null };

function dateChange({
  newDate,
  from,
  setFrom,
  until,
  setUntil,
  closePopover,
}: DateChangeArgs) {
  if (!newDate) return;

  const newDateString = format(newDate, "yyyy-MM-dd");

  if (!from || (!!from && !!until)) {
    setFrom(newDateString);
    setUntil(null);
    return;
  }

  if (from <= newDate) {
    setUntil(newDateString);
    closePopover();
  }

  if (from > newDate) {
    setFrom(newDateString);
  }
}
