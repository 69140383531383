import { Card, CardContent, CardHeader } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import TagsSelection from "../../../../components/TagsSelection";
import { Tag } from "../../../../types";

export default function Tags(props: TagsProps) {
  const tags = props.tags.value ? props.tags.value : [];

  return (
    <Card>
      <CardHeader title="Tags" />
      <CardContent>
        <TagsSelection tags={tags} setTags={props.tags.set} />
      </CardContent>
    </Card>
  );
}

interface TagsProps {
  tags: {
    value: Tag[] | undefined;
    set: Dispatch<SetStateAction<Tag[]>>;
  };
}
