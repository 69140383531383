import { Card, CardHeader, CardContent, Theme, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextField from "../../../../../components/TextField";

const useStyles = makeStyles((theme: Theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

export interface Activity {
  contract?: {
    commissionRate?: number;
    onSiteCommissionRate?: number;
  };
}

type ActivityFormProps = {
  activity?: Activity;
  onChange: (activity: Activity) => void;
};

export default function ActivityForm(props: ActivityFormProps) {
  const classes = useStyles();
  const { contract } = props.activity || {};
  const { commissionRate, onSiteCommissionRate } = contract || {};

  return (
    <Card>
      <CardHeader title="Contract" className={classes.cardTitle} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Commission rate"
              type="number"
              value={commissionRate}
              endAdornment="%"
              onChange={(e: any) => {
                props.onChange({
                  contract: {
                    ...contract,
                    commissionRate: Number(e.target.value),
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="On-site Commission rate"
              type="number"
              value={onSiteCommissionRate ? onSiteCommissionRate * 100 : ""}
              endAdornment="%"
              onChange={(e: any) => {
                props.onChange({
                  contract: {
                    ...contract,
                    onSiteCommissionRate: Number(e.target.value / 100),
                  },
                });
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
