import { useParams } from "react-router-dom";
import DataTabs from "../../../../components/DataTabs";
import PendingEditList from "./tabs/PendingEditList";
import HistoryEditList from "./tabs/HistoryEditList";

export default function PartnerEditsPage() {
  const id = useParams().id!;

  const tabs = [
    {
      title: "pending",
      content: <PendingEditList id={id} />,
    },
    {
      title: "history",
      content: <HistoryEditList id={id} />,
    },
  ];

  return <DataTabs tabs={tabs} selectedTab={0} />;
}
