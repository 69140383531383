import { Paper, Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";

type DataTabsProps = {
  tabs: {
    title: string;
    content: JSX.Element;
  }[];
  selectedTab?: number;
  active?: number;
};

export default function DataTabs(props: DataTabsProps) {
  const [selectedTab, setSelectedTab] = useState(props.selectedTab || 0);

  useEffect(() => {
    if (props.active) {
      setSelectedTab(props?.active);
    }
  }, [props.active]);

  return (
    <>
      <Paper style={{ marginBottom: "10px" }}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#c8a063" } }}
          value={selectedTab}
          onChange={(_event: any, newValue: number) => setSelectedTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {props.tabs.map((tab) => (
            <Tab key={tab.title} label={tab.title} />
          ))}
        </Tabs>
      </Paper>
      <div>{props.tabs[selectedTab]?.content}</div>
    </>
  );
}
