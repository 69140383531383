import { Chip, ChipProps, IconButton } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useMemo } from "react";

type DateChipProps = ChipProps;
export function DateChip(props: DateChipProps) {
  return <Chip variant="outlined" size="small" color="primary" {...props} />;
}

type LockableChipProps = DateChipProps & {
  locked: boolean;
  onClickLock: () => void;
};

export function LockableChip({
  locked,
  onClickLock,
  ...chipProps
}: LockableChipProps) {
  const Lock = useMemo(() => (locked ? LockIcon : LockOpenIcon), [locked]);
  return (
    <DateChip
      {...chipProps}
      icon={
        <IconButton
          onClick={onClickLock}
          sx={{ height: "22px", width: "22px" }}
        >
          <Lock sx={{ height: "18px", width: "18px" }} />
        </IconButton>
      }
      color={locked ? "error" : "primary"}
    />
  );
}
