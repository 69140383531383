import { gql, useMutation } from "@apollo/client";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { TextRenderer } from "../components/renderers";
import { CardProps, GetOriginalValueProps } from "../../types";

type CourseMenuCardProps = Partial<CardProps> & {
  activityId: string;
  text: string;
};

const CourseMenuCard = forwardRef((props: CourseMenuCardProps, ref) => {
  const [text, setText] = useState(props.text);

  const [mutation] = useMutation<EditCourseMenuResponse, EditCourseMenuInput>(
    EDIT_COURSE_MENU_MUTATION
  );

  const persistChanges = async () => {
    const input: EditCourseMenuInput["input"] = {
      id: props.activityId,
      courseMenu: text,
    };

    return await mutation({ variables: { input } });
  };

  const getNewValue = () => ({ text });

  useImperativeHandle(ref, () => ({
    persistChanges,
    getNewValue,
  }));

  return (
    <TextRenderer text={text} setText={setText} mutable={!!props.mutable} />
  );
});

const getOriginalValue = ({ data }: GetOriginalValueProps) => ({
  text: data.activities[0].courseMenu,
});

const courseMenuCard = {
  Content: CourseMenuCard,
  getOriginalValue,
};
export default courseMenuCard;

const EDIT_COURSE_MENU_MUTATION = gql`
  mutation EditCourseMenuMutation($input: EditCourseMenuInput!) {
    editCourseMenu(input: $input) {
      courseMenu
    }
  }
`;

type EditCourseMenuResponse = {
  activity: {
    id: string;
  };
};

type EditCourseMenuInput = {
  input: {
    id: string;
    courseMenu: string;
  };
};
