import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Card, CardContent, Grid } from "@mui/material";

import makeStyles from "./styles";
import TextField from "../../../components/TextField";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { Navigate } from "react-router-dom";

export default function ChangePassword() {
  const classes = makeStyles();
  const { showNotification } = useNotifications();
  const [redirect, setRedirect] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD_MUTATION);

  if (redirect) return <Navigate to="/" />;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Card>
          <CardContent>
            <TextField
              type="password"
              style={{ marginTop: "0px" }}
              label="Current password"
              value={currentPassword}
              onChange={(e: TextFieldElement) => {
                setCurrentPassword(e.target.value);
              }}
            />
            <TextField
              type="password"
              label="New password"
              value={newPassword}
              onChange={(e: TextFieldElement) => {
                setNewPassword(e.target.value);
              }}
            />
            <TextField
              type="password"
              label="New password again"
              value={newPasswordAgain}
              onChange={(e: TextFieldElement) => {
                setNewPasswordAgain(e.target.value);
              }}
            />
            <Button className={classes.button} onClick={handleChangePassword}>
              Change password
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  function handleChangePassword() {
    if (currentPassword === "")
      return showErrorNotification("Please input current password");
    if (newPassword === "")
      return showErrorNotification("Please set a new password");
    if (newPassword !== newPasswordAgain)
      return showErrorNotification("New passwords are not the same");

    const localStorageUser = localStorage.getItem("user");
    const user = localStorageUser ? JSON.parse(localStorageUser) : null;

    changePasswordMutation({
      variables: {
        userId: user?.id,
        currentPassword: currentPassword,
        newPassword: newPassword,
      },
    })
      .then(() => {
        showNotification({
          message: "Password successfully changed",
          severity: "success",
        });

        setRedirect(true);
      })
      .catch(() => {
        showNotification({
          message:
            "Changing password failed! (Current password probably incorrect)",
          severity: "error",
        });
      });
  }

  function showErrorNotification(message: string) {
    showNotification({ message: message, severity: "error" });

    return;
  }
}

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation(
    $userId: ID!
    $currentPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      input: {
        userId: $userId
        currentPassword: $currentPassword
        newPassword: $newPassword
      }
    ) {
      success
    }
  }
`;

interface TextFieldElement {
  target: {
    value: string;
  };
}
