import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import { default as TF } from "../../../components/TextField";
import MediaUploader, { MediaType } from "../../../components/MediaUploader";
import * as uuid from "uuid";

export default function AddNewConcierge() {
  const { showNotification } = useNotifications();
  const [conciergeId, setConciergeId] = useState(null);
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [addConcierge] = useMutation(ADD_CONCIERGE);
  const [id, setId] = useState("");
  useEffect(() => setId(uuid.v4()), [imageUrl]);

  if (conciergeId) return <Navigate to={`/concierges/${conciergeId}`} />;

  return (
    <>
      <div>
        <Button
          style={{ marginBottom: "15px" }}
          component={Link}
          to={`/concierges`}
        >
          Back to list
        </Button>
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <MediaUploader
                allowedTypes={[MediaType.Image]}
                height="200px"
                media={imageUrl}
                uploadPath={`concierges/${id}/image`}
                onChangeFunction={(e) => setImageUrl(e || "")}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "200px",
          backgroundColor: "#fff",
          width: "100%",
          padding: "10px 0px 10px 20px",
        }}
      >
        <Button onClick={handleAddNewConcierge}>Add New Concierge</Button>
      </div>
    </>
  );

  function handleAddNewConcierge() {
    if (!name)
      return showNotification({
        message: "Please input a name",
        severity: "error",
      });

    if (!imageUrl)
      return showNotification({
        message: "Please input an imageUrl",
        severity: "error",
      });

    addConcierge({ variables: { name, imageUrl } })
      .then((result) => {
        showNotification({
          message: "New concierge added",
          severity: "success",
        });
        setConciergeId(result.data.addConcierge.conciergeId);
      })
      .catch((error) => {
        showNotification({
          message: `Failed to add new concierge! ${error.message}`,
          severity: "error",
        });
      });
  }
}

function TextField(props: any) {
  return <TF {...props} style={{ marginTop: "0px" }} />;
}

const ADD_CONCIERGE = gql`
  mutation AddConciergeMutation($name: String!, $imageUrl: String!) {
    addConcierge(input: { name: $name, imageUrl: $imageUrl }) {
      success
      conciergeId
    }
  }
`;
