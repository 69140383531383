import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import queryString from "query-string";

import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button2";
import AddIcon from "@mui/icons-material/Add";
import { Template } from "../../../types";

const TEMPLATES_QUERY = gql`
  query TemplatesQuery($offset: Int!, $limit: Int!, $search: String) {
    templates(filter: { offset: $offset, limit: $limit, search: $search }) {
      totalRecords
      records {
        id
        displayName
        overrideDisplayName
        name
        giftmallUrl
      }
    }
  }
`;

export default function ItemsList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );

  useEffect(updateUrl, [page, search, navigate]);

  const { loading, error, data, fetchMore } = useQuery(TEMPLATES_QUERY, {
    variables: { offset: page * pageSize, limit: pageSize, search: search },
    notifyOnNetworkStatusChange: true,
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEditTemplate },
    { name: "ID", field: "id" },
    { name: "Display name", render: renderTemplateDisplayName },
    { name: "URL", render: renderTemplateShopURL },
    // { name: ".", render: renderDeleteItem },
  ];
  const rows = data ? data.templates.records : null;

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        topBarExtras={renderTopBarExtras()}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.templates.totalRecords : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
    </>
  );

  function updateUrl() {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderEditTemplate(template: { id: string }) {
    return (
      <Button
        variant="contained"
        component={Link}
        to={`/items/edit/${template.id}`}
      >
        <EditIcon />
      </Button>
    );
  }

  function renderTemplateDisplayName(template: Template) {
    return (
      <>
        {template.overrideDisplayName ?? template.displayName}
        <br />
        <span style={{ color: "grey" }}>{template.name}</span>
      </>
    );
  }

  function renderTemplateShopURL(template: Template) {
    return (
      <a
        style={{ color: "grey" }}
        href={template.giftmallUrl}
        target="_blank"
        rel="noreferrer"
      >
        {template.giftmallUrl}
      </a>
    );
  }

  function renderTopBarExtras() {
    return (
      <Button
        style={{ backgroundColor: "white", color: "#c8a063" }}
        variant="contained"
        component={Link}
        to="/items/new"
        startIcon={<AddIcon />}
      >
        New
      </Button>
    );
  }
}
