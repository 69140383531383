import { makeStyles } from "@mui/styles";

export default makeStyles({
  container: {
    textAlign: "center",
  },
  loaderDiv: {
    textAlign: "center",
  },
  uploadButton: {
    backgroundColor: "#ffffff",
    color: "#c8a063",
  },
  changeButton: {
    marginBottom: "10px",
  },
  deleteButton: {
    backgroundColor: "#ffffff",
    color: "#e53935",
    marginLeft: "5px",
  },
  image: {
    cursor: "pointer",
  },
});
