import { useParams } from "react-router-dom";
import { useMutation, useQuery, gql, ApolloCache } from "@apollo/client";

import { useNotifications } from "../../../../components/Notification";
import ImageGalleryUploader from "../../../../components/ImageGalleryUploader";
import { ImageType } from "../../../../components/ImageGalleryUploader/index";

export default function Photos() {
  const { showNotification } = useNotifications();
  const { id: venueId } = useParams<{ id: string }>();
  const [saveVenuePhotos] = useMutation(SAVE_PLAN_PHOTOS_MUTATION);
  const { data, loading, error, refetch } = useQuery(PLAN_PHOTOS_QUERY, {
    variables: { id: venueId },
  });
  if (loading) return <>loading...</>;
  if (error) return <>something went wrong</>;

  return (
    <>
      {data.venue.photos.length === 0 ? (
        <div>
          Attention: This venue would be displayed without images. Please upload
          images!
        </div>
      ) : (
        <></>
      )}
      <ImageGalleryUploader
        images={data.venue.photos.map((photo: ImageType) => ({
          id: photo.id,
          category: photo.category,
          imageUrl: photo.imageUrl,
        }))}
        uploadPath={`venues/${venueId}`}
        saveImagesFunction={handleSavePhotos}
        useImageCategory={true}
        gridSizes={{ xs: 12, sm: 6, md: 3 }}
      />
    </>
  );

  function handleSavePhotos(photos: ImageType[]) {
    saveVenuePhotos({
      variables: { input: { id: venueId, photos: photos } },
      update: (cache: ApolloCache<any>, { data: { saveVenuePhotos: _ } }) => {
        cache.writeQuery({
          query: PLAN_PHOTOS_QUERY,
          variables: { id: venueId },
          data: {
            venue: {
              photos: photos,
            },
          },
        });
      },
    })
      .then(() => {
        showNotification({
          message: "Venue photos saved",
          severity: "success",
        });
        refetch(); // Refetch to ensure we have the latest data
      })
      .catch(() =>
        showNotification({
          message: "Saving venue photos failed",
          severity: "error",
        })
      );
  }
}

const PLAN_PHOTOS_QUERY = gql`
  query VenuePhotosQuery($id: ID!) {
    venue(id: $id) {
      photos {
        id
        imageUrl
        category
      }
    }
  }
`;

const SAVE_PLAN_PHOTOS_MUTATION = gql`
  mutation SaveVenuePhotosMutation($input: SaveVenuePhotosInput!) {
    saveVenuePhotos(input: $input) {
      venue {
        id
      }
    }
  }
`;
