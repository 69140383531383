import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import queryString from "query-string";

import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button2";
import { Font } from "../../../types";

const FONTS_QUERY = gql`
  query FontsQuery($offset: Int!, $limit: Int!, $search: String) {
    fonts(filter: { offset: $offset, limit: $limit, search: $search }) {
      totalRecords
      records {
        id
        displayName
        overrideDisplayName
        name
        imageUrl
      }
    }
  }
`;

export default function FontsList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );

  useEffect(updateUrl, [page, search, navigate]);

  const { loading, error, data, fetchMore } = useQuery(FONTS_QUERY, {
    variables: { offset: 0, limit: pageSize, search: search },
    notifyOnNetworkStatusChange: true,
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEditFont },
    { name: "ID", field: "id" },
    { name: "Display name", render: renderFontDisplayName },
    { name: "Preview", render: renderFontURL },
  ];
  const rows = data ? data.fonts.records : null;

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.fonts.totalRecords : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
    </>
  );

  function updateUrl() {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderEditFont(font: { id: string }) {
    return (
      <Button
        variant="contained"
        component={Link}
        to={`/items/fonts/${font.id}`}
      >
        <EditIcon />
      </Button>
    );
  }

  function renderFontDisplayName(font: Font) {
    return (
      <>
        {font.overrideDisplayName ?? font.displayName}
        <br />
        <span style={{ color: "grey" }}>{font.name}</span>
      </>
    );
  }

  function renderFontURL(font: Font) {
    return font.imageUrl.length > 0 ? (
      <img
        style={{
          width: "40px",
          maxHeight: "40px",
          backgroundColor: "lightgrey",
        }}
        src={font.imageUrl}
        alt="font preview"
      />
    ) : (
      <> </>
    );
  }
}
