import { gql, useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { STOREFRONT_URL } from "../../../../app.config";

import { useNotifications } from "../../../../components/Notification";
import { Plan } from "../../../../types";

export default function PublishPlanDialog(props: {
  plan: Plan;
  draftId: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const { showNotification } = useNotifications();
  const [publishPlanMutation] = useMutation<
    PublishPlanResponse,
    PublishPlanInput
  >(PUBLISH_PLAN);

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
          Publishing <b>{props.plan.name}</b>
        </DialogTitle>
        <DialogContent>
          Are you sure you want to publish this version of the plan?
          <br />
          <br />
          Did you check the draft in storefront? If not, follow this{" "}
          <a
            href={`${STOREFRONT_URL}/draft/${props.plan.id}?draftId=${props.draftId}`}
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
          <br />
          This action will release this version of the plan to the customers.{" "}
          <br />
          <br />
          You can undo the change by publishing the previous version.
          <br />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.onClose}>
            No
          </Button>
          <Button color="primary" onClick={publishPlan}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  function publishPlan() {
    publishPlanMutation({
      variables: {
        input: {
          planDraftId: props.draftId,
        },
      },
    })
      .then(() => {
        props.onConfirm();
        showNotification({
          message: `Plan was published successfully`,
          severity: "success",
        });
      })
      .catch((reason: Error) => {
        showNotification({
          message: `Unpublish plan failed! ${reason.message}`,
          severity: "error",
        });
      });

    props.onClose();
  }
}

type PublishPlanInput = {
  input: {
    planDraftId: string;
  };
};

type PublishPlanResponse = {
  publishedAt: string;
};

const PUBLISH_PLAN = gql`
  mutation PublishPlanMutation($input: PublishPlanInput!) {
    publishPlan(input: $input) {
      publishedAt
    }
  }
`;
