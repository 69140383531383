import { Card, CardHeader, CardContent, Divider } from "@mui/material";
import Checkbox from "../../../../components/Checkbox";

export default function PaymentMethods(props: PaymentProps) {
  return (
    <Card>
      <CardHeader title={"Payment Methods"} />
      <Divider />
      <CardContent style={{ padding: "16px" }}>
        <Checkbox
          label="Stripe"
          checked={props.stripe.value}
          onChange={(checked: boolean) => props.stripe.set(checked)}
        />
        <Checkbox
          label="Amazon Pay"
          checked={props.amazonPay.value}
          onChange={(checked: boolean) => props.amazonPay.set(checked)}
        />
        <Checkbox
          label="On-Site Payment"
          checked={props.onSitePayment.value}
          onChange={(checked: boolean) => props.onSitePayment.set(checked)}
        />
      </CardContent>
    </Card>
  );
}

interface PaymentProps {
  stripe: { value: boolean; set: (value: boolean) => void };
  amazonPay: { value: boolean; set: (value: boolean) => void };
  onSitePayment: { value: boolean; set: (value: boolean) => void };
}
